import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Api from '../../../service/Api';
import DataTable from './../../../component/dataTable';
import Icon from './../../../component/icon';
import Loader from './../../../component/loader';
import ProtectedContent from './../../../component/protectedContent';
import { formatPrice, __ } from './../../../utilities/common';

class LastImportedReceipts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      receipts: [],
      loader: false,
    };
  }

  componentDidMount() {
    this.loadReceipts(this.props.cashRegisterId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    var nextId = nextProps.cashRegisterId;
    if (nextId !== this.props.cashRegisterId) {
      this.loadReceipts(nextId);
    }
  }

  loadReceipts(cashRegisterId) {
    // nacitame zoznam adries
    this.setState({ loader: true });
    Api.get(`/user/cash-registers/${cashRegisterId}/translog`, { limit: 5 })
      .then(response => {
        this.setState({ loader: false, receipts: response.receipts });
      })
      .catch(error => {
        this.setState({ loader: false });
      });
  }

  render() {
    return (
      <div className="panel panel-secondary">
        <div className="panel-heading panel-separated">
          {__('Transakčná história')}
        </div>
        <div className="panel-body panel-separated min-height-loader">
          <Loader show={this.state.loader}>
            <DataTable
              cols={[__('Vystavený'), __('Počet položiek'), __('Cena')]}
              rows={this.state.receipts.map(receipt => {
                return [
                  moment(receipt.datetime).format('DD.MM.YYYY HH:mm'),
                  receipt.items.length,
                  formatPrice(receipt.total.final_price_tx, receipt.currency),
                ];
              })}
            />
            <h4
              className={cx('text-center', {
                hidden:
                  this.state.loader === true || this.state.receipts.length > 0,
              })}
            >
              {__('Neboli pridané žiadne paragóny')}
            </h4>
          </Loader>
          <div className="text-right">
            <Link
              to={`/app/cash-register/${this.props.cashRegisterId}/translog`}
              className={cx('btn btn-primary', {
                hidden:
                  this.state.loader === false &&
                  this.state.receipts.length === 0,
              })}
            >
              {__('Zobraziť všetky')}
            </Link>
            <ProtectedContent resource="bm.paragon_import" action="enabled">
              <span>
                <span>&nbsp;</span>
                <Link
                  to={`/app/cash-register/${
                    this.props.cashRegisterId
                  }/translog/add`}
                  className="btn btn-circle btn-primary"
                >
                  <Icon name="plus-math" />
                </Link>
              </span>
            </ProtectedContent>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({}))(LastImportedReceipts);
