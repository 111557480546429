import cx from 'classnames';
import React from 'react';
import { getProp, __ } from '../utilities/common';

const UserForm = props => {
  return (
    <div>
      <fieldset className="fieldset">
        <div
          className={cx('form-group', {
            'has-error': 'forename' in props.errors,
          })}
        >
          <label className="col-sm-4 control-label">{__('Meno')}:</label>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              onChange={props.onChange.bind(null, 'forename')}
              value={getProp(props.user, 'forename')}
              required={false}
            />
          </div>
        </div>
        <div
          className={cx('form-group', {
            'has-error': 'surname' in props.errors,
          })}
        >
          <label className="col-sm-4 control-label">{__('Priezvisko')}:</label>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              onChange={props.onChange.bind(null, 'surname')}
              value={getProp(props.user, 'surname')}
              required={false}
            />
          </div>
        </div>
        <div
          className={cx('form-group', { 'has-error': 'email' in props.errors })}
        >
          <label className="col-sm-4 control-label">{__('Email')}:</label>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              onChange={props.onChange.bind(null, 'email')}
              value={getProp(props.user, 'email')}
              required={false}
            />
          </div>
        </div>
        <div
          className={cx('form-group', {
            'has-error': 'phones' in props.errors,
          })}
        >
          <label className="col-sm-4 control-label">{__('Telefón')}:</label>
          <div className="col-sm-4">
            <input
              type="text"
              className="form-control"
              placeholder={__('(nepovinné)')}
              onChange={props.onChange.bind(null, 'phones')}
              value={getProp(props.user, 'phones')}
            />
          </div>
        </div>
        {!props.isNew && props.roles.length > 1 ? (
          <div
            className={cx('form-group', {
              'has-error': 'company_role' in props.errors,
            })}
          >
            <label className="col-sm-4 control-label">{__('Rola')}:</label>
            <div className="col-sm-4">
              <select
                className="form-control"
                onChange={props.onChange.bind(null, 'company_role')}
                value={getProp(props.user, 'company_role')}
              >
                {props.roles.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : null}
        {props.isNew ? (
          <div
            className={cx('form-group', {
              'has-error': 'password' in props.errors,
            })}
          >
            <label className="col-sm-4 control-label">{__('Heslo')}:</label>
            <div className="col-sm-4">
              <input
                type="text"
                className="form-control"
                onChange={props.onChange.bind(null, 'password')}
                value={getProp(props.user, 'password')}
                required={false}
              />
            </div>
          </div>
        ) : null}
      </fieldset>
    </div>
  );
};

UserForm.defaultProps = {
  user: {},
  isNew: false,
  errors: {},
  roles: [],
};

export default UserForm;
