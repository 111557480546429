import { ACTION } from './actions';

const reducers = (state, action) => {
  let newState;
  switch (action.type) {
    case ACTION.RESET:
      return {
        alert: null,
        selectCashRegister: null,
        user: null,
        filter: {},
        filterShow: false,
        saveCounter: 0,
        query: '',
      };
    case ACTION.SET_ALERT:
      newState = Object.assign({}, state, {
        alert: action.config === null ? null : Object.assign({}, action.config),
      });
      return newState;
    case ACTION.SELECT_CASH_REGISTER:
      newState = Object.assign({}, state, {
        selectedCashRegister:
          action.cashRegister === null
            ? null
            : Object.assign({}, action.cashRegister),
      });
      return newState;
    case ACTION.SET_USER: {
      let user = action.user;

      if (action.user !== null) {
        user = Object.assign({}, action.user);
        user.company = Object.assign({}, action.user.company);
        user.company.address = Object.assign({}, action.user.company.address);
      }

      newState = Object.assign({}, state, {
        user: user,
      });

      return newState;
    }
    case ACTION.SHOW_FILTER:
      newState = Object.assign({}, state, {
        filterShow: action.show,
      });
      return newState;
    case ACTION.SET_FILTER:
      newState = Object.assign({}, state, {
        filter: JSON.parse(JSON.stringify(action.filter)),
      });
      return newState;
    case ACTION.INCREMENT_SAVE_COUNTER:
      newState = Object.assign({}, state, {
        saveCounter: state.saveCounter + 1,
      });
      return newState;
    case ACTION.DECREMENT_SAVE_COUNTER:
      newState = Object.assign({}, state, {
        saveCounter: state.saveCounter - 1,
      });
      return newState;
    case ACTION.SHOW_MENU:
      newState = Object.assign({}, state, {
        menuVisible: true,
      });
      return newState;
    case ACTION.HIDE_MENU:
      newState = Object.assign({}, state, {
        menuVisible: false,
      });
      return newState;
    case ACTION.QUERY_CHANGE:
      newState = Object.assign({}, state, {
        query: action.query,
      });
      return newState;
    default:
      return state;
  }
};

export default reducers;
