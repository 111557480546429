import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getProp, __, createUrl } from '../../utilities/common';

class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      country: this.props.location.query.country || '',
      lang: this.props.location.query.lang || '',
      ecrType: this.props.location.query.ecrType || '',
      cpuVersion: this.props.location.query.cpuVersion || '',
      icmVersion: this.props.location.query.icmVersion || '',
      ecrVersion: this.props.location.query.ecrVersion || '',
    };
  }

  onChangeValue = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = e => {
    e.preventDefault();

    const {
      country,
      lang,
      ecrType,
      cpuVersion,
      icmVersion,
      ecrVersion,
    } = this.state;
    const { parameters } = this.props;

    let filter = {};

    if (country.trim() !== '') {
      filter.country = country;
    }

    if (ecrType.trim() !== '') {
      filter.ecrType = ecrType;
    }

    if (lang.trim() !== '') {
      filter.lang = lang;
    }

    if (cpuVersion.trim() !== '') {
      filter.cpuVersion = cpuVersion;
    }

    if (ecrVersion.trim() !== '') {
      filter.ecrVersion = ecrVersion;
    }

    if (icmVersion.trim() !== '') {
      filter.icmVersion = icmVersion;
    }

    this.context.router.push(createUrl(parameters.baseUrl, { ...filter }));
  };

  render() {
    const {
      country,
      ecrType,
      lang,
      cpuVersion,
      ecrVersion,
      icmVersion,
    } = this.state;
    const { parameters } = this.props;

    return (
      <div>
        <h2 className="side-panel-title">{__('Filter')}</h2>
        <hr />
        <form className="form-horizontal" onSubmit={this.onSubmit}>
          <fieldset className="fieldset fieldset-root">
            <div className="form-group">
              <label className="col-sm-4 control-label">{__('Krajina')}:</label>
              <div className="col-sm-4">
                <select
                  className="form-control"
                  value={country}
                  onChange={this.onChangeValue}
                  name="country"
                >
                  <option value="">{__('všetky')}</option>
                  {getProp(parameters, 'codeLists.country', []).map(
                    (i, index) => {
                      return (
                        <option key={index} value={i}>
                          {i}
                        </option>
                      );
                    },
                  )}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">ECRTYPE:</label>
              <div className="col-sm-4">
                <select
                  className="form-control"
                  value={ecrType}
                  onChange={this.onChangeValue}
                  name="ecrType"
                >
                  <option value="">{__('všetky')}</option>
                  {getProp(parameters, 'codeLists.ecrtype', []).map(
                    (i, index) => {
                      return (
                        <option key={index} value={i}>
                          {i}
                        </option>
                      );
                    },
                  )}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">{__('Jazyk')}:</label>
              <div className="col-sm-4">
                <select
                  className="form-control"
                  value={lang}
                  onChange={this.onChangeValue}
                  name="lang"
                >
                  <option value="">{__('všetky')}</option>
                  {getProp(parameters, 'codeLists.lang', []).map((i, index) => {
                    return (
                      <option key={index} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                {__('CPU verzia')}:
              </label>
              <div className="col-sm-4">
                <select
                  className="form-control"
                  value={cpuVersion}
                  onChange={this.onChangeValue}
                  name="cpuVersion"
                >
                  <option value="">{__('všetky')}</option>
                  {getProp(parameters, 'codeLists.cpuversion', []).map(
                    (i, index) => {
                      return (
                        <option key={index} value={i}>
                          {i}
                        </option>
                      );
                    },
                  )}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                {__('Verzia ECR')}:
              </label>
              <div className="col-sm-4">
                <select
                  className="form-control"
                  value={ecrVersion}
                  onChange={this.onChangeValue}
                  name="ecrVersion"
                >
                  <option value="">{__('všetky')}</option>
                  {getProp(parameters, 'codeLists.ecr_version', []).map(
                    (i, index) => {
                      return (
                        <option key={index} value={i}>
                          {i}
                        </option>
                      );
                    },
                  )}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                {__('Verzia SM')}:
              </label>
              <div className="col-sm-4">
                <select
                  className="form-control"
                  value={icmVersion}
                  onChange={this.onChangeValue}
                  name="icmVersion"
                >
                  <option value="">{__('všetky')}</option>
                  {getProp(parameters, 'codeLists.icm_version', []).map(
                    (i, index) => {
                      return (
                        <option key={index} value={i}>
                          {i}
                        </option>
                      );
                    },
                  )}
                </select>
              </div>
            </div>
            <div className="text-center">
              <button className="btn btn-primary">{__('Nastaviť')}</button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

Filter.contextTypes = {
  router: PropTypes.object,
};

Filter.defaultProps = {};

export default connect(state => ({}))(Filter);
