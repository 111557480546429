import cx from 'classnames';
import React from 'react';
import { getProp, __ } from '../../utilities/common';
import { formatPrice } from './../../utilities/common';

const TaxSummary = props => {
  let taxItems;

  if (props.items !== null) {
    taxItems = generateTaxItems(props.items, props.vatListObj);
  } else if (props.taxItems !== null) {
    taxItems = props.taxItems;
  } else {
    return null;
  }

  return (
    <fieldset
      className={cx('fieldset fieldset-root', {
        hidden: Object.keys(taxItems).length === 0,
      })}
    >
      <legend>{__('Daň')}</legend>
      {Object.keys(taxItems).map(idx => {
        let item = taxItems[idx];
        return (
          <fieldset className="fieldset" key={idx}>
            <legend>
              {__('Daň')} {props.vatListObj[idx].vat_name}
            </legend>
            <div className="row form-group">
              <label className="col-sm-4 control-label">{__('Daň %')}:</label>
              <div className="col-sm-4 font-lg">
                <strong>{item.tax_rate} %</strong>
              </div>
            </div>
            <div className="row form-group">
              <label className="col-sm-4 control-label">
                {__('Suma s DPH')}:
              </label>
              <div className="col-sm-4 font-lg">
                <strong>{formatPrice(item.tax_gross, props.currency)}</strong>
              </div>
            </div>
            <div className="row form-group">
              <label className="col-sm-4 control-label">{__('Základ')}:</label>
              <div className="col-sm-4 font-lg">
                <strong>{formatPrice(item.tax_net, props.currency)}</strong>
              </div>
            </div>
            <div className="row form-group">
              <label className="col-sm-4 control-label">{__('Daň')}:</label>
              <div className="col-sm-4 font-lg">
                <strong>{formatPrice(item.tax_value, props.currency)}</strong>
              </div>
            </div>
          </fieldset>
        );
      })}
    </fieldset>
  );
};

TaxSummary.defaultProps = {
  items: null,
  taxItems: null,
  vatList: [],
};

export default TaxSummary;

export const generateTaxItems = (items, vatListObj) => {
  const taxItems = {};
  let itemsPrice, itemsPriceTx;
  for (const item of items) {
    if (!item.tax_id) {
      continue;
    }

    if (!(item.tax_id in taxItems)) {
      taxItems[item.tax_id] = {
        tax_id: item.tax_id,
        tax_rate: Number(vatListObj[item.tax_id].vat_value) / 100,
        tax_gross: 0,
        tax_net: 0,
        tax_value: 0,
      };
    }

    itemsPrice = Number(getProp(item, 'items_price', 0));
    if (isNaN(itemsPrice)) {
      itemsPrice = 0;
    }

    itemsPriceTx = Number(getProp(item, 'items_price_tx', 0));
    if (isNaN(itemsPriceTx)) {
      itemsPriceTx = 0;
    }

    taxItems[item.tax_id].tax_gross += itemsPriceTx;
    taxItems[item.tax_id].tax_net += itemsPrice;
    taxItems[item.tax_id].tax_value += itemsPriceTx - itemsPrice;
  }

  for (const id in taxItems) {
    if (taxItems.hasOwnProperty(id)) {
      taxItems[id].tax_gross = Number.parseFloat(
        taxItems[id].tax_gross.toFixed(2),
      );
      taxItems[id].tax_net = Number.parseFloat(taxItems[id].tax_net.toFixed(2));
      taxItems[id].tax_value = Number.parseFloat(
        taxItems[id].tax_value.toFixed(2),
      );
    }
  }

  return taxItems;
};
