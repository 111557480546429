import Cookies from 'js-cookie';
import 'whatwg-fetch';

class Api {
  constructor(baseUrl, delay = 0) {
    this.baseUrl = baseUrl;
    this.delay = delay;
  }

  arrayToURL(array) {
    var pairs = [];
    for (var key in array)
      if (array.hasOwnProperty(key))
        pairs.push(
          encodeURIComponent(key) + '=' + encodeURIComponent(array[key]),
        );
    return pairs.join('&');
  }

  get(url, params = {}) {
    let queryParams = this.arrayToURL(params);
    let urlWithQueryParams =
      `${this.baseUrl}${url}` + (queryParams ? '?' + queryParams : '');
    return this.request(urlWithQueryParams);
  }

  post(url, body, contentType = 'application/json') {
    return this.request(`${this.baseUrl}${url}`, {
      method: 'POST',
      body,
      headers: { 'Content-Type': contentType },
    });
  }

  put(url, body) {
    return this.request(`${this.baseUrl}${url}`, {
      method: 'PUT',
      body: body,
    });
  }

  delete(url) {
    return this.request(`${this.baseUrl}${url}`, {
      method: 'DELETE',
    });
  }

  redirect(url) {
    window.location = url;
  }

  request(url, config = {}, checkFor401 = true) {
    const headers = Object.assign(
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      config.headers || {},
    );

    const token = Cookies.get('token');
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    config = Object.assign(config || {}, {
      headers: headers,
    });

    if (!config.headers['Content-Type']) {
      delete config.headers['Content-Type'];
    }

    return fetch(url, config)
      .then(response => {
        if (checkFor401) {
          if (response.status === 401) {
            Cookies.remove('token');
            window.location.href = '/';
          }
        }

        if (response.ok) {
          return response.json();
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch(error => {
        return error.response.json().then(error_details => {
          error.details = error_details;
          throw error;
        });
      })
      .then(json => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(json);
          }, this.delay);
        });
      });
  }
}

export default new Api();
