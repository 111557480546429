import React from 'react';
import { __ } from '../utilities/common';
import FloatingActionButton from './../component/floatingActionButton';
import ProtectedContent from './../component/protectedContent';
import View from './../component/view';

class Stats extends React.Component {
  constructor(props) {
    super(props);
    this.view = null;
  }

  onClickAddView() {
    this.view.addView();
  }

  render() {
    return (
      <div id="content-wrapper">
        <ProtectedContent
          resource="bm.data_evaluation"
          action="enabled"
          showWarning
        >
          <React.Fragment>
            <View defaultId="default" ref={el => (this.view = el)} />
            <FloatingActionButton
              onClick={this.onClickAddView.bind(this)}
              title={__('Pridať pohľad')}
            />
          </React.Fragment>
        </ProtectedContent>
      </div>
    );
  }
}

export default Stats;
