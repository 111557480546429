import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Api from '../service/Api';
import { getProp, __, externalSuccessPage } from '../utilities/common';
import { setAlert } from './../actions';
import Content from './../component/content';
import DataFilter from './../component/dataFilter';
import DataTable from './../component/dataTable';
import FloatingActionButton from './../component/floatingActionButton';
import Icon from './../component/icon';
import Loader from './../component/loader';
import MainHeader from './../component/mainHeader';
import ProtectedContent from './../component/protectedContent';
import App from './app';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getCashRegisterLoader: false,
      cashRegistersList: [],
    };
  }

  componentDidMount() {
    this.loadCashRegisters();
  }

  loadCashRegisters() {
    // nacitame zoznam adries
    this.setState({ getCashRegisterLoader: true });
    Api.get('/user/cash-registers')
      .then(response => {
        if (response.cashregisters.length === 0) {
          this.context.router.push('/external/cash-register/list');
        }

        let registerList = getProp(response, 'cashregisters', []).filter(item => {
            if (item.status === 'inactive') {
                return true;
            }

            return false;
        });

        this.setState({
          getCashRegisterLoader: false,
          cashRegistersList: registerList,
        });
      })
      .catch(response => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Nebolo možné načítať zoznam pokladní'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
        this.setState({ savingLoader: false, getCashRegisterLoader: false });
      });
  }

  onClickSelect(cashRegister) {
    this.setState({ getCashRegisterLoader: true });

    Api.get(`/user/cash-registers/${cashRegister._id}`)
      .then(kasa => {
        Api.get(`/user/cash-registers/${cashRegister._id}/twig_license`)
          .then(license => {
            kasa['twig_activation_key'] = license.twig_activation_key;
            externalSuccessPage(kasa);
          })
          .catch(e => {
            externalSuccessPage(kasa);
          });
      })
      .catch(e => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Nebolo možné načítať detail pokladne'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
        this.setState({ getCashRegisterLoader: false });
      });
  }

  onClickBack() {
    this.context.router.push('/external/dashboard');
  }

  render() {
    return (
      <div id="content-wrapper">
        <MainHeader title={__('Zoznam pokladní')} progress={100} onClickBack="">
          <span
            onClick={App.logout.bind(this, this.context)}
            className="clickable"
          >
            <Icon name="exit" />
          </span>
        </MainHeader>
        <Content>
          <Loader
            show={this.state.getCashRegisterLoader}
            className="min-height-loader-large"
          >
            {!this.state.getCashRegisterLoader ? (
              <DataFilter data={this.state.cashRegistersList}>
                {items => {
                  return (
                    <DataTable
                      cols={[__('ID'), __('Názov'), '']}
                      rows={items.map(cashRegister => {
                        return [
                          cashRegister._id,
                          getProp(cashRegister, 'name', '-'),
                          <span
                            onClick={this.onClickSelect.bind(
                              this,
                              cashRegister,
                            )}
                            className="btn btn-primary-o btn-circle"
                          >
                            <Icon name="checkmark" />
                          </span>,
                        ];
                      })}
                    />
                  );
                }}
              </DataFilter>
            ) : null}
          </Loader>
          <h4
            className={cx('text-center', {
              hidden:
                this.state.getCashRegisterLoader === true ||
                this.state.cashRegistersList.length > 0,
            })}
          >
            {__('Nemáte pridané žiadne pokladne')}
          </h4>

          <ProtectedContent resource="cash_register" action="create">
            <FloatingActionButton
              linkTo="/external/cash-register/add"
              title={__('Pridať pokladňu')}
            />
          </ProtectedContent>
          <div className="cleaner" />
        </Content>
      </div>
    );
  }
}

List.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({}))(List);
