import React from 'react';
import { getProp, __ } from '../../utilities/common';
import { formatPrice } from './../../utilities/common';

const Totals = props => {
  const totals = getTotals(props.items);

  return (
    <fieldset className="fieldset fieldset-root">
      <legend>{__('Suma')}</legend>
      <div className="row form-group">
        <label className="col-sm-4 control-label">
          {__('Celková suma s DPH')}:
        </label>
        <div className="col-sm-4 font-lg">
          <strong>{formatPrice(totals.total_price_tx, props.currency)}</strong>
        </div>
      </div>
      <div className="row form-group">
        <label className="col-sm-4 control-label">
          {__('Celková suma bez DPH')}:
        </label>
        <div className="col-sm-4 font-lg">
          <strong>{formatPrice(totals.total_price, props.currency)}</strong>
        </div>
      </div>
    </fieldset>
  );
};

export const getTotals = items => {
  let total = 0,
    totalTx = 0,
    itemsPrice,
    itemsPriceTx;

  for (const item of items) {
    if (!item.tax_id) {
      continue;
    }

    itemsPrice = Number(getProp(item, 'items_price', 0));
    if (isNaN(itemsPrice)) {
      itemsPrice = 0;
    }

    itemsPriceTx = Number(getProp(item, 'items_price_tx', 0));
    if (isNaN(itemsPriceTx)) {
      itemsPriceTx = 0;
    }

    total += itemsPrice;
    totalTx += itemsPriceTx;
  }

  return {
    total_price_tx: Number.parseFloat(totalTx.toFixed(2)),
    total_price: Number.parseFloat(total.toFixed(2)),
  };
};

export default Totals;
