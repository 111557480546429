import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Api from '../../service/Api';
import { getProp, setProp, __ } from '../../utilities/common';
import { setAlert } from './../../actions';
import Loader from './../../component/loader';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: Object.assign(
        {},
        getProp(this.props.parameters.user, 'company'),
      ),
      savingLoader: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      company: Object.assign({}, getProp(nextProps.parameters.user, 'company')),
    });
  }

  onChangeObjectProp(prop, ev) {
    const obj = setProp(this.state.company, prop, ev.target.value);
    this.setState({ company: obj });
  }

  getObjectProp(prop, defaultValue = '') {
    return getProp(this.state.company, prop, defaultValue);
  }

  onSubmit(ev) {
    ev.preventDefault();
    this.setState({ savingLoader: true });

    const obj = {
      company_name: this.getObjectProp('company_name'),
      regnr: this.getObjectProp('regnr'),
      taxid: this.getObjectProp('taxid'),
      vatid: this.getObjectProp('vatid'),
      address: {
        street: this.getObjectProp('address.street'),
        number: this.getObjectProp('address.number'),
        city: this.getObjectProp('address.city'),
        zip: this.getObjectProp('address.zip'),
        country: this.getObjectProp('address.country'),
      },
    };

    Api.put(`/user/company`, JSON.stringify(obj))
      .then(() => {
        this.setState({ savingLoader: false });
        this.props.parameters.onSuccessCompany(obj);
        this.context.router.push(this.props.returnUrl);
      })
      .catch(error => {
        this.setState({ savingLoader: false });
        if (error.response.status === 400) {
          let msg = '';
          if (error.details.name === 'INVALID_VAT') {
            msg = __('Zadané IčDPH nie je platné');
          } else if (error.details.name === 'VAT_VALIDATION_FAILED') {
            msg = __('Nepodarilo sa overiť IčDPH, skúste registráciu neskôr');
          } else if (error.details.name === 'INVALID_VAT_COMPANY_NAME') {
            msg =
              __('Prekontrolujte prosím názov Vašej spoločnosti. Správne: ') +
              getProp(error.details, 'payload.company_name');
          }

          if (msg) {
            this.props.dispatch(
              setAlert({
                type: 'warning',
                title: msg,
                onConfirm: () => this.props.dispatch(setAlert(null)),
              }),
            );
          }
        } else if (error.response.status === 403) {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Pre úpravu firmy nemáte právo'),
              onConfirm: () => {
                this.props.dispatch(setAlert(null));
              },
            }),
          );
        } else if (error.response.status === 409) {
          if (error.details.name === 'REGNR_ALREADY_EXISTS') {
            this.props.dispatch(
              setAlert({
                type: 'warning',
                title: __('Firma s týmto IČOm už existuje'),
                onConfirm: () => this.props.dispatch(setAlert(null)),
              }),
            );
          }
        } else {
          this.props.dispatch(
            setAlert({
              type: 'error',
              title: __('Pri ukladaní firmy došlo k chybe'),
              onConfirm: () => {
                this.props.dispatch(setAlert(null));
              },
            }),
          );
        }
      });
  }

  render() {
    return (
      <div>
        <h2 className="side-panel-title">{__('Zmena údajov')}</h2>
        <hr />
        <Loader show={this.state.savingLoader}>
          <form className="form-horizontal" onSubmit={this.onSubmit}>
            <fieldset className="fieldset">
              <legend>{__('Údaje o firme')}</legend>
              <div className="form-group">
                <label className="col-sm-4 control-label">{__('Názov')}:</label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('Názov')}
                    onChange={this.onChangeObjectProp.bind(
                      this,
                      'company_name',
                    )}
                    value={this.getObjectProp('company_name')}
                    required={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">{__('IČO')}:</label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('IČO')}
                    onChange={this.onChangeObjectProp.bind(this, 'regnr')}
                    value={this.getObjectProp('regnr')}
                    required={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">{__('DIČ')}:</label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('(nepovinné)')}
                    onChange={this.onChangeObjectProp.bind(this, 'taxid')}
                    value={this.getObjectProp('taxid')}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">{__('IčDPH')}:</label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('(nepovinné)')}
                    onChange={this.onChangeObjectProp.bind(this, 'vatid')}
                    value={this.getObjectProp('vatid')}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="fieldset">
              <legend>{__('Adresa')}</legend>
              <div className="form-group">
                <label className="col-sm-4 control-label">{__('Ulica')}:</label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('Názov')}
                    onChange={this.onChangeObjectProp.bind(
                      this,
                      'address.street',
                    )}
                    value={this.getObjectProp('address.street')}
                    required={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">{__('Číslo')}:</label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('IČO')}
                    onChange={this.onChangeObjectProp.bind(
                      this,
                      'address.number',
                    )}
                    value={this.getObjectProp('address.number')}
                    required={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">{__('Mesto')}:</label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('Mesto')}
                    onChange={this.onChangeObjectProp.bind(
                      this,
                      'address.city',
                    )}
                    value={this.getObjectProp('address.city')}
                    required={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">{__('PSČ')}:</label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('PSČ')}
                    onChange={this.onChangeObjectProp.bind(this, 'address.zip')}
                    value={this.getObjectProp('address.zip')}
                    required={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Krajina')}:
                </label>
                <div className="col-sm-4">
                  <select
                    className="form-control"
                    onChange={this.onChangeObjectProp.bind(
                      this,
                      'address.country',
                    )}
                    value={this.getObjectProp('address.country')}
                  >
                    <option value="SK">{__('Slovensko')}</option>
                    <option value="CZ">{__('Česká republika')}</option>
                  </select>
                </div>
              </div>
            </fieldset>
            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                {__('Uložiť')}
              </button>
            </div>
          </form>
        </Loader>
      </div>
    );
  }
}

Form.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({
  user: state.user,
}))(Form);
