import React from 'react';
import { connect } from 'react-redux';
import Api from '../service/Api';
import { __ } from '../utilities/common';
import { setAlert } from './../actions';
import Loader from './../component/loader';

class PaymentGpwebpay extends React.Component {
  constructor(props, context) {
    super(props);
  }

  componentDidMount() {
    Api.post(`/paygate/gpwebpay${window.location.search}`)
      .then(response => {
        this.props.dispatch(
          setAlert({
            type: 'success',
            title: __('Ďakujeme, zaplatené!'),
            onConfirm: () => {
              this.props.dispatch(setAlert(null));
              window.location.href = '/app/license';
            },
          }),
        );
      })
      .catch(() => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Platba zlyhala'),
            onConfirm: () => {
              this.props.dispatch(setAlert(null));
              window.location.href = '/app/license';
            },
          }),
        );
      });
  }

  render() {
    return (
      <div id="app-container">
        <Loader show={true}>
          <h1 className="text-center">{__('Vaša platba sa spracúva ...')}</h1>
        </Loader>
      </div>
    );
  }
}

export default connect(state => ({}))(PaymentGpwebpay);
