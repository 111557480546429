import cx from 'classnames';
import React from 'react';
import { __ } from '../../../utilities/common';
import Checkbox from './../../../component/form/checkbox';

export class FormGenerator {
  constructor(actionHandler = () => {}, schema = {}) {
    this.actionHandler = actionHandler;
    this.schema = schema;
    this.schemaProperties = this.schema.properties;
    this.ignoreProps = this.schema.ignored;
    this.schemaRequiredProperties = this.schema.required;
    this.values = {};
    this.currentLoggedUser = null;
  }

  generate(values = {}, formError = {}, editing = false) {
    this.values = values;
    const required = this.schemaRequiredProperties;

    if (editing) {
      for (let k in required) {
        if (!required.hasOwnProperty(k)) continue;

        if (required[k] === 'use_pass_plain') {
          required.splice(k, 1);
        }
      }
    } else {
      required.push('use_pass_plain');
    }

    return (
      <div className="form-horizontal">
        <fieldset className="fieldset">
          {Object.keys(this.schemaProperties)
            .filter(
              property =>
                !this.ignoreProps.includes(property) &&
                !this.hiddenProperties.includes(property),
            )
            .map(property => {
              const definition = this.schemaProperties[property];
              return (
                <div
                  key={property}
                  className={cx('form-group', {
                    'has-error': property in formError,
                  })}
                >
                  <label className="col-xs-4 control-label">
                    {__(definition.description)}:
                  </label>
                  <div className="col-lg-6 col-xs-8">
                    {this.generateFormControl(property, values[property], editing)}
                  </div>
                </div>
              );
            })}
        </fieldset>
      </div>
    );
  }

  get hiddenProperties() {
    return [];
  }

  isRequired(property) {
    return this.schemaRequiredProperties.includes(property);
  }

  generateFormControl(property, value, editing = false) {
    const definition = this.schemaProperties[property];
    if ('enum' in definition) {
      // ma moznosti - select, checkbox
      return this.generateSelectControl(property, value, editing);
    } else {
      // nema preddefinovane moznosti - textovy input
      return this.generateInputControl(property, value, editing);
    }
  }

  generateInputControl(property, value, editing = false) {
    let attrs = {};
    if (typeof value !== 'undefined') {
      attrs = { value };
    } else {
      attrs = { value: '' };
    }
    return (
      <input
        className="form-control"
        type="text"
        placeholder={this.isRequired(property) ? null : __('(nepovinné)')}
        onChange={this.onChange.bind(this, property)}
        {...attrs}
      />
    );
  }

  generateSelectControl(property, value, editing = false) {
    const definition = this.schemaProperties[property];
    let attrs = {};
    if ('default' in definition) {
      attrs = { value: definition.default };
    }
    if (typeof value !== 'undefined') {
      attrs = { value };
    }
    if (
      definition.enum.length === 2 &&
      definition.enum[0] === 0 &&
      definition.enum[1] === 1
    ) {
      // Ak mame len dve moznosti a tie moznosti su 1 a 0, sformatujeme ich na checkbox
      const onChange = (property, ev) => {
        this.emitAction('change', {
          property,
          value: ev.target.checked ? 1 : 0,
        });
      };

      return (
        <div className="checkbox">
          <Checkbox
            id={`role-${property}-chk`}
            checked={value === 1}
            onChange={onChange.bind(this, property)}
          />
          <label htmlFor={`role-${property}-chk`}>{__('Áno')}</label>
        </div>
      );
    } else {
      return (
        <select
          className="form-control"
          onChange={this.onChange.bind(this, property)}
          {...attrs}
        >
          {this.generateSelectControlOptions(property)}
        </select>
      );
    }
  }

  generateSelectControlOptions(property, isRequired) {
    const definition = this.schemaProperties[property];
    return definition.enum.map((value, idx) => {
      return (
        <option key={idx} value={value}>
          {value}
        </option>
      );
    });
  }

  onChange(property, ev) {
    this.emitAction('change', {
      property,
      value: ev.target.value,
    });
  }

  emitAction(type, payload) {
    this.actionHandler({
      type,
      payload,
    });
  }

  static factory(
    actionHandler = () => {},
    props = {},
    required = [],
    ignored = [],
  ) {
    return new this(actionHandler, props, required, ignored);
  }
}
