import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import Api from '../../service/Api';
import {getProp, __, setProp} from '../../utilities/common';
import {setAlert} from './../../actions';
import Loader from './../../component/loader';
import cx from 'classnames';

class Form extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ecrGroup: {},
            ecrGroupId: props.params.id,
            loadEcrGroupLoader: false,
            saveEcrGroupLoader: false,
            formErrors: {}
        };
    }

    componentDidMount() {
        const {ecrGroupId} = this.state;

        if (ecrGroupId) {
            this.loadEcrGroup();
        }
    }

    loadEcrGroup = () => {
        const {dispatch} = this.props;
        const {ecrGroupId} = this.state;

        this.setState({
            loadEcrGroupLoader: true
        }, () => {
            Api.get(`/admin/ecrgroups/${ecrGroupId}`)
                .then(response => {
                    const ecrGroup = {
                        id: response._id,
                        name: getProp(response, 'name', ''),
                        ecrSns: getProp(response, 'ecrsns', []).join('\n'),
                    };

                    this.setState({
                        loadEcrGroupLoader: false,
                        ecrGroup
                    });
                })
                .catch(response => {
                    dispatch(setAlert({
                        type: 'error',
                        title: __('Nebolo možné načítať detail ECR skupiny'),
                        onConfirm: () => dispatch(setAlert(null))
                    }));

                    this.setState({
                        loadEcrGroupLoader: false
                    });
                });
        });
    };

    onChangeEcrGroupProp = (prop, e, isCheckbox = false) => {
        const ecrGroup = setProp(this.state.ecrGroup, prop, isCheckbox ? e.target.checked : e.target.value);

        this.setState({
            ecrGroup
        });
    };

    getEcrGroupProp = (prop, defaultValue = '') => {
        const {ecrGroup} = this.state;

        return getProp(ecrGroup, prop, defaultValue);
    };

    onSubmit = (e) => {
        e.preventDefault();

        const {dispatch, parameters} = this.props;
        const {ecrGroupId, ecrGroup} = this.state;
        const name = getProp(ecrGroup, 'name', '');
        const ecrSns = getProp(ecrGroup, 'ecrSns', []);

        const onSuccess = response => {
            this.setState({
                saveEcrGroupLoader: false
            });

            const newEcrGroup = {
                ...ecrGroup,
                name: getProp(response, 'name', ''),
                ecrSns: getProp(response, 'ecrsns', []).join('\n')
            };

            this.setState({
                ecrGroup: newEcrGroup
            });

            this.props.onSuccess(parameters.offset + parameters.limit, 0);

            if (!ecrGroupId) {
                this.context.router.push(this.props.returnUrl);
            }
        };

        const onError = error => {
            this.setState({
                saveEcrGroupLoader: false
            });

            if (error.response.status === 400) {
                if (error.details.name === 'INVALID_SCHEMA') {
                    const paths = getProp(error.details, 'payload.paths', []);

                    for (const path of paths) {
                        const realt_path = path.split('.');

                        formErrors[realt_path[0]] = true;
                    }

                    this.setState({formErrors});
                }

                dispatch(
                    setAlert({
                        type: 'warning',
                        title: __('Nesprávne vyplnená položka'),
                        onConfirm: () => dispatch(setAlert(null)),
                    }),
                );
            } else {
                dispatch(
                    setAlert({
                        type: 'error',
                        title: __('Pri ukladaní nastala neznáma chyba'),
                        onConfirm: () => dispatch(setAlert(null)),
                    }),
                );
            }
        };

        let err = false;
        let formErrors = {};

        this.setState({
            saveEcrGroupLoader: true,
            formErrors
        }, () => {
            if (name.trim() === '') {
                formErrors.name = __('Zadajte názov skupiny');
                err = true;
            }

            if (err) {
                this.setState({
                    saveEcrGroupLoader: false,
                    formErrors
                });

                if (formErrors) {
                    for (let a in formErrors) {
                        if (!formErrors.hasOwnProperty(a)) continue;

                        dispatch(setAlert({
                            type: 'warning',
                            title: formErrors[a],
                            onConfirm: () => dispatch(setAlert(null))
                        }));

                        break;
                    }
                }

                return;
            }

            const obj = {
                name,
                ecrsns: ecrSns.split('\n')
            };

            if (ecrGroupId) {
                Api.put(`/admin/ecrgroups/${ecrGroupId}`, JSON.stringify(obj))
                    .then(onSuccess)
                    .catch(onError);
            } else {
                Api.post('/admin/ecrgroups', JSON.stringify(obj))
                    .then(onSuccess)
                    .catch(onError)
            }
        });
    };

    render() {
        const {saveEcrGroupLoader, loadEcrGroupLoader, formErrors} = this.state;

        return (
            <div>
                <h2 className="side-panel-title">{__('Úprava ECR skupiny')}</h2>
                <hr />
                <Loader show={saveEcrGroupLoader || loadEcrGroupLoader}>
                    <form className="form-horizontal" onSubmit={this.onSubmit}>
                        <fieldset className="fieldset">
                            <div className={cx('form-group', {
                                'has-error': 'name' in formErrors,
                            })}>
                                <label className="col-xs-4 control-label">{__('Názov')}: <span className="text-danger">*</span></label>
                                <div className="col-sm-8 col-xs-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={__('Názov')}
                                        onChange={(e) => {
                                            this.onChangeEcrGroupProp('name', e)
                                        }}
                                        value={this.getEcrGroupProp('name')}
                                    />
                                </div>
                            </div>
                            <div className={cx('form-group', {
                                'has-error': 'ecrsns' in formErrors,
                            })}>
                                <label className="col-xs-4 control-label">{__('Sériové čísla ECR')}:</label>
                                <div className="col-sm-6 col-xs-8">
                                    <textarea
                                        style={{minHeight: 200}}
                                        className="form-control"
                                        placeholder={__('Uveďte každé sériové číslo na jeden riadok. Ak update platí pre každú ECR, uveďte *.')}
                                        onChange={(e) => {
                                            this.onChangeEcrGroupProp('ecrSns', e)
                                        }}
                                        value={this.getEcrGroupProp('ecrSns')}
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary">
                                {__('Uložiť')}
                            </button>
                        </div>
                    </form>
                </Loader>
            </div>
        );
    }
}

Form.contextTypes = {
    router: PropTypes.object
};

Form.defaultProps = {};

export default connect(state => ({}))(Form);
