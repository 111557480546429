import cx from 'classnames';
import React from 'react';
import { __ } from '../../utilities/common';

class Search extends React.Component {
  constructor() {
    super();

    this.state = {
      popupOpened: false,
      focusItemIndex: 0,
      data: [],
    };

    this.inputEl = null;
  }

  onKeyDown(event) {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.setState({
        focusItemIndex:
          (this.state.focusItemIndex + 1) % this.state.data.length,
      });
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.setState({
        focusItemIndex:
          (this.state.focusItemIndex + this.state.data.length - 1) %
          this.state.data.length,
      });
    } else if (event.key === 'Enter') {
      this.selectItem(this.state.focusItemIndex);
    } else if (event.key === 'Escape') {
      this.inputEl.blur();
    }
  }

  selectItem(idx) {
    this.inputEl.blur();
    this.props.onSelect(this.state.data[idx]);
  }

  onFocus() {
    this.setState({ popupOpened: true, focusItemIndex: -1 });
    if (this.props.searchEmptyQuery && !this.state.data.length) {
      this.props.loadData('').then(data => {
        this.setState({ data });
      });
    }
  }

  onBlur(ev) {
    this.setState({ popupOpened: false });
  }

  onMouseEnterItem(idx) {
    this.setState({ focusItemIndex: idx });
  }

  onClickItem(idx) {
    this.selectItem(idx);
  }

  onChangeQuery(e) {
    this.props.onChangeQuery(e.target.value);
    this.props.loadData(e.target.value).then(data => {
      this.setState({ data });
    });
  }

  render() {
    let i = 0;

    return (
      <div className="search-control">
        <input type="hidden" value="something" />
        <input
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
          autoComplete="off"
          ref={element => {
            this.inputEl = element;
          }}
          onChange={this.onChangeQuery.bind(this)}
          onKeyDown={this.onKeyDown.bind(this)}
          value={this.props.value}
          type="text"
          name="search"
          className="form-control"
          placeholder={__('Vyhľadaj...')}
        />
        {this.state.popupOpened ? (
          <div className="search-control-results-wrapper">
            <div className="search-control-results">
              {this.state.data.length === 0 ? (
                this.props.value.length > 1 || this.props.searchEmptyQuery ? (
                  <div className="result-item result-nothing">
                    {__('Žiaľ, nič sme nenašli')}
                  </div>
                ) : (
                  <div className="result-item result-nothing">
                    {__('Napíšte aspoň dva znaky ...')}
                  </div>
                )
              ) : (
                this.state.data.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      onMouseDown={this.onClickItem.bind(this, i)}
                      onMouseEnter={this.onMouseEnterItem.bind(this, i)}
                      className={cx('result-item', {
                        focus: this.state.focusItemIndex === i++,
                      })}
                    >
                      {item.label}
                    </div>
                  );
                })
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

Search.defaultProps = {
  value: '',
  onSelect: () => {},
  onChangeQuery: () => {},
  loadData: Promise.resolve(),
  searchEmptyQuery: false,
};

export default Search;
