import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Api from '../service/Api';
import { getProp, setProp, __ } from '../utilities/common';
import { setAlert } from './../actions';
import GlobalFilterForm from './../component/globalFilterForm';
import ProtectedContent from './../component/protectedContent';
import { getChartName, getChartTypes } from './../utilities/chart';
import { guid } from './../utilities/common';

class ChartForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      descr: '',
      type1: '',
      type2: '',
      type3: '',
      venues: [],
      vats: [],
      cashRegisters: [],
      filters: [{}],
    };

    this.guid = guid();
  }

  componentDidMount() {
    Api.get('/user/venues').then(response => {
      this.setState({ venues: response.venues });
    });

    Api.get('/user/cash-registers').then(response => {
      this.setState({ cashRegisters: response.cashregisters });
    });

    Api.get('/user/setup-globals/vat').then(response => {
      let vats = {};
      response.items.forEach(item => {
        if (!(item.vat_name in vats)) {
          vats[item.vat_name] = [];
        }
        vats[item.vat_name].push(`${item.vat_value / 100}%`);
      });

      let vatsArr = [];
      Object.keys(vats).forEach(vat_name => {
        vatsArr.push({
          id: vat_name,
          values: vats[vat_name].join(', '),
        });
      });

      this.setState({ vats: vatsArr });
    });

    this.init();
  }

  init() {
    // Set defaults
    const filters = this.state.filters;
    filters[0] = this.initFilter(filters[0]);
    this.setState({ filters: filters });

    if (this.props.data !== null) {
      let state = this.state;
      Object.keys(this.props.data).forEach(prop => {
        state[prop] = this.props.data[prop];
      });
      this.setState(state);
    }
  }

  initFilter(filter) {
    return filter;
  }

  save() {
    let name = this.state.name;
    if (!name.trim()) {
      name = getChartName(this.state.type1, this.state.type2, this.state.type3);
    }

    if (this.state.type1 !== 'histogram') {
      this.setState({ filters: [this.state.filters[0]] });
    }

    if (!this.typesSpecified()) {
      this.props.dispatch(
        setAlert({
          type: 'warning',
          title: __('Vyplňte typy grafu'),
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
    } else {
      if (typeof this.props.onSave === 'function') {
        this.props.onSave(null, {
          name: name,
          descr: this.state.descr,
          type1: this.state.type1,
          type2: this.state.type2,
          type3: this.state.type3,
          filters: this.state.filters,
        });
      }
    }
  }

  typesSpecified() {
    const chartTypes = getChartTypes();
    if (!this.state.type1) {
      return false;
    }
    if (chartTypes[this.state.type1].subtypes) {
      if (!this.state.type2) {
        return false;
      }
      if (chartTypes[this.state.type1].subtypes[this.state.type2].subtypes) {
        if (!this.state.type3) {
          return false;
        }
      }
    }
    return true;
  }

  onChangeObjectProp(prop, ev) {
    let value = ev.target.value;
    const state = this.state;
    if (prop === 'type1') {
      if (typeof getChartTypes()[value].subtypes !== 'undefined') {
        state.type2 = Object.keys(getChartTypes()[value].subtypes)[0];

        if (
          typeof getChartTypes()[value].subtypes[state.type2].subtypes !==
          'undefined'
        ) {
          state.type3 = Object.keys(
            getChartTypes()[value].subtypes[state.type2].subtypes,
          )[0];
        } else {
          state.type3 = '';
        }
      } else {
        state.type2 = '';
        state.type3 = '';
      }
    }
    if (prop === 'type2') {
      if (
        typeof getChartTypes()[state.type1].subtypes !== 'undefined' &&
        typeof getChartTypes()[state.type1].subtypes[value].subtypes !==
          'undefined'
      ) {
        state.type3 = Object.keys(
          getChartTypes()[state.type1].subtypes[value].subtypes,
        )[0];
      } else {
        state.type3 = '';
      }
    }
    this.setState(setProp(state, prop, value));
  }

  getObjectProp(prop, defaultValue = '') {
    return getProp(this.state, prop, defaultValue);
  }

  onChangeGlobalFilter(data, idx) {
    const filters = this.state.filters;
    filters[idx] = data;
    this.setState({ filters: filters });
  }

  onSubmit(ev) {
    ev.preventDefault();
    this.save();
  }

  onToggleFilters(idx, ev) {
    const checked = ev.target.checked;
    if (checked) {
      let filters = getProp(this.state.filters, []);
      filters[idx] = {};

      this.setState({ filters: setProp(this.state.filters, '') });
    } else {
      let filters = getProp(this.state.filters, []);
      filters.splice(idx, 1);

      this.setState({ filters: setProp(this.state.filters, '') });
    }
  }

  onClickRemoveFilter(idx) {
    let filters = getProp(this.state, 'filters', []);
    filters.splice(idx, 1);
    this.setState({ filters: filters });
  }

  onClickAddFilter() {
    if (!this.canAddFilter()) {
      return;
    }
    let filters = getProp(this.state, 'filters', []);
    filters.push(this.initFilter({}));
    this.setState({ filters: filters });
  }

  canAddFilter() {
    let filters = getProp(this.state, 'filters', []);
    return filters.length < 2;
  }

  render() {
    try {
      return (
        <div>
          <form className="form-horizontal" onSubmit={this.onSubmit.bind(this)}>
            <fieldset className="fieldset">
              <legend>{__('Základné nastavenie')}</legend>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Názov')}:{' '}
                </label>
                <div className="col-sm-4">
                  <input
                    className="form-control"
                    onChange={this.onChangeObjectProp.bind(this, 'name')}
                    value={this.getObjectProp('name')}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Popis')}:{' '}
                </label>
                <div className="col-sm-4">
                  <input
                    className="form-control"
                    onChange={this.onChangeObjectProp.bind(this, 'descr')}
                    value={this.getObjectProp('descr')}
                    placeholder={__('nepovinné')}
                  />
                </div>
              </div>
              <TypesSelect
                label={__('Druh grafu') + ':'}
                types={getChartTypes()}
                selected={this.getObjectProp('type1', null)}
                onChange={this.onChangeObjectProp.bind(this, 'type1')}
              />
              <TypesSelect
                label={''}
                types={getProp(
                  getChartTypes(),
                  this.getObjectProp('type1') + '.subtypes',
                  null,
                )}
                selected={this.getObjectProp('type2')}
                onChange={this.onChangeObjectProp.bind(this, 'type2')}
              />
              <TypesSelect
                label={''}
                types={getProp(
                  getChartTypes(),
                  this.getObjectProp('type1') +
                    '.subtypes.' +
                    this.getObjectProp('type2') +
                    '.subtypes',
                  null,
                )}
                selected={this.getObjectProp('type3')}
                onChange={this.onChangeObjectProp.bind(this, 'type3')}
              />
            </fieldset>

            {this.props.allowFilters ? (
              <div>
                <fieldset className="fieldset fieldset-root">
                  <legend>{__('Filter')}</legend>
                  <GlobalFilterForm
                    vats={this.state.vats}
                    allVenues={this.state.venues}
                    allCashRegisters={this.state.cashRegisters}
                    data={this.state.filters[0]}
                    onChange={this.onChangeGlobalFilter.bind(this, 0)}
                  />
                </fieldset>

                {this.state.type1 === 'histogram'
                  ? this.state.filters.slice(1).map((item, idx) => {
                      idx = idx + 1;
                      return (
                        <fieldset key={idx} className="fieldset fieldset-root">
                          <legend>
                            {__('Porovnávací filter')} {idx}
                          </legend>
                          <GlobalFilterForm
                            vats={this.state.vats}
                            allVenues={this.state.venues}
                            allCashRegisters={this.state.cashRegisters}
                            data={this.state.filters[idx]}
                            onChange={this.onChangeGlobalFilter.bind(this, idx)}
                          />
                          <div className="text-center">
                            <span
                              className="btn btn-danger-o"
                              onClick={this.onClickRemoveFilter.bind(this, idx)}
                              title={__('Odstrániť porovnávací filter')}
                            >
                              {__('Odstrániť porovnávací filter')}
                            </span>
                          </div>
                        </fieldset>
                      );
                    })
                  : null}

                {this.canAddFilter() && this.state.type1 === 'histogram' ? (
                  <ProtectedContent resource="bm.comparing" action="enabled">
                    <div className="text-center">
                      <button
                        type="button"
                        onClick={this.onClickAddFilter.bind(this)}
                        className="btn btn-secondary"
                      >
                        {__('Pridať porovnávací filter')}
                      </button>
                    </div>
                  </ProtectedContent>
                ) : null}
              </div>
            ) : null}

            <br />

            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                {this.props.data !== null ? __('Uložiť') : __('Pridať graf')}
              </button>
            </div>
          </form>
        </div>
      );
    } catch (e) {
      console.error(e);
    }
  }
}

const TypesSelect = props => {
  if (props.types !== null) {
    return (
      <div className="form-group">
        <label className="col-sm-4 control-label">{props.label}</label>
        <div className="col-sm-4">
          <select
            id="range"
            className="form-control"
            value={props.selected}
            onChange={props.onChange.bind(null)}
          >
            <option value="">{__('Vyberte')}</option>
            {Object.keys(props.types).map(type => {
              return (
                <option key={type} value={type}>
                  {props.types[type].label}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

ChartForm.contextTypes = {
  router: PropTypes.object,
};

ChartForm.defaultProps = {
  allowFilters: true,
  data: null,
};

export default connect(state => ({
  user: state.user,
}))(ChartForm);
