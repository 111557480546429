import cx from 'classnames';
import React from 'react';
import Icon from './../icon';

const TokenGroup = props => {
  return (
    <div className="btn-group token-group" data-toggle="buttons">
      {props.items.map((item, idx) => {
        return (
          <span key={idx} className={cx('btn')}>
            {item.label} &nbsp;
            <span
              onClick={props.onRemove.bind(null, idx, item)}
              className="clickable"
            >
              <Icon name="multiply" className="pull-right" />
            </span>
          </span>
        );
      })}
    </div>
  );
};

export default TokenGroup;
