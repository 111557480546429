import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setProp } from '../../utilities/common';
import { showFilter } from './../../actions';
import ProtectedContent from './../../component/protectedContent';
import SidePanel from './../../component/sidePanel';
import View from './../../component/view';
import GlobalFilter from './../globalFilter';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.filter = null;
  }

  onChangeCashRegister(cashregister) {
    let filter = this.props.filter;
    setProp(filter, 'venue.cashregister', [cashregister]);

    this.filter.onChangeGlobalFilterForm(filter);
    this.filter.save();
  }

  render() {
    return (
      <div id="content-wrapper">
        <ProtectedContent resource="bm.dashboard" action="enabled" showWarning>
          <View
            defaultId="dashboard"
            onChangeCashRegister={this.onChangeCashRegister.bind(this)}
          />
        </ProtectedContent>
        <SidePanel returnUrl="/app/dashboard">{this.props.children}</SidePanel>
        <SidePanel
          show={this.props.filterShow}
          onClose={() => {
            this.props.dispatch(showFilter(false));
          }}
        >
          <GlobalFilter ref={el => (this.filter = el)} />
        </SidePanel>
      </div>
    );
  }
}

Dashboard.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({
  selectedCashRegister: state.selectedCashRegister || null,
  filterShow: state.filterShow,
  filter: state.filter,
}))(Dashboard);
