import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Api from '../../service/Api';
import { getProp, __, createUrl } from '../../utilities/common';
import { setAlert } from './../../actions';
import Loader from './../../component/loader';
import LoadMoreBtn from './../../component/loadMoreBtn';
import DataTable from './../../component/dataTable';
import { BASE_URL, LIMIT } from './list';


class PartnerMembers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      users: [],

      offset: parseInt(props.location.query.offset, 10) || 0,
      nextOffset: null,
      loadedOnce: false
    };
  }

  componentDidMount() {
    this.loadPartnerMembers(this.props.params.id, this.state.offset);
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { location: { query: { offset: nextOffset } }, params: { id: nextId } } = nextProps;
    const { location: { query: { offset } }, params: { id } } = this.props;


    if ((nextOffset && offset !== nextOffset) || id !== nextId) {
      this.loadPartnerMembers(nextId, nextOffset);
    }
  }

  loadPartnerMembers = async (id, offset = 0, loadAll = false) => {
    const { loadedOnce } = this.state;

    this.setState({ isFetching: true });

    let limit = LIMIT;

    if (!loadedOnce) {
      limit = LIMIT + offset;
      offset = 0;
    }

    try {
      const response = await Api.get(`/admin/partners/${id}/users`, { limit, offset });
      this.setState({
        users: parseInt(offset, 10) === 0 ? getProp(response, 'users', []) : [...this.state.users, ...response.users],
        nextOffset: response.next_offset,
        isFetching: false,
        loadedOnce: true,
      });
    } catch {
      this.props.dispatch(
        setAlert({
          type: 'error',
          title: __('Nebolo možné načítať zoznam používateľov partnera'),
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
      this.setState({ isFetching: false });
    };
  }

  handleChangeUserRole = async (item) => {
    this.setState({ isFetching: true });
    const { _id, acl } = item;

    try {
      const updatedUser = await Api.put(`/admin/partners/${this.props.params.id}/users/${_id}`, JSON.stringify({ partner: !getProp(acl, 'partner.__all__', false) }));
      this.setState(state => {

        const { users } = state;

        return {
          isFetching: false,
          users: users.map(user => {
            if (updatedUser && user._id === updatedUser._id) {
              return updatedUser;
            }
            return user;
          })
        };
      });
    } catch (error) {
      console.error(error);
      this.props.dispatch(
        setAlert({
          type: 'error',
          title: __('Nebolo možné upraviť rolu používateľa'),
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
      this.setState({ isFetching: false });
    };
  }

  render() {
    const { users, nextOffset, isFetching } = this.state;

    return (
      <div id="content-wrapper partners">
        <Loader show={isFetching}>
          {(users.length === 0 && !isFetching)
            ?
            <h4 className='text-center'>
              {__('Patrnerovi neprislúchajú žiadny používatelia')}
            </h4>
            :
            <DataTable
              cols={[__("ID"), __('Používateľské meno'), __("Viditeľnosť partnerských pokladníc")]}
              rows={users.map(item => {
                return [
                  item._id,
                  getProp(item, 'username', '-'),
                  <span>
                    <input
                      type="checkbox"
                      id={`item-${item._id}`}
                      onChange={() => this.handleChangeUserRole(item)}
                      checked={getProp(item, 'acl.partner.__all__', false)}
                    />
                  </span>,
                ];
              })}
              classNames={[null, null, 'text-center', 'text-right']}
            />
          }
          <LoadMoreBtn
            nextOffset={nextOffset}
            onClick={() => this.context.router.push(createUrl([BASE_URL, this.props.params.id], {
              offset: nextOffset
            }))}
          />
        </Loader>
        <div className="cleaner" />
      </div >
    );
  }
}

PartnerMembers.contextTypes = {
  router: PropTypes.object
};

export default connect()(PartnerMembers);
