import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Api from '../../service/Api';
import { getProp, setProp, __ } from '../../utilities/common';
import { setAlert } from './../../actions';
import Loader from './../../component/loader';
import UserForm from './../../component/userForm';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: Object.assign({}, props.parameters.user),
      formError: {},
      savingLoader: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ user: Object.assign({}, nextProps.parameters.user) });
  }

  onChangeUser(prop, ev) {
    const formError = this.state.formError;
    if (prop in formError) {
      delete formError[prop];
    }
    this.setState({
      formError: formError,
      user: setProp(this.state.user, prop, ev.target.value),
    });
  }

  onSubmit(ev) {
    ev.preventDefault();
    this.setState({ savingLoader: true });

    const user = {
      forename: getProp(this.state.user, 'forename'),
      surname: getProp(this.state.user, 'surname'),
      email: getProp(this.state.user, 'email'),
    };

    const phone = getProp(this.state.user, 'phones');
    if (typeof phone === 'object') {
      user.phones = phone;
    } else if (phone) {
      user.phones = [phone];
    } else {
      user.phones = [];
    }

    Api.put(`/user/profile`, JSON.stringify(user))
      .then(() => {
        this.setState({ savingLoader: false });
        this.props.parameters.onSuccessProfile(user);
        this.context.router.push(this.props.returnUrl);
      })
      .catch(error => {
        this.setState({ savingLoader: false });
        if (error.response.status === 409) {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('E-mail už existuje'),
              onConfirm: () => {
                this.props.dispatch(setAlert(null));
              },
            }),
          );
        } else {
          this.props.dispatch(
            setAlert({
              type: 'error',
              title: __('Pri ukladaní profilu došlo k chybe'),
              onConfirm: () => {
                this.props.dispatch(setAlert(null));
              },
            }),
          );
        }
      });
  }

  render() {
    return (
      <div>
        <h2 className="side-panel-title">{__('Zmena profilových údajov')}</h2>
        <hr />
        <Loader show={this.state.savingLoader}>
          <form className="form-horizontal" onSubmit={this.onSubmit.bind(this)}>
            <UserForm
              onChange={this.onChangeUser.bind(this)}
              user={this.state.user}
              errors={this.state.formError}
            />

            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                {__('Uložiť')}
              </button>
            </div>
          </form>
        </Loader>
      </div>
    );
  }
}

Form.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({
  user: state.user,
}))(Form);
