import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Icon from './icon';

class SidePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.statusBarEl = null;
  }

  componentDidMount() {
    this.statusBarEl = document.getElementById('status-bar');
  }

  render() {
    const returnUrl = this.props.returnUrl || '/app';

    const returnBack = () => {
      if (typeof this.props.onClose === 'function') {
        this.props.onClose.call(this);
      } else {
        console.log(returnUrl);
        this.context.router.push(returnUrl);
      }
    };

    const isHidden = (() => {
      if (!this.props.show) {
        return true;
      }

      if (!this.props.children) {
        return true;
      }
      return false;
    })();

    if (this.statusBarEl) {
      if (isHidden) {
        this.statusBarEl.classList.remove('hidden-on-mobile');
      } else {
        this.statusBarEl.classList.add('hidden-on-mobile');
      }
    }

    let children = null;
    if (typeof this.props.children !== 'undefined' && this.props.children) {
      if (this.props.children.type === 'div') {
        children = this.props.children;
      } else {
        children = React.cloneElement(this.props.children, {
          onSuccess: this.props.onSuccess || function() {},
          onClose: this.props.onClose || function() {},
          returnUrl: returnUrl,
          parameters: this.props.parameters || {},
        });
      }
    }

    return (
      <div>
        <div id="side-panel" className={cx({ 'sidepanel-hidden': isHidden })}>
          <div className="pull-right">
            {typeof this.props.onClose === 'function' ? (
              <span
                className="clickable"
                onClick={this.props.onClose.bind(this)}
              >
                <Icon name="multiply" className="pull-right icon-xlg" />
              </span>
            ) : (
              <Link to={returnUrl}>
                <Icon name="multiply" className="pull-right icon-xlg" />
              </Link>
            )}
          </div>
          {children}
        </div>
        <div
          id="side-panel-overlay"
          onClick={returnBack}
          className={cx({ clickable: true, 'overlay-hidden': isHidden })}
        />
      </div>
    );
  }
}

SidePanel.defaultProps = {
  show: true,
};

SidePanel.contextTypes = {
  router: PropTypes.object,
};

export default SidePanel;
