import React from 'react';
import { __ } from '../../../utilities/common';
import { ColorPalette } from './../../../component/form/color-palette';
import { FormGenerator } from './formGenerator';

export class DeskFormGenerator extends FormGenerator {
  generateInputControl(property, value, editing = false) {
    if (property === 'des_color') {
      let attrs = {};
      if (typeof value !== 'undefined') {
        attrs = { value };
      }

      const onChange = value => {
        this.emitAction('change', {
          property,
          value,
        });
      };

      return (
        <ColorPalette
          onChange={onChange.bind(this)}
          {...attrs}
          colors={[
            '#FFFFFF',
            '#F44336',
            '#E91E63',
            '#9C27B0',
            '#673AB7',
            '#3F51B5',
            '#2196F3',
            '#03A9F4',
            '#00BCD4',
            '#009688',
            '#4CAF50',
            '#8BC34A',
            '#CDDC39',
            '#FFEB3B',
            '#FFC107',
            '#FF9800',
            '#FF5722',
            '#795548',
            '#9E9E9E',
            '#607D8B',
            '#000000',
          ]}
        />
      );
    } else if (property === 'roo_id') {
      let attrs = {};
      if (typeof value !== 'undefined') {
        attrs = { value };
      }
      return (
        <select
          className="form-control"
          onChange={this.onChange.bind(this, property)}
          {...attrs}
        >
          <option value="" />
          {this.schema.payload.map(item => {
            return (
              <option key={item.roo_id} value={item.roo_id}>
                {item.roo_name}
              </option>
            );
          })}
        </select>
      );
    } else {
      return super.generateInputControl(property, value);
    }
  }

  generateSelectControlOptions(property, isRequired) {
    if (property === 'des_type') {
      return [
        <option key={0} value={1}>
          {__('Stôl')}
        </option>,
        <option key={1} value={2}>
          {__('Stena')}
        </option>,
      ];
    } else {
      return super.generateSelectControlOptions(property, isRequired);
    }
  }
}
