import React from 'react';

const Card = props => {
  return (
    <div className="col-md-4 col-sm-6 col-xs-12">
      <div className="panel">
        <div className="panel-body bg-info">{props.children}</div>
      </div>
    </div>
  );
};

export default Card;
