import React from 'react';
import { __ } from '../utilities/common';
import Icon from './icon';

const LoadMoreBtn = props => {
  if (props.nextOffset === null) {
    return null;
  } else {
    return (
      <div className="text-center" style={{ marginTop: '2em' }}>
        <button
          className="btn btn-secondary-o btn-lg"
          onClick={props.onClick.bind(null)}
        >
          {__('Načítať viac')}
          &nbsp;
          <Icon name="load-more" />
        </button>
      </div>
    );
  }
};

LoadMoreBtn.defaultProps = {
  onClick: () => {
    console.warn('onClick not defined');
  },
  nextOffset: null,
};

export default LoadMoreBtn;
