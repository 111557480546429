import * as Cookies from 'js-cookie';

import langCz from './assets/locale/cz.json';
import langEn from './assets/locale/en.json';
import langSk from './assets/locale/sk.json';

export const locale = {
  lang: Cookies.get('lang') || 'sk',
  translations: null,
};

switch (locale.lang) {
  case 'cz':
    locale.translations = langCz;
    break;
  case 'sk':
    locale.translations = langSk;
    break;
  case 'en':
    locale.translations = langEn;
    break;
  default: {
    if (window.navigator.language === 'en') {
      locale.translations = langEn;
      locale.lang = 'en';
    } else {
      locale.translations = langSk;
    }
  }
}
