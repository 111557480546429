import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import banner from '../assets/img/banner-cloud-platinum-zadarmo.jpg';
import Api from '../service/Api';
import { setAlert } from './../actions';
import Content from './../component/content';
import DataFilter from './../component/dataFilter';
import DataTable from './../component/dataTable';
import Icon from './../component/icon';
import Loader from './../component/loader';
import LoadMoreBtn from './../component/loadMoreBtn';
import MainHeader from './../component/mainHeader';
import SidePanel from './../component/sidePanel';
import { formatPrice, getProp, isOrderPaid, __ } from './../utilities/common';
import { locale } from '../locale';

class License extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingOrders: false,
      orders: [],
      ordersNextOffset: null,
      order: null, // detail of order
      orderById: {},
    };
  }

  componentDidMount() {
    this.loadOrders();
  }

  loadOrders() {
    this.setState({ loadingOrders: true });
    Api.get('/user/orders', { offset: this.state.ordersNextOffset, limit: 100 })
      .then(response => {
        this.setState({
          loadingOrders: false,
          orders: this.state.orders.concat(response.orders),
          ordersNextOffset: response.next_offset,
        });
      })
      .catch(error => {
        this.setState({ loadingOrders: false });
      });
  }

  orderDetail(order) {
    this.setState({ order: order });
  }

  onCloseSidebar() {
    this.setState({ order: null });
  }

  onClickPay(order) {
    this.setState({ loadingOrders: true });
    Api.get(`/user/orders/${order._id}/gpwebpay`)
      .then(response => {
        window.location.href = response.url;
        this.setState({ loadingOrders: false });
      })
      .catch(error => {
        if (error.response.status === 409) {
          this.props.dispatch(
            setAlert({
              type: 'error',
              title: __('Objednávka už bola zaplatená'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        } else {
          this.props.dispatch(
            setAlert({
              type: 'error',
              title: __('Chyba pri platbe za objednávku'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        }
        this.setState({ loadingOrders: false });
      });
  }

  onClickExtendLicense() {
    this.context.buyLicense(this.props.user.license);
  }

  handleDownload = async order => {
    try {
      this.setState(prevState => ({
        ...prevState,
        orderById: {
          ...prevState.orderById,
          [order._id]: {
            isFetching: true,
          },
        },
      }));
      const response = await Api.get(`/user/orders/${order._id}/pdf`);
      this.setState(prevState => ({
        ...prevState,
        orderById: {
          ...prevState.orderById,
          [order._id]: {
            isFetching: false,
          },
        },
      }));
      Api.redirect(response.url);
    } catch (e) {
      this.setState(prevState => ({
        ...prevState,
        orderById: {
          ...prevState.orderById,
          [order._id]: {
            isFetching: false,
          },
        },
      }));
      this.props.dispatch(
        setAlert({
          type: 'error',
          title: __('Stiahnutie súboru zlyhalo'),
          onConfirm: () => {
            this.props.dispatch(setAlert(null));
          },
        }),
      );
    }
  };

  render() {
    const license = this.props.user.license;

    return (
      <div id="content-wrapper">
        <MainHeader
          title={__('Licencia %(name)s').format({ name: license.name })}
          progress={100}
        />
        <Content>
          <div className="text-center">
            <img
              alt="cloud platinum zadarmo"
              src={banner}
              style={{
                maxWidth: '100%',
              }}
            />
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-secondary">
                <div className="panel-heading">{__('Aktuálna licencia')}</div>
                <div className="panel-body">
                  <div className="form-horizontal">
                    <div className="row form-group">
                      <label className="col-xs-12">
                        <h2>
                          {__('Licencia')}{' '}
                          {getProp(this.props.user, 'license.name')}
                        </h2>
                      </label>
                    </div>
                    {getProp(this.props.user, 'license.valid_until') ? (
                      <div className="row form-group">
                        <label className="col-xs-4 control-label">
                          {__('Platná do')}:
                        </label>
                        <div className="col-xs-8 font-lg">
                          <strong>
                            {moment(
                              getProp(this.props.user, 'license.valid_until'),
                            ).format('D.M.Y')}
                          </strong>
                        </div>
                      </div>
                    ) : null}
                    <div className="row form-group">
                      <label className="col-xs-4 control-label">
                        {__('Mesačný poplatok')}:
                      </label>
                      <div className="col-xs-8 font-lg">
                        <strong>
                          {formatPrice(
                            getProp(this.props.user, 'license.price.amount', 0),
                            getProp(this.props.user, 'license.price.currency'),
                          )}
                        </strong>
                      </div>
                    </div>
                    <div className="row form-group">
                      <label className="col-xs-4 control-label">
                        {__('Všeobecné obchodné podmienky')}:
                      </label>
                      <div className="col-xs-8 font-lg">
                        <span
                          href={`${
                            process.env.REACT_APP_API_BASE_URL
                          }/docs/vop/${locale.lang}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {__('zobraziť')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h2 className="content-title">{__('Objednávky')}</h2>

          <Loader show={this.state.loadingOrders}>
            <DataFilter
              data={this.state.orders}
              placeholder={__('Číslo objednávky ...')}
              searchProps={['_id']}
            >
              {items => {
                return (
                  <div>
                    <DataTable
                      cols={[
                        __('Číslo objednávky'),
                        __('Dátum vytvorenia'),
                        __('Dátum zaplatenia'),
                        __('Celková suma'),
                        '',
                      ]}
                      rows={items.map(order => {
                        return [
                          order._id,
                          moment(order.created).format('DD.MM.YYYY HH:mm'),
                          getProp(order, 'payment.date', null) !== null
                            ? moment(order.payment.date).format(
                                'DD.MM.YYYY HH:mm',
                              )
                            : null,
                          formatPrice(
                            order.totalPrice.amount,
                            order.totalPrice.currency,
                          ),
                          <span>
                            {!isOrderPaid(order) ? (
                              <span
                                className="btn btn-primary-o btn-circle"
                                onClick={this.onClickPay.bind(this, order)}
                              >
                                <Icon name="banknotes" />
                              </span>
                            ) : null}
                            &nbsp;
                            <span
                              className="btn btn-default-o btn-circle"
                              onClick={this.orderDetail.bind(this, order)}
                            >
                              <Icon name="bill" />
                            </span>
                          </span>,
                        ];
                      })}
                      classNames={[
                        null,
                        null,
                        null,
                        'text-right font-lg',
                        'text-right',
                      ]}
                    />
                    <h4
                      className={cx('text-center', {
                        hidden:
                          this.state.loadingOrders === true || items.length > 0,
                      })}
                    >
                      {__('Žiadne objednávky')}
                    </h4>
                  </div>
                );
              }}
            </DataFilter>
            <LoadMoreBtn
              nextOffset={this.state.ordersNextOffset}
              onClick={this.loadOrders.bind(this)}
            />
          </Loader>
        </Content>
        <SidePanel
          show={this.state.order !== null}
          onClose={this.onCloseSidebar.bind(this)}
        >
          <OrderDetail
            order={this.state.order}
            onDownload={this.handleDownload}
            downloading={getProp(
              this.state,
              `orderById.${this.state.order &&
                this.state.order._id}.isFetching`,
              false,
            )}
          />
        </SidePanel>
      </div>
    );
  }
}

const OrderDetail = props => {
  const order = props.order;
  if (order === null) {
    return null;
  }

  const handleDownload = () => {
    props.onDownload(order);
  };

  return (
    <div>
      <h2 className="side-panel-title">
        {__('Detail objednávky %(id)s').format({ id: order._id })}
      </h2>
      <hr />
      <form className="form-horizontal">
        <fieldset className="fieldset fieldset-root">
          <legend>{__('Základné údaje')}</legend>
          <div className="form-group">
            <label className="col-xs-4 control-label">
              {__('Vytvorená dňa')}:
            </label>
            <div className="col-xs-8 font-lg">
              <strong>
                {moment(order.created).format('DD.MM.YYYY HH:mm')}
              </strong>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">
              {__('Celková cena')}:
            </label>
            <div className="col-xs-8 font-lg">
              <strong>
                {formatPrice(
                  order.totalPrice.amount,
                  order.totalPrice.currency,
                )}
              </strong>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">
              {order.invoice_number ? __('Fakúra') : __('Predfaktúra')}:
            </label>
            <div className="col-xs-8 font-lg">
              <span
                onClick={handleDownload}
                className="btn btn-primary-o"
                title={__('Stiahnúť')}
              >
                <Icon name="download" /> {__('Stiahnúť')}
              </span>
              {props.downloading && __(' počkajte prosím ...')}
            </div>
          </div>
        </fieldset>
        <fieldset className="fieldset fieldset-root">
          <legend>{__('Položky')}</legend>
          {getProp(order, 'items', []).map((item, idx) => {
            return (
              <fieldset className="fieldset" key={idx}>
                <div className="form-group">
                  <div className="col-xs-8 font-lg">
                    <strong>
                      {item.quantity}x {item.name}
                    </strong>
                  </div>
                  <div className="col-xs-4 text-right">
                    {formatPrice(
                      item.totalPrice.amount,
                      item.totalPrice.currency,
                    )}
                  </div>
                </div>
              </fieldset>
            );
          })}
        </fieldset>
      </form>
    </div>
  );
};

License.contextTypes = {
  buyLicense: PropTypes.func,
};

export default connect(state => ({
  user: state.user,
}))(License);
