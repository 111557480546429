import React from 'react';
import { __ } from '../utilities/common';

class NoMatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <h2>{__('Stránka nenájdená')}</h2>
            <p>
              {__(
                'Mrzí nás to, ale stránka, ktorú hľadáte, nebola nájdená. Uistite sa, že ste zadali správnu adresu, a v prípade pretrvávajúcich problémov nás kontaktujte',
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default NoMatch;
