import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Api from '../service/Api';
import { __ } from '../utilities/common';
import { setAlert } from './../actions';
import Loader from './../component/loader';

class PasswordRecovery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: props.params.token ? true : false,
      token: props.params.token || null,
      email: '',
      password: '',
      password_verify: '',
    };

    if (props.params.token) {
      this.checkToken();
    }

    this.onSubmitEmail = this.onSubmitEmail.bind(this);
    this.onSubmitNewPassword = this.onSubmitNewPassword.bind(this);
  }

  checkToken() {
    Api.get(`/users/restore-password/${this.state.token}`)
      .then(response => {
        this.setState({ email: response.email, loading: false });
      })
      .catch(response => {
        this.props.dispatch(
          setAlert({
            type: 'warning',
            title: __('Vypršala platnosť tokenu pre obnovenie hesla'),
            onConfirm: () => {
              this.context.router.push('/login');
              this.props.dispatch(setAlert(null));
            },
          }),
        );
        this.setState({ loading: false });
      });
  }

  onChangeEmail(ev) {
    this.setState({ email: ev.target.value });
  }

  onChangePassword(ev) {
    this.setState({ password: ev.target.value });
  }

  onChangePasswordVerify(ev) {
    this.setState({ password_verify: ev.target.value });
  }

  onSubmitNewPassword(ev) {
    ev.preventDefault();

    let error = '';
    if (!this.state.password) {
      error = __('Zadajte prosím nové heslo');
    } else if (this.state.password !== this.state.password_verify) {
      error = __('Zadané heslá musia byť rovnaké');
    }

    if (error !== '') {
      this.props.dispatch(
        setAlert({
          type: 'warning',
          title: error,
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
      return;
    }

    this.setState({ loading: true });
    let data = {
      password: this.state.password,
    };
    Api.put(`/users/restore-password/${this.state.token}`, JSON.stringify(data))
      .then(response => {
        this.setState({ loading: false, email: '' });
        this.props.dispatch(
          setAlert({
            type: 'success',
            title: __('Heslo bolo úspešne nastavené'),
            onConfirm: () => {
              this.props.dispatch(setAlert(null));
              this.context.router.push('/login');
            },
          }),
        );
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Pri obnove hesla nastala neznáma chyba'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
      });
  }

  onSubmitEmail(ev) {
    ev.preventDefault();
    this.setState({ loading: true });
    let data = {
      username: this.state.email,
    };
    Api.post('/users/restore-password', JSON.stringify(data))
      .then(response => {
        this.setState({ loading: false, email: '' });
        this.props.dispatch(
          setAlert({
            type: 'success',
            title: __('Email úspešne odoslaný'),
            text: __(
              'Na zadanú emailovú adresu boli odoslané inštrukcie pre zmenu hesla',
            ),
            onConfirm: () => {
              this.props.dispatch(setAlert(null));
            },
          }),
        );
      })
      .catch(error => {
        this.setState({ loading: false });
        if (error.response.status === 404) {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Emailová adresa neexistuje'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        } else {
          this.props.dispatch(
            setAlert({
              type: 'error',
              title: __('Pri obnove hesla nastala neznáma chyba'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        }
      });
  }

  render() {
    return (
      <Loader show={this.state.loading}>
        <div className={cx('container', { hidden: this.state.token !== null })}>
          <div className="forgot-form login-form panel panel-secondary">
            <div className="panel-heading">{__('Obnovenie hesla')}</div>
            <div className="panel-body">
              <form onSubmit={this.onSubmitEmail}>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    value={this.state.email}
                    onChange={this.onChangeEmail.bind(this)}
                    placeholder={__('Email')}
                  />
                </div>
                <div className="clearfix">
                  <Link to="/login" className="btn btn-primary-o">
                    {__('Späť')}
                  </Link>
                  <button type="submit" className="btn btn-primary pull-right">
                    {__('Poslať nové ')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={cx('container', { hidden: this.state.token === null })}>
          <div className="forgot-form panel panel-secondary">
            <div className="panel-heading">
              {__('Obnovenie hesla')} - {this.state.email}
            </div>
            <div className="panel-body">
              <form onSubmit={this.onSubmitNewPassword}>
                <div className="form-group">
                  <input
                    type="password"
                    name="password-recovery-password"
                    className="form-control"
                    autoComplete="off"
                    value={this.state.password}
                    onChange={this.onChangePassword.bind(this)}
                    placeholder={__('Nové heslo')}
                  />
                  <input
                    type="password"
                    name="password-recovery-password2"
                    className="form-control"
                    autoComplete="off"
                    value={this.state.password_verify}
                    onChange={this.onChangePasswordVerify.bind(this)}
                    placeholder={__('Nové heslo znova')}
                  />
                </div>
                <div className="clearfix">
                  <Link to="/login" className="btn btn-primary-o">
                    {__('Späť')}
                  </Link>
                  <button type="submit" className="btn btn-primary pull-right">
                    {__('Nastaviť heslo')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

PasswordRecovery.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({}))(PasswordRecovery);
