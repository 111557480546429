import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Api from '../../service/Api';
import { getProp, __ } from '../../utilities/common';
import { setAlert } from './../../actions';
import Card from './../../component/card';
import Content from './../../component/content';
import DataFilter from './../../component/dataFilter';
import FloatingActionButton from './../../component/floatingActionButton';
import Icon from './../../component/icon';
import Loader from './../../component/loader';
import MainHeader from './../../component/mainHeader';
import SidePanel from './../../component/sidePanel';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getVenuesLoader: true,
      venuesList: [],
    };
  }

  componentDidMount() {
    this.loadVenues();
  }

  onClickDeleteVenue(venue) {
    this.props.dispatch(
      setAlert({
        type: 'warning',
        title: __('Ste si istý?'),
        confirmButtonColor: '#DD6B55',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: __('Áno, zmazať'),
        cancelButtonText: __('Nie, zrušiť'),
        buttonsStyling: false,
        text: __("Naozaj chcete zmazať prevádzku '%(name)s'?").format({
          name: venue.name,
        }),
        onConfirm: () => {
          this.deleteVenue(venue._id);
        },
        onCancel: () => this.props.dispatch(setAlert(null)),
      }),
    );
  }

  deleteVenue(venueId) {
    this.setState({ getVenuesLoader: true });
    Api.delete(`/user/venues/${venueId}`)
      .then(response => {
        // ak bolo zmazanie uspesne, tak refreshneme zoznam venues
        this.props.dispatch(
          setAlert({
            type: 'success',
            title: __('Prevádzka bola zmazaná'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
        this.loadVenues();
      })
      .catch(response => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Pri mazaní prevádzky došlo k chybe'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
        this.setState({ getVenuesLoader: false });
      });
  }

  loadVenues() {
    this.setState({ getVenuesLoader: true });
    Api.get('/user/venues')
      .then(response => {
        this.setState({ getVenuesLoader: false, venuesList: response.venues });
      })
      .catch(response => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Nebolo možné načítať zoznam prevádzok'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
        this.setState({ savingLoader: false, getVenuesLoader: false });
      });
  }

  formatOpeningHours(hours) {
    let table = [];

    let closedEveryDay = true;
    for (let day of hours) {
      if (day.hours.length > 0) {
        closedEveryDay = false;
      }
    }

    for (let i in hours) {
      if (hours.hasOwnProperty(i)) {
        let h = hours[i].hours
          .map(item => `${item.from}h - ${item.to}h`)
          .join(', ');
        if (hours[i].hours.length === 0) {
          h = closedEveryDay ? __('otvorené') : __('zatvorené');
        }
        table.push(
          <tr key={i}>
            <td width={1}>
              <strong>
                {moment()
                  .weekday(i)
                  .format('dd')}
              </strong>
            </td>
            <td>{h}</td>
          </tr>,
        );
      }
    }

    return (
      <div>
        <br />
        <h4>{__('Otváracie hodiny:')}</h4>
        <div className="table-responsive">
          <table className="table table-sm">
            <tbody>{table}</tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    let onSuccessSidePanel = () => {
      this.loadVenues();
    };

    return (
      <div id="content-wrapper">
        <MainHeader title={__('Zoznam prevádzok')} progress={100} />
        <Content>
          <Loader show={this.state.getVenuesLoader}>
            <DataFilter data={this.state.venuesList}>
              {items => {
                return (
                  <div>
                    <div className="row row-flex">
                      {items.map(venue => {
                        let address = '-';
                        if (getProp(venue, 'address.street')) {
                          address =
                            getProp(venue, 'address.street') +
                            ' ' +
                            getProp(venue, 'address.number') +
                            ', ' +
                            getProp(venue, 'address.city') +
                            ' ' +
                            getProp(venue, 'address.zip');
                        }
                        return (
                          <Card key={venue._id}>
                            <h3>{venue.name}</h3>
                            <div>
                              {__('Počet pokladní')}:{' '}
                              {getProp(venue, 'cash_register_count', 0)}
                            </div>
                            <div>{address}</div>
                            {venue.supervisor ? (
                              <div>
                                {__('Zodpovedný')}: {venue.supervisor}
                              </div>
                            ) : null}
                            {venue.phone ? (
                              <div>
                                {__('Tel.č.')}: {venue.phone}
                              </div>
                            ) : null}
                            <div>
                              {this.formatOpeningHours(
                                getProp(venue, 'opening_hours', []),
                              )}
                            </div>
                            <div className="text-right">
                              <div>
                                <Link
                                  to={'/app/venue/' + venue._id}
                                  className="btn btn-primary-o btn-circle"
                                >
                                  <Icon name="edit" />
                                </Link>
                                &nbsp;
                                <button
                                  onClick={this.onClickDeleteVenue.bind(
                                    this,
                                    venue,
                                  )}
                                  className="btn btn-danger-o btn-circle"
                                  title={__('Zmazať')}
                                >
                                  <Icon name="remove" />
                                </button>
                              </div>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                    <h4
                      className={cx('text-center', {
                        hidden:
                          this.state.getVenuesLoader === true ||
                          items.length > 0,
                      })}
                    >
                      {__('Žiadne prevádzky')}
                    </h4>
                  </div>
                );
              }}
            </DataFilter>
          </Loader>
          <FloatingActionButton
            linkTo="/app/venue/add"
            title={__('Pridať prevádzku')}
          />
          <div className="cleaner" />
        </Content>
        <SidePanel onSuccess={onSuccessSidePanel} returnUrl="/app/venue">
          {this.props.children}
        </SidePanel>
      </div>
    );
  }
}

export default connect(state => ({}))(List);
