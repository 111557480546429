import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { __ } from '../utilities/common';
import { setAlert } from './../actions';
import Icon from './../component/icon';
import Loader from './../component/loader';

function parseQs(qs) {
  const result = {};
  if (qs.length === 0) {
    return result;
  }
  if (qs[0] === '#') {
    const query = qs.substr(1);
    query.split('&').forEach(part => {
      const item = part.split('=');
      result[decodeURIComponent(item[0])] = decodeURIComponent(item[1]);
    });
  }
  return result;
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: '',
      password: '',
    };
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const params = parseQs(window.location.hash);
    if (!Object.keys(params) === 0) {
      return;
    }

    let state = {};
    try {
      state = JSON.parse(params.state);
    } catch (e) {
      state = {};
    }

    if (state.app === 'EXTERNAL') {
      this.context.router.replace('/external/login');
    }

    if ('error' in params) {
      if (
        params.error === 'UNAUTHORIZED_USER' ||
        params.error === 'WRONG_PASSWORD'
      ) {
        dispatch(
          setAlert({
            type: 'error',
            title: __('Nesprávne prihlasovacie údaje'),
            onConfirm: () => dispatch(setAlert(null)),
          }),
        );
      } else {
        dispatch(
          setAlert({
            type: 'error',
            title: __('Pri prihlasovaní nastala neznáma chyba'),
            onConfirm: () => dispatch(setAlert(null)),
          }),
        );
      }
    } else if (params.access_token) {
      Cookies.set('token', params.access_token);
      if (state.app === 'EXTERNAL') {
        window.location.href = '/external';
      } else {
        window.location.href = '/app/ota-updates';
      }
    }
  }

  onChangeEmail(ev) {
    this.setState({ email: ev.target.value });
  }

  onChangePassword(ev) {
    this.setState({ password: ev.target.value });
  }

  changeLang(lang) {
    Cookies.set('lang', lang);
    if (this.props.route.path === '/external/login') {
      window.location.href = '/external';
    } else {
      window.location.reload();
    }
  }

  render() {
    return (
      <div className="container">
        <div className="login-form">
          <div className="panel panel-secondary">
            <div className="panel-bookmark logo">
              <Icon name="elcom-cloudove-sluzby-biele" />
            </div>
            <div className="panel-body">
              <Loader show={this.state.loading}>
                <form
                  method="POST"
                  action={`${
                    process.env.REACT_APP_API_BASE_URL
                  }/auth/authorize`}
                >
                  <div className="form-group row">
                    <div className="col-xs-2 text-center">
                      <span className="icon-container">
                        <Icon name="custom-zavinac" />
                      </span>
                    </div>
                    <div className="col-xs-10">
                      <input
                        type="email"
                        name="username"
                        className="form-control"
                        placeholder={__('Email')}
                        required
                        onChange={this.onChangeEmail}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-xs-2 text-center">
                      <span className="icon-container">
                        <Icon name="custom-kladka" />
                      </span>
                    </div>
                    <div className="col-xs-10">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder={__('Heslo')}
                        required
                        onChange={this.onChangePassword}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-offset-2 col-xs-10">
                      <Link to="/registration" className="btn btn-primary-o">
                        {__('Registrácia')}
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-primary pull-right"
                      >
                        {__('Prihlásiť')}
                      </button>
                    </div>
                  </div>
                  <input
                    type="hidden"
                    name="redirectUri"
                    value={`${window.location.protocol}//${
                      window.location.host
                    }/login`}
                  />
                  <input type="hidden" name="clientId" value="webapp" />
                  <input
                    type="hidden"
                    name="state"
                    value={JSON.stringify({
                      app:
                        this.props.route.path === '/external/login'
                          ? 'EXTERNAL'
                          : 'APP',
                    })}
                  />
                </form>
              </Loader>
            </div>
            <div className="panel-bookmark recovery">
              <Link to="/password-recovery">
                <span className="icon-container">
                  <Icon name="custom-kladka-otaznik" />
                </span>
                <span className="text-right">{__('Zabudol som heslo')}</span>
              </Link>
            </div>
            <div className="langswitch">
              <span
                className="clickable"
                onClick={this.changeLang.bind(this, 'cz')}
              >
                CZ
              </span>
              |
              <span
                className="clickable"
                onClick={this.changeLang.bind(this, 'sk')}
              >
                SK
              </span>
              |
              <span
                className="clickable"
                onClick={this.changeLang.bind(this, 'en')}
              >
                EN
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({}))(Login);
