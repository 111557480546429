import React from 'react';
import { __ } from '../../utilities/common';
import CashRegisterList from './list';

class RemoteAccessList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <CashRegisterList
        headerText={__('Vzdialená správa')}
        remoteAccess={true}
      />
    );
  }
}

export default RemoteAccessList;
