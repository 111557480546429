import React from 'react';

const Content = props => {
  return (
    <div className="container-fluid container-wrapper">
      {props.children}
      <div id="roztahovac" />
    </div>
  );
};

export default Content;
