import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Api from '../service/Api';
import { getProp, __ } from '../utilities/common';
import { setAlert, setUser } from './../actions';
import Card from './../component/card';
import Content from './../component/content';
import Icon from './../component/icon';
import Loader from './../component/loader';
import MainHeader from './../component/mainHeader';
import ProtectedContent from './../component/protectedContent';
import SidePanel from './../component/sidePanel';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: {},
      loadingUsers: false,
    };
  }

  componentDidMount() {
    this.loadAll();
  }

  loadAll() {
    this.setState({ loadingUsers: true });
    Api.get('/user/company/users').then(response => {
      const users = {};
      for (const user of response.users) {
        if (!(user.company_role in users)) {
          users[user.company_role] = [];
        }
        if (user._id === this.props.user._id) {
          users[user.company_role].unshift(user);
        } else {
          users[user.company_role].push(user);
        }
      }
      this.setState({ users: users, loadingUsers: false });
    });
  }

  onClickDeleteUser(user) {
    this.props.dispatch(
      setAlert({
        type: 'warning',
        title: __('Ste si istý?'),
        confirmButtonColor: '#DD6B55',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: __('Áno, odstrániť'),
        cancelButtonText: __('Nie'),
        buttonsStyling: false,
        text: __("Naozaj chcete odstrániť používateľa '%(name)s'?").format({
          name: `${user.forename} ${user.surname}`,
        }),
        onConfirm: () => {
          Api.delete(`/user/company/users/${user._id}`)
            .then(() => {
              this.loadAll();
              this.props.dispatch(setAlert(null));
            })
            .catch(() => {
              this.props.dispatch(setAlert(null));
            });
        },
        onCancel: () => this.props.dispatch(setAlert(null)),
      }),
    );
  }

  render() {
    const owners = getProp(this.state.users, 'owner', []);
    const managers = getProp(this.state.users, 'manager', []);
    const employees = getProp(this.state.users, 'employee', []);

    return (
      <div id="content-wrapper">
        <MainHeader title={__('Moja firma')} progress={100} />
        <Content>
          <div className="panel panel-secondary">
            <div className="panel-heading">{__('Údaje o firme')}</div>
            <div className="panel-body row">
              <div className="col-xs-6">
                <strong className="font-lg">
                  {getProp(this.props.user, 'company.company_name')}
                </strong>
                <br />
                {__('IČO')}: {getProp(this.props.user, 'company.regnr')}
                <br />
                {__('DIČ')}: {getProp(this.props.user, 'company.taxid')}
                <br />
                {__('IčDPH')}: {getProp(this.props.user, 'company.vatid')}
              </div>
              <div className="col-xs-6">
                {getProp(this.props.user, 'company.address.street')}{' '}
                {getProp(this.props.user, 'company.address.number')}
                <br />
                {getProp(this.props.user, 'company.address.zip')}{' '}
                {getProp(this.props.user, 'company.address.city')}
                <br />
                {getProp(this.props.user, 'company.address.country')}
              </div>
              <div className="col-xs-12 text-center">
                <Link
                  to="/app/profile/edit-company"
                  className="btn btn-primary-o"
                >
                  {__('Zmena firemných údajov')}
                </Link>
              </div>
            </div>
          </div>

          <Loader show={this.state.loadingUsers}>
            <div>
              <h2 className="content-title">{__('Administrátori')}</h2>
              <div className="row row-flex">
                {owners.map(user => (
                  <UserCard
                    self={user._id === this.props.user._id}
                    key={user._id}
                    user={user}
                    onClickDelete={this.onClickDeleteUser.bind(this)}
                  />
                ))}
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <ProtectedContent resource="company.owners" action="create">
                    <Link
                      to="/app/user/add/owner"
                      className="content-placeholder"
                    >
                      <Icon name="plus-math" />
                    </Link>
                  </ProtectedContent>
                </div>
              </div>
            </div>

            <div>
              <h2 className="content-title">{__('Manažéri')}</h2>
              <div className="row row-flex">
                {managers.map(user => (
                  <UserCard
                    self={user._id === this.props.user._id}
                    key={user._id}
                    user={user}
                    onClickDelete={this.onClickDeleteUser.bind(this)}
                  />
                ))}
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <ProtectedContent resource="company.managers" action="create">
                    <Link
                      to="/app/user/add/manager"
                      className="content-placeholder"
                    >
                      <Icon name="plus-math" />
                    </Link>
                  </ProtectedContent>
                </div>
              </div>
            </div>

            <div>
              <h2 className="content-title">{__('Zamestnanci')}</h2>
              <div className="row row-flex">
                {employees.map(user => (
                  <UserCard
                    self={user._id === this.props.user._id}
                    key={user._id}
                    user={user}
                    onClickDelete={this.onClickDeleteUser.bind(this)}
                  />
                ))}
                <div className="col-md-4 col-sm-6 col-xs-12">
                  <ProtectedContent
                    resource="company.employees"
                    action="create"
                  >
                    <Link
                      to="/app/user/add/employee"
                      className="content-placeholder"
                    >
                      <Icon name="plus-math" />
                    </Link>
                  </ProtectedContent>
                </div>
              </div>
            </div>
          </Loader>
        </Content>
        <SidePanel
          parameters={{
            user: this.props.user,
            onSuccess: () => {
              this.loadAll();
            },
            onSuccessProfile: newUser => {
              this.props.dispatch(
                setUser(Object.assign(this.props.user, newUser)),
              );
            },
            onSuccessCompany: newCompany => {
              const user = this.props.user;
              user.company = Object.assign({}, user.company, newCompany);
              this.props.dispatch(setUser(user));
            },
          }}
          returnUrl="/app/profile"
        >
          {this.props.children}
        </SidePanel>
      </div>
    );
  }
}

const UserCard = props => {
  return (
    <Card>
      <h3>
        {props.self ? (
          <span className="btn btn-circle bg-info">
            <Icon name="gender-neutral-user" />
          </span>
        ) : null}{' '}
        {props.user.forename} {props.user.surname}
      </h3>
      <div>{props.user.email}</div>
      <div>{getProp(props.user, 'phones', [''])[0]}</div>
      <div className="text-right">
        <div>
          <a
            href={`mailto:${props.user.email}`}
            target="_top"
            className={cx('btn btn-circle btn-primary-o', {
              hidden: props.self,
            })}
            title={__('Poslať email')}
          >
            <Icon name="email" />
          </a>
          <ProtectedContent
            resource={`company.${getProp(
              props.user,
              'company_role',
              'owner',
            )}s`}
            action="update"
          >
            <span>
              {props.self ? (
                <span>
                  <Link
                    to="/app/profile/edit"
                    className="btn btn-primary-o btn-circle"
                  >
                    <Icon name="edit" />
                  </Link>
                  <span>&nbsp;</span>
                  <Link to="/app/profile/passwd" className="btn btn-primary-o">
                    {__('Zmena hesla')}
                  </Link>
                </span>
              ) : (
                <span>
                  &nbsp;
                  <Link
                    to={`/app/user/edit/${props.user._id}`}
                    className="btn btn-circle btn-primary-o"
                    title={__('Zmena údajov')}
                  >
                    <Icon name="edit" />
                  </Link>
                  &nbsp;
                  <span
                    className="btn btn-circle btn-danger-o"
                    title={__('Odstrániť')}
                    onClick={props.onClickDelete.bind(null, props.user)}
                  >
                    <Icon name="remove" />
                  </span>
                </span>
              )}
            </span>
          </ProtectedContent>
        </div>
      </div>
    </Card>
  );
};

export default connect(state => ({
  user: state.user,
}))(Profile);
