import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Api from '../../service/Api';
import { getProp, __ } from '../../utilities/common';
import Content from './../../component/content';
import MainHeader from './../../component/mainHeader';
import { setAlert } from '../../actions';
import Icon from './../../component/icon';
import SidePanel from './../../component/sidePanel';
import Loader from './../../component/loader';
import DataTable from '../../component/dataTable';
import moment from 'moment';

class Detail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      otaUpdate: null,
      otaUpdateId: props.params.id,
      loadOtaUpdateLoader: false,
      ecrs: [],
      loadEcrsLoader: false,
    };
  }

  componentDidMount() {
    this.loadOtaUpdate();
    this.loadEcrs();
  }

  loadOtaUpdate = () => {
    const { otaUpdateId } = this.state;
    const { dispatch } = this.props;

    // nacitame detail aktualizacie
    this.setState({
      loadOtaUpdateLoader: true,
    });

    Api.get(`/admin/otaupdates/${otaUpdateId}`)
      .then(response => {
        this.setState({
          otaUpdate: response,
          loadOtaUpdateLoader: false,
        });
      })
      .catch(response => {
        dispatch(
          setAlert({
            type: 'error',
            title: __('Nebolo možné načítať detail aktualizácie'),
            onConfirm: () => dispatch(setAlert(null)),
          }),
        );

        this.setState({
          loadOtaUpdateLoader: false,
        });
      });
  };

  loadEcrs = () => {
    const { otaUpdateId } = this.state;
    const { dispatch } = this.props;

    // nacitame detail aktualizacie
    this.setState({
      loadEcrsLoader: true,
    });

    const params = {
      limit: 1000,
      otaupdate: otaUpdateId,
    };

    Api.get(`/admin/ecrs`, params)
      .then(response => {
        this.setState({
          ecrs: getProp(response, 'ecrs', []).reduce((acc, e) => {
            const lastDownloads = getProp(e, 'otaupdate_downloads', []);
            let lastDownload = null;

            for (let d in lastDownloads) {
              if (!lastDownloads.hasOwnProperty(d)) continue;

              const item = lastDownloads[d];

              if (otaUpdateId === item._id) {
                lastDownload = moment(item.last_download);
                break;
              }
            }

            return [
              ...acc,
              {
                id: getProp(e, '_id', ''),
                ecrVersion: getProp(e, 'ecr_version', []),
                icmVersion: getProp(e, 'icm_version', []),
                lastDownload,
              },
            ];
          }, []),
          loadEcrsLoader: false,
        });
      })
      .catch(response => {
        dispatch(
          setAlert({
            type: 'error',
            title: __('Nebolo možné načítať zoznam ECR verzií'),
            onConfirm: () => dispatch(setAlert(null)),
          }),
        );

        this.setState({
          loadEcrsLoader: false,
        });
      });
  };

  onClickBack = () => {
    this.context.router.goBack();
  };

  render() {
    const { sidepanel } = this.props;
    const { otaUpdate, loadOtaUpdateLoader, ecrs, loadEcrsLoader } = this.state;
    const isActive = getProp(otaUpdate, 'active', '');

    const onSuccessSidePanel = () => {
      this.loadOtaUpdate();
    };

    const printDate = input => {
      let output = '';

      if (input !== '') {
        const datetime = moment(input);

        output = datetime.format('DD.MM.YYYY, HH:mm');
      }

      return output;
    };

    return (
      <div id="content-wrapper">
        <MainHeader
          title={__('OTA aktualizácia %(id)s').format({
            id: getProp(otaUpdate, 'filename'),
          })}
          progress={100}
          onClickBack={this.onClickBack}
        />
        <Content>
          <Loader show={loadOtaUpdateLoader}>
            <div className="row">
              <div className="col-md-6">
                <div className={cx('panel panel-secondary')}>
                  <div className="panel-heading panel-separated">
                    {__('Informácie o aktualizácii')}
                  </div>
                  <div
                    className="panel-body panel-separated"
                    style={{
                      position: 'relative',
                    }}
                  >
                    <div className="form-horizontal">
                      <div className="row">
                        <label className="col-xs-4 control-label">
                          {__('Názov')}:
                        </label>
                        <div className="col-xs-8">
                          <div className="form-control-static">
                            <strong>{getProp(otaUpdate, 'filename')}</strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-xs-4 control-label">
                          {__('Krajina')}:
                        </label>
                        <div className="col-xs-8">
                          <div className="form-control-static">
                            <strong>{getProp(otaUpdate, 'country')}</strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-xs-4 control-label">
                          ECRTYPE
                        </label>
                        <div className="col-xs-8">
                          <div className="form-control-static">
                            <strong>{getProp(otaUpdate, 'ecrtype')}</strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-xs-4 control-label">
                          {__('Jazyk')}:
                        </label>
                        <div className="col-xs-8">
                          <div className="form-control-static">
                            <strong>{getProp(otaUpdate, 'lang')}</strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-xs-4 control-label">
                          {__('CPU verzia')}:
                        </label>
                        <div className="col-xs-8">
                          <div className="form-control-static">
                            <strong>{getProp(otaUpdate, 'cpuversion')}</strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-xs-4 control-label">
                          {__('Verzia ECR')}:
                        </label>
                        <div className="col-xs-8">
                          <div className="form-control-static">
                            <strong>
                              {getProp(otaUpdate, 'ecr_version', []).join('.')}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-xs-4 control-label">
                          {__('Min. verzia ECR')}:
                        </label>
                        <div className="col-xs-8">
                          <div className="form-control-static">
                            <strong>
                              {getProp(otaUpdate, 'ecr_version_min', []).join(
                                '.',
                              )}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-xs-4 control-label">
                          {__('Verzia SM')}:
                        </label>
                        <div className="col-xs-8">
                          <div className="form-control-static">
                            <strong>
                              {getProp(otaUpdate, 'icm_version', []).join('.')}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-xs-4 control-label">
                          {__('Min. verzia SM')}:
                        </label>
                        <div className="col-xs-8">
                          <div className="form-control-static">
                            <strong>
                              {getProp(otaUpdate, 'icm_version_min', []).join(
                                '.',
                              )}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-xs-4 control-label">
                          {__('Platné od')}:
                        </label>
                        <div className="col-xs-8">
                          <div className="form-control-static">
                            <strong>
                              {printDate(
                                getProp(otaUpdate, 'availability', ''),
                              )}
                            </strong>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-xs-4 control-label">
                          {__('Stav')}:
                        </label>
                        <div className="col-xs-8">
                          <div className="form-control-static">
                            {isActive === true ? (
                              <div className="text-success">
                                <Icon name="checkmark" />
                              </div>
                            ) : isActive === false ? (
                              <div className="text-danger">
                                <Icon name="cancel" />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-right">
                          <a
                            href={`/app/ota-updates/${getProp(
                              otaUpdate,
                              '_id',
                            )}/detail/edit`}
                            className="btn btn-primary-o btn-circle"
                            onClick={e => {
                              const url = `/app/ota-updates/${getProp(
                                otaUpdate,
                                '_id',
                              )}/detail/edit`;
                              browserHistory.replace(url);
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                          >
                            <Icon name="edit" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="panel panel-secondary">
                  <div className="panel-heading panel-separated">
                    {__('Zoznam ECR')}
                  </div>
                  <div className="panel-body panel-separated min-height-loader">
                    <Loader show={loadEcrsLoader}>
                      <DataTable
                        cols={[
                          'ECRSN',
                          __('Aktuálna verzia ECR/SM'),
                          __('Naposledy stiahnutý update'),
                        ]}
                        rows={ecrs.map(e => {
                          const lastDownload = e.lastDownload;

                          return [
                            e.id,
                            `${e.ecrVersion.join('.')}/${e.icmVersion.join(
                              '.',
                            )}`,
                            lastDownload
                              ? lastDownload.format('DD.MM.YYYY, HH:mm')
                              : null,
                          ];
                        })}
                        classNames={[null, null]}
                      />
                      <h4
                        className={cx('text-center', {
                          hidden:
                            loadOtaUpdateLoader === true || ecrs.length > 0,
                        })}
                      >
                        {__('Žiadne ECR verzie')}
                      </h4>
                    </Loader>
                  </div>
                </div>
              </div>
            </div>
          </Loader>
        </Content>
        {sidepanel ? (
          <SidePanel
            onSuccess={onSuccessSidePanel}
            onClose={() => {
              browserHistory.replace(
                `/app/ota-updates/${getProp(otaUpdate, '_id')}/detail`,
              );
            }}
          >
            {sidepanel}
          </SidePanel>
        ) : null}
      </div>
    );
  }
}

Detail.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({
  // user: state.user
}))(Detail);
