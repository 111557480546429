import React from 'react';
import { getProp, __ } from '../../utilities/common';
import Icon from '../icon';
import ProtectedContent from '../protectedContent';
import { chartErrors } from './../../utilities/chart';
import { getChartStringValue, getChartValue } from './basechart';

const ValueChart = props => {
  let round = 2;
  // chceli zaokruhlovat na ziadne desatine miesto
  round = 0;
  const v = getChartValue(props.value);
  if (v % 1 === 0) {
    round = 0;
  }
  const svalue = getChartStringValue(props.value, round);

  let content = (
    <div>
      <div className="value-icon">
        <Icon
          name={props.icon}
          style={{ position: 'absolute', top: '50%', left: '0px' }}
        />
      </div>
      <div className="value-content">
        {props.title}
        <br />
        <strong>{svalue}</strong>
      </div>
    </div>
  );
  if (props.error === chartErrors().ACL_ERROR) {
    content = (
      <ProtectedContent
        showWarning
        msg={__(
          'Graf nemožno zobraziť, pretože používa filter, ktorý nie je povolený pre Vašu licenciu',
        )}
      />
    );
  } else if (props.error === chartErrors().GENERIC_ERROR) {
    content = (
      <ProtectedContent showWarning msg={__('Graf nemožno zobraziť')} />
    );
  }

  return (
    <div className="panel panel-default value-view">
      <div className="panel-body" style={{ minHeight: 86, paddingTop: 26 }}>
        {content}
      </div>
    </div>
  );
};

export default ValueChart;

export const create = (chart, additionalProps = {}) => {
  let icon = '';
  switch (chart.type2) {
    case 'finalprice':
      icon = 'custom-trzba';
      break;
    case 'finalprice_vat':
      icon = 'custom-trzba-dph';
      break;
    case 'nrofrecords':
      icon = 'custom-zaznamy';
      break;
    case 'nrofitems':
      icon = 'custom-polozky';
      break;
    case 'avgdailynrofrecords':
      icon = 'custom-zaznamy-denne';
      break;
    case 'avgdailyfinalprice':
      icon = 'custom-trzba-denna';
      break;
    case 'avgdailyfinalprice_vat':
      icon = 'custom-trzba-denna-dph';
      break;
    default:
      icon = 'combo-chart';
  }

  return React.createElement(
    ValueChart,
    Object.assign(additionalProps, {
      title: getProp(chart, 'name', ''),
      value: getProp(chart, 'data', { amount: 0, currency: '' }),
      icon,
    }),
  );
};
