import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import banner from '../assets/img/banner-cloud-platinum-zadarmo.jpg';
import Api from '../service/Api';
import { getProp, __ } from '../utilities/common';
import Content from './../component/content';
import Icon from './../component/icon';
import Loader from './../component/loader';
import MainHeader from './../component/mainHeader';

class License extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      licenses: [],
    };
  }

  componentDidMount() {
    Api.get('/licenses')
      .then(response => {
        this.setState({ loading: false, licenses: response.licenses });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  }

  onClickBuyLicense(license) {
    this.buyLicense(license);
  }

  onClickExtendLicense(license) {
    this.buyLicense(license);
  }

  buyLicense(license) {
    this.setState({ loading: true });
    this.context
      .buyLicense(license)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    try {
      const licenses = this.state.licenses;

      const colWidth = (100 - 20) / licenses.length;

      const printAclPermission = (lic, prop) => {
        return getProp(lic, `acl.${prop}.enabled`, false) === true ? (
          <Icon name="checkmark" />
        ) : null;
      };

      return (
        <div id="content-wrapper">
          <MainHeader title={__('Prehľad licencií')} progress={100} />
          <Content>
            <Loader show={this.state.loading}>
              <div className="text-center">
                <img
                  alt="cloud-platinum-zadarmo"
                  src={banner}
                  style={{
                    maxWidth: '100%',
                  }}
                />
              </div>
              <br />
              <div className="table-responsive">
                <table className="table table-licenses">
                  <thead>
                    <tr>
                      <th />
                      {licenses.map((lic, key) => {
                        return (
                          <th
                            style={{ width: `${colWidth}%` }}
                            className={`license-${lic._id}`}
                            key={lic._id}
                          >
                            {lic.name}
                            <div>
                              {[...Array(3)].map((val, idx) => {
                                return (
                                  <Icon
                                    key={idx}
                                    className="star"
                                    name="star"
                                  />
                                );
                              })}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>{__('Remote control')}</th>
                      {licenses.map(lic => {
                        return (
                          <td key={lic._id}>
                            {printAclPermission(lic, 'bm.remote_control')}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        {__('EJ BackUp')}
                        <br />
                        <em>
                          <strong>{__('pripravujeme')}</strong>
                        </em>
                      </th>
                      {licenses.map(lic => {
                        return (
                          <td key={lic._id}>
                            {printAclPermission(lic, 'bm.ej_backup')}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>{__('DB BackUp')}</th>
                      {licenses.map(lic => {
                        return (
                          <td key={lic._id}>
                            {printAclPermission(lic, 'bm.db_backup')}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>{__('File Manager')}</th>
                      {licenses.map(lic => {
                        return (
                          <td key={lic._id}>
                            {printAclPermission(lic, 'bm.file_manager')}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>{__('Dashboard')}</th>
                      {licenses.map(lic => {
                        return (
                          <td key={lic._id}>
                            {printAclPermission(lic, 'bm.dashboard')}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>{__('Synchronization')}</th>
                      {licenses.map(lic => {
                        return (
                          <td key={lic._id}>
                            {printAclPermission(lic, 'bm.erp_synchronisation')}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>{__('Data Evaluation')}</th>
                      {licenses.map(lic => {
                        return (
                          <td key={lic._id}>
                            {printAclPermission(lic, 'bm.data_evaluation')}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>{__('Paragon Import')}</th>
                      {licenses.map(lic => {
                        return (
                          <td key={lic._id}>
                            {printAclPermission(lic, 'bm.paragon_import')}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        {__('Notification Z')}
                        <br />
                        <em>
                          <strong>{__('pripravujeme')}</strong>
                        </em>
                      </th>
                      {licenses.map(lic => {
                        return (
                          <td key={lic._id}>
                            {printAclPermission(lic, 'bm.notification_z')}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>{__('Filters (employee)')}</th>
                      {licenses.map(lic => {
                        return (
                          <td key={lic._id}>
                            {printAclPermission(lic, 'bm.filters_employee')}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        {__('Exports')}
                        <br />
                        <em>
                          <strong>{__('pripravujeme')}</strong>
                        </em>
                      </th>
                      {licenses.map(lic => {
                        return (
                          <td key={lic._id}>
                            {printAclPermission(lic, 'bm.exports')}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>
                        {__('Notification')}
                        <br />
                        <em>
                          <strong>{__('pripravujeme')}</strong>
                        </em>
                      </th>
                      {licenses.map(lic => {
                        return (
                          <td key={lic._id}>
                            {printAclPermission(lic, 'bm.notification')}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th>{__('Comparing')}</th>
                      {licenses.map(lic => {
                        return (
                          <td key={lic._id}>
                            {printAclPermission(lic, 'bm.comparing')}
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </Loader>
          </Content>
        </div>
      );
    } catch (e) {
      console.error(e);
    }
  }
}

License.contextTypes = {
  buyLicense: PropTypes.func,
};

export default connect(state => ({
  user: state.user,
}))(License);
