import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Api from '../../service/Api';
import { getProp, __ } from '../../utilities/common';
import ProtectedContent from '../../component/protectedContent';
import { setAlert } from './../../actions';
import Content from './../../component/content';
import DataTable from './../../component/dataTable';
import Icon from './../../component/icon';
import Loader from './../../component/loader';
import MainHeader from './../../component/mainHeader';
import BackupsList from './dashboard/backupsList';
import LastImportedReceipts from './dashboard/lastImportedReceipts';
import UpdatesList from './dashboard/updatesList';
import { RemoteAccessEntities } from './remoteAccess';

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cashRegister: null,
      cashRegisterId: props.params.id,
      users: {},
      loadingUsers: false,
      foo: 'random string',
      statuses: {},
      licenseStatus: {},
      licenseKey: '',
    };
  }

  componentDidMount() {
    this.loadCashRegisterDetail();
    this.refreshLicenseStatus();
    this.getSyncStatus();
  }

  refreshLicenseStatus() {
    Api.get(`/user/cash-registers/${this.state.cashRegisterId}/twig_license`)
      .then(response => {
        this.setState({ licenseStatus: response });
      })
      .catch(() => {
        this.setState({ licenseStatus: {} });
      });
  }

  loadCashRegisterDetail() {
    Api.get(`/user/cash-registers/${this.state.cashRegisterId}`)
      .then(response => {
        this.setState({ cashRegister: response });
      })
      .catch(response => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Nebolo možné načítať detail pokladne'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
      });
  }

  onClickBack() {
    this.context.router.push('/app/cash-register');
  }

  onClickRemoteAccess() {
    this.context.router.push(
      `/app/cash-register/${this.state.cashRegisterId}/remote-access`,
    );
  }

  getSyncStatus() {
    return Api.get(
      `/user/cash-registers/${this.state.cashRegisterId}/setup/status`,
    ).then(response => {
      this.setState({ statuses: response.status });
    });
  }

  unregisterLicenseKey() {
    return Api.delete(
      `/user/cash-registers/${this.state.cashRegisterId}/twig_license`,
    ).then(() => {
      this.refreshLicenseStatus();
      this.loadCashRegisterDetail();
    });
  }

  registerLicenseKey(key) {
    return Api.post(
      `/user/cash-registers/${this.state.cashRegisterId}/twig_license`,
      JSON.stringify({
        twig_activation_key: key,
      }),
    )
      .then(() => {
        this.refreshLicenseStatus();
        this.loadCashRegisterDetail();
      })
      .catch(response => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Neznámy licenčný kľúč'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
      });
  }

  onClickRegisterLicenseKey() {
    this.registerLicenseKey(this.state.licenseKey);
  }

  onChangeLicenseKey(ev) {
    this.setState({ licenseKey: ev.target.value });
  }

  render() {
    const isActive = getProp(this.state.cashRegister, 'status') === 'active';

    return (
      <div id="content-wrapper">
        <MainHeader
          title={__('Pokladnica %(id)s | %(name)s').format({
            id: getProp(this.state.cashRegister, '_id'),
            name: getProp(this.state.cashRegister, 'name'),
          })}
          progress={100}
          onClickBack={this.onClickBack.bind(this)}
        />
        <Content>
          <div className="row">
            <div className="col-md-6">
              <div className={cx('panel panel-secondary')}>
                <div className="panel-heading panel-separated">
                  {__('Informácie o pokladnici')}
                </div>
                <div
                  className="panel-body panel-separated"
                  style={{ position: 'relative' }}
                >
                  <div
                    title={
                      isActive
                        ? __('Pokladnica je aktívna')
                        : __('Pokladnica nie je aktívna')
                    }
                    className={cx({
                      'bg-success': isActive,
                      'bg-danger': !isActive,
                    })}
                    style={{
                      height: '5px',
                      position: 'absolute',
                      width: '100%',
                      top: 0,
                      left: 0,
                    }}
                  />
                  <div className="form-horizontal">
                    <div className="row">
                      <label className="col-xs-12">
                        <h2>
                          {getProp(
                            this.state.cashRegister,
                            'name',
                            <em>{__('nepomenovaná pokladnica')}</em>,
                          )}
                        </h2>
                      </label>
                    </div>
                    <div className="row">
                      <label className="col-xs-4 control-label">
                        {__('JKP')}:
                      </label>
                      <div className="col-xs-8">
                        <div className="form-control-static">
                          <strong>
                            {getProp(this.state.cashRegister, '_id')}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-xs-4 control-label">
                        {__('Vytvorená dňa')}:
                      </label>
                      <div className="col-xs-8">
                        <div className="form-control-static">
                          <strong>
                            {moment(
                              getProp(this.state.cashRegister, 'created'),
                            ).format('D.M.Y')}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <ProtectedContent
                      resource="cash_register"
                      action="twig_license_key_manage"
                    >
                      {getProp(
                        this.state.licenseStatus,
                        'twig_activation_key',
                        null,
                      ) !== null ? (
                        <div className="row">
                          <label className="col-xs-4 control-label">
                            {__('Licenčný kľúč')}:
                          </label>
                          <div className="col-xs-8">
                            <div className="form-control-static">
                              <strong>
                                {getProp(
                                  this.state.licenseStatus,
                                  'twig_activation_key',
                                )}
                              </strong>{' '}
                              &nbsp;&nbsp;
                              <span
                                
                                className="btn btn-sm btn-danger-o"
                                onClick={this.unregisterLicenseKey.bind(this)}
                              >
                                {__('odpojiť')}
                              </span>
                            </div>
                          </div>
                          {getProp(
                            this.state.licenseStatus,
                            'twig_key_validity',
                            null,
                          ) !== null ? (
                            <div>
                              <label className="col-xs-4 control-label">
                                {__('Platnosť kľúča')}:
                              </label>
                              <div className="col-xs-8">
                                <div className="form-control-static">
                                  <strong>
                                    {moment(
                                      getProp(
                                        this.state.licenseStatus,
                                        'twig_key_validity',
                                      ),
                                    ).format('D.M.Y')}
                                  </strong>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="row">
                          <label className="col-xs-4 control-label">
                            {__('Licenčný kľúč')}:
                          </label>
                          <div className="col-xs-8">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={__('nový licenčný kľúč')}
                                value={this.state.licenseKey}
                                onChange={this.onChangeLicenseKey.bind(this)}
                              />
                              <span className="input-group-btn">
                                <span
                                  
                                  className="btn btn-success-o"
                                  onClick={this.onClickRegisterLicenseKey.bind(
                                    this,
                                  )}
                                >
                                  <Icon name="plus-math" />
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </ProtectedContent>
                    <div className="row">
                      <label className="col-xs-4 control-label">
                        {__('Typ pokladnice')}:
                      </label>
                      <div className="col-xs-8">
                        <div className="form-control-static">
                          <strong>
                            {getProp(this.state.cashRegister, 'type')}{' '}
                            {getProp(
                              this.state.cashRegister,
                              'twig_license_type',
                              null,
                            ) !== null
                              ? `(${this.state.cashRegister.twig_license_type})`
                              : null}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <LastImportedReceipts
                cashRegisterId={this.state.cashRegisterId}
              />

              <UpdatesList cashRegisterId={this.state.cashRegisterId} />
            </div>
            <div className="col-md-6">
              <BackupsList cashRegisterId={this.state.cashRegisterId} />

              <ProtectedContent resource="cash_register" action="setup">
                <div className="panel panel-secondary">
                  <div className="panel-heading panel-separated">
                    {__('Stav synchronizácie')}
                  </div>
                  <div className="panel-body panel-separated min-height-loader">
                    <Loader
                      show={Object.keys(this.state.statuses).length === 0}
                    >
                      <DataTable
                        cols={[__('Typ dát'), __('Stav')]}
                        rows={Object.keys(this.state.statuses)
                          .filter(type => type in RemoteAccessEntities)
                          .map(type => {
                            return [
                              RemoteAccessEntities[type].label,
                              this.state.statuses[type].status === 'DIRTY' ? (
                                <div
                                  className="text-warning"
                                  title={__('Nesynchronizované')}
                                >
                                  <Icon name="attention" />
                                </div>
                              ) : (
                                <div
                                  className="text-success"
                                  title={__('Synchronizované')}
                                >
                                  <Icon name="checkmark" />
                                </div>
                              ),
                            ];
                          })}
                      />
                      <div className="text-right">
                        <Link
                          to={`/app/cash-register/${
                            this.state.cashRegisterId
                          }/remote-access`}
                          className="btn btn-primary"
                        >
                          <Icon name="custom-vzdialena-sprava" />
                          &nbsp;
                          {__('Vzdialená správa')}
                        </Link>
                      </div>
                    </Loader>
                  </div>
                </div>
              </ProtectedContent>
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

View.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({
  user: state.user,
}))(View);
