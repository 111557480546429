import moment from 'moment';
import React from 'react';
import { getProp, __ } from '../../utilities/common';
import { getDateRanges } from './../../utilities/common';

const Info = props => {
  try {
    let content = [];
    let filter = getProp(props, 'filter', {});

    if (getProp(filter, `data.use`, true) === false || props.showAll) {
      if (getProp(filter, 'data.fiction_data') === true) {
        content.push([__('Používa reálne a fiktívne dáta')]);
      } else if (getProp(filter, 'data.fiction_data') === false) {
        content.push([__('Používa reálne dáta')]);
      }
    }

    if (getProp(filter, `date.use`, true) === false || props.showAll) {
      const dateRange = getProp(filter, 'date.date_range');
      if (dateRange) {
        content.push([__('Obdobie'), getDateRanges().get(dateRange)]);
      } else if (
        getProp(filter, 'date.date_from') ||
        getProp(filter, 'date.date_to')
      ) {
        const dateFrom = getProp(filter, 'date.date_from');
        const dateTo = getProp(filter, 'date.date_to');
        let dateString = '';
        if (dateFrom) {
          dateString += __('od %(date)s').format({
            date: moment(dateFrom).format('DD.MM.YYYY'),
          });
        }
        dateString += ' ';
        if (dateTo) {
          dateString += __('do %(date)s').format({
            date: moment(dateTo).format('DD.MM.YYYY'),
          });
        }
        content.push([__('Obdobie'), dateString]);
      }

      const days = getProp(filter, 'date.days', []).map(day => {
        return moment()
          .weekday(day)
          .format('dddd');
      });
      if (days.length > 0) {
        content.push([__('Dni'), days.join(', ')]);
      }
    }

    if (getProp(filter, `finance.use`, true) === false || props.showAll) {
      const currency = getProp(filter, 'finance.currency');
      if (currency) {
        content.push([__('Mena'), currency]);
      }

      const paymentTypes = getProp(filter, 'finance.payment_type', []).map(
        item => {
          return item.label;
        },
      );
      if (paymentTypes.length > 0) {
        content.push([__('Spôsoby platby'), paymentTypes.join(', ')]);
      }

      const taxGroups = getProp(filter, 'finance.tax_groups', []).map(item => {
        return item.label;
      });
      if (taxGroups.length > 0) {
        content.push([__('Daňové skupiny'), taxGroups.join(', ')]);
      }
    }

    if (getProp(filter, `product.use`, true) === false || props.showAll) {
      const priceFrom = getProp(filter, 'product.price_from');
      const priceTo = getProp(filter, 'product.price_to');
      let price = [];
      if (priceFrom) {
        price.push(__('od %(price)s').format({ price: priceFrom }));
      }
      if (priceTo) {
        price.push(__('do %(price)s').format({ price: priceTo }));
      }
      if (priceFrom || priceTo) {
        content.push([__('Cena'), price.join(' ')]);
      }

      const plu = getProp(filter, 'product.plu', []).map(item => {
        return item.label;
      });
      if (plu.length > 0) {
        content.push([__('PLU'), plu.join(', ')]);
      }

      const products = getProp(filter, 'product.product', []).map(item => {
        return item.label;
      });
      if (products.length > 0) {
        content.push([__('Produkty'), products.join(', ')]);
      }
    }

    if (getProp(filter, `venue.use`, true) === false || props.showAll) {
      const venues = getProp(filter, 'venue.venues', []).map(item => {
        return item.label;
      });
      if (venues.length > 0) {
        content.push([__('Prevádzky'), venues.join(', ')]);
      }

      const cashregisters = getProp(filter, 'venue.cashregister', []).map(
        item => {
          return item.label;
        },
      );
      if (cashregisters.length > 0) {
        content.push([__('Pokladne'), cashregisters.join(', ')]);
      }

      const employees = getProp(filter, 'venue.employee', []).map(item => {
        return item.label;
      });
      if (employees.length > 0) {
        content.push([__('Operátori'), employees.join(', ')]);
      }
    }

    return (
      <div>
        {content.length === 0 ? (
          <em className="font-lg">{__('Žiadne filtre')}</em>
        ) : null}
        <ul className="list list-unstyled">
          {content.map((item, idx) => {
            if (typeof item[1] === 'undefined') {
              return <li key={idx}>{item[0]}</li>;
            } else {
              return (
                <li key={idx}>
                  <strong>{item[0]}:</strong> {item[1]}
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  } catch (e) {
    console.error(e);
  }
};

Info.defaultProps = {
  showAll: false,
};

export default Info;
