import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Api from '../../service/Api';
import { getProp, setProp, __ } from '../../utilities/common';
import { setAlert } from './../../actions';
import Loader from './../../component/loader';
import { externalSuccessPage, generateID } from './../../utilities/common';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cashRegister: null,
      cashRegisterId: props.params.id,
      savingLoader: false,
      editing: props.params.id ? true : false,
      loadDetailLoader: props.params.id ? true : false,
      venuesList: [],
      selectedVenue: '',
      selectedType: 'twig',
      selectedApkType: '',
      licenses: [],
      formErrors: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  init(id) {
    let editing = false;
    if (typeof id !== 'undefined' && id) {
      editing = true;
    }

    let afterStateChange = () => {
      Api.get('/licenses').then(response => {
        this.setState({ licenses: response.licenses });

        if (this.state.editing) {
          this.loadCashRegisterDetail();
        } else {
          this.setState(
            { cashRegister: {} },
            this.regenerateCashRegisterPassword,
          );
        }
      });
    };

    this.setState(
      {
        editing: editing,
        cashRegisterId: id || null,
      },
      afterStateChange,
    );

    this.loadVenues();
  }

  componentDidMount() {
    this.init(this.props.params.id);
  }

  loadVenues() {
    // nacitame zoznam adries
    return Api.get('/user/venues')
      .then(response => {
        this.setState({ venuesList: response.venues });
      })
      .catch(response => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Nebolo možné načítať zoznam prevádzok'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
      });
  }

  onChangeVenue(ev) {
    this.setState({ selectedVenue: ev.target.value });
  }

  onChangeType(ev) {
    this.setState({ selectedType: ev.target.value });
  }

  onChangeApkType(ev) {
    this.setState({ selectedApkType: ev.target.value });
  }

  onChangeCashRegisterProp(prop, ev) {
    let cashRegister = this.state.cashRegister;
    cashRegister = setProp(cashRegister, prop, ev.target.value);
    this.setState({ cashRegister: cashRegister });
  }

  getCashRegisterProp(prop, defaultValue = '') {
    return getProp(this.state.cashRegister, prop, defaultValue);
  }

  loadCashRegisterDetail() {
    Api.get(`/user/cash-registers/${this.state.cashRegisterId}`)
      .then(response => {
        let newState = this.state;

        let cashRegister = {
          _id: response._id,
          name: response.name || '',
          venue_id: response.venue_id || '',
        };

        if (typeof response.venue_id !== 'undefined' && response.venue_id) {
          newState.selectedVenue = response.venue_id;
        }

        newState.loadDetailLoader = false;
        newState.cashRegister = cashRegister;
        newState.selectedType = response.type;
        newState.selectedApkType = response.twig_license_type;

        this.setState(newState);
      })
      .catch(response => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Nebolo možné načítať detail pokladne'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
        this.setState({ loadDetailLoader: false });
      });
  }

  setVenueForCashRegister() {
    return new Promise((resolve, reject) => {
      const onSuccess = response => {
        this.setState({ savingLoader: false });
        resolve();
      };

      const onError = response => {
        this.setState({ savingLoader: false });
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Pri ukladaní pokladne došlo k chybe'),
            onConfirm: () => {
              this.props.dispatch(setAlert(null));
            },
          }),
        );
        reject();
      };

      if (this.state.selectedVenue) {
        // ak je vybrana venue, pridame ju, inak zmazeme
        Api.post(
          `/user/venues/${this.state.selectedVenue}/cash-registers/${
            this.state.cashRegister._id
          }`,
          '',
        )
          .then(onSuccess)
          .catch(onError);
      } else {
        if (this.state.editing && this.state.cashRegister.venue_id) {
          Api.delete(`/user/cash-registers/${this.state.cashRegisterId}/venue`)
            .then(onSuccess)
            .catch(onError);
        } else {
          onSuccess('');
        }
      }
    });
  }

  onSubmit(ev) {
    ev.preventDefault();

    const {dispatch} = this.props;
    const idPattern = /^[A-Z\-_a-z0-9]+$/i;

    const onSuccess = response => {
      this.setVenueForCashRegister().then(() => {
        if (this.props.route.path.indexOf('/external') === 0) {
          if (this.state.selectedVenue) {
            response['venue_id'] = this.state.selectedVenue;
          }
          // Auth code nepouzivame zatial
          // let auth_code = getProp(this.state.cashRegister, "auth_code");
          // if (auth_code && this.state.selectedType !== 'twig') {
          //     response.auth_code = auth_code;
          // }
          externalSuccessPage(response);
        }

        if ('twig_activation_key' in response) {
          this.props.dispatch(
            setAlert({
              type: 'success',
              title: __('Pokladňa bola úspešne uložená'),
              text: __('Váš autorizačný kód: %(code)s').format({
                code: response.twig_activation_key,
              }),
              showCancelButton: true,
              confirmButtonText: __('Poslať na e-mail'),
              cancelButtonText: __('Zavrieť'),
              onCancel: () => {
                this.props.dispatch(setAlert(null));
                this.props.onSuccess();
                this.context.router.push('/app/cash-register');
              },
              onConfirm: () => {
                window.location = `mailto:${this.props.user.email}?subject=${__(
                  'Pokladňa s JKP "%(id)s" vytvorená',
                ).format({
                  id: getProp(this.state.cashRegister, '_id'),
                })}&body=${__('Autorizačný kód: %(code)s').format({
                  code: response.twig_activation_key,
                })}`;
              },
            }),
          );
        } else {
          this.props.dispatch(
            setAlert({
              type: 'success',
              title: __('Pokladňa bola úspešne uložená'),
              onConfirm: () => {
                this.props.dispatch(setAlert(null));
                this.props.onSuccess();
                this.context.router.push('/app/cash-register');
              },
            }),
          );
        }
      });
    };

    const onError = error => {
      this.setState({ savingLoader: false });
      if (error.response.status === 409) {
        if (error.details.name === 'CASH_REGISTER_ALREADY_EXISTS') {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Pokladňa s týmto JKP už existuje'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
          return;
        }
      } else if (
        error.response.status === 404 &&
        error.details.name === 'TWIG_KEY_NOT_FOUND'
      ) {
        this.props.dispatch(
          setAlert({
            type: 'warning',
            title: __('Zadaný licenčný kľúč je neplatný'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
        return;
      }
      this.props.dispatch(
        setAlert({
          type: 'error',
          title: __('Pri ukladaní nastala neznáma chyba'),
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
    };

    let err = false;
    let formErrors = {};

    this.setState({
        savingLoader: true,
        formErrors
    }, () => {
        let data = {};
        let name = this.state.cashRegister.name;

        if (name) {
            data.name = name;
        }

        // let auth_code = getProp(this.state.cashRegister, "auth_code");
        // if (auth_code && this.state.selectedType !== 'twig') {
        //     data.auth_code = auth_code;
        // }

    if (this.state.selectedApkType) {
      data.twig_license_type = this.state.selectedApkType;
    }

        data.type = this.state.selectedType;

        if (!this.state.editing) {
            data.twig_activation_key = this.state.cashRegister.twig_activation_key;
        }

        let _id = getProp(this.state.cashRegister, '_id');
        if (this.state.editing === false) {
            if (!idPattern.test(_id)) {
                formErrors._id = __('JKP môže obsahovať iba alfanumerické znaky bez diakritiky');
                err = true;
            } else {
                data._id = _id;
            }
        }

        if (err) {
            this.setState({
                savingLoader: false,
                formErrors
            });

            if (formErrors) {
                for (let a in formErrors) {
                    if (!formErrors.hasOwnProperty(a)) continue;
                    dispatch(setAlert({
                        type: 'warning',
                        title: formErrors[a],
                        onConfirm: () => dispatch(setAlert(null))
                    }));

                    break;
                }
            }

            return;
        }

        if (this.state.editing) {
            delete data['type'];
            Api.put(
                `/user/cash-registers/${this.state.cashRegisterId}`,
                JSON.stringify(data),
            )
                .then(onSuccess)
                .catch(onError);
        } else {
            Api.post('/user/cash-registers', JSON.stringify(data))
                .then(onSuccess)
                .catch(onError);
        }
    });
  };

  regenerateCashRegisterPassword() {
    let cashRegister = this.state.cashRegister;
    cashRegister = setProp(cashRegister, 'auth_code', generateID());
    this.setState({ cashRegister: cashRegister });
  }

  getDescriptionForApkType() {
    let type = this.state.selectedApkType;
    if (type === 'lite') {
      return {
        __html: __(
          'Lite - max 200 položiek. Predaj úzkeho spektra produktov a služieb. Ideálny pre: wellness, fitness, kaderníctvo, zdravotníctvo, služby...',
        ),
      };
    }
    if (type === 'medium') {
      return {
        __html: __(
          'Medium - max 10 000 položiek. Rušné prevádzky s vysokým počtom predávaných položiek. Ideálny pre: maloobchod, potraviny, rýchle občerstvenie, kníhkupectvo, obuv, butik...',
        ),
      };
    }
    if (type === 'ultra') {
      return {
        __html: __(
          'Ultra - max 30 000 položiek. Kompletný reštauračný režim pre prácu s otvorenými účtami, rozúčtovaním na stoly a komunikáciou s kuchyňou. Ideálny pre: reštuarácie, kaviarne, bary, cukrárne...',
        ),
      };
    }

    // TENTO TYP SA NEPOUZIVA ZATIAL
    // if(type === 'e80a') {
    //     return {__html: __('E80A')};
    // }

    // DEFAULT RETURN
    return { __html: '' };
  }

  newVenue() {
    this.props.dispatch(
      setAlert({
        type: 'input',
        title: __('Nová prevádzka'),
        text: __('Zadajte názov novej prevádzky'),
        showCancelButton: true,
        confirmButtonText: __('Vytvoriť'),
        cancelButtonText: __('Zrušiť'),
        inputPlaceholder: '',
        onConfirm: val => {
          if (val.trim()) {
            const data = {
              name: val.trim(),
            };
            Api.post('/user/venues', JSON.stringify(data))
              .then(response => {
                this.loadVenues().then(() => {
                  for (let venue of this.state.venuesList) {
                    if (venue.name === val.trim()) {
                      this.setState({ selectedVenue: venue._id });
                      break;
                    }
                  }
                  this.props.dispatch(setAlert(null));
                });
              })
              .catch(error => {
                this.props.dispatch(
                  setAlert({
                    type: 'error',
                    title: __('Pri ukladaní nastala neznáma chyba'),
                    onConfirm: () => this.props.dispatch(setAlert(null)),
                  }),
                );
              });
          }
        },
        onCancel: () => {
          this.props.dispatch(setAlert(null));
        },
      }),
    );
  }

  isEIMType(type) {
    const eim_types = ['Euro50', 'Euro100', 'Euro150', 'Euro200', 'Euro2100', 'Efox'];

    return eim_types.includes(type);
  }

  render() {
    const {formErrors} = this.state;

    const authProviders = [
      {
        id: 'ElcomPOS',
        label: __('Elcom POS'),
      },
      {
        id: 'ElcomBravo',
        label: __('Elcom Bravo'),
      },
      {
        id: 'MobilePOS',
        label: __('Mobile POS'),
      },
      {
        id: 'Euro50',
        label: __('Euro-50'),
      },
      {
        id: 'Euro100',
        label: __('Euro-100'),
      },
      {
        id: 'Euro150',
        label: __('Euro-150'),
      },
      {
        id: 'Euro200',
        label: __('Euro-200'),
      },
      {
        id: 'Euro2100',
        label: __('Euro-2100'),
      },
      {
        id: 'Efox',
        label: __('Efox'),
      },
    ];

    let title = this.state.editing
      ? __('Detail pokladne')
      : __('Nová pokladňa');
    let buttonText = this.state.editing ? __('Uložiť') : __('Pridať');

    let venuesHtml = [];
    for (let i = 0; i < this.state.venuesList.length; i++) {
      var venue = this.state.venuesList[i];
      venuesHtml.push(
        <option key={venue._id} value={venue._id}>
          {venue.name}
        </option>,
      );
    }

    let titleHtml = null;
    if (this.props.renderForExternal === false) {
      titleHtml = (
        <div>
          <h2 className="side-panel-title">{title}</h2>
          <hr />
        </div>
      );
    }

    let apkOptions = [<option key={0} value="" />];
    [
      { _id: 'lite', name: __('Lite - služby') },
      { _id: 'medium', name: __('Medium - retail') },
      { _id: 'ultra', name: __('Ultra - reštaurácia') },
    ].forEach(apk => {
      apkOptions.push(
        <option key={apk._id} value={apk._id}>
          {apk.name}
        </option>,
      );
    });

    return (
      <div>
        {titleHtml}
        <Loader show={this.state.savingLoader || this.state.loadDetailLoader}>
          <form className="form-horizontal" onSubmit={this.onSubmit}>
            <fieldset className="fieldset">
                <div className={cx('form-group', {
                    'has-error': '_id' in formErrors,
                })}>
                <label className="col-xs-4 control-label">{__('JKP')}:</label>
                <div className="col-sm-4 col-xs-8">
                  <input
                    type="text"
                    readOnly={this.state.editing ? true : false}
                    className="form-control"
                    placeholder={__('JKP')}
                    onChange={this.onChangeCashRegisterProp.bind(this, '_id')}
                    value={this.getCashRegisterProp('_id')}
                    required={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{__('Názov')}:</label>
                <div className="col-sm-4 col-xs-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('Názov')}
                    onChange={this.onChangeCashRegisterProp.bind(this, 'name')}
                    value={this.getCashRegisterProp('name')}
                    required={false}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">{__('Typ')}:</label>
                <div className="col-sm-4 col-xs-8">
                  <select
                    className="form-control"
                    onChange={this.onChangeType.bind(this)}
                    value={this.state.selectedType}
                    required={true}
                  >
                    <option key={0} value="" />
                    {authProviders.map(provider => {
                      return (
                        <option key={provider.id} value={provider.id}>
                          {provider.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {!this.isEIMType(this.state.selectedType) ? (
                <div className="form-group">
                  <label className="col-xs-4 control-label">
                    {__('Typ použitia')}:
                  </label>
                  <div className="col-sm-4 col-xs-8">
                    <select
                      className="form-control"
                      onChange={this.onChangeApkType.bind(this)}
                      value={this.state.selectedApkType}
                      required={true}
                    >
                      {apkOptions}
                    </select>
                  </div>
                </div>
              ) : null}
              {this.state.selectedApkType ? (
                <div className="form-group">
                  <div className="col-xs-8 col-xs-offset-4">
                    <div
                      dangerouslySetInnerHTML={this.getDescriptionForApkType()}
                    />
                  </div>
                </div>
              ) : null}
              <div
                className={cx('form-group', {
                  hidden: this.state.selectedType === 'twig' || true,
                })}
              >
                <label className="col-xs-4 control-label">
                  {__('Autorizačný kód')}:
                </label>
                <div className="col-sm-4 col-xs-8">
                  <input
                    type="text"
                    className="form-control"
                    readOnly="readOnly"
                    placeholder={__('Autorizačný kód')}
                    value={this.getCashRegisterProp('auth_code')}
                    required={true}
                  />
                </div>
                <div className="col-sm-4 col-xs-4 col-xs-offset-4">
                  <button
                    onClick={this.regenerateCashRegisterPassword.bind(this)}
                    type="button"
                    className="btn btn-default-o"
                  >
                    {__('Vygenerovať nový')}
                  </button>
                </div>
              </div>
              <div className={cx({ hidden: this.state.editing })}>
                <div className="form-group">
                  <label className="col-xs-4 control-label">
                    {__('Licenčný kľúč')}:
                  </label>
                  <div className="col-sm-4 col-xs-8">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={__('(nepovinné)')}
                      onChange={this.onChangeCashRegisterProp.bind(
                        this,
                        'twig_activation_key',
                      )}
                      required={false}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-8 col-xs-8 col-xs-offset-4">
                    {__('Licenčný kľúč zadajte v prípade, že ho vlastníte.')}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">
                  {__('Prevádzka')}:
                </label>
                <div className="col-sm-4 col-xs-8">
                  <select
                    className="form-control"
                    onChange={this.onChangeVenue.bind(this)}
                    value={this.state.selectedVenue}
                    required={false}
                  >
                    <option value=""> - </option>
                    {venuesHtml}
                  </select>
                </div>
                <div className="col-sm-4 col-xs-4 col-sm-offset-0 col-xs-offset-4">
                  <button
                    onClick={this.newVenue.bind(this)}
                    type="button"
                    className="btn btn-secondary"
                  >
                    {__('Nová prevádzka')}
                  </button>
                </div>
              </div>
            </fieldset>
            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                {buttonText}
              </button>
            </div>
          </form>
        </Loader>
      </div>
    );
  }
}

Form.contextTypes = {
  router: PropTypes.object,
};

Form.defaultProps = {
  renderForExternal: false,
};

export default connect(state => ({
  user: state.user,
}))(Form);
