import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { connect } from 'react-redux';
import Api from '../service/Api';
import { getProp, __ } from '../utilities/common';
import { showFilter } from './../actions';
import Icon from './icon';
import Loader from './loader';

class MainHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listOfCashRegisters: [],
      selectingCashRegister: false,
      getCashRegistersLoader: true,

      views: [],
      selectingView: false,
      viewLoader: true,
    };

    this.toggleCashRegisterList = this.toggleCashRegisterList.bind(this);
  }

  loadCashRegisters() {
    // nacitame zoznam adries
    this.setState({ getCashRegistersLoader: true });
    Api.get('/user/cash-registers')
      .then(response => {
        this.setState({
          getCashRegistersLoader: false,
          listOfCashRegisters: response.cashregisters,
        });
      })
      .catch(response => {
        this.setState({ getCashRegistersLoader: false });
      });
  }

  handleClickOutside() {
    this.setState({ selectingCashRegister: false });
  }

  toggleCashRegisterList() {
    this.setState(
      { selectingCashRegister: !this.state.selectingCashRegister },
      () => {
        if (this.state.selectingCashRegister) {
          this.loadCashRegisters();
        }
      },
    );
  }

  onSelectCashRegister(cashRegister) {
    //this.props.dispatch(selectCashRegister(cashRegister));
    this.toggleCashRegisterList();
    //this.context.router.push('/app/dashboard');
    this.props.onChangeCashRegister({
      id: cashRegister._id,
      label: cashRegister.name,
    });
  }

  toggleViews() {
    this.setState({ selectingView: !this.state.selectingView });
  }

  onClickFilter() {
    this.props.dispatch(showFilter());
  }

  onChangeView(item) {
    this.setState({ selectingView: false });
    this.props.onChangeView(item);
  }

  render() {
    try {
      // Cash registers
      let selected = __('Vyberte pokladňu');
      let selectedCashRegister = getProp(
        this.props.filter,
        'venue.cashregister',
        [],
      );
      let selectedCashRegisterIds = selectedCashRegister.map(item => item.id);
      if (selectedCashRegister.length === 1) {
        selected = (
          <span>
            <strong>
              {__('ID')}: {selectedCashRegister[0].id}
            </strong>
            &#160;&#160;|&#160;&#160;{getProp(selectedCashRegister[0], 'label')}
          </span>
        );
      } else if (selectedCashRegister.length > 1) {
        selected = __('Viac pokladní');
      }

      let cashRegistersHtml = [];

      for (let i = 0; i < this.state.listOfCashRegisters.length; i++) {
        let cashRegister = this.state.listOfCashRegisters[i];
        cashRegistersHtml.push(
          <li
            key={i}
            onClick={this.onSelectCashRegister.bind(this, cashRegister)}
          >
            <Icon
              name="checkmark"
              className={cx({
                invisible: !selectedCashRegisterIds.includes(cashRegister._id),
              })}
            />
            <strong>
              {__('ID')}: {cashRegister._id}
            </strong>
            &#160;&#160;|&#160;&#160;{getProp(cashRegister, 'name')}
          </li>,
        );
      }

      // Views
      let selectedView = __('Vyberte pohľad');
      if (this.props.selectedView) {
        selectedView = <span>{getProp(this.props.selectedView, 'title')}</span>;
      }

      return (
        <div className="main-header">
          {typeof this.props.onClickBack === 'function' ? (
            <span
              className="back clickable"
              onClick={this.props.onClickBack.bind(this)}
            >
              <Icon name="long-arrow-left" />
            </span>
          ) : null}

          {this.props.leftItem}

          {this.props.editTitle &&
          typeof this.props.onChangeTitle === 'function' ? (
            <h1 className="title">
              <input
                type="text"
                value={this.props.title}
                onChange={this.props.onChangeTitle.bind(this)}
              />
            </h1>
          ) : (
            <h1 className="title">{this.props.title}</h1>
          )}

          {this.props.afterTitle}

          <div className="flex" />

          {this.props.children}

          {this.props.viewSelect &&
          typeof this.props.onChangeView === 'function' ? (
            <span>
              <span
                onClick={this.toggleViews.bind(this)}
                className="selected-item clickable"
              >
                {selectedView}
                <Icon
                  name={
                    !this.state.selectingView
                      ? 'expand-arrow'
                      : 'collapse-arrow'
                  }
                />
              </span>
              <div className="cash-registers-popup-container">
                <div
                  className={cx('cash-registers-popup', {
                    hidden: !this.state.selectingView,
                  })}
                >
                  <ul className="nav">
                    {this.props.views.map((item, idx) => {
                      return (
                        <li
                          key={idx}
                          onClick={this.onChangeView.bind(this, item)}
                        >
                          {getProp(item, 'title')}
                        </li>
                      );
                    })}
                  </ul>
                  <span
                    className={cx('text-center', {
                      hidden: this.props.views.length > 0,
                    })}
                  >
                    <strong>{__('Žiadne pohľady')}</strong>
                  </span>
                </div>
              </div>
            </span>
          ) : null}

          {this.props.cashregisterSelect ? (
            <span style={{ whiteSpace: 'no-wrap' }}>
              <span
                onClick={this.toggleCashRegisterList}
                className="selected-item clickable"
              >
                <span className="selected-item-label">{selected}</span>
                <span className="selected-item-icon">
                  <Icon
                    name={
                      !this.state.selectingCashRegister
                        ? 'expand-arrow'
                        : 'collapse-arrow'
                    }
                  />
                </span>
                <span className="selected-item-icon-mobile">
                  <Icon name="cash-register" />
                </span>
              </span>
              <div className="cash-registers-popup-container">
                <div
                  className={cx('cash-registers-popup', {
                    hidden: !this.state.selectingCashRegister,
                  })}
                >
                  <Loader
                    show={this.state.getCashRegistersLoader}
                    className="loader-small"
                  >
                    <ul className="nav">{cashRegistersHtml}</ul>
                    <span
                      className={cx('text-center', {
                        hidden:
                          this.state.getCashRegistersLoader === true ||
                          cashRegistersHtml.length > 0,
                      })}
                    >
                      <strong>{__('Nemáte pridané žiadne pokladne')}</strong>
                    </span>
                  </Loader>
                </div>
              </div>
            </span>
          ) : null}
          {this.props.showFilter ? (
            <span className="clickable" onClick={this.onClickFilter.bind(this)}>
              <Icon name="filter" />
            </span>
          ) : null}
          <div className={cx('progress', { hidden: !this.props.progress })}>
            <div
              className="progress-bar progress-bar-warning"
              style={{ width: this.props.progress + '%' }}
            />
          </div>
        </div>
      );
    } catch (e) {
      console.error(e);
    }
  }
}

MainHeader.contextTypes = {
  router: PropTypes.object,
};

MainHeader.defaultProps = {
  editTitle: false,
  cashregisterSelect: false,
  viewSelect: false,
  showFilter: false,
};

export default connect(state => ({
  filter: state.filter,
}))(enhanceWithClickOutside(MainHeader));
