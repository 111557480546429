import cx from 'classnames';
import React from 'react';
import { guid } from './../../utilities/common';

const RadioButton = props => {
  let inputname = props.id;
  if (!inputname) {
    inputname = guid();
  }
  const options = props.options.map((item, idx) => {
    const isActive = item.value === props.value;
    return (
      <label
        key={idx}
        className={cx('btn', { active: isActive, 'btn-sm': props.sm })}
      >
        <input
          type="radio"
          name={inputname}
          autoComplete="off"
          checked={isActive}
          value={item.value}
          onChange={props.onChange.bind(null, item)}
        />
        {item.label}
      </label>
    );
  });

  let customClass = '';
  if (props.size) {
    customClass += `btn-group-${props.size}`;
  }
  return (
    <span
      className={cx('btn-group', props.className, customClass)}
      data-toggle="buttons"
    >
      {options}
    </span>
  );
};

export default RadioButton;
