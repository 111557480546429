import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Api from '../../service/Api';
import { getProp, __, createUrl } from '../../utilities/common';
import { setAlert } from './../../actions';
import Content from './../../component/content';
import DataTable from './../../component/dataTable';
import Loader from './../../component/loader';
import MainHeader from './../../component/mainHeader';
import LoadMoreBtn from './../../component/loadMoreBtn';

const BASE_URL = '/app/partner-cash-register';
const LIMIT = 1000;

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      cashRegistersOfPartner: [],
      totalCashRegisters: 0,
      venuesListAssoc: {},

      offset: parseInt(props.location.query.offset, 10) || 0,
      nextOffset: null,
      loadedOnce: false
    };
  }

  componentDidMount() {
    this.loadVenues();
    this.loadPartnerCashRegisters(this.state.offset);
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { location: { query: { offset: nextOffset } } } = nextProps;
    const { location: { query: { offset } } } = this.props;

    if (nextOffset && offset !== nextOffset) {
      this.loadPartnerCashRegisters(nextOffset);
    }
  }

  loadVenues = async () => {
    try {
      const response = await Api.get('/user/venues');
      const assoc = {};
      for (let venue of response.venues) {
        assoc[venue._id] = venue;
      }
      this.setState({ venuesListAssoc: assoc });
    } catch (e) {
      console.error(e);
    }
  }

  loadPartnerCashRegisters = async (offset = 0, loadAll = false) => {
    const { loadedOnce } = this.state;

    this.setState({ isFetching: true });

    let limit = LIMIT;

    if (!loadedOnce) {
      limit = LIMIT + offset;
      offset = 0;
    }

    try {
      const response = await Api.get('/user/partner-cash-registers', { limit, offset });
      this.setState({
        isFetching: false,
        cashRegistersOfPartner: parseInt(offset, 10) === 0 ? response.cashregisters : [...this.state.cashRegistersOfPartner, ...response.cashregisters],
        totalCashRegisters: getProp(response, 'total', 0),
        nextOffset: response.next_offset,
        loadedOnce: true,
      });
    } catch (error) {
      console.error(error);
      this.props.dispatch(
        setAlert({
          type: 'error',
          title: __('Nebolo možné načítať zoznam partnerských pokladníc'),
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
      this.setState({ isFetching: false });
    }
  }

  render() {
    const { nextOffset, isFetching, cashRegistersOfPartner, totalCashRegisters } = this.state;

    return (
      <div id="content-wrapper partners">
        <MainHeader
          title={__('Partnerské pokladnice')}
          progress={100}
        />
        <Content>
          <Loader show={isFetching}>
            {(cashRegistersOfPartner.length === 0 && !isFetching) ?
              <h4 className={'text-center'}>
                {__('Žiadne partnerské pokladnice')}
              </h4>
              :
              <React.Fragment>
                <h4>{__(`Počet všetkých pokladníc partnera: ${totalCashRegisters}`)}</h4>
                <DataTable
                  cols={[
                    __('JKP'),
                    __('Firma'),
                    __('Prevádzka'),
                    __("ECRSN"),
                    __("Typ licencie"),
                    __("Platnosť licencie"),
                    __("Odmena"),
                    __("Dátum splatnosti odmeny"),
                    __("Číslo faktúry"),
                    __("Uhradené / Neuhradené"),
                  ]}
                  rows={cashRegistersOfPartner.map(item => {
                    return [
                      item._id,
                      getProp(item, 'company.company_name', '-'),
                      getProp(
                        this.state.venuesListAssoc[item.venue_id],
                        'name',
                      ),
                      getProp(item, 'ecrsn', '-'), // ECRSN
                      '-', // typ licencie
                      '-', // platnost licencie
                      '-', // odmena
                      '-', // dátum splatnosti odmeny
                      '-', // Číslo faktúry
                      '-', // uhradené / neuhradené
                    ];
                  })}
                  classNames={[null, null, null, null, null, null, null, null, null, null]}
                />
                <LoadMoreBtn
                  nextOffset={nextOffset}
                  onClick={() => this.context.router.push(createUrl(BASE_URL, {
                    offset: nextOffset
                  }))}
                />
              </React.Fragment>
            }
          </Loader>
          <div className="cleaner" />
        </Content>
      </div>
    );
  }
}

List.contextTypes = {
  router: PropTypes.object
};


List.defaultProps = {
  remoteAccess: false,
};

export default connect()(List);
