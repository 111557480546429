import React from 'react';
import { connect } from 'react-redux';
import { __ } from '../utilities/common';
import { queryChange } from './../actions';
import DataFilterInput from './../component/dataFilterInput';

class DataFilter extends React.Component {
  onChangeQuery(e) {
    this.props.dispatch(queryChange(e.target.value));
  }

  match(needle, regex) {
    if (typeof needle === 'string' || typeof needle === 'number') {
      needle = needle + '';
      if (needle.match(regex) !== null) {
        return true;
      }
    }
    return false;
  }

  filterData(query, searchProps, data) {
    let regex
    try {
      regex = new RegExp(query, 'ig');

      if (query.length > 0) {
        return data.filter(item => {
          let found = false;
          if (searchProps.length > 0) {
            searchProps.forEach(prop => {
              if (!found && prop in item) {
                found = this.match(item[prop], regex) === true;
              }
            });
          } else {
            for (let prop in item) {
              if (item.hasOwnProperty(prop)) {
                found = this.match(item[prop], regex);
                if (found) {
                  break;
                }
              }
            }
          }
          return found;
        });
      }
    } catch(e) {
      console.error(e);
      return [];
    }
    return data;
  }

  clearResults() {
    this.props.dispatch(queryChange(''));
  }

  render() {
    const data = this.filterData(
      this.props.query,
      this.props.searchProps,
      this.props.data,
    );

    return (
      <div>
        <DataFilterInput
          placeholder={this.props.placeholder}
          onChangeQuery={this.onChangeQuery.bind(this)}
          value={this.props.query}
        />

        {this.props.children(data)}
      </div>
    );
  }
}

DataFilter.defaultProps = {
  data: [],
  query: '',
  placeholder: __('Začnite písať ...'),
  searchProps: [],
};

export default connect(state => ({
  query: state.query,
}))(DataFilter);
