import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Api from '../../service/Api';
import { getProp, __, createUrl } from '../../utilities/common';
import { setAlert } from './../../actions';
import Loader from './../../component/loader';
import LoadMoreBtn from './../../component/loadMoreBtn';
import DataTable from './../../component/dataTable';
import { BASE_URL, LIMIT } from './list';

class PartnerCashRegisters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      cashregisters: [],
      venuesListAssoc: {},

      offset: parseInt(props.location.query.offset, 10) || 0,
      nextOffset: null,
      loadedOnce: false
    };
  }

  componentDidMount() {
    this.loadVenues();
    this.loadCashRegisters(this.props.params.id, this.state.offset);
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { location: { query: { offset: nextOffset } }, params: { id: nextId } } = nextProps;
    const { location: { query: { offset } }, params: { id } } = this.props;

    if ((nextOffset && offset !== nextOffset) || id !== nextId) {
      this.loadCashRegisters(nextId, nextOffset);
    }
  }

  loadVenues = async () => {
    try {
      const response = await Api.get('/user/venues');
      const assoc = {};
      for (let venue of response.venues) {
        assoc[venue._id] = venue;
      }
      this.setState({ venuesListAssoc: assoc });
    } catch (e) {
      console.error(e);
    }
  }

  loadCashRegisters = async (id, offset = 0, loadAll = false) => {
    const { loadedOnce } = this.state;

    this.setState({ isFetching: true });

    let limit = LIMIT;

    if (!loadedOnce) {
      limit = LIMIT + offset;
      offset = 0;
    }

    try {
      const response = await Api.get(`/admin/partners/${id}/cash-registers`, { limit, offset })
      this.setState({
        cashregisters: parseInt(offset, 10) === 0 ? getProp(response, 'cashregisters', []) : [...this.state.cashregisters, ...response.cashregisters],
        nextOffset: response.next_offset,
        isFetching: false,
        loadedOnce: true,
      });
    } catch (error) {
      console.error(error);
      this.props.dispatch(
        setAlert({
          type: 'error',
          title: __('Nebolo možné načítať zoznam partnerských pokladní'),
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
      this.setState({ isFetching: false });
    }
  }

  render() {
    const { cashregisters, nextOffset, isFetching } = this.state;

    return (
      <div id="content-wrapper partners">
        <Loader show={isFetching}>
          {(cashregisters.length === 0 && !isFetching)
            ?
            <h4 className='text-center'>
              {__('Patrnerovi neprislúchajú žiadne pokladnice')}
            </h4>
            :
            <DataTable
              cols={[
                __('JKP'),
                __('Firma'),
                __('Prevádzka'),
                __("ECRSN"),
                __("Typ licencie"),
                __("Platnosť licencie"),
                __("Odmena"),
                __("Dátum splatnosti odmeny"),
                __("Číslo faktúry"),
                __("Uhradené / Neuhradené"),
              ]}
              rows={cashregisters.map(item => {
                return [
                  item._id,
                  getProp(item, 'company.company_name', '-'),
                  getProp(
                    this.state.venuesListAssoc[item.venue_id],
                    'name',
                  ),
                  getProp(item, 'ecrsn', '-'), // ECRSN
                  '-', // typ licencie
                  '-', // platnost licencie
                  '-', // odmena
                  '-', // dátum splatnosti odmeny
                  '-', // Číslo faktúry
                  '-', // uhradené / neuhradené
                ];
              })}
              classNames={[null, null, null, null, null, null, null, null, null, null]}
            />
          }
          <LoadMoreBtn
            nextOffset={nextOffset}
            onClick={() => this.context.router.push(createUrl([BASE_URL, this.props.params.id, 'cash-registers'], {
              offset: nextOffset
            }))}
          />
        </Loader>
        <div className="cleaner" />
      </div>
    );
  }
}

PartnerCashRegisters.contextTypes = {
  router: PropTypes.object
};

export default connect()(PartnerCashRegisters);
