import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router';
import { __ } from '../../utilities/common';
import { formatPrice } from './../../utilities/common';
import Icon from './../icon';

const UserPopup = props => {
  return (
    <div className={cx('user-popup', { hidden: props.opened === false })}>
      <div className="arrow-up" />
      <ul className="nav">
        <li>
          <Link onClick={props.onClickUser} to="/app/profile">
            <Icon name="user-groups" />
            {__('Moja firma')}
          </Link>
        </li>
        <li>
          <Link onClick={props.onClickUser} to="/app/profile/edit">
            <Icon name="gender-neutral-user" />
            {__('Moje údaje')}
          </Link>
        </li>
        <li>
          <Link onClick={props.onClickUser} to="/app/profile/passwd">
            <Icon name="data-protection" />
            {__('Zmena hesla')}
          </Link>
        </li>
      </ul>
      <hr />
      <h3>{__('Aktuálna licencia')}</h3>
      <div className="clearfix">
        <div className="pull-left text-negative font-lg">
          {props.licenseName}
        </div>
        <div className="pull-right text-right">
          <strong className="text-negative font-lg">
            {formatPrice(props.licensePrice, props.licensePriceCurrency)}
          </strong>
          <div>
            <em>{__('mesačne')}</em>
          </div>
        </div>
      </div>
      <div className="">
        {__('Platná do: %(valid)s').format({
          valid: props.licenseValidUntil.format('D.M.Y'),
        })}
      </div>
      <br />
      <div className="text-center">
        <Link
          to="/app/license"
          className="btn btn-primary"
          onClick={props.onClickUser}
        >
          {__('Moja licencia')}
        </Link>
      </div>
    </div>
  );
};

export default UserPopup;
