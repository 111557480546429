import cx from 'classnames';
import React from 'react';
import { getProp, __ } from '../../utilities/common';
import { chartErrors } from './../../utilities/chart';
import { formatNumber, formatPrice } from './../../utilities/common';
import Icon from './../icon';
import ProtectedContent from './../protectedContent';
import Info from './info';

export const getChartValue = (v, def = null) => {
  let value = 0.0;
  if (v instanceof Object) {
    value = v.amount;
  } else {
    if (typeof v === 'undefined') {
      value = def;
    } else {
      value = v;
    }
  }
  return value;
};

export const getChartValueCurrency = v => {
  if (v instanceof Object) {
    return v.currency;
  }
  return null;
};

export const getChartStringValue = (v, c = 0) => {
  let value = '';
  if (v instanceof Object) {
    value = formatPrice(v.amount, v.currency, c);
  } else {
    value = formatNumber(v, c);
  }
  return value;
};

export const isChartPropsEqual = (props1, props2, keys) => {
  //return false;
  let equal = 0;
  for (const key in keys) {
    let c1, c2;
    if (keys[key]) {
      c1 = JSON.stringify(props1[key]);
      c2 = JSON.stringify(props2[key]);
    } else {
      c1 = props1[key];
      c2 = props2[key];
    }
    if (c1 === c2) {
      equal++;
    }
  }
  if (equal === Object.keys(keys).length) {
    return true;
  }
  return false;
};

export default class BaseChart extends React.Component {
  /**
   * Required props:
   * - histogram: data directly from webservice
   * - key_x: key to histogram time interval, e.g. day.
   * - key_y: key to actual stats value
   */

  constructor(props) {
    super(props);
    this.state = {
      info: false,
    };

    this.kurvaTimeout = null;
  }

  onClickHideInfo() {
    this.setState({ info: false });
  }

  onClickShowInfo() {
    this.setState({ info: true });
  }

  render() {
    const showFilters = getProp(this.props, 'showFilters', false);

    const filters = () => {
      const filtersExtended = getProp(this.props, 'chart.filtersExtended', []);
      if (typeof filtersExtended[0] !== 'object') {
        filtersExtended[0] = {};
      }
      if (typeof filtersExtended[1] !== 'object') {
        filtersExtended[1] = {};
      }

      return (
        <div>
          <br />
          <div className="text-right">
            <span
              onClick={this.onClickShowInfo.bind(this)}
              className="text-light font-sm clickable"
            >
              {__('Zobraziť použité filtre')} <Icon name="collapse-arrow" />
            </span>
          </div>
          <div className={cx('chart-info', { show: this.state.info })}>
            <div className="col-md-6">
              <h3>{__('Filter')}</h3>
              <Info filter={filtersExtended[0]} showAll={true} />
            </div>
            {Object.keys(filtersExtended[1]).length > 0 ? (
              <div className="col-md-6">
                <h3>{__('Porovnávací filter')}</h3>
                <Info filter={filtersExtended[1]} showAll={true} />
              </div>
            ) : null}
            <span
              className="close-btn"
              onClick={this.onClickHideInfo.bind(this)}
            >
              <Icon name="multiply" />
            </span>
          </div>
        </div>
      );
    };

    let content = this.props.children;
    if (this.props.error === chartErrors().ACL_ERROR) {
      content = (
        <ProtectedContent
          showWarning
          msg={__(
            'Graf nemožno zobraziť, pretože používa filter, ktorý nie je povolený pre Vašu licenciu',
          )}
        />
      );
    } else if (this.props.error === chartErrors().GENERIC_ERROR) {
      content = (
        <ProtectedContent showWarning msg={__('Graf nemožno zobraziť')} />
      );
    }

    return (
      <div
        className={cx('panel panel-default', { 'panel-dim': this.state.info })}
      >
        <div className="panel-heading">
          {this.props.title}{' '}
          {getProp(this.props, 'subtitle') ? (
            <span className="subtitle">| {this.props.subtitle}</span>
          ) : null}
        </div>
        <div className="panel-body relative">
          {content}
          {showFilters ? filters() : null}
        </div>
        <div
          className="panel-dimmer clickable"
          onClick={this.onClickHideInfo.bind(this)}
        />
      </div>
    );
  }
}
