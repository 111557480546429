import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Api from '../../service/Api';
import { __ } from '../../utilities/common';
import Content from './../../component/content';
import DataTable from './../../component/dataTable';
import Icon from './../../component/icon';
import Loader from './../../component/loader';
import LoadMoreBtn from './../../component/loadMoreBtn';
import MainHeader from './../../component/mainHeader';

class UpdatesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: 100,
      next_offset: null,
      cashRegisterId: props.params.id,
      updates: [],
      loader: false,
    };
  }

  componentDidMount() {
    this.loadUpdates(this.state.limit, this.state.next_offset);
  }

  onChangeDate(date) {
    this.setState({ date: date });
  }

  loadUpdates(limit, offset) {
    // nacitame zoznam adries
    this.setState({ loader: true });

    Api.get(`/user/cash-registers/${this.state.cashRegisterId}/updates`, {
      limit: limit,
      offset: offset,
    })
      .then(response => {
        this.setState({
          loader: false,
          updates: this.state.updates.concat(response.updates),
          next_offset: response.next_offset,
        });
      })
      .catch(error => {
        this.setState({ loader: false });
      });
  }

  onClickBack() {
    this.context.router.push(
      `/app/cash-register/${this.state.cashRegisterId}/view`,
    );
  }

  render() {
    return (
      <div id="content-wrapper">
        <MainHeader
          title={__('Aktualizácie pokladne')}
          progress={100}
          onClickBack={this.onClickBack.bind(this)}
        />
        <Content>
          <Loader show={this.state.loader}>
            <DataTable
              cols={['', __('Dátum a čas'), __('Typ')]}
              rows={this.state.updates.map(update => {
                let statusClass = 'text-danger';
                let statusText = '';
                switch (update.status) {
                  case 'pending':
                    statusClass = 'text-warning';
                    statusText = __('Prebieha aktualizácia');
                    break;
                  case 'error':
                    statusClass = 'text-danger';
                    statusText = update.error.code;
                    break;
                  case 'success':
                    statusClass = 'text-success';
                    statusText = __('Úspešná aktualizácia');
                    break;
                  default:
                    break;
                }
                return {
                  title: statusText,
                  _data: [
                    <span className={statusClass}>
                      <Icon name="new-moon" />
                    </span>,
                    moment(update.datetime).format('DD.MM.YYYY HH:mm'),
                    update.update_type,
                  ],
                };
              })}
            />
            <h4
              className={cx('text-center', {
                hidden:
                  this.state.loader === true || this.state.updates.length > 0,
              })}
            >
              {__('Neboli pridané žiadne aktualizácie')}
            </h4>
          </Loader>
          <LoadMoreBtn
            nextOffset={this.state.next_offset}
            onClick={this.loadMore.bind(this)}
          />
        </Content>
        {/*<SidePanel onSuccess={onSuccessSidePanel} parameters={{updates: this.state.updates, filter: this.state.filter}} returnUrl={`/app/cash-register/${this.state.cashRegisterId}/updates`}>{this.props.children}</SidePanel>*/}
      </div>
    );
  }

  loadMore() {
    this.loadUpdates(this.state.limit, this.state.next_offset);
  }
}

UpdatesPage.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({}))(UpdatesPage);
