class GlobalFilterDelegator {
  constructor() {
    this.delegates = new Set();
  }

  addDelegate(inst) {
    this.delegates.add(inst);
  }

  removeDelegate(inst) {
    this.delegates.delete(inst);
  }

  globalFilterChanged() {
    for (const delegate of this.delegates) {
      if (typeof delegate.onChangeGlobalFilter === 'function') {
        delegate.onChangeGlobalFilter();
      }
    }
  }
}

export default new GlobalFilterDelegator();
