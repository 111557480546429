export const ACTION = {
  RESET: 'RESET',
  SET_ALERT: 'SET_ALERT',
  SELECT_CASH_REGISTER: 'SELECT_CASH_REGISTER',
  SET_USER: 'SET_USER',
  SHOW_FILTER: 'SHOW_FILTER',
  SET_FILTER: 'SET_FILTER',
  INCREMENT_SAVE_COUNTER: 'INCREMENT_SAVE_COUNTER',
  DECREMENT_SAVE_COUNTER: 'DECREMENT_SAVE_COUNTER',
  SHOW_MENU: 'SHOW_MENU',
  HIDE_MENU: 'HIDE_MENU',
  QUERY_CHANGE: 'QUERY_CHANGE',
};

export const reset = () => {
  return {
    type: ACTION.RESET,
  };
};

export const setAlert = (config = null) => {
  return {
    type: ACTION.SET_ALERT,
    config: config,
  };
};

export const selectCashRegister = (cashRegister = null) => {
  return {
    type: ACTION.SELECT_CASH_REGISTER,
    cashRegister: cashRegister,
  };
};

export const setUser = (user = null) => {
  return {
    type: ACTION.SET_USER,
    user: user,
  };
};

export const showFilter = (show = true) => {
  return {
    type: ACTION.SHOW_FILTER,
    show: show,
  };
};

export const setFilter = (filter = {}) => {
  return {
    type: ACTION.SET_FILTER,
    filter: filter,
  };
};

export const incrementSaveCounter = () => {
  return {
    type: ACTION.INCREMENT_SAVE_COUNTER,
  };
};

export const decrementSaveCounter = () => {
  return {
    type: ACTION.DECREMENT_SAVE_COUNTER,
  };
};

export const showMenu = () => {
  return {
    type: ACTION.SHOW_MENU,
  };
};

export const hideMenu = () => {
  return {
    type: ACTION.HIDE_MENU,
  };
};

export const queryChange = query => {
  return {
    type: ACTION.QUERY_CHANGE,
    query,
  };
};
