import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import Api from '../service/Api';
import { getProp, setProp, __ } from '../utilities/common';
import Checkbox from './../component/form/checkbox';
import TokenGroup from './../component/form/tokenGroup';
import ProtectedContent from './../component/protectedContent';
import {
  getDateRange,
  getDateRanges,
  getPaymentMethods,
  guid,
} from './../utilities/common';
import Datepicker from './form/datepicker';
import Search from './form/search';

class GlobalFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.guid = guid();

    this.getProductOptions = this.getProductOptions.bind(this);
  }

  onChangeLocal(prop, ev) {
    this.onChangeLocalValue(prop, ev.target.value);
  }

  onChangeLocalValue(prop, value) {
    const obj = setProp(this.state, prop, value);
    this.setState(obj);
  }

  getLocal(prop, defaultValue = '') {
    return getProp(this.state, prop, defaultValue);
  }

  onChangeObjectProp(prop, ev) {
    let value = ev.target.value;
    if (ev.target.type && ev.target.type === 'checkbox') {
      value = ev.target.checked;
    }
    if (prop === 'date.date_range' && ev.target.value) {
      let state = setProp(
        this.props.data,
        'date.date_from',
        getDateRange(ev.target.value)[0],
      );
      state = setProp(state, 'date.date_to', getDateRange(ev.target.value)[1]);
      this.props.onChange(state);
    }
    const obj = setProp(this.props.data, prop, value);
    this.props.onChange(obj);
  }

  getObjectProp(prop, defaultValue = '') {
    return getProp(this.props.data, prop, defaultValue);
  }

  getDateObjectProp(prop, defaultValue = '') {
    const date = this.getObjectProp(prop, defaultValue);
    if (date) {
      return moment(date);
    }
    return null;
  }

  onChangeDaySelection(ev) {
    let filter = this.props.data;
    const days = getProp(filter, 'date.days', []);
    const idx = days.indexOf(ev.target.value);
    if (idx !== -1) {
      days.splice(idx, 1);
    }

    if (ev.target.checked) {
      days.push(ev.target.value);
    }

    filter = setProp(filter, 'date.days', days);
    this.props.onChange(filter);
  }

  getDaySelection(day) {
    const days = getProp(this.props.data, 'date.days', []);
    return days.indexOf(day) !== -1;
  }

  onChangeDateObjectProp(prop, moment) {
    this.props.onChange(setProp(this.props.data, prop, moment));
  }

  onSubmitToken(prop, ev, value) {
    if (!value) {
      return;
    }
    const arr = getProp(this.props.data, prop, []);
    arr.push({ label: value });

    // Vyprazdnime input
    this.setState(setProp(this.state, prop, ''));

    this.props.onChange(setProp(this.props.data, prop, arr));
  }

  onRemoveToken(prop, idx, item, ev) {
    const arr = getProp(this.props.data, prop, []);
    arr.splice(idx, 1);

    this.props.onChange(setProp(this.props.data, prop, arr));
  }

  onChangeTokenSelect(prop, ev) {
    const arr = getProp(this.props.data, prop, []);
    if (ev.target.value) {
      arr.push({
        label: ev.target.options[ev.target.selectedIndex].text,
        id: ev.target.value,
      });
      this.props.onChange(setProp(this.props.data, prop, arr));
    }
  }

  onSearchProduct(value) {
    this.onChangeLocalValue('product.plu', value);
  }

  onSearchEmployee(value) {
    this.onChangeLocalValue('venue.employee', value);
  }

  onSearchVat(value) {
    this.onChangeLocalValue('product.vat', value);
  }

  getProductOptions(input) {
    if (!input) {
      return Promise.resolve([]);
    }
    return new Promise((resolve, reject) => {
      Api.get(`/user/setup-globals/plu?search=${input}`).then(response => {
        resolve(
          response.items.map(item => {
            return Object.assign({}, item, {
              label: item.pro_name,
            });
          }),
        );
      });
    });
  }

  getEmployeeOptions(input) {
    return new Promise((resolve, reject) => {
      Api.get(`/user/setup-globals/users`).then(response => {
        resolve(
          response.items.map(item => {
            return Object.assign({}, item, {
              label: getProp(item, 'use_name', '-') || '-',
            });
          }),
        );
      });
    });
  }

  render() {
    try {
      return (
        <section>
          <FieldSection
            guid={this.guid}
            name="data"
            legend={__('Dáta')}
            toggleable={this.props.toggleableSections}
            active={this.getObjectProp('data.use', true)}
            onChangeActive={this.onChangeObjectProp.bind(this, 'data.use')}
          >
            <div className="form-group">
              <label className="col-xs-4 control-label">
                {__('Zdroj dát')}:{' '}
              </label>
              <div className="col-xs-8 checkbox">
                <Checkbox
                  id={`fictiondata-chk-${this.guid}`}
                  checked={this.getObjectProp('data.fiction_data', false)}
                  onChange={this.onChangeObjectProp.bind(
                    this,
                    'data.fiction_data',
                  )}
                />
                <label htmlFor={`fictiondata-chk-${this.guid}`}>
                  {__('Zahrnúť hodnoty pre štatistiky')}
                </label>
              </div>
            </div>
          </FieldSection>

          <FieldSection
            guid={this.guid}
            name="date"
            legend={__('Dátum')}
            toggleable={this.props.toggleableSections}
            active={this.getObjectProp('date.use', true)}
            onChangeActive={this.onChangeObjectProp.bind(this, 'date.use')}
          >
            <div className="form-group">
              <label htmlFor="date_from" className="col-xs-4 control-label">
                {__('Rozsah')}:
              </label>
              <div className="col-sm-4 col-xs-8">
                <Datepicker
                  disabled={
                    this.getObjectProp('date.date_range', false) ? true : false
                  }
                  id="date_from"
                  selected={this.getDateObjectProp('date.date_from', null)}
                  onChange={this.onChangeDateObjectProp.bind(
                    this,
                    'date.date_from',
                  )}
                />
              </div>
              <div className="col-sm-4 col-sm-offset-0 col-xs-8 col-xs-offset-4">
                <Datepicker
                  disabled={
                    this.getObjectProp('date.date_range', false) ? true : false
                  }
                  selected={this.getDateObjectProp('date.date_to', null)}
                  onChange={this.onChangeDateObjectProp.bind(
                    this,
                    'date.date_to',
                  )}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="range" className="col-xs-4 control-label">
                {__('Obľúbené')}:
              </label>
              <div className="col-sm-6 col-xs-8">
                <select
                  id="range"
                  className="form-control"
                  value={this.getObjectProp('date.date_range')}
                  onChange={this.onChangeObjectProp.bind(
                    this,
                    'date.date_range',
                  )}
                >
                  <option value="">{__('Vlastný rozsah')}</option>
                  {Array.from(getDateRanges()).map((item, idx) => {
                    return (
                      <option key={idx} value={item[0]}>
                        {item[1]}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">
                {__('Deň v týždni')}:{' '}
              </label>
              <div className="col-xs-8 checkbox">
                {[
                  __('Po'),
                  __('Ut'),
                  __('St'),
                  __('Št'),
                  __('Pi'),
                  __('So'),
                  __('Ne'),
                ].map((day, idx) => {
                  return (
                    <span key={idx}>
                      <span className="checkbox-inline">
                        <Checkbox
                          id={`day-${idx}-${this.guid}`}
                          onChange={this.onChangeDaySelection.bind(this)}
                          checked={this.getDaySelection((idx + 1).toString())}
                          value={(idx + 1).toString()}
                        />
                        <label htmlFor={`day-${idx}-${this.guid}`}>{day}</label>
                      </span>
                      {idx === 4 ? <span>&nbsp;&nbsp;&nbsp;</span> : ''}
                    </span>
                  );
                })}
              </div>
            </div>
          </FieldSection>

          <FieldSection
            guid={this.guid}
            name="venue"
            legend={__('Prevádzka')}
            toggleable={this.props.toggleableSections}
            active={this.getObjectProp('venue.use', true)}
            onChangeActive={this.onChangeObjectProp.bind(this, 'venue.use')}
          >
            <div className="form-group">
              <label className="col-xs-4 control-label" htmlFor="venue">
                {__('Prevádzka')}:{' '}
              </label>
              <div className="col-sm-6 col-xs-8">
                <select
                  id="venue"
                  className="form-control"
                  onChange={this.onChangeTokenSelect.bind(this, 'venue.venues')}
                >
                  <option value="">{__('Vyberte')}</option>
                  {this.props.allVenues
                    .filter(item => {
                      const items = getProp(this.props.data, 'venue.venues');
                      for (const i of items) {
                        if (i.id === item._id) {
                          return false;
                        }
                      }
                      return true;
                    })
                    .map((item, idx) => {
                      return (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div
              className={cx('form-group', {
                hidden: this.getObjectProp('venue.venues', []).length === 0,
              })}
            >
              <div className="col-xs-8 col-xs-offset-4">
                <TokenGroup
                  items={this.getObjectProp('venue.venues', [])}
                  onRemove={this.onRemoveToken.bind(this, 'venue.venues')}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label" htmlFor="cashregister">
                {__('Pokladňa')}:{' '}
              </label>
              <div className="col-sm-6 col-xs-8">
                <select
                  id="cashregister"
                  className="form-control"
                  onChange={this.onChangeTokenSelect.bind(
                    this,
                    'venue.cashregister',
                  )}
                >
                  <option value="">{__('Vyberte')}</option>
                  {this.props.allCashRegisters
                    .filter(item => {
                      const items = getProp(
                        this.props.data,
                        'venue.cashregister',
                      );
                      for (const i of items) {
                        if (i.id === item._id) {
                          return false;
                        }
                      }
                      return true;
                    })
                    .map((item, idx) => {
                      return (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div
              className={cx('form-group', {
                hidden:
                  this.getObjectProp('venue.cashregister', []).length === 0,
              })}
            >
              <div className="col-xs-8 col-xs-offset-4">
                <TokenGroup
                  items={this.getObjectProp('venue.cashregister', [])}
                  onRemove={this.onRemoveToken.bind(this, 'venue.cashregister')}
                />
              </div>
            </div>
            <ProtectedContent
              resource="bm.filters_employee"
              action="enabled"
              showWarning
              overlay
            >
              <div className="form-group">
                <label
                  htmlFor="inputPassword4"
                  className="col-xs-4 control-label"
                >
                  {__('Zamestnanec')}:
                </label>
                <div className="col-sm-6 col-xs-8">
                  <Search
                    value={this.getLocal('venue.employee')}
                    loadData={this.getEmployeeOptions}
                    onSelect={item => {
                      if (item) {
                        this.onSubmitToken(
                          'venue.employee',
                          null,
                          item.use_name,
                        );
                      } else {
                        this.onSubmitToken(
                          'venue.employee',
                          null,
                          this.getLocal('venue.employee'),
                        );
                      }
                      this.onChangeLocalValue('venue.employee', '');
                    }}
                    onChangeQuery={this.onChangeLocalValue.bind(
                      this,
                      'venue.employee',
                    )}
                    searchEmptyQuery={true}
                  />
                  {/*<TokenInput value={this.getLocal('venue.employee')} onChange={this.onChangeLocal.bind(this, 'venue.employee')} onSubmit={this.onSubmitToken.bind(this, 'venue.employee')} />*/}
                </div>
              </div>
            </ProtectedContent>
            <div
              className={cx('form-group', {
                hidden: this.getObjectProp('venue.employee', []).length === 0,
              })}
            >
              <div className="col-xs-8 col-xs-offset-4">
                <TokenGroup
                  items={this.getObjectProp('venue.employee', [])}
                  onRemove={this.onRemoveToken.bind(this, 'venue.employee')}
                />
              </div>
            </div>
          </FieldSection>

          <FieldSection
            guid={this.guid}
            name="finance"
            legend={__('Financie')}
            toggleable={this.props.toggleableSections}
            active={this.getObjectProp('finance.use', true)}
            onChangeActive={this.onChangeObjectProp.bind(this, 'finance.use')}
          >
            <div className="form-group">
              <label className="col-xs-4 control-label" htmlFor="payment_type">
                {__('Spôsob platby')}:{' '}
              </label>
              <div className="col-sm-6 col-xs-8">
                <select
                  id="payment_type"
                  className="form-control"
                  onChange={this.onChangeTokenSelect.bind(
                    this,
                    'finance.payment_type',
                  )}
                >
                  <option value="">{__('Vyberte')}</option>
                  {Array.from(getPaymentMethods())
                    .filter(([id, label]) => {
                      const items = getProp(
                        this.props.data,
                        'finance.payment_type',
                      );
                      for (const i of items) {
                        if (i.id === id) {
                          return false;
                        }
                      }
                      return true;
                    })
                    .map(([id, label], idx) => {
                      return (
                        <option key={id} value={id}>
                          {label}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div
              className={cx('form-group', {
                hidden:
                  this.getObjectProp('finance.payment_type', []).length === 0,
              })}
            >
              <div className="col-xs-8 col-xs-offset-4">
                <TokenGroup
                  items={this.getObjectProp('finance.payment_type', [])}
                  onRemove={this.onRemoveToken.bind(
                    this,
                    'finance.payment_type',
                  )}
                />
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="inputPassword4"
                className="col-xs-4 control-label"
              >
                {__('Daňové hladiny')}:
              </label>
              <div className="col-sm-6 col-xs-8">
                <select
                  id="vatselect"
                  className="form-control"
                  onChange={this.onChangeTokenSelect.bind(
                    this,
                    'finance.tax_groups',
                  )}
                >
                  <option value="">{__('Vyberte')}</option>
                  {this.props.vats
                    .filter(item => {
                      const items = getProp(
                        this.props.data,
                        'finance.tax_groups',
                      );
                      for (const i of items) {
                        if (i.id === item.id) {
                          return false;
                        }
                      }
                      return true;
                    })
                    .map((item, idx) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.id} ({item.values})
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div
              className={cx('form-group', {
                hidden:
                  this.getObjectProp('finance.tax_groups', []).length === 0,
              })}
            >
              <div className="col-xs-8 col-xs-offset-4">
                <TokenGroup
                  items={this.getObjectProp('finance.tax_groups', [])}
                  onRemove={this.onRemoveToken.bind(this, 'finance.tax_groups')}
                />
              </div>
            </div>
          </FieldSection>

          <FieldSection
            guid={this.guid}
            name="product"
            legend={__('Produkty')}
            toggleable={this.props.toggleableSections}
            active={this.getObjectProp('product.use', true)}
            onChangeActive={this.onChangeObjectProp.bind(this, 'product.use')}
          >
            <div className="form-group">
              <label
                htmlFor="inputPassword4"
                className="col-xs-4 control-label"
              >
                {__('Produkt')}:
              </label>
              <div className="col-sm-6 col-xs-8">
                <Search
                  value={this.getLocal('product.plu')}
                  loadData={this.getProductOptions}
                  onSelect={item => {
                    if (item) {
                      this.onSubmitToken(
                        'product.plu',
                        null,
                        item.pro_short_name,
                      );
                    } else {
                      this.onSubmitToken(
                        'product.plu',
                        null,
                        this.getLocal('product.plu'),
                      );
                    }
                  }}
                  onChangeQuery={this.onChangeLocalValue.bind(
                    this,
                    'product.plu',
                  )}
                />
              </div>
            </div>

            {/*<div className="form-group">
                        <label htmlFor="inputPassword4" className="col-xs-4 control-label">{__('Produkt')}:</label>
                        <div className="col-sm-6 col-xs-8">
                            <TokenInput value={this.getLocal('product.plu')} onChange={this.onChangeLocal.bind(this, 'product.plu')} onSubmit={this.onSubmitToken.bind(this, 'product.plu')} />
                        </div>
                    </div>*/}
            <div
              className={cx('form-group', {
                hidden: this.getObjectProp('product.plu', []).length === 0,
              })}
            >
              <div className="col-xs-8 col-xs-offset-4">
                <TokenGroup
                  items={this.getObjectProp('product.plu', [])}
                  onRemove={this.onRemoveToken.bind(this, 'product.plu')}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">
                {__('Rozsah cien')}:
              </label>
              <div className="col-sm-4 col-xs-8">
                <input
                  className="form-control"
                  placeholder={__('cena od')}
                  onChange={this.onChangeObjectProp.bind(
                    this,
                    'product.price_from',
                  )}
                  value={this.getObjectProp('product.price_from')}
                />
              </div>
              <div className="col-sm-4 col-sm-offset-0 col-xs-8 col-xs-offset-4">
                <input
                  className="form-control"
                  placeholder={__('cena do')}
                  onChange={this.onChangeObjectProp.bind(
                    this,
                    'product.price_to',
                  )}
                  value={this.getObjectProp('product.price_to')}
                />
              </div>
            </div>
          </FieldSection>
        </section>
      );
    } catch (e) {
      console.error(e);
    }
  }
}

const FieldSection = props => {
  return (
    <fieldset className="fieldset">
      <legend>
        {props.legend}
        {props.toggleable ? (
          <small className="checkbox pull-right">
            <label htmlFor={`${props.name}-chk-${props.guid}`}>
              {__('Použiť globálny filter')}
            </label>
            <span>&nbsp;</span>
            <Checkbox
              id={`${props.name}-chk-${props.guid}`}
              checked={props.active}
              onChange={props.onChangeActive.bind(this)}
            />
          </small>
        ) : null}
      </legend>
      <div
        className={cx('text-center', {
          hidden: !props.toggleable || !props.active,
        })}
      >
        <em>{__('Používa sa globálny filter')}</em>
      </div>
      <div className={cx({ hidden: props.toggleable && props.active })}>
        {props.children}
      </div>
    </fieldset>
  );
};

GlobalFilterForm.defaultProps = {
  toggleableSections: false,
  vats: [],
};

export default GlobalFilterForm;
