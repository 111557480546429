import 'polyfill-array-includes';
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import './locale';
import './sass/app.scss';
import * as Cookies from 'js-cookie';
import Routes from './routes';

import 'moment/locale/sk';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import reducers from './reducers';
import Api from './service/Api';

// set base url
Api.baseUrl = process.env.REACT_APP_API_BASE_URL;

export const Acl = {
  acl: {},
};

// Disable zoom on mobile safari
document.addEventListener('gesturestart', function(e) {
  e.preventDefault();
});

let menuVisible = Cookies.get('menu_visible');
if (menuVisible === 'YES') {
  menuVisible = true;
} else if (menuVisible === 'NO') {
  menuVisible = false;
} else {
  menuVisible = window.innerWidth > 1200;
}

const initialState = {
  alert: null,
  selectCashRegister: null,
  user: null,
  filter: {},
  filterShow: false,
  saveCounter: 0,
  menuVisible: menuVisible,
  query: '',
};
const store = createStore(
  reducers,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root'),
);
