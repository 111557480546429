import React from 'react';
import {__, getProp} from '../../../utilities/common';
import Checkbox from './../../../component/form/checkbox';
import { FormGenerator } from './formGenerator';

export class UserFormGenerator extends FormGenerator {
  generateInputControl(property, value, editing = false) {
    if (property === 'right_name') {
      const definition = this.schemaProperties[property];
      const items = getProp(definition, 'items.enum', []);

      const onChange = (val, e) => {
        if (value.includes(val)) {
          value.splice(value.indexOf(val), 1);
        }
        if (e.target.checked) {
          value.push(val);
        }
        this.emitAction('change', {
          property,
          value,
        });
      };

      if (typeof value === 'undefined') {
        value = [];
      }

      let values = {
        CASHIER: __('Pokladník'),
        KITCHEN: __('Kuchyňa'),
        STORNO: __('Storno'),
        MANUAL_DISCOUNT: __('Udeľovanie zliav'),
        ORDER_MANAGEMENT: __('Tvorba a zrušenie účtov'),
        ORDER_REPAIR: __('Úprava účtu'),
        JOURNAL_X: __('X uzávierka'),
        JOURNAL_Z: __('Z uzávierka'),
        JOURNAL_INTERVAL: __('Intervalová uzávierka'),
        JOURNAL_RANGED: __('Rozsahová uzávierka'),
        HISTORY_OF_REVENUE: __('Zobrazenie histórie tržieb'),
        CLIENT_REGISTRATION: __('Registrácia klienta'),
        CASH_DEPOSIT: __('Vklad'),
        CASH_WITHDRAW: __('Výber'),
        INVOICE_PAYMENT: __('Úhrada faktúry'),
        DAEMON_SETTINGS: __('Nastavenia'),
        PRODUCT_MANAGEMENT: __('Správa produktov'),
        ROOM_MANAGEMENT: __('Správa miestností a stolov'),
      };

      const onCheckAll = () => {
        let _value = [];

        if (value.length !== items.length) {
          for (let key in items) {
            if (items.hasOwnProperty(key)) {
              _value.push(items[key]);
            }
          }
        }

        value = _value;

        this.emitAction('change', {
          property,
          value,
        });
      };

      return (
        <div>
          <div className="checkbox">
            <Checkbox
                id="role-chk-all"
                checked={value.length > 0 && value.length === items.length}
                onChange={onCheckAll}
            />
            <label htmlFor="role-chk-all">{__('Vybrať všetko')}</label>
          </div>
          <div>&nbsp;</div>
          {definition.items.enum
            .filter(val => val !== 'COLLEAGUE_ACCESS' && val !== 'BONUS_SYSTEM')
            .map((val, idx) => {
              return (
                <div key={val} className="checkbox">
                  <Checkbox
                    id={`role-chk-${idx}`}
                    checked={value.includes(val)}
                    onChange={onChange.bind(this, val)}
                  />
                  <label htmlFor={`role-chk-${idx}`}>{values[val]}</label>
                </div>
              );
            })}
        </div>
      );
    } else {
      return super.generateInputControl(property, value);
    }
  }
}
