import cx from 'classnames';
import React from 'react';
import Icon from './../component/icon';

const Placeholder = props => {
  return (
    <span
      className={cx('clickable content-placeholder', {
        hidden: props.show !== true,
        active: props.active,
      })}
      onClick={props.onClick.bind(null)}
    >
      <Icon name={props.iconName} />
    </span>
  );
};

Placeholder.defaultProps = {
  active: false,
  show: true,
  iconName: 'plus-math',
  onClick: () => {},
};

export default Placeholder;
