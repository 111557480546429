import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../component/icon';
import {getProp} from '../utilities/common';
import Checkbox from "./form/checkbox";

const DataTable = props => {
  const getClassName = idx => {
    if (typeof props.classNames[idx] !== 'undefined' && props.classNames[idx]) {
      return props.classNames[idx];
    }
    return null;
  };

  const getTd = (colIdx, col) => {
    return (
      <td
        key={colIdx}
        data-label={props.cols[colIdx]}
        className={getClassName(colIdx)}
      >
        {col}
      </td>
    );
  };

  const onSelectItem = (idx) => {
    props.onSelectItem(idx);
  };

  let rows = [];
  rows = props.rows.map((row, idx) => {
    let checkbox = null;
    if (props.selectableItems) {
      checkbox = <td>
        <div className="checkbox">
          <Checkbox
            id={`item-${idx}`}
            onChange={(e) => {onSelectItem(idx)}}
            checked={props.selectedItems.indexOf(idx) !== -1}
            value={(idx + 1).toString()}
          />
        </div>
      </td>;
    }

    if (typeof row._data !== 'undefined') {
      return (
        <tr
          key={idx}
          title={getProp(row, 'title')}
          className={getProp(row, 'className')}
        >
          {checkbox}
          {row._data.map((col, idx) => getTd(idx, col))}
        </tr>
      );
    } else {
      return <tr key={idx}>
          {checkbox}
          {row.map((col, idx) => getTd(idx, col))}
        </tr>;
    }
  });

  const getSortIcon = sort => {
    if (props.currentSort === `${sort}`) {
      return <Icon name="collapse-arrow" />;
    } else if (props.currentSort === `-${sort}`) {
      return <Icon name="expand-arrow" />;
    }
    return null;
  };

  const onSelectAllItems = e => {
    const selectAll = e.target.checked;
    props.onSelectAllItems(selectAll);
  };

  return (
    <table className="table table-respo">
      {props.cols.length > 0 ? (
        <thead>
          <tr>
            {props.selectableItems ? <th>
              <div className="check-all">
                <Checkbox
                  onChange={(e) => {onSelectAllItems(e)}}
                />
              </div>
            </th> : null}
            {props.cols.map((col, idx) => {
              return (
                <th key={idx} className={getClassName(idx)}>
                  {props.sortableColsIdx.includes(idx) ? (
                    <span
                      className="clickable"
                      onClick={() => {
                        if (props.currentSort === null) {
                          props.onSort(`${idx}`);
                        } else if (props.currentSort === `${idx}`) {
                          props.onSort(`-${idx}`);
                        } else if (props.currentSort === `-${idx}`) {
                          props.onSort(null);
                        } else {
                          props.onSort(`${idx}`);
                        }
                      }}
                    >
                      {col} {getSortIcon(idx)}
                    </span>
                  ) : (
                    col
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
      ) : null}
      {props.rows.length > 0 ? <tbody>{rows}</tbody> : null}
    </table>
  );
};

DataTable.propTypes = {
  sortableColsIdx: PropTypes.arrayOf(PropTypes.number),
  currentSort: PropTypes.string,
  onSort: PropTypes.func,
  selectableItems: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(PropTypes.number),
  onSelectItem: PropTypes.func,
  onSelectAllItems: PropTypes.func,
};

DataTable.defaultProps = {
  cols: [],
  rows: [],
  classNames: [],
  sortableColsIdx: [],
  currentSort: null,
  selectableItems: false,
  selectedItems: [],
  onSelectItem: () => {},
  onSelectAllItems: () => {},
  onSort: () => {},
};

export default DataTable;
