import React from 'react';
import Icon from './../icon';

const Checkbox = props => {
  return (
    <span>
      <input type="checkbox" id={props.id} {...props} />
      <label htmlFor={props.id}>
        <Icon name="unchecked-checkbox" className="checkbox-unchecked" />
        <Icon name="checked-checkbox" className="checkbox-checked" />
      </label>
    </span>
  );
};

export default Checkbox;
