import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Api from '../../service/Api';
import { getProp, setProp, __ } from '../../utilities/common';
import { setAlert } from './../../actions';
import Loader from './../../component/loader';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      savingLoader: false,
    };
  }

  getObjectProp(prop, defaultValue = '') {
    return getProp(this.state, prop, defaultValue);
  }

  onChangeObjectProp(prop, ev) {
    this.setState(setProp(this.state, prop, ev.target.value));
  }

  onSubmit(ev) {
    ev.preventDefault();

    const obj = {
      old_password: this.getObjectProp('oldPassword'),
      password: this.getObjectProp('newPassword'),
    };

    if (
      this.getObjectProp('newPassword') !== this.getObjectProp('newPassword2')
    ) {
      this.props.dispatch(
        setAlert({
          type: 'warning',
          title: __('Heslá sa nezhodujú'),
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
    } else {
      this.setState({ savingLoader: true });
      Api.post(`/user/passwd`, JSON.stringify(obj))
        .then(() => {
          this.setState({ savingLoader: false });
          this.context.router.push(this.props.returnUrl);
        })
        .catch(error => {
          this.setState({ savingLoader: false });
          if (error.response.status === 403) {
            this.props.dispatch(
              setAlert({
                type: 'warning',
                title: __('Nesprávne staré heslo'),
                onConfirm: () => {
                  this.props.dispatch(setAlert(null));
                },
              }),
            );
          } else {
            this.props.dispatch(
              setAlert({
                type: 'error',
                title: __('Pri ukladaní hesla došlo k chybe'),
                onConfirm: () => {
                  this.props.dispatch(setAlert(null));
                },
              }),
            );
          }
        });
    }
  }

  render() {
    return (
      <div>
        <h2 className="side-panel-title">{__('Zmena hesla')}</h2>
        <hr />
        <Loader show={this.state.savingLoader}>
          <form className="form-horizontal" onSubmit={this.onSubmit.bind(this)}>
            <fieldset className="fieldset">
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Staré heslo')}:
                </label>
                <div className="col-sm-4">
                  <input
                    type="password"
                    className="form-control"
                    onChange={this.onChangeObjectProp.bind(this, 'oldPassword')}
                    value={this.getObjectProp('oldPassword')}
                    required={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Nové heslo')}:
                </label>
                <div className="col-sm-4">
                  <input
                    type="password"
                    className="form-control"
                    onChange={this.onChangeObjectProp.bind(this, 'newPassword')}
                    value={this.getObjectProp('newPassword')}
                    required={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Nové heslo znova')}:
                </label>
                <div className="col-sm-4">
                  <input
                    type="password"
                    className="form-control"
                    onChange={this.onChangeObjectProp.bind(
                      this,
                      'newPassword2',
                    )}
                    value={this.getObjectProp('newPassword2')}
                    required={true}
                  />
                </div>
              </div>
            </fieldset>
            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                {__('Uložiť')}
              </button>
            </div>
          </form>
        </Loader>
      </div>
    );
  }
}

Form.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({
  user: state.user,
}))(Form);
