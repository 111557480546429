import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Api from '../../../service/Api';
import { getProp, setProp, __ } from '../../../utilities/common';
import Datepicker from '../../../component/form/datepicker';
import { setAlert } from './../../../actions';
import Checkbox from './../../../component/form/checkbox';
import Loader from './../../../component/loader';
import ProtectedContent from './../../../component/protectedContent';
import TaxSummary, {
  generateTaxItems,
} from './../../../component/translog/taxSummary';
import Totals, { getTotals } from './../../../component/translog/totals';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paragon: {
        items: [],
        date: null,
      },
      loading: true,
      vatListObj: {},
      savingLoader: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  init(props) {
    this.setState({
      paragon: {
        currency: getProp(props, 'user.company.currency'),
        items: [],
        date: moment(),
      },
    });

    Api.get(`/user/cash-registers/${this.props.params.id}/setup/vat`)
      .then(response => {
        const vatListObj = {};

        if (response.items.length === 0) {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Pokladnica nemá nastavené daňové hladiny'),
              confirmButtonColor: '#DD6B55',
              cancelButtonColor: '#d33',
              showCancelButton: true,
              confirmButtonText: __('Nastaviť'),
              cancelButtonText: __('Zrušiť'),
              buttonsStyling: false,
              onConfirm: () => {
                this.context.router.push(
                  `/app/cash-register/${
                    this.props.params.id
                  }/remote-access?default=vat`,
                );
                this.props.dispatch(setAlert(null));
              },
              onCancel: () => {
                this.context.router.push(
                  `/app/cash-register/${this.props.params.id}/view`,
                );
                this.props.dispatch(setAlert(null));
              },
            }),
          );
        }

        for (let vat of response.items) {
          vatListObj[vat.vat_id] = vat;
        }
        this.setState({ vatListObj: vatListObj, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  componentDidMount() {
    this.init(this.props);
  }

  onChangeObjectProp(prop, ev) {
    let value = ev.target.value;
    if (ev.target.type && ev.target.type === 'checkbox') {
      value = ev.target.checked;
    }
    const obj = setProp(this.state.paragon, prop, value);
    this.setState({ paragon: obj });
  }

  onChangeDate(date) {
    const obj = setProp(this.state.paragon, 'date', date);
    this.setState({ paragon: obj });
  }

  getObjectProp(prop, defaultValue = '') {
    return getProp(this.state.paragon, prop, defaultValue);
  }

  getTaxRate(vat_id) {
    for (let vat of this.state.vatList) {
      if (vat.vat_id === vat_id) {
        return Number(vat.vat_value) / 100;
      }
    }
    return 0;
  }

  onChangeItemProp(idx, prop, ev) {
    const obj = this.state.paragon;
    let item = obj.items[idx];
    let value = ev.target.value;
    if (
      ['unit_price', 'unit_price_tx', 'items_price', 'items_price_tx'].includes(
        prop,
      )
    ) {
      item = setProp(this.state.paragon.items[idx], `_${prop}`, value);
    }
    item = setProp(this.state.paragon.items[idx], prop, value);

    const taxRate =
      Number(this.state.vatListObj[getProp(item, 'tax_id', '')].vat_value) /
      100;

    if (prop !== 'unit_price') {
      item = setProp(
        item,
        'unit_price',
        (getProp(item, 'unit_price_tx', 0) / (100 + taxRate)) * 100,
      );
      item = setProp(
        item,
        '_unit_price',
        ((getProp(item, 'unit_price_tx', 0) / (100 + taxRate)) * 100).toFixed(
          2,
        ),
      );
    }
    if (prop !== 'unit_price_tx') {
      item = setProp(
        item,
        'unit_price_tx',
        (getProp(item, 'unit_price', 0) * (100 + taxRate)) / 100,
      );
      item = setProp(
        item,
        '_unit_price_tx',
        ((getProp(item, 'unit_price', 0) * (100 + taxRate)) / 100).toFixed(2),
      );
    }
    if (prop !== 'items_price') {
      item = setProp(
        item,
        'items_price',
        getProp(item, 'unit_price', 0) * item.quantity,
      );
      item = setProp(
        item,
        '_items_price',
        (getProp(item, 'unit_price', 0) * item.quantity).toFixed(2),
      );
    }
    if (prop !== 'items_price_tx') {
      item = setProp(
        item,
        'items_price_tx',
        getProp(item, 'unit_price_tx', 0) * item.quantity,
      );
      item = setProp(
        item,
        '_items_price_tx',
        (getProp(item, 'unit_price_tx', 0) * item.quantity).toFixed(2),
      );
    }

    this.setState({ paragon: obj });
  }

  getItemProp(idx, prop, defaultValue = '') {
    return getProp(this.state.paragon.items[idx], prop, defaultValue);
  }

  onClickAddItem() {
    const paragon = this.state.paragon;
    paragon.items.push({
      operation_type: 'sale',
      quantity: 1,
      tax_id: Object.keys(this.state.vatListObj)[0],
    });
    this.setState({ paragon: paragon });
  }

  onClickRemoveItem(idx) {
    const paragon = this.state.paragon;
    paragon.items.splice(idx, 1);
    this.setState({ paragon: paragon });
  }

  onChangeTaxSummary(data) {
    const paragon = this.state.paragon;
    paragon.taxSummary = data;
    this.setState({ paragon: paragon });
  }

  onChangeTotals(data) {
    const paragon = this.state.paragon;
    paragon.totals = data;
    this.setState({ paragon: paragon });
  }

  onSubmit(ev, repeat = false) {
    ev.preventDefault();

    this.setState({ savingLoader: true });

    let err = false;

    const datetime = getProp(this.state.paragon, 'date', moment());
    datetime.startOf('date');

    if (!datetime) {
      this.props.dispatch(
        setAlert({
          type: 'warning',
          title: __('Vyplňte dátum'),
          onConfirm: () => {
            this.props.dispatch(setAlert(null));
          },
        }),
      );
    }

    // Check time
    const time = getProp(this.state.paragon, 'time');
    const pattern = /^[0-2]?\d:\d{2}$/;
    if (!pattern.test(time)) {
      this.props.dispatch(
        setAlert({
          type: 'warning',
          title: __('Zadajte čas vo formáte 00:00'),
          onConfirm: () => {
            this.props.dispatch(setAlert(null));
          },
        }),
      );
      err = true;
    }

    // Set time
    const timeParsed = time.split(':');
    datetime.hours(timeParsed[0]);
    datetime.minutes(timeParsed[1]);

    // Do we have any items?
    if (this.state.paragon.items.length === 0) {
      this.props.dispatch(
        setAlert({
          type: 'warning',
          title: __('Pridajte aspoň jednu položku'),
          onConfirm: () => {
            this.props.dispatch(setAlert(null));
          },
        }),
      );
      err = true;
    }

    let i = 0;
    for (const item of this.state.paragon.items) {
      if (!item.name) {
        this.props.dispatch(
          setAlert({
            type: 'warning',
            title: __('Vyplňte názov položky %(idx)s').format({ idx: i + 1 }),
            onConfirm: () => {
              this.props.dispatch(setAlert(null));
            },
          }),
        );
        err = true;
        break;
      }
      item.quantity = Number(item.quantity);
      item.unit_price = Number(item.unit_price);
      item.unit_price_tx = Number(item.unit_price_tx);
      item.items_price = Number(item.items_price);
      item.items_price_tx = Number(item.items_price_tx);
      i++;
    }

    if (err) {
      this.setState({ savingLoader: false });
      return;
    }

    const totals = getTotals(this.state.paragon.items);

    let operator_id = getProp(this.state.paragon, 'operator_id');
    let operator_name = '';
    if (!operator_id) {
      operator_id = getProp(this.props.user, 'username');
    }

    const obj = {
      receipt_id: getProp(this.state.paragon, 'receipt_id'),
      ej_id: getProp(this.state.paragon, 'ej_id'),
      operator_id: operator_id,
      operator_name: operator_name,
      datetime: datetime.toISOString(),
      currency: getProp(this.props.user, 'company.currency'),
      payments: [
        {
          type: 'cash',
          paid_amount: totals.total_price_tx,
        },
      ],
      items: this.state.paragon.items,
      total: totals,
      tax_items: generateTaxItems(
        this.state.paragon.items,
        this.state.vatListObj,
      ),
    };

    let url = `/user/cash-registers/${this.props.params.id}/paragons/`;
    if (getProp(this.state.paragon, 'fiction', false)) {
      url += 'fiction';
    } else {
      url += 'real';
    }

    Api.post(url, JSON.stringify(obj))
      .then(() => {
        this.setState({ savingLoader: false });
        if (repeat) {
          this.init(this.props);
        } else {
          this.props.onSuccess();
          this.context.router.push(this.props.returnUrl);
        }
      })
      .catch(error => {
        this.setState({ savingLoader: false });
        if (error.response.status === 403) {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Do tejto pokladne nemáte práve pridávať paragony'),
              onConfirm: () => {
                this.props.dispatch(setAlert(null));
              },
            }),
          );
        } else if (error.response.status === 409) {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __(
                'Bloček s receipt id %(receipt_id)s už existuje',
              ).format({ receipt_id: this.state.paragon.receipt_id }),
              onConfirm: () => {
                this.props.dispatch(setAlert(null));
              },
            }),
          );
        } else {
          this.props.dispatch(
            setAlert({
              type: 'error',
              title: __('Pri ukladaní paragonu došlo k chybe'),
              onConfirm: () => {
                this.props.dispatch(setAlert(null));
              },
            }),
          );
        }
      });
  }

  onClickSave(ev) {
    this.onSubmit(ev);
  }

  onClickSaveRepeat(ev) {
    this.onSubmit(ev, true);
  }

  render() {
    return (
      <ProtectedContent
        resource="bm.paragon_import"
        action="enabled"
        showWarning
      >
        <div>
          <h2 className="side-panel-title">{__('Nový paragon')}</h2>
          <hr />
          <Loader show={this.state.savingLoader || this.state.loading}>
            <form className="form-horizontal" onSubmit={this.onSubmit}>
              <fieldset className="fieldset fieldset-root">
                <legend>{__('Základné údaje')}</legend>
                <div className="form-group">
                  <label className="col-sm-4 control-label">
                    {__('Receipt ID')}:
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={__('(nepovinné)')}
                      onChange={this.onChangeObjectProp.bind(
                        this,
                        'receipt_id',
                      )}
                      value={this.getObjectProp('receipt_id')}
                      required={true}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-4 control-label">
                    {__('EJ ID')}:
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={__('(nepovinné)')}
                      onChange={this.onChangeObjectProp.bind(this, 'ej_id')}
                      value={this.getObjectProp('ej_id')}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-4 control-label">
                    {__('ID operátora')}:
                  </label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={__('(nepovinné)')}
                      onChange={this.onChangeObjectProp.bind(
                        this,
                        'operator_id',
                      )}
                      value={this.getObjectProp('operator_id')}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-4 control-label">
                    {__('Dátum')}:
                  </label>
                  <div className="col-sm-4">
                    <Datepicker
                      selected={this.getObjectProp('date')}
                      onChange={this.onChangeDate.bind(this)}
                      required={true}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-4 control-label">{__('Čas')}:</label>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="00:00"
                      onChange={this.onChangeObjectProp.bind(this, 'time')}
                      value={this.getObjectProp('time')}
                      required={true}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-xs-4 control-label">
                    {__('Autenticita')}:{' '}
                  </label>
                  <div className="col-sm-4 col-xs-8 checkbox">
                    <Checkbox
                      id={`fictiondata-chk-form`}
                      checked={this.getObjectProp('fiction', false)}
                      onChange={this.onChangeObjectProp.bind(this, 'fiction')}
                    />
                    <label htmlFor={`fictiondata-chk-form`}>
                      {__('Paragon je fiktívny')}
                    </label>
                  </div>
                </div>
              </fieldset>
              <fieldset className="fieldset fieldset-root">
                <legend>{__('Položky')}</legend>
                {this.state.paragon.items.map((item, idx) => {
                  return (
                    <Item
                      key={idx}
                      vatListObj={this.state.vatListObj}
                      idx={idx}
                      onChangeProp={this.onChangeItemProp.bind(this)}
                      getProp={this.getItemProp.bind(this)}
                      onRemove={this.onClickRemoveItem.bind(this, idx)}
                    />
                  );
                })}
                <div className="text-center">
                  <span
                    onClick={this.onClickAddItem.bind(this)}
                    className="btn btn-primary-o"
                  >
                    {__('Pridať ďalšiu položku')}
                  </span>
                </div>
              </fieldset>
              <TaxSummary
                vatListObj={this.state.vatListObj}
                items={this.state.paragon.items}
                currency={getProp(this.props.user, 'company.currency')}
              />
              <Totals
                items={this.state.paragon.items}
                currency={getProp(this.props.user, 'company.currency')}
              />
              <div className="text-center">
                <button
                  type="button"
                  onClick={this.onClickSave.bind(this)}
                  className="btn btn-primary"
                >
                  {__('Uložiť')}
                </button>
                <span>&nbsp;</span>
                <button
                  type="button"
                  onClick={this.onClickSaveRepeat.bind(this)}
                  name="more"
                  className="btn btn-primary"
                >
                  {__('Uložiť a pridať ďalší')}
                </button>
              </div>
            </form>
          </Loader>
        </div>
      </ProtectedContent>
    );
  }
}

const Item = props => {
  return (
    <fieldset className="fieldset">
      <legend>
        {__('Položka')} {props.idx + 1}
      </legend>
      <div className="form-group">
        <label className="col-sm-4 control-label">{__('EAN')}:</label>
        <div className="col-sm-4">
          <input
            type="text"
            className="form-control"
            placeholder={__('(nepovinné)')}
            onChange={props.onChangeProp.bind(null, props.idx, 'ean')}
            value={props.getProp(props.idx, 'ean')}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{__('PLU')}:</label>
        <div className="col-sm-4">
          <input
            type="text"
            className="form-control"
            placeholder={__('(nepovinné)')}
            onChange={props.onChangeProp.bind(null, props.idx, 'plu')}
            value={props.getProp(props.idx, 'plu')}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{__('Názov')}:</label>
        <div className="col-sm-4">
          <input
            type="text"
            className="form-control"
            onChange={props.onChangeProp.bind(null, props.idx, 'name')}
            value={props.getProp(props.idx, 'name')}
            required={true}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{__('Počet')}:</label>
        <div className="col-sm-4">
          <input
            type="number"
            step="any"
            min="1"
            className="form-control"
            onChange={props.onChangeProp.bind(null, props.idx, 'quantity')}
            value={props.getProp(props.idx, 'quantity')}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">{__('Daň')}:</label>
        <div className="col-sm-4">
          <select
            className="form-control"
            onChange={props.onChangeProp.bind(null, props.idx, 'tax_id')}
            value={props.getProp(props.idx, 'tax_id')}
          >
            {Object.keys(props.vatListObj).map(vat_id => {
              const item = props.vatListObj[vat_id];
              return (
                <option key={vat_id} value={vat_id}>{`${item.vat_name}, ${(
                  item.vat_value / 100
                ).toFixed(2)}%`}</option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">
          {__('Jednotková cena s DPH')}:
        </label>
        <div className="col-sm-4">
          <input
            type="number"
            step="any"
            className="form-control"
            onChange={props.onChangeProp.bind(null, props.idx, 'unit_price_tx')}
            value={props.getProp(props.idx, '_unit_price_tx')}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">
          {__('Jednotková cena bez DPH')}:
        </label>
        <div className="col-sm-4">
          <input
            type="number"
            step="any"
            className="form-control"
            onChange={props.onChangeProp.bind(null, props.idx, 'unit_price')}
            value={props.getProp(props.idx, '_unit_price')}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">
          {__('Celková cena s DPH')}:
        </label>
        <div className="col-sm-4">
          <input
            type="number"
            step="any"
            className="form-control"
            onChange={props.onChangeProp.bind(
              null,
              props.idx,
              'items_price_tx',
            )}
            value={props.getProp(props.idx, '_items_price_tx')}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="col-sm-4 control-label">
          {__('Celková cena bez DPH')}:
        </label>
        <div className="col-sm-4">
          <input
            type="number"
            step="any"
            className="form-control"
            onChange={props.onChangeProp.bind(null, props.idx, 'items_price')}
            value={props.getProp(props.idx, '_items_price')}
          />
        </div>
      </div>
      <div className="text-center">
        <span
          className="btn btn-danger-o"
          onClick={props.onRemove.bind(null)}
          title={__('Odstrániť položku')}
        >
          {__('Odstrániť položku')}
        </span>
      </div>
    </fieldset>
  );
};

Form.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({
  user: state.user,
}))(Form);
