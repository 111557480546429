import React from 'react';
import DatePickerComponent from 'react-datepicker';
import TextInput from '../form/textInput';
import Icon from '../icon';

require('react-datepicker/dist/react-datepicker.css');

class Datepicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };

    this.datepicker = null;
  }

  onClickCalendar() {
    this.datepicker.setFocus(true);
  }

  onFocus() {
    this.setState({ focused: true });
  }

  onBlur() {
    this.setState({ focused: false });
  }

  render() {
    return (
      <div className="input-group">
        <DatePickerComponent
          {...this.props}
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
          onChange={
            typeof this.props.onChange === 'function'
              ? this.props.onChange.bind(this)
              : null
          }
          selected={
            this.props.selected
              ? this.props.selected.toDate()
              : this.props.selected
          }
          className="form-control"
          ref={el => {
            this.datepicker = el;
          }}
        />
        <TextInput
          className="datepicker"
          focused={this.state.focused || this.props.selected}
          label={this.props.label}
        />
        <span className="input-group-btn">
          <button
            className="btn btn-primary-o"
            type="button"
            onClick={this.onClickCalendar.bind(this)}
          >
            <Icon name="calendar" />
          </button>
        </span>
      </div>
    );
  }
}

export default Datepicker;
