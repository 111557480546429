import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import Api from '../../service/Api';
import { getProp, __, createUrl } from '../../utilities/common';
import { setAlert } from './../../actions';
import Content from './../../component/content';
import DataFilter from './../../component/dataFilter';
import DataTable from './../../component/dataTable';
import FloatingActionButton from './../../component/floatingActionButton';
import Icon from './../../component/icon';
import Loader from './../../component/loader';
import MainHeader from './../../component/mainHeader';
import ProtectedContent from './../../component/protectedContent';
import LoadMoreBtn from './../../component/loadMoreBtn';
import SidePanel from './../../component/sidePanel';

export const BASE_URL = '/app/partners';
export const LIMIT = 1000;

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      partners: [],

      offset: parseInt(props.location.query.offset, 10) || 0,
      nextOffset: null,
      loadedOnce: false
    };
  }

  componentDidMount() {
    this.loadPartners(this.state.offset);
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { location: { query: { offset: nextOffset } } } = nextProps;
    const { location: { query: { offset } } } = this.props;

    if (nextOffset && offset !== nextOffset) {
      this.loadPartners(nextOffset);
    }
  }

  loadPartners = async (offset = 0, loadAll = false) => {
    const { loadedOnce } = this.state;

    this.setState({ isFetching: true });

    let limit = LIMIT;

    if (!loadedOnce) {
      limit = LIMIT + offset;
      offset = 0;
    }

    try {
      const response = await Api.get('/admin/partners', { limit, offset });
      this.setState({
        isFetching: false,
        partners: parseInt(offset, 10) === 0 ? response.partners : [...this.state.partners, ...response.partners],
        nextOffset: response.next_offset,
        loadedOnce: true,
      });
    } catch (error) {
      console.error(error);

      this.props.dispatch(
        setAlert({
          type: 'error',
          title: __('Nebolo možné načítať zoznam partnerov'),
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
      this.setState({ isFetching: false });
    }
  }

  render() {
    const { sidepanel } = this.props;
    const { nextOffset, isFetching, partners, offset } = this.state;

    let onSuccessSidePanel = () => {
      this.loadPartners(offset);
    };

    let onCloseSidePanel = () => {
      this.context.router.push(createUrl(BASE_URL, { offset }))
    }

    const searchData = partners.map(item => {
      return {
        _id: item._id,
        partner_id: item.partner_id,
        company_name: getProp(item, 'company_name', '-'),
      };
    });

    return (
      <div id="content-wrapper partners">
        <MainHeader
          title={__('Partneri')}
          progress={100}
        />
        <Content>
          <Loader show={isFetching}>
            {(searchData.length === 0 && !isFetching) ?
              <h4 className={'text-center'}>
                {__('Žiadny partneri')}
              </h4>
              :
              <React.Fragment>
                <DataFilter data={searchData}>
                  {items => {
                    return (
                      <div>
                        <DataTable
                          cols={[__('Partner ID'), __('Názov'), '']}
                          rows={items.map(item => {
                            return [
                              <Link to={createUrl([BASE_URL, item._id])}>
                                {item.partner_id}
                              </Link>,
                              <Link to={createUrl([BASE_URL, item._id])}>
                                {getProp(item, 'company_name', '-')}
                              </Link>,
                              <span>
                                <Link
                                  to={`/app/partners/${item._id}/edit`}
                                  className="btn btn-primary-o btn-circle"
                                >
                                  <Icon name="edit" />
                                </Link>
                              </span>
                              ,
                            ];
                          })}
                          classNames={[null, null, 'text-right']}
                        />
                        {(items.length === 0 && !isFetching) &&
                          <h4
                            className={'text-center'}
                          >
                            {__('S vyhľadávaným textom sa nezhoduje žiaden partner')}
                          </h4>
                        }
                      </div>
                    );
                  }}
                </DataFilter>

                <LoadMoreBtn
                  nextOffset={nextOffset}
                  onClick={() => this.context.router.push(createUrl(BASE_URL, {
                    offset: nextOffset
                  }))}
                />
              </React.Fragment>
            }
          </Loader>

          {!this.props.remoteAccess && (
            <ProtectedContent resource="cash_register" action="create">
              <FloatingActionButton
                linkTo={createUrl([BASE_URL, 'add'])}
                title={__('Pridať partnerskú firmu')}
              />
            </ProtectedContent>
          )}
          <div className="cleaner" />
        </Content>
        {sidepanel && (
          <SidePanel
            onSuccess={onSuccessSidePanel}
            onClose={onCloseSidePanel}
            returnUrl={createUrl(BASE_URL, { offset: this.state.offset })}
            parameters={{ baseUrl: BASE_URL }}
          >
            {sidepanel}
          </SidePanel>
        )}
      </div>
    );
  }
}

List.contextTypes = {
  router: PropTypes.object
};

List.defaultProps = {
  remoteAccess: false,
};

export default connect()(List);
