import Cookies from 'js-cookie';
import React from 'react';
import AppAuthorized from './../module/appAuthorized';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static logout(context) {
    Cookies.remove('token');
    context.router.push('/external/login');
  }

  render() {
    return (
      <AppAuthorized>
        <div id="container">
          <div id="content">{this.props.children}</div>
        </div>
      </AppAuthorized>
    );
  }
}

export default App;
