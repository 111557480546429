import cx from 'classnames';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import Api from '../../service/Api';
import {__, getProp} from '../../utilities/common';
import {setAlert} from './../../actions';
import Content from './../../component/content';
import DataTable from './../../component/dataTable';
import Icon from './../../component/icon';
import Loader from './../../component/loader';
import MainHeader from './../../component/mainHeader';
import SidePanel from './../../component/sidePanel';
import ProtectedContent from './../../component/protectedContent';
import FloatingActionButton from './../../component/floatingActionButton';
import LoadMoreBtn from './../../component/loadMoreBtn';

class List extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadEcrGroupsLoader: false,
            ecrGroups: [],
            offset: 0,
            nextOffset: null,
            limit: 100
        };
    }

    componentDidMount() {
        this.loadEcrGroups();
    }

    loadEcrGroups = (_limit = null, _offset = 0) => {
        const {ecrGroups} = this.state;
        const {dispatch} = this.props;
        let {limit, offset} = this.state;

        if (_limit) {
            limit = _limit;
            offset = _offset;
        }

        // nacitame zoznam updatov
        this.setState({
            loadEcrGroupsLoader: true
        }, () => {
            const params = {
                limit,
                offset
            };

            Api.get('/admin/ecrgroups', params)
                .then(response => {
                    this.setState({
                        loadEcrGroupsLoader: false,
                        ecrGroups: (offset === 0 ? response.ecrgroups : ecrGroups.concat(response.ecrgroups)),
                        nextOffset: response.next_offset
                    });
                })
                .catch(response => {
                    dispatch(
                        setAlert({
                            type: 'error',
                            title: __('Nebolo možné načítať zoznam ECR skupín'),
                            onConfirm: () => dispatch(setAlert(null))
                        })
                    );

                    this.setState({
                        loadEcrGroupsLoader: false
                    });
                });
        });
    };

    onClickDeleteEcrGroup = (ecrGroup) => {
        const {dispatch} = this.props;

        dispatch(
            setAlert({
                type: 'warning',
                title: __('Ste si istý?'),
                confirmButtonColor: '#DD6B55',
                cancelButtonColor: '#d33',
                showCancelButton: true,
                confirmButtonText: __('Áno, zmazať'),
                cancelButtonText: __('Nie, zrušiť'),
                buttonsStyling: false,
                text: __("Naozaj chcete zmazať ECR skupinu '%(name)s'?").format({
                    name: ecrGroup.name,
                }),
                onConfirm: () => {
                    this.deleteEcrGroup(ecrGroup._id);
                },
                onCancel: () => dispatch(setAlert(null)),
            }),
        );
    };

    deleteEcrGroup = (ecrGroupId) => {
        const {limit, offset} = this.state;
        const {dispatch} = this.props;

        this.setState({
            loadEcrGroupsLoader: true
        });

        Api.delete(`/admin/ecrgroups/${ecrGroupId}`)
            .then(response => {
                dispatch(
                    setAlert({
                        type: 'success',
                        title: __('ECR skupina bola zmazaná'),
                        onConfirm: () => dispatch(setAlert(null)),
                    }),
                );

                this.loadEcrGroups(limit + offset, 0);
            })
            .catch(error => {
                dispatch(
                    setAlert({
                        type: 'error',
                        title: __('Pri mazaní ECR skupiny došlo k chybe'),
                        onConfirm: () => dispatch(setAlert(null)),
                    }),
                );

                this.setState({
                    loadEcrGroupsLoader: false
                });
            });
    };

    render() {
        const {ecrGroups, limit, offset, nextOffset, loadEcrGroupsLoader} = this.state;
        const {sidepanel} = this.props;

        const onSuccessSidePanel = (limit = null, offset = 0) => {
            this.loadEcrGroups(limit, offset);
        };

        return (
            <div id="content-wrapper">
                <MainHeader
                    title={__('Skupiny ECR')}
                    progress={100}
                />
                <Content>
                    <Loader show={loadEcrGroupsLoader}>
                        <div>
                            <DataTable
                                cols={[__('Názov'), '']}
                                rows={ecrGroups.map(i => {
                                    let item = {};

                                    for (let c of ecrGroups) {
                                        if (c._id === i._id) {
                                            item = c;
                                            break;
                                        }
                                    }

                                    return [
                                        <Link to={'/app/ecr-groups/' + item._id + '/edit'}>
                                            {getProp(item, 'name', '-')}
                                        </Link>,
                                        <Fragment>
                                            <Link to={'/app/ecr-groups/' + item._id + '/edit'} className="btn btn-primary-o btn-circle">
                                                <Icon name="edit" />
                                            </Link>
                                            &nbsp;
                                            <button
                                                onClick={() => this.onClickDeleteEcrGroup(item)}
                                                className="btn btn-danger-o btn-circle"
                                                title={__('Zmazať')}
                                            >
                                                <Icon name="remove" />
                                            </button>
                                        </Fragment>
                                    ];
                                })}
                                classNames={[null, 'text-right']} />
                            <h4 className={cx('text-center', {
                                hidden: ecrGroups.length > 0
                            })}>
                                {__('Žiadne aktualizácie')}
                            </h4>
                        </div>
                        <LoadMoreBtn
                            nextOffset={nextOffset}
                            onClick={() => {
                                this.setState({
                                    offset: nextOffset
                                }, this.loadEcrGroups)
                            }}
                        />
                    </Loader>
                    <ProtectedContent resource="otaupdates">
                        <FloatingActionButton
                            linkTo="/app/ecr-groups/add"
                            title={__('Pridať skupinu')}
                        />
                    </ProtectedContent>
                    <div className="cleaner" />
                </Content>
                {sidepanel ? (
                    <SidePanel
                        onSuccess={onSuccessSidePanel}
                        returnUrl="/app/ecr-groups"
                        parameters={{limit, offset}}
                    >
                        {sidepanel}
                    </SidePanel>
                ) : null}
            </div>
        )
    }
}

List.defaultProps = {};

export default connect(state => ({}))(List);
