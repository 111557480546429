import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Api from '../../../service/Api';
import { __ } from '../../../utilities/common';
import DataTable from './../../../component/dataTable';
import Icon from './../../../component/icon';
import Loader from './../../../component/loader';

class UpdatesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updates: [],
      loader: false,
    };
  }

  componentDidMount() {
    this.loadUpdates(this.props.cashRegisterId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    var nextId = nextProps.cashRegisterId;
    if (nextId !== this.props.cashRegisterId) {
      this.loadUpdates(nextId);
    }
  }

  loadUpdates(cashRegisterId) {
    this.setState({ loader: true });
    Api.get(`/user/cash-registers/${cashRegisterId}/updates`, { limit: 5 })
      .then(response => {
        this.setState({ loader: false, updates: response.updates });
      })
      .catch(error => {
        this.setState({ loader: false });
      });
  }

  render() {
    return (
      <div className="panel panel-secondary">
        <div className="panel-heading panel-separated">
          {__('Zoznam aktualizácii')}
        </div>
        <div className="panel-body panel-separated min-height-loader">
          <Loader show={this.state.loader}>
            <DataTable
              cols={['', __('Dátum a čas'), __('Typ')]}
              rows={this.state.updates.map(update => {
                let statusClass = 'text-danger';
                let statusText = '';
                switch (update.status) {
                  case 'pending':
                    statusClass = 'text-warning';
                    statusText = __('Prebieha aktualizácia');
                    break;
                  case 'error':
                    statusClass = 'text-danger';
                    statusText = update.error.code;
                    break;
                  case 'success':
                    statusClass = 'text-success';
                    statusText = __('Úspešná aktualizácia');
                    break;
                  default:
                    break;
                }
                return {
                  title: statusText,
                  _data: [
                    <span className={statusClass}>
                      <Icon name="new-moon" />
                    </span>,
                    moment(update.datetime).format('DD.MM.YYYY HH:mm'),
                    update.update_type,
                  ],
                };
              })}
            />
            <h4
              className={cx('text-center', {
                hidden:
                  this.state.loader === true || this.state.updates.length > 0,
              })}
            >
              {__('Neboli pridané žiadne aktualizácie')}
            </h4>
          </Loader>
          <div className="text-right">
            <Link
              to={`/app/cash-register/${this.props.cashRegisterId}/updates`}
              className={cx('btn btn-primary', {
                hidden:
                  this.state.loader === false &&
                  this.state.updates.length === 0,
              })}
            >
              {__('Zobraziť všetky')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({}))(UpdatesList);
