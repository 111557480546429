/* eslint-disable no-extend-native */
import moment from 'moment';
import { Acl } from './..';
import { locale } from '../locale';

String.prototype.format = function(args = {}) {
  return this.replace(/%\(([a-zA-Z]+)\)s/g, (str, match) => {
    return typeof args[match] === 'undefined' ? str : args[match];
  });
};

export const generateID = () => {
  var groupsCount = 3;
  var groups = [];
  var length = 4,
    charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  for (var i = 0; i < groupsCount; ++i) {
    groups[i] = '';
    for (var j = 0, n = charset.length; j < length; ++j) {
      groups[i] += charset.charAt(Math.floor(Math.random() * n));
    }
  }
  return groups.join('-');
};

export const formatNumber = (n, c = 2, d = ',', t = ' ') => {
  let j;
  const s = n < 0 ? '-' : '';
  const i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + '';
  j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : '')
  );
};

export const formatPrice = (
  n,
  currency = 'EUR',
  c = 2,
  d = ',',
  t = '\u00a0',
) => {
  if (currency === 'CZK') {
    return `${formatNumber(n, c, d, t, c)} Kč`;
  } else if (currency === 'EUR') {
    return `${formatNumber(n, c, d, t, c)} €`;
  } else {
    return `${formatNumber(n, c, d, t, c)} ${currency}`;
  }
};

export const getDateRange = string => {
  if (string === 'TODAY') {
    return [moment().startOf('day'), moment().endOf('day')];
  } else if (string === 'WEEK') {
    return [moment().startOf('week'), moment().endOf('week')];
  } else if (string === 'MONTH') {
    return [moment().startOf('month'), moment().endOf('month')];
  } else if (string === 'YEAR') {
    return [moment().startOf('year'), moment().endOf('year')];
  } else if (string === 'QUARTER') {
    return [moment().startOf('quarter'), moment().endOf('quarter')];
  } else if (string === 'LAST_DAY') {
    return [
      getDateRange('TODAY')[0].subtract(1, 'days'),
      getDateRange('TODAY')[1].subtract(1, 'days'),
    ];
  } else if (string === 'LAST_WEEK') {
    return [
      getDateRange('WEEK')[0].subtract(1, 'weeks'),
      getDateRange('WEEK')[1].subtract(1, 'weeks'),
    ];
  } else if (string === 'LAST_MONTH') {
    return [
      getDateRange('MONTH')[0].subtract(1, 'months'),
      getDateRange('MONTH')[1].subtract(1, 'months'),
    ];
  } else if (string === 'LAST_QUARTER') {
    return [
      getDateRange('QUARTER')[0].subtract(1, 'quarters'),
      getDateRange('QUARTER')[1].subtract(1, 'quarters'),
    ];
  } else if (string === 'LAST_YEAR') {
    return [
      getDateRange('YEAR')[0].subtract(1, 'years'),
      getDateRange('YEAR')[1].subtract(1, 'years'),
    ];
  } else {
    throw Error(`Undefined range '${string}'`);
  }
};

export const getDateRanges = () => {
  return new Map([
    ['LAST_DAY', __('Predchádzajúci deň')],
    ['LAST_WEEK', __('Predchádzajúci týždeň')],
    ['LAST_MONTH', __('Predchádzajúci mesiac')],
    ['LAST_QUARTER', __('Predchádzajúci kvartál')],
    ['LAST_YEAR', __('Predchádzajúci rok')],
    ['TODAY', __('Dnes')],
    ['WEEK', __('Tento týždeň')],
    ['MONTH', __('Tento mesiac')],
    ['QUARTER', __('Tento kvartál')],
    ['YEAR', __('Tento rok')],
  ]);
};

export const getPaymentMethods = () => {
  return new Map([
    ['cash', __('Hotovosť')],
    ['card', __('Platobná karta')],
    ['checque', __('Šek')],
    ['retpack', __('Vratný obal')],
    ['coupon', __('Kupón')],
  ]);
};

export const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
};

String.prototype.removeAccents = function() {
  return this.toLowerCase()
    .replace(/[áàãâä]/gi, 'a')
    .replace(/[čç]/gi, 'c')
    .replace(/[ď]/gi, 'd')
    .replace(/[éèê]/gi, 'e')
    .replace(/[íìïî]/gi, 'i')
    .replace(/[ľĺ]/gi, 'l')
    .replace(/[ñň]/gi, 'n')
    .replace(/[óòöôõ]/gi, 'o')
    .replace(/[ř]/gi, 'r')
    .replace(/[š]/gi, 's')
    .replace(/[ť]/gi, 't')
    .replace(/[úùüûů]/gi, 'u')
    .replace(/[ý]/gi, 'y')
    .replace(/[ž]/gi, 'z')
    .replace(/[^a-zA-Z0-9]/g, ' ');
};

String.prototype.slugify = function() {
  return this.removeAccents()
    .toLowerCase()
    .replace(/\s+/g, '_') // Replace spaces with _
    .replace(/[^\w_]+/g, '') // Remove all non_word chars
    .replace(/__+/g, '_') // Replace multiple _ with single _
    .replace(/^_+/, '') // Trim _ from start of text
    .replace(/_+$/, '');
};

Array.prototype.move = function(idx, moveRight = true) {
  if (typeof this[idx] === 'undefined') {
    throw new Error('Index specified is not available in array');
  }
  if (this.length === 0 || this.length === 1) {
    return this;
  }
  let newidx = moveRight ? idx + 1 : idx - 1;
  if (newidx < 0) {
    newidx = this.length - 1;
  }
  if (newidx > this.length - 1) {
    newidx = 0;
  }
  const tmp = this[newidx];
  this[newidx] = this[idx];
  this[idx] = tmp;
  return this;
};

export const formatFilesize = (bytes, precision) => {
  if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
  if (typeof precision === 'undefined') precision = 1;
  let units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
  let number = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +
    ' ' +
    units[number]
  );
};

export const externalSuccessPage = data => {
  const params = Object.keys(data)
    .map(paramName => {
      return `${paramName}=${encodeURIComponent(data[paramName])}`;
    })
    .join('&');
  window.location = `/external/success.html?${params}`;
};

export const isOrderPaid = order => {
  return getProp(order, 'payment.date', null) !== null;
};

String.prototype.replaceAll = function(search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};

export const findGetParameter = parameterName => {
  var result = null,
    tmp = [];
  window.location.search
    .substr(1)
    .split('&')
    .forEach(function(item) {
      tmp = item.split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
};

export const __ = toTranslate => {
  if (toTranslate in locale.translations && locale.translations[toTranslate]) {
    return locale.translations[toTranslate];
  }
  return toTranslate;
};

export const setProp = (object, path, value) => {
  path = path.split('.');
  const seg = path.shift();
  if (!(seg in object)) {
    object[seg] = {};
  }
  object[seg] =
    path.length === 0 ? value : setProp(object[seg], path.join('.'), value);
  return object;
};

export const getProp = (object, path, defaultValue = '') => {
  const val = path.split('.').reduce(function(o, x) {
    return typeof o === 'undefined' || o === null ? defaultValue : o[x];
  }, object);
  if (typeof val === 'undefined') {
    return defaultValue;
  }
  return val;
};

export const isAllowed = (resource, action = null) => {
  if (
    action === null &&
    getProp(Acl, `acl.${resource}.__all__`, false) === true
  ) {
    return true;
  }
  if (getProp(Acl, `acl.${resource}.${action}`, false) === true) {
    return true;
  }
};

export const createUrl = (parts, params) => {
  let url = parts;

  if (Array.isArray(parts)) {
    url = parts.join('/');
  }

  if (url.charAt(0) !== '/') {
    url = '/' + url;
  }

  let i = 0;
  for (let key in params) {
    if (!params.hasOwnProperty(key)) continue;

    let val = params[key] + '';

    if (val.trim() !== '') {
      url += (i === 0 ? '?' : '&') + key + '=' + val;
      i++;
    }
  }

  return url;
};