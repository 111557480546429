import cx from 'classnames';
import React from 'react';
import { ReactComponent as appointment_remainders } from '../assets/icon/appointment-remainders.svg';
import { ReactComponent as attention } from '../assets/icon/attention.svg';
import { ReactComponent as back } from '../assets/icon/back.svg';
import { ReactComponent as banknotes } from '../assets/icon/banknotes.svg';
import { ReactComponent as bill } from '../assets/icon/bill.svg';
import { ReactComponent as calendar } from '../assets/icon/calendar.svg';
import { ReactComponent as cancel } from '../assets/icon/cancel.svg';
import { ReactComponent as cash_register } from '../assets/icon/cash-register.svg';
import { ReactComponent as collapse_arrow } from '../assets/icon/collapse-arrow.svg';
import { ReactComponent as combo_chart } from '../assets/icon/combo-chart.svg';
import { ReactComponent as contact_card } from '../assets/icon/contact-card.svg';
import { ReactComponent as custom_kladka } from '../assets/icon/custom-kladka.svg';
import { ReactComponent as custom_kladka_otaznik } from '../assets/icon/custom-kladka-otaznik.svg';
import { ReactComponent as custom_licencia } from '../assets/icon/custom-licencia.svg';
import { ReactComponent as custom_menu_off } from '../assets/icon/custom-menu-off.svg';
import { ReactComponent as custom_menu_on } from '../assets/icon/custom-menu-on.svg';
import { ReactComponent as custom_moja_firma } from '../assets/icon/custom-moja-firma.svg';
import { ReactComponent as custom_plu } from '../assets/icon/custom-plu.svg';
import { ReactComponent as custom_pokladne } from '../assets/icon/custom-pokladne.svg';
import { ReactComponent as custom_polozky } from '../assets/icon/custom-polozky.svg';
import { ReactComponent as custom_predlzenie_licencie } from '../assets/icon/custom-predlzenie-licencie.svg';
import { ReactComponent as custom_prehlady } from '../assets/icon/custom-prehlady.svg';
import { ReactComponent as custom_prevadzky } from '../assets/icon/custom-prevadzky.svg';
import { ReactComponent as custom_sprava_suborov } from '../assets/icon/custom-sprava-suborov.svg';
import { ReactComponent as custom_statistiky } from '../assets/icon/custom-statistiky.svg';
import { ReactComponent as custom_trzba } from '../assets/icon/custom-trzba.svg';
import { ReactComponent as custom_trzba_denna } from '../assets/icon/custom-trzba-denna.svg';
import { ReactComponent as custom_trzba_denna_dph } from '../assets/icon/custom-trzba-denna-dph.svg';
import { ReactComponent as custom_trzba_dph } from '../assets/icon/custom-trzba-dph.svg';
import { ReactComponent as custom_vzdialena_sprava } from '../assets/icon/custom-vzdialena-sprava.svg';
import { ReactComponent as custom_zavinac } from '../assets/icon/custom-zavinac.svg';
import { ReactComponent as custom_zaznamy } from '../assets/icon/custom-zaznamy.svg';
import { ReactComponent as custom_zaznamy_denne } from '../assets/icon/custom-zaznamy-denne.svg';
import { ReactComponent as data_protection } from '../assets/icon/data-protection.svg';
import { ReactComponent as download } from '../assets/icon/download.svg';
import { ReactComponent as edit } from '../assets/icon/edit.svg';
import { ReactComponent as elcom_cloudove_sluzby_biele } from '../assets/icon/elcom-cloudove-sluzby-biele.svg';
import { ReactComponent as email } from '../assets/icon/email.svg';
import { ReactComponent as error } from '../assets/icon/error.svg';
import { ReactComponent as exit } from '../assets/icon/exit.svg';
import { ReactComponent as expand_arrow } from '../assets/icon/expand-arrow.svg';
import { ReactComponent as filing_cabinet } from '../assets/icon/filing-cabinet.svg';
import { ReactComponent as filled_filter } from '../assets/icon/filled-filter.svg';
import { ReactComponent as filter } from '../assets/icon/filter.svg';
import { ReactComponent as forward } from '../assets/icon/forward.svg';
import { ReactComponent as gender_neutral_user } from '../assets/icon/gender-neutral-user.svg';
import { ReactComponent as checked_checkbox } from '../assets/icon/checked-checkbox.svg';
import { ReactComponent as checkmark } from '../assets/icon/checkmark.svg';
import { ReactComponent as ingredients_list } from '../assets/icon/ingredients-list.svg';
import { ReactComponent as load_more } from '../assets/icon/load-more.svg';
import { ReactComponent as logo_bi } from '../assets/icon/logo-bi.svg';
import { ReactComponent as long_arrow_left } from '../assets/icon/long-arrow-left.svg';
import { ReactComponent as manager } from '../assets/icon/manager.svg';
import { ReactComponent as marker } from '../assets/icon/marker.svg';
import { ReactComponent as menu } from '../assets/icon/menu.svg';
import { ReactComponent as multiply } from '../assets/icon/multiply.svg';
import { ReactComponent as new_moon } from '../assets/icon/new-moon.svg';
import { ReactComponent as plus_math } from '../assets/icon/plus-math.svg';
import { ReactComponent as remote_working } from '../assets/icon/remote-working.svg';
import { ReactComponent as remove } from '../assets/icon/remove.svg';
import { ReactComponent as resize_four_directions } from '../assets/icon/resize-four-directions.svg';
import { ReactComponent as restart } from '../assets/icon/restart.svg';
import { ReactComponent as sad } from '../assets/icon/sad.svg';
import { ReactComponent as save } from '../assets/icon/save.svg';
import { ReactComponent as search } from '../assets/icon/search.svg';
import { ReactComponent as services } from '../assets/icon/services.svg';
import { ReactComponent as speed } from '../assets/icon/speed.svg';
import { ReactComponent as star } from '../assets/icon/star.svg';
import { ReactComponent as unchecked_checkbox } from '../assets/icon/unchecked-checkbox.svg';
import { ReactComponent as user_groups } from '../assets/icon/user-groups.svg';
import { ReactComponent as synchron } from '../assets/icon/synchron.svg';

const Icon = props => {
  const SvgIcon = (N => {
    const n = N.replace(/-/g, '_');
    switch (n) {
      case 'appointment_remainders':
        return appointment_remainders;
      case 'attention':
        return attention;
      case 'back':
        return back;
      case 'banknotes':
        return banknotes;
      case 'bill':
        return bill;
      case 'calendar':
        return calendar;
      case 'cancel':
        return cancel;
      case 'cash_register':
        return cash_register;
      case 'collapse_arrow':
        return collapse_arrow;
      case 'combo_chart':
        return combo_chart;
      case 'contact_card':
        return contact_card;
      case 'custom_kladka_otaznik':
        return custom_kladka_otaznik;
      case 'custom_kladka':
        return custom_kladka;
      case 'custom_licencia':
        return custom_licencia;
      case 'custom_menu_off':
        return custom_menu_off;
      case 'custom_menu_on':
        return custom_menu_on;
      case 'custom_moja_firma':
        return custom_moja_firma;
      case 'custom_plu':
        return custom_plu;
      case 'custom_pokladne':
        return custom_pokladne;
      case 'custom_polozky':
        return custom_polozky;
      case 'custom_predlzenie_licencie':
        return custom_predlzenie_licencie;
      case 'custom_prehlady':
        return custom_prehlady;
      case 'custom_prevadzky':
        return custom_prevadzky;
      case 'custom_sprava_suborov':
        return custom_sprava_suborov;
      case 'custom_statistiky':
        return custom_statistiky;
      case 'custom_trzba_denna_dph':
        return custom_trzba_denna_dph;
      case 'custom_trzba_denna':
        return custom_trzba_denna;
      case 'custom_trzba_dph':
        return custom_trzba_dph;
      case 'custom_trzba':
        return custom_trzba;
      case 'custom_vzdialena_sprava':
        return custom_vzdialena_sprava;
      case 'custom_zavinac':
        return custom_zavinac;
      case 'custom_zaznamy_denne':
        return custom_zaznamy_denne;
      case 'custom_zaznamy':
        return custom_zaznamy;
      case 'data_protection':
        return data_protection;
      case 'remove':
        return remove;
      case 'download':
        return download;
      case 'edit':
        return edit;
      case 'elcom_cloudove_sluzby_biele':
        return elcom_cloudove_sluzby_biele;
      case 'email':
        return email;
      case 'error':
        return error;
      case 'exit':
        return exit;
      case 'expand_arrow':
        return expand_arrow;
      case 'filing_cabinet':
        return filing_cabinet;
      case 'filled_filter':
        return filled_filter;
      case 'filter':
        return filter;
      case 'forward':
        return forward;
      case 'gender_neutral_user':
        return gender_neutral_user;
      case 'checked_checkbox':
        return checked_checkbox;
      case 'checkmark':
        return checkmark;
      case 'ingredients_list':
        return ingredients_list;
      case 'load_more':
        return load_more;
      case 'logo_bi':
        return logo_bi;
      case 'long_arrow_left':
        return long_arrow_left;
      case 'manager':
        return manager;
      case 'marker':
        return marker;
      case 'menu':
        return menu;
      case 'multiply':
        return multiply;
      case 'new_moon':
        return new_moon;
      case 'plus_math':
        return plus_math;
      case 'remote_working':
        return remote_working;
      case 'resize_four_directions':
        return resize_four_directions;
      case 'restart':
        return restart;
      case 'sad':
        return sad;
      case 'save':
        return save;
      case 'search':
        return search;
      case 'services':
        return services;
      case 'speed':
        return speed;
      case 'star':
        return star;
      case 'unchecked_checkbox':
        return unchecked_checkbox;
      case 'user_groups':
        return user_groups;
      case 'synchron':
        return synchron;
      default:
        return null;
    }
  })(props.name);

  if (!SvgIcon) {
    return null;
  }
  return <SvgIcon className={cx('icon', props.className)} />;
};

export default Icon;
