import c3 from 'c3';
import 'c3/c3.css';
import React from 'react';
import { getProp, __ } from '../../utilities/common';
import { getChartName } from './../../utilities/chart';
import ProtectedContent from './../protectedContent';
import BaseChart, { getChartStringValue, getChartValue } from './basechart';

export default class HistogramChart extends React.Component {
  /**
   * Props:
   * - histogram: data directly from webservice
   * - key_x (optional - system will quess): key to histogram time interval, e.g. day.
   * - key_y (optional - system will quess): key to actual stats value
   * - c3options - other c3 chart options, system will merge. Be careful.
   */

  constructor(props) {
    super(props);
    this.chart = null;
    this._key_x_options = [
      'min15',
      'hour',
      'day',
      'week',
      'month',
      'quarter',
      'year',
    ];
  }

  _guessXYKeys(s) {
    let key_x, key_y;
    for (let k of Object.keys(s)) {
      if (this._key_x_options.indexOf(k) >= 0) {
        key_x = k;
      } else {
        key_y = k;
      }
      if (key_x && key_y) {
        break;
      }
    }
    return [key_x, key_y];
  }

  _prepareData() {
    const data = {};
    let key_x = this.props.key_x;
    let key_y = this.props.key_y;
    data.columns = [[]];
    const len = this.props.data.length;
    // Iterate over multiple data sets
    for (let dataIdx = 0; dataIdx < len; dataIdx++) {
      data.columns.push([]);
      // Iterate over concrete data set
      let idx = 0;
      for (const s of this.props.data[dataIdx]) {
        if (!key_x || !key_y) {
          [key_x, key_y] = this._guessXYKeys(s);
        }
        // xova os
        // @TODO prepisujeme si X-ovu os, ak mame viac filtrov
        if (typeof data.columns[0][idx] === 'string') {
          data.columns[0][idx] += ' / ' + s[key_x];
        } else {
          data.columns[0].push(s[key_x]);
        }
        // yova os
        data.columns[dataIdx + 1].push(getChartValue(s[key_y]));

        idx++;
      }
      if (key_x) {
        data.columns[dataIdx + 1].unshift(
          getProp(this.props, 'title', key_x) +
            (len > 1 ? ' ' + (dataIdx + 1) : ''),
        );
      }
    }
    if (key_x) {
      data.x = key_x;
      data.columns[0].unshift(key_x);
    }
    data.type = 'area';
    data.labels = false;

    return data;
  }

  _generateChart() {
    if (this.generateTimeout) {
      clearTimeout(this.generateTimeout);
      this.generateTimeout = null;
    }
    this.generateTimeout = setTimeout(() => {
      const data = this._prepareData();
      // let tick = {};
      // if (data.columns[0].length > 10) { // if there are mroe than 10 ticks on x-axis
      //     tick = { rotate: 55, fit: true, culling: {max: 10} };
      // }
      const options = {
        bindto: this.refs.mountpoint,
        data: data,
        axis: {
          x: {
            type: 'category',
            tick: { rotate: -35, fit: false, culling: { max: 30 } },
            height: 60,
          },
          y: {
            label: {
              text: this.props.title,
              position: 'outer-middle',
            },
            tick: {
              format: d => {
                return getChartStringValue(d, 0);
              },
            },
          },
        },
        grid: {
          y: {
            show: true,
          },
        },
        zoom: {
          enabled: this.props.zoom,
        },
        subchart: {
          show: this.props.subchart,
        },
        size: {
          height: this.props.chartHeight,
        },
      };
      options.legend = { show: false };
      if (this.props.c3options) {
        Object.assign(options, this.props.c3options);
      }
      this.chart = c3.generate(options);
    }, 500);
  }

  componentDidMount() {
    this._generateChart();
    // Pojebany hack
    this.sidenav = document.querySelector('#side-nav');
    this.sidenavClassName = this.props.className;
    this.windowW = window.innerWidth;
    this.kurvaTimeout = null;
    this.interval = setInterval(() => {
      if (
        this.sidenav.className !== this.sidenavClassName ||
        this.windowW !== window.innerWidth
      ) {
        clearTimeout(this.kurvaTimeout);
        this.kurvaTimeout = setTimeout(() => {
          this._generateChart();
        }, 500);
        this.sidenavClassName = this.sidenav.className;
        this.windowW = window.innerWidth;
      }
    }, 100);
  }

  componentDidUpdate(prevProps, prevState) {
    this._generateChart();
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.destroy();
    }
    clearInterval(this.interval);
    clearTimeout(this.kurvaTimeout);
  }

  render() {
    if (this.props.data.length > 1) {
      // Compare chart
      return (
        <ProtectedContent
          resource="bm.comparing"
          action="enabled"
          showWarning
          msg={__('Porovnávacie grafy nie sú dostupné pre Vašu licenciu')}
        >
          <div>
            <div ref="mountpoint" />
          </div>
        </ProtectedContent>
      );
    } else {
      return (
        <div style={{ minHeight: '400px' }}>
          <div ref="mountpoint" />
        </div>
      );
    }
  }
}

HistogramChart.defaultProps = {
  chartHeight: 600,
  subchart: true,
  zoom: false,
};

export const create = (chart, additionalProps = {}, showFilters = false) => {
  return React.createElement(
    BaseChart,
    {
      title: getProp(chart, 'name', ''),
      subtitle: getProp(chart, 'descr', ''),
      showFilters: showFilters,
      error: getProp(additionalProps, 'error', 0),
    },
    React.createElement(
      HistogramChart,
      Object.assign(additionalProps, {
        data: getProp(chart, 'data', [[]]),
        title: getChartName(
          getProp(chart, 'type1', ''),
          getProp(chart, 'type2', ''),
          getProp(chart, 'type3', ''),
        ),
      }),
    ),
  );
};
