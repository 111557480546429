import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import Api from '../../service/Api';
import { getProp, __ } from '../../utilities/common';
import { setAlert } from './../../actions';
import Content from './../../component/content';
import DataTable from './../../component/dataTable';
import Loader from './../../component/loader';
import MainHeader from './../../component/mainHeader';
import LoadMoreBtn from './../../component/loadMoreBtn';
import { Link } from 'react-router';
import SidePanel from './../../component/sidePanel';

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadEcrsLoader: false,
      ecrs: [],
      codeLists: [],
      filter: {},
      offset: 0,
      nextOffset: null,
      limit: 1000,
    };
  }

  componentDidMount() {
    this.loadEcrs();
  }

  loadEcrs = (_limit = null, _offset = 0, _filter = null) => {
    const { ecrs } = this.state;
    const { dispatch } = this.props;
    let { limit, offset, filter } = this.state;

    if (_limit) {
      limit = _limit;
      offset = _offset;
    }

    if (_filter) {
      filter = _filter;
    }

    // nacitame zoznam verzii
    this.setState(
      {
        loadEcrsLoader: true,
        filter,
      },
      () => {
        const params = {
          limit,
          offset,
        };

        if (getProp(filter, 'ecrsn', null)) {
          params.ecrsn = filter.ecrsn;
        }

        if (getProp(filter, 'country', null)) {
          params.country = filter.country;
        }

        if (getProp(filter, 'ecrType', null)) {
          params.ecrtype = filter.ecrType;
        }

        if (getProp(filter, 'lang', null)) {
          params.lang = filter.lang;
        }

        if (getProp(filter, 'cpuVersion', null)) {
          params.cpuversion = filter.cpuVersion;
        }

        if (getProp(filter, 'ecrVersion', null)) {
          params.ecr_version = filter.ecrVersion;
        }

        if (getProp(filter, 'icmVersion', null)) {
          params.icm_version = filter.icmVersion;
        }

        Api.get('/admin/ecrs', params)
          .then(response => {
            console.log(response);
            this.setState({
              loadEcrsLoader: false,
              ecrs: offset === 0 ? response.ecrs : ecrs.concat(response.ecrs),
              codeLists: response.codelists,
              nextOffset: response.next_offset,
            });
          })
          .catch(response => {
            dispatch(
              setAlert({
                type: 'error',
                title: __('Nebolo možné načítať zoznam verzií'),
                onConfirm: () => dispatch(setAlert(null)),
              }),
            );

            this.setState({
              loadEcrsLoader: false,
            });
          });
      },
    );
  };

  render() {
    const {
      ecrs,
      codeLists,
      filter,
      limit,
      offset,
      nextOffset,
      loadEcrsLoader,
    } = this.state;
    const { sidepanel } = this.props;

    const onSuccessSidePanel = (limit = null, offset = 0, filter = null) => {
      this.loadEcrs(limit, offset, filter);
    };

    return (
      <div id="content-wrapper">
        <MainHeader title={__('Zoznam ECR')} progress={100} />
        <Content>
          <Loader show={loadEcrsLoader}>
            <div className="row">
              <div className="col-md-6 col-md-offset-6">
                <Link
                  to="/app/ecrs/filter"
                  className="btn btn-primary-o pull-right"
                >
                  {__('Upraviť filter')}
                </Link>
              </div>
            </div>
            <div>
              <DataTable
                cols={[
                  'ECRSN',
                  __('Krajina'),
                  'ECRTYPE',
                  __('Jazyk'),
                  __('CPU verzia'),
                  __('Aktuálna verzia ECR'),
                  __('Aktuálna verzia SM'),
                ]}
                rows={ecrs.map(i => {
                  let item = {};

                  for (let c of ecrs) {
                    if (c._id === i._id) {
                      item = c;
                      break;
                    }
                  }

                  return [
                    getProp(item, '_id'),
                    getProp(item, 'country'),
                    getProp(item, 'ecrtype'),
                    getProp(item, 'lang'),
                    getProp(item, 'cpuversion'),
                    getProp(item, 'ecr_version', []).join('.'),
                    getProp(item, 'icm_version', []).join('.'),
                  ];
                })}
              />
              <h4
                className={cx('text-center', {
                  hidden: ecrs.length > 0,
                })}
              >
                {__('Žiadne verzie')}
              </h4>
            </div>
            <LoadMoreBtn
              nextOffset={nextOffset}
              onClick={() => {
                this.setState(
                  {
                    offset: nextOffset,
                  },
                  this.loadEcrs,
                );
              }}
            />
          </Loader>
          <div className="cleaner" />
        </Content>
        {sidepanel ? (
          <SidePanel
            onSuccess={onSuccessSidePanel}
            returnUrl="/app/ecrs"
            parameters={{ codeLists, filter, limit, offset }}
          >
            {sidepanel}
          </SidePanel>
        ) : null}
      </div>
    );
  }
}

List.defaultProps = {};

export default connect(state => ({}))(List);
