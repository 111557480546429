import c3 from 'c3';
import 'c3/c3.css';
import React from 'react';
import { getProp, __ } from '../../utilities/common';
import { getChartName } from './../../utilities/chart';
import { formatNumber, formatPrice } from './../../utilities/common';
import RadioButton from './../form/radioButton';
import BaseChart, {
  getChartStringValue,
  getChartValue,
  getChartValueCurrency,
  isChartPropsEqual,
} from './basechart';

export default class SummaryChart extends React.Component {
  /**
   * Props:
   * - data: data directly from webservice
   * - name_key: X name.
   * - value_key: key_id
   * - value_key_name: (optional) Key name
   * - c3options - other c3 chart options, system will merge. Be carefull.
   */

  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      chartType: props.chartType,
    };

    this.didChangeChartType = this.didChangeChartType.bind(this);
  }

  _guessYKey(s) {
    let key_y;
    for (let k of Object.keys(s)) {
      if (k !== this.props.name_key) {
        key_y = k;
        break;
      }
    }
    return key_y;
  }

  _prepareData() {
    // ak nemame data
    if (!this.props.data.length) {
      return {
        columns: [['1', 1], ['2', 1], ['3', 1]],
        type: 'donut',
        labels: { show: false },
        colors: {
          '1': '#AAAAAA',
          '2': '#BBBBBB',
          '3': '#CCCCCC',
        },
      };
    }
    const data = {};

    if (this.state.chartType === 'bar') {
      let name_key = this.props.name_key;
      let value_key = this.props.value_key;
      const columns = {};
      const categories = ['x'];
      let currency = null;
      let checkValueCurrency = false;
      for (const s of this.props.data) {
        categories.push(s[name_key] || 'undefined');
        if (!columns[value_key]) {
          columns[value_key] = [this.props.value_key_name || value_key];
        }
        if (!checkValueCurrency) {
          currency = getChartValueCurrency(s[value_key]);
          checkValueCurrency = true;
        }
        columns[value_key].push(getChartValue(s[value_key], 0.0));
      }
      data.columns = [categories];
      for (const k in columns) {
        data.columns.push(columns[k]);
      }
      data.x = 'x';
      data.type = 'bar';
      data.labels = {
        show: true,
        format: (v, id, i, j) => {
          if (currency) {
            return formatPrice(v, currency, 0);
          } else {
            return formatNumber(v, 0);
          }
        },
      };
    } else {
      let key_x = this.props.name_key;
      let key_y = this.props.value_key;
      data.columns = [];
      data.names = {};
      for (const s of this.props.data) {
        if (!key_y) {
          key_y = this._guessYKey(s);
        }
        const v = getChartValue(s[key_y]);
        data.columns.push([s[key_x], v]);
        data.names[s[key_x]] =
          s[key_x] + ' (' + getChartStringValue(s[key_y]) + ')';
      }
      data.type = 'pie';
      data.labels = {
        show: true,
      };
    }

    return data;
  }

  _generateChart() {
    const show_labels = this.props.data.length > 0;
    const data = this._prepareData();
    let options = {};
    options = {
      bindto: this.refs.mountpoint,
      data: data,
      legend: {
        show: show_labels && this.state.chartType !== 'bar',
        position: 'bottom',
      },
      pie: {
        label: {
          show: show_labels,
        },
      },
      donut: {
        label: {
          show: false,
        },
      },
      axis: {
        rotated: true,
        x: {
          type: 'category',
        },
        y: {
          show: false,
          tick: {
            format: d => {
              return getChartStringValue(d, 0);
            },
          },
        },
      },
      tooltip: { show: show_labels && this.state.chartType === 'bar' },
      size: {
        height: this.props.chartHeight,
      },
    };
    if (this.props.c3options) {
      Object.assign(options, this.props.c3options);
    }
    return c3.generate(options);
  }

  componentDidMount() {
    this.chart = this._generateChart();
  }

  componentDidUpdate(prevProps, prevState) {
    this.chart = this._generateChart();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isChartPropsEqual(this.props, nextProps, {
        data: true,
        name_key: false,
        value_key: false,
        value_key_name: false,
        c3options: true,
      }) ||
      !isChartPropsEqual(this.state, nextState, {
        chartType: false,
      })
    );
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  didChangeChartType(item) {
    this.setState({ chartType: item.value });
  }

  render() {
    return (
      <div>
        <div className="text-center">
          <RadioButton
            sm={true}
            value={this.state.chartType}
            onChange={this.didChangeChartType}
            options={[
              { value: 'bar', label: __('Stĺpcový graf') },
              { value: 'pie', label: __('Koláčový graf') },
            ]}
          />
        </div>
        <div ref="mountpoint" />
      </div>
    );
  }
}

SummaryChart.defaultProps = {
  chartHeight: 600,
  chartType: 'bar',
};

export const create = (chart, additionalProps = {}, showFilters = false) => {
  const type3 = getProp(chart, 'type3', null);
  return React.createElement(
    BaseChart,
    {
      title: getProp(chart, 'name', ''),
      subtitle: getProp(chart, 'descr', ''),
      chart: chart,
      showFilters: showFilters,
      error: getProp(additionalProps, 'error', 0),
    },
    React.createElement(
      SummaryChart,
      Object.assign(additionalProps, {
        data: getProp(chart, 'data', []),
        name_key: 'item',
        value_key: type3,
        value_key_name: getChartName(
          getProp(chart, 'type1', ''),
          getProp(chart, 'type2', ''),
          getProp(chart, 'type3', ''),
        ),
      }),
    ),
  );
};
