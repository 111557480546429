import Cookies from 'js-cookie';
import moment from 'moment';
import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { __ } from '../utilities/common';
import { hideMenu, showMenu } from './../actions';
import Icon from './icon';
import UserPopup from './statusBar/userPopup';

class StatusBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userPopupOpened: false,
      saveIndicatorTimerRunning: false,
    };
    this.onClickUser = this.onClickUser.bind(this);
    this.saveTimeout = null;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.saveCounter > 0 && nextProps.saveCounter === 0) {
      this.setState({ saveIndicatorTimerRunning: true });
      this.saveTimeout = window.setTimeout(() => {
        this.setState({ saveIndicatorTimerRunning: false });
      }, 2500);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.saveTimeout);
  }

  handleClickOutside() {
    this.setState({ userPopupOpened: false });
  }

  onClickUser() {
    this.setState({ userPopupOpened: !this.state.userPopupOpened });
  }

  toggleMenu() {
    if (this.props.menuVisible) {
      Cookies.set('menu_visible', 'NO');
      this.props.dispatch(hideMenu());
    } else {
      Cookies.set('menu_visible', 'YES');
      this.props.dispatch(showMenu());
    }
  }

  render() {
    return (
      <div id="status-bar">
        <span
          className="clickable"
          onClick={this.toggleMenu.bind(this)}
          title={
            this.props.menuVisible ? __('Skryť menu') : __('Zobraziť menu')
          }
        >
          {this.props.menuVisible ? (
            <Icon name="menu" className="menu-collapse-icon" />
          ) : (
            <Icon name="menu" className="menu-collapse-icon" />
          )}
        </span>

        <div id="logo">
          <Link to="/app">
            <Icon name="logo-bi" />
          </Link>
        </div>

        <span className="flex" />

        <span className="save-indicator">
          {this.props.saveCounter >= 1
            ? __('Ukladá sa ...')
            : this.state.saveIndicatorTimerRunning
            ? __('Uložené')
            : null}
        </span>

        <span className="user-popup-container">
          <UserPopup
            onClickUser={this.onClickUser}
            opened={this.state.userPopupOpened}
            licenseName={this.props.license.name}
            licensePrice={this.props.license.price.amount}
            licensePriceCurrency={this.props.license.price.currency}
            licenseValidUntil={moment(this.props.license.valid_until)}
          />
        </span>

        <span className="clickable picture-frame" onClick={this.onClickUser}>
          <img src="/default-user-icon.svg" alt={this.props.forename} />
        </span>

        <span className="profile">
          <span className="clickable name hidden-xs" onClick={this.onClickUser}>
            {__('%(name)s %(surname)s (%(license)s používateľ)').format({
              name: this.props.forename,
              surname: this.props.surname,
              company: this.props.companyName,
              license: this.props.license.name,
            })}
          </span>
          {/*<span >
                    <IconNotify name="appointment-remainders" label={1} labelClassName="label-danger" />
                </span>*/}
          <span
            className="clickable"
            onClick={this.props.onClickLogout.bind(null)}
          >
            <Icon name="exit" />
          </span>
        </span>
      </div>
    );
  }
}

export default connect(state => ({
  saveCounter: state.saveCounter,
  menuVisible: state.menuVisible,
}))(enhanceWithClickOutside(StatusBar));
