import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Api from '../../service/Api';
import { getProp, __, createUrl } from '../../utilities/common';
import { setAlert } from './../../actions';
import Content from './../../component/content';
import Loader from './../../component/loader';
import MainHeader from './../../component/mainHeader';

import { BASE_URL } from './list';

class Detail extends React.Component {
  state = {
    isFetching: false,
    partners: [],
  };

  componentDidMount() {
    this.loadDetail(this.props.params.id);
  }

  loadDetail = async (id) => {
    this.setState({ isFetching: true });
    try {
      const response = await Api.get(`/admin/partners/${id}`);
      this.setState({
        partner: response,
        isFetching: false,
      });
    }
    catch (error) {
      console.error(error);
      this.props.dispatch(
        setAlert({
          type: 'error',
          title: __('Nebolo možné načítať detail partnera'),
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
      this.setState({ isFetching: false });
    }
  }

  render() {
    const { location: { pathname } } = this.props;
    const currentTab = pathname.split('/')[4];
    return (
      <div id="content-wrapper partners">
        <MainHeader
          title={__('Detail partnera')}
          progress={100}
        />
        <Content>
          <Loader show={this.state.isFetching}>
            <div className="panel panel-secondary">
              <div className="panel-heading">{__('Údaje o partnerovi')}</div>
              <div className="panel-body row">
                <div className="col-xs-6">
                  <strong className="font-lg">
                    {getProp(this.state.partner, 'company_name')}
                  </strong>
                  <br />
                  {__('IČO')}: {getProp(this.state.partner, 'regnr')}
                  <br />
                  {__('DIČ')}: {getProp(this.state.partner, 'taxid')}
                  <br />
                  {__('IčDPH')}: {getProp(this.state.partner, 'vatid')}
                  <br />
                  {__('ID partnera')}: {getProp(this.state.partner, 'partner_id')}
                </div>
                <div className="col-xs-6">
                  {getProp(this.state.partner, 'address.street')}{' '}
                  {getProp(this.state.partner, 'address.number')}
                  <br />
                  {getProp(this.state.partner, 'address.zip')}{' '}
                  {getProp(this.state.partner, 'address.city')}
                  <br />
                  {getProp(this.state.partner, 'address.country')}
                </div>
              </div>
            </div>
            <Content>
              <ul className="nav nav-pills nav-justified">
                <li
                  className={cx({ active: currentTab === undefined })}
                >
                  <Link
                    to={createUrl([BASE_URL, this.props.params.id])}
                    className="clickable"
                  >
                    {__("Zoznam pokladníc")}
                  </Link>
                </li>
                <li
                  className={cx({ active: currentTab === 'members' })}
                >
                  <Link
                    to={createUrl([BASE_URL, this.props.params.id, 'members'])}
                    className="clickable"
                  >
                    {__("Zoznam požívateľov")}
                  </Link>
                </li>
              </ul>
              {this.props.children}
            </Content>
          </Loader>
          <div className="cleaner" />
        </Content>
      </div >
    );
  }
}

export default connect()(Detail);
