import React, {Fragment} from 'react';
import Api from '../../../service/Api';
import { getProp, __ } from '../../../utilities/common';
import Checkbox from './../../../component/form/checkbox';
import Search from './../../../component/form/search';
import { FormGenerator } from './formGenerator';

export class ProductFormGenerator extends FormGenerator {
  generateInputControl(property, value, editing = false) {
    if (property === 'pro_name') {
      const onChange = (property, val) => {
        this.emitAction('change', {
          property,
          value: val,
        });
      };

      const getOptions = input => {
        if (!input) {
          return Promise.resolve([]);
        }
        return new Promise((resolve, reject) => {
          Api.get(`/products/search?q=${input}`).then(response => {
            resolve(
              response.products.map(item => {
                return Object.assign({}, item, {
                  label: item.name,
                });
              }),
            );
          });
        });
      };

      return (
        <Search
          value={value}
          loadData={getOptions}
          onSelect={item => {
            this.emitAction('change', {
              property: 'pro_name',
              value: item.name,
            });
            this.emitAction('change', {
              property: 'pro_short_name',
              value: item.name_short,
            });
          }}
          onChangeQuery={onChange.bind(this, property)}
        />
      );
    } else if (property === 'pro_unit_id') {
      let attrs = {};
      if (typeof value !== 'undefined') {
        attrs = { value };
      }
      return (
        <select
          className="form-control"
          onChange={this.onChange.bind(this, property)}
          {...attrs}
        >
          <option value="" />
          {this.schema.payload.unitList.map(item => {
            return (
              <option key={item.uni_id} value={item.uni_id}>
                {item.uni_name}
              </option>
            );
          })}
        </select>
      );
    } else if (property === 'cat_id') {
      let attrs = {};
      if (typeof value !== 'undefined') {
        attrs = { value };
      }
      return (
        <select
          className="form-control"
          onChange={this.onChange.bind(this, property)}
          {...attrs}
        >
          <option value="" />
          {this.schema.payload.categoryList.map(item => {
            return (
              <option key={item.cat_id} value={item.cat_id}>
                {item.cat_name}
              </option>
            );
          })}
        </select>
      );
    } else if (property === 'pro_vat') {
      let attrs = {};
      if (typeof value !== 'undefined') {
        attrs = { value };
      }

      const onChange = (property, ev) => {
        let vatId = ev.target.value;
        this.emitAction('change', {
          property,
          value: vatId,
        });

        // Get VAT value
        let vatVal = 0;
        for (let v of this.schema.payload.vatList) {
          if (v.vat_id === vatId) {
            vatVal = v.vat_value;
          }
        }
        vatVal /= 100; // VAT is multiplied by 100;

        // Recalcalate sum with VAT
        let sum = Number(this.values['pro_sum']);
        if (!Number.isNaN(sum)) {
          this.emitAction('change', {
            property: 'pro_sum_vat',
            value: (sum * (1 + vatVal / 100)).toFixed(2),
          });
        }
      };

      return (
        <select
          className="form-control"
          onChange={onChange.bind(this, property)}
          {...attrs}
        >
          <option value="" />
          {this.schema.payload.vatList.map(item => {
            return (
              <option key={item.vat_value} value={item.vat_id}>
                {(item.vat_value / 100).toFixed(2)}%
              </option>
            );
          })}
        </select>
      );
    } else if (property === 'pro_sum') {
      let attrs = {};
      if (typeof value !== 'undefined') {
        attrs = { value };
      }

      const onChange = (property, ev) => {
        let val = ev.target.value;
        this.emitAction('change', {
          property,
          value: val,
        });

        // Get VAT value
        let vatId = this.values['pro_vat'];
        let vatVal = 0;
        for (let v of this.schema.payload.vatList) {
          if (v.vat_id === vatId) {
            vatVal = v.vat_value;
          }
        }
        vatVal /= 100; // VAT is multiplied by 100;

        // Recalcalate sum with VAT
        let sum = Number(val);
        if (!Number.isNaN(sum)) {
          this.emitAction('change', {
            property: 'pro_sum_vat',
            value: (sum * (1 + vatVal / 100)).toFixed(2),
          });
        }
      };

      return (
        <input
          className="form-control"
          type="text"
          placeholder={this.isRequired(property) ? null : __('(nepovinné)')}
          onChange={onChange.bind(this, property)}
          {...attrs}
        />
      );
    } else if (property === 'pro_sum_vat') {
      let attrs = {};
      if (typeof value !== 'undefined') {
        attrs = { value };
      }

      const onChange = (property, ev) => {
        let val = ev.target.value;
        this.emitAction('change', {
          property,
          value: val,
        });

        // Get VAT value
        let vatId = this.values['pro_vat'];
        let vatVal = 0;
        for (let v of this.schema.payload.vatList) {
          if (v.vat_id === vatId) {
            vatVal = v.vat_value;
          }
        }
        vatVal /= 100; // VAT is multiplied by 100;

        // Recalcalate sum with VAT
        let sum = Number(val);
        if (!Number.isNaN(sum)) {
          this.emitAction('change', {
            property: 'pro_sum',
            value: (sum / (1 + vatVal / 100)).toFixed(2),
          });
        }
      };

      return (
        <input
          className="form-control"
          type="text"
          placeholder={this.isRequired(property) ? null : __('(nepovinné)')}
          onChange={onChange.bind(this, property)}
          {...attrs}
        />
      );
    } else {
      return super.generateInputControl(property, value);
    }
  }

  generateSelectControlOptions(property, isRequired) {
    if (property === 'pro_type') {
      const onChange = (property, ev) => {
        this.emitAction('change', {
          property,
          value: ev.target.checked ? 1 : 0,
        });
      };

      return (
        <div className="checkbox">
          <Checkbox
            id={`role-${property}-chk`}
            onChange={onChange.bind(this, property)}
          />
          <label htmlFor={`role-${property}-chk`}>{__('Áno')}</label>
        </div>
      );
    } else {
      return super.generateSelectControlOptions(property, isRequired);
    }
  }

  generateSelectControl(property, value, editing = false) {
    if (property === 'pro_type') {
      const onChange = (property, ev) => {
        this.emitAction('change', {
          property,
          value: ev.target.checked ? 2 : 1,
        });
      };

      return (
        <div className="checkbox">
          {editing ? (
              value === 2 ? __('Áno') : __('Nie')
          ) : (
              <Fragment>
                <Checkbox
                    id={`role-${property}-chk`}
                    checked={value === 2}
                    onChange={onChange.bind(this, property)}
                />
                <label htmlFor={`role-${property}-chk`}>{__('Áno')}</label>
              </Fragment>
          )}
        </div>
      );
    } else {
      return super.generateSelectControl(property, value);
    }
  }

  get hiddenProperties() {
    const hidden = [];

    if (this.values['pro_type'] === 2) {
      hidden.push('pro_no_discount');
      hidden.push('pro_vat');
    }

    if (getProp(this.currentLoggedUser, 'company.address.country') === 'CZ') {
      hidden.push('pro_nonfiscal_item');
    }

    return hidden;
  }
}
