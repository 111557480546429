import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import Api from '../service/Api';
import { getProp, setProp, __ } from '../utilities/common';
import { create as createHistogramChart } from './../component/chart/histogram';
import ChartInfo from './../component/chart/info';
import { create as createSummaryChart } from './../component/chart/summary';
import { create as createValueChart } from './../component/chart/value';
import Loader from './../component/loader';
import {
  buildChartFilter,
  buildQueryParams,
  buildQueryUrl,
  getChartName,
} from './../utilities/chart';

class UseChartForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      charts: [],
      typeIdx: null,
      chartIdx: null,
      loading: true,
      chartLoading: false,
      chart: null,
    };
  }

  componentDidMount() {
    Api.get('/user/ui/charts')
      .then(response => {
        this.setState({ charts: response.user_charts, loading: false }, () => {
          this.chartPreview();
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  onChangeObjectProp(prop, ev) {
    this.setState(setProp(this.state, prop, ev.target.value));
  }

  getObjectProp(prop, defaultValue = '') {
    return getProp(this.state, prop, defaultValue);
  }

  onSubmit(ev) {
    ev.preventDefault();
    this.save();
  }

  onClickType(idx) {
    this.setState({ typeIdx: idx, chartIdx: null, chart: null });
  }

  onClickChart(idx) {
    if (idx === this.state.chartIdx) {
      this.setState({ chartIdx: idx }, () => {
        this.chartPreview();
      });
    } else {
      this.setState({ chartIdx: idx, chart: null }, () => {
        this.chartPreview();
      });
    }
  }

  chartPreview() {
    if (this.state.typeIdx !== null && this.state.chartIdx !== null) {
      this.setState(
        {
          chartLoading: true,
          chart: this.state.charts[this.state.typeIdx].charts[
            this.state.chartIdx
          ],
        },
        () => {
          this.loadChartData(this.state.chart);
        },
      );
    }
  }

  onClickPreview() {
    this.chartPreview();
  }

  onClickAdd() {
    if (typeof this.props.onSave === 'function') {
      const chart = this.state.charts[this.state.typeIdx].charts[
        this.state.chartIdx
      ];

      const type1 = getProp(chart, 'type1', '');
      const type2 = getProp(chart, 'type2', '');
      const type3 = getProp(chart, 'type3', '');

      this.props.onSave(null, {
        name: getProp(chart, 'name', ''),
        type1: type1,
        type2: type2,
        type3: type3,
        filters: getProp(chart, 'filters', [{}]),
      });
    }
  }

  // @TODO velmi podobna metoda tej vo view.js
  loadChartData(chart) {
    const type1 = getProp(chart, 'type1', '');
    const type2 = getProp(chart, 'type2', '');
    const type3 = getProp(chart, 'type3', '');

    // Construct url from selected types
    let apiUrl = buildQueryUrl(type1, type2, type3);

    // Need for multiple API calls because there are multiple filters available
    const promises = [];

    for (let i = 0; i < chart.filters.length; i++) {
      // i is index of each filter
      (idx => {
        // Create search filter from either local or global filters
        const chartFilter = buildChartFilter(
          chart.filters[idx],
          getProp(this.props, 'filter', {}),
        );
        if (typeof chart.filtersExtended === 'undefined') {
          chart.filtersExtended = [];
        }
        chart.filtersExtended[idx] = JSON.parse(JSON.stringify(chartFilter));

        // Build params
        const params = buildQueryParams(chartFilter);

        // Api call
        promises.push(
          Api.get(apiUrl, params).then(response => {
            if (type1 === 'total' || type1 === 'summary') {
              chart.data = response[type2.replaceAll('-', '_')];
              chart.invalid = false;
              //this.setState({chart: chart});
            } else if (type1 === 'histogram') {
              if (typeof chart.data === 'undefined') {
                chart.data = [];
              }
              chart.data[idx] = response[type1];
              chart.invalid = false;
            }
          }),
        );
      })(i);
    }

    return Promise.all(promises).then(() => {
      this.setState({ chart: chart, chartLoading: false });
    });
  }

  createChart(chart) {
    if (!chart) {
      return null;
    }

    const type1 = getProp(chart, 'type1', null);

    if (type1 === 'total') {
      return createValueChart(chart);
    } else if (type1 === 'summary') {
      return createSummaryChart(chart);
    } else if (type1 === 'histogram') {
      return createHistogramChart(chart, {
        subchart: false,
      });
    }
  }

  render() {
    try {
      let content;
      if (this.state.loading) {
        content = (
          <Loader className="min-height-loader loader-small" show={true} />
        );
      } else {
        if (this.state.charts.length > 0) {
          let filters = [];
          if (this.state.typeIdx !== null && this.state.chartIdx !== null) {
            filters = getProp(
              this.state.charts[this.state.typeIdx].charts[this.state.chartIdx],
              'filters',
              [],
            );
          }
          content = (
            <div>
              <div className="row">
                <div className="col-md-4">
                  <fieldset className="fieldset">
                    <legend>{__('Typy grafov')}</legend>
                    <ul className="list list-unstyled">
                      {this.state.charts.map((item, idx) => {
                        return (
                          <li
                            key={idx}
                            className={cx({
                              active: idx === this.state.typeIdx,
                            })}
                          >
                            <span
                              onClick={this.onClickType.bind(this, idx)}
                              className="clickable"
                            >
                              {getChartName(item.type)}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </fieldset>
                </div>
                <div className="col-md-4">
                  <fieldset className="fieldset">
                    <legend>{__('Grafy')}</legend>
                    {this.state.typeIdx === null ? (
                      <em className="font-lg">{__('Vyberte typ grafu')}</em>
                    ) : null}
                    <ul className="list list-unstyled">
                      {this.state.typeIdx !== null &&
                        this.state.charts[this.state.typeIdx].charts.map(
                          (item, idx) => {
                            return (
                              <li
                                key={idx}
                                className={cx({
                                  active: idx === this.state.chartIdx,
                                })}
                              >
                                <span
                                  className="clickable"
                                  onClick={this.onClickChart.bind(this, idx)}
                                >
                                  {item.name ? (
                                    item.name
                                  ) : (
                                    <em>{__('Bez názvu')}</em>
                                  )}
                                </span>
                              </li>
                            );
                          },
                        )}
                    </ul>
                  </fieldset>
                </div>
                <div className="col-md-4">
                  <fieldset className="fieldset">
                    <legend>{__('Nastavené filtre')}</legend>
                    {this.state.chartIdx === null ? (
                      <em className="font-lg">
                        {__('Vyberte konkrétny graf')}
                      </em>
                    ) : null}
                    {this.state.typeIdx !== null &&
                    this.state.chartIdx !== null ? (
                      <div>
                        <ChartInfo filter={filters[0]} />
                        {typeof filters[1] !== 'undefined' ? (
                          <div>
                            <strong>{__('Porovnávací filter')}:</strong>
                            <ChartInfo filter={filters[1]} />
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </fieldset>
                </div>
              </div>

              <div
                className={cx('text-center', {
                  hidden:
                    this.state.typeIdx === null || this.state.chartIdx === null,
                })}
              >
                <span
                  onClick={this.onClickAdd.bind(this)}
                  className="btn btn-primary"
                >
                  {__('Pridať tento graf')}
                </span>
                <br />
                <br />
              </div>

              <Loader show={this.state.chartLoading}>
                <div
                  className={cx('alternative-content', {
                    hidden: this.state.chart === null,
                  })}
                >
                  <h2>{__('Náhľad grafu')}</h2>
                  {this.createChart(this.state.chart)}
                </div>
              </Loader>
            </div>
          );
        } else {
          content = (
            <div className="text-center">
              <em className="font-xlg">{__('Žiadne grafy')}</em>
              <br />
              <br />
            </div>
          );
        }
      }

      return (
        <div>
          <br />
          {content}
        </div>
      );
    } catch (e) {
      console.error(e);
    }
  }
}

export default connect(state => ({
  filter: state.filter,
}))(UseChartForm);
