import cx from 'classnames';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Api from '../service/Api';
import { getProp, isAllowed, __ } from '../utilities/common';
import { hideMenu, reset, setAlert } from './../actions';
import Icon from './../component/icon';
import StatusBar from './../component/statusBar';
import AppAuthorized from './appAuthorized';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuToggle: false,
    };
  }

  getChildContext() {
    return {
      buyLicense: license => {
        let extend = true;
        let url = `/user/license`;
        if (license.order > getProp(this.props.user, 'license.order', 0)) {
          url += `/upgrade/${license._id}`;
          extend = false;
        }
        return new Promise((resolve, reject) => {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Ste si istý?'),
              confirmButtonColor: '#DD6B55',
              cancelButtonColor: '#d33',
              showCancelButton: true,
              confirmButtonText: __('Áno, predĺžiť'),
              cancelButtonText: __('Nie, zrušiť'),
              buttonsStyling: false,
              text: extend
                ? __('Naozaj chcete predĺžiť aktuálnu licenciu?')
                : __('Naozaj chcete kúpiť túto licenciu?'),
              onConfirm: () => {
                Api.post(
                  url,
                  JSON.stringify({
                    period: 'month',
                  }),
                )
                  .then(response => {
                    this.props.dispatch(
                      setAlert({
                        type: 'success',
                        title: __('Objednávka bola úspešne vytvorená'),
                        text: __(
                          'Po zaplatení Vám bude vybraná licencia aktivovaná',
                        ).format({ code: response.twig_activation_key }),
                        showCancelButton: true,
                        confirmButtonText: __('Zaplatiť'),
                        cancelButtonText: __('Neskôr'),
                        onCancel: () => {
                          this.props.dispatch(setAlert(null));
                        },
                        onConfirm: () => {
                          window.location.href = response.payment.url;
                        },
                      }),
                    );
                    resolve(response);
                  })
                  .catch(error => {
                    if (error.response.status === 409) {
                      this.props.dispatch(
                        setAlert({
                          type: 'error',
                          title: __('Objednávka už bola zaplatená'),
                          onConfirm: () => this.props.dispatch(setAlert(null)),
                        }),
                      );
                    } else {
                      this.props.dispatch(
                        setAlert({
                          type: 'error',
                          title: __('Objednávku nebolo možné vytvoriť'),
                          onConfirm: () => this.props.dispatch(setAlert(null)),
                        }),
                      );
                    }
                    reject(error);
                  });
              },
              onCancel: () => {
                reject();
                this.props.dispatch(setAlert(null));
              },
            }),
          );
        });
      },
    };
  }

  onClickMenuItem(ev) {
    if (window.innerWidth <= 1200 && !this.state.menuToggle) {
      this.props.dispatch(hideMenu());
    }
  }

  onChangeMenuToggle(ev) {
    this.setState({ menuToggle: !this.state.menuToggle });
  }

  onLogout() {
    this.props.dispatch(reset());
    AppAuthorized.logout();
  }

  render() {
    return (
      <AppAuthorized>
        <div id="app-container">
          <StatusBar
            forename={getProp(this.props.user, 'forename')}
            surname={getProp(this.props.user, 'surname')}
            companyName={getProp(this.props.user, 'company.company_name')}
            onClickLogout={this.onLogout.bind(this)}
            license={getProp(this.props.user, 'license')}
          />
          <div id="container">
            <div
              id="side-nav"
              className={cx({
                'nav-hidden': this.props.menuVisible === false,
                'menu-toggle': this.state.menuToggle,
              })}
            >
              <nav>
                <ul className="nav">
                  <li>
                    <Link
                      to="/app/profile"
                      activeClassName="active"
                      onClick={this.onClickMenuItem.bind(this)}
                    >
                      <Icon name="custom-moja-firma" />
                      <span className="menu-item-label">
                        {__('Moja firma')}
                      </span>
                    </Link>
                  </li>
                  {isAllowed('otaupdates') || true ? (
                    <Fragment>
                      <li>
                        <Link
                          to="/app/ota-updates"
                          activeClassName="active"
                          onClick={this.onClickMenuItem.bind(this)}
                        >
                          <Icon name="download" />
                          <span className="menu-item-label">
                            {__('OTA aktualizácie')}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/app/ecrs"
                          activeClassName="active"
                          onClick={this.onClickMenuItem.bind(this)}
                        >
                          <Icon name="ingredients_list" />
                          <span className="menu-item-label">
                            {__('Zoznam ECR')}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/app/ecr-groups"
                          activeClassName="active"
                          onClick={this.onClickMenuItem.bind(this)}
                        >
                          <Icon name="filing_cabinet" />
                          <span className="menu-item-label">
                            {__('Skupiny ECR')}
                          </span>
                        </Link>
                      </li>
                    </Fragment>
                  ) : null}
                </ul>
              </nav>

              <div
                className="text-center menu-btn"
                onClick={this.onChangeMenuToggle.bind(this)}
              >
                {this.state.menuToggle ? (
                  <Icon name="custom-menu-on" />
                ) : (
                  <Icon name="custom-menu-off" />
                )}
              </div>
            </div>

            <div
              id="content"
              className={cx({
                'menu-toggle': this.state.menuToggle && this.props.menuVisible,
              })}
            >
              {this.props.children}
            </div>
          </div>
        </div>
      </AppAuthorized>
    );
  }
}

App.contextTypes = {
  router: PropTypes.object,
};

App.childContextTypes = {
  buyLicense: PropTypes.func,
};

export default connect(state => ({
  user: state.user,
  filter: state.filter,
  filterShow: state.filterShow,
  menuVisible: state.menuVisible,
}))(App);
