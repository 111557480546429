import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import GlobalFilterDelegator from '../GlobalFilterDelegator';
import Api from '../service/Api';
import { getProp, setProp, __ } from '../utilities/common';
import {
  decrementSaveCounter,
  incrementSaveCounter,
  setAlert,
} from './../actions';
import { create as createHistogramChart } from './../component/chart/histogram';
import { create as createSummaryChart } from './../component/chart/summary';
import { create as createValueChart } from './../component/chart/value';
import Content from './../component/content';
import Icon from './../component/icon';
import MainHeader from './../component/mainHeader';
import Placeholder from './../component/placeholder';
import SidePanel from './../component/sidePanel';
import ViewElement from './../component/viewElement';
import AddWidget from './../module/addWidget';
import {
  buildChartFilter,
  buildQueryParams,
  buildQueryUrl,
  chartErrors,
} from './../utilities/chart';
import ChartForm from './chartForm';

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      moveChart: null, // Can be null or { area: 'source_area', idx: 'chart_idx' }
      dropzoneSrc: null,
      editPosition: null,
      editChart: null,
      view: {
        title: '',
        settings: {},
      },
      views: [],
      loading: false,
    };

    this.loadDefaultView();

    this.loadAllViews();

    this.viewChangeTimeout = null;

    GlobalFilterDelegator.addDelegate(this);
  }

  componentWillUnmount() {
    GlobalFilterDelegator.removeDelegate(this);
    window.clearTimeout(this.viewChangeTimeout);
  }

  onChangeGlobalFilter() {
    Promise.all(this.loadDataForCharts(true, true)).then(() => {
      this.save();
    });
  }

  loadDefaultView() {
    if (this.props.defaultId) {
      // If we get id from props, load it
      this.loadView(this.props.defaultId);
    } else {
      console.warn('No default view specified');
    }
  }

  loadAllViews() {
    Api.get(`/user/ui/stats-views`).then(response => {
      this.setState({ views: response.stats_views });
    });
  }

  loadView(id) {
    this.setState({ loading: true });
    return Api.get(`/user/ui/stats-views/${id}`)
      .then(response => {
        this.setState({ loading: false, view: response }, () => {
          Promise.all(this.loadDataForCharts(true)).then(() => {
            this.save();
          });
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  onChangeTitle(ev) {
    this.setState(
      { view: setProp(this.state.view, 'title', ev.target.value) },
      () => {
        // Debounce
        window.clearTimeout(this.viewChangeTimeout);
        this.viewChangeTimeout = window.setTimeout(() => {
          if (this.state.view.title.trim()) {
            this.save();
          }
        }, 500);
      },
    );
  }

  onChangeEditMode(data, ev) {
    this.setState({ edit: data.value, moveChart: null });
  }

  onClickAdd(position) {
    if (this.state.moveChart) {
      let view = this.state.view;

      // Source
      let charts = getProp(view, `settings.${this.state.moveChart.area}`, []);
      let chart = charts.splice(this.state.moveChart.idx, 1);
      view = setProp(
        this.state.view,
        `settings.${this.state.moveChart.area}`,
        charts,
      );

      // Destination
      charts = getProp(view, `settings.${position}`, []);
      charts.push(chart[0]);
      view = setProp(view, `settings.${position}`, charts);
      this.setState({ view: view, moveChart: null });
      this.save();
    } else {
      this.setState({ editPosition: position });
    }
  }

  removeChart(chart, areaId) {
    const charts = getProp(this.state.view.settings, areaId, []);
    for (let idx in charts) {
      if (charts[idx] === chart) {
        charts.splice(idx, 1);
        break;
      }
    }
    this.setState(
      { view: setProp(this.state.view, `settings.${areaId}`, charts) },
      () => {
        this.save();
      },
    );
  }

  createChart(chart, area) {
    const type1 = getProp(chart, 'type1', null);

    let active = false;
    if (this.state.moveChart !== null) {
      const charts = getProp(
        this.state.view.settings,
        this.state.moveChart.area,
        [],
      );
      if (charts[this.state.moveChart.idx] === chart) {
        active = true;
      }
    }

    let reorderCallbacks = {
      onClickMoveUp: () => {
        this._moveChartLeft(chart, area);
      },
      onClickMoveDown: () => {
        this._moveChartRight(chart, area);
      },
    };

    let commonCallbacks = {
      active: active,
      showControls: this.state.edit,
      onClickEdit: () => {
        this.setState({ editChart: chart, editPosition: area });
      },
      onClickMove: () => {
        const charts = getProp(this.state.view, `settings.${area}`, []);
        const idx = charts.indexOf(chart);
        if (
          this.state.moveChart !== null &&
          this.state.moveChart.area === area &&
          this.state.moveChart.idx === idx
        ) {
          this.setState({ moveChart: null });
        } else {
          this.setState({
            moveChart: {
              area: area,
              idx: charts.indexOf(chart),
            },
          });
        }
      },
      onClickRemove: () => {
        this.removeChart(chart, area);
      },
    };

    if (area === 'area_1') {
      reorderCallbacks = {
        onClickMoveLeft: () => {
          this._moveChartLeft(chart, area);
        },
        onClickMoveRight: () => {
          this._moveChartRight(chart, area);
        },
      };
    }

    if (type1 === 'total') {
      return React.createElement(
        ViewElement,
        Object.assign(commonCallbacks, reorderCallbacks), // props
        createValueChart(chart, { error: chart.error }),
      );
    } else if (type1 === 'summary') {
      return React.createElement(
        ViewElement,
        Object.assign(commonCallbacks, reorderCallbacks), // props
        createSummaryChart(chart, { error: chart.error }, !this.isDashboard()),
      );
    } else if (type1 === 'histogram') {
      return React.createElement(
        ViewElement,
        Object.assign(commonCallbacks, reorderCallbacks), // props
        createHistogramChart(
          chart,
          { error: chart.error },
          !this.isDashboard(),
        ),
      );
    }
  }

  _moveChartLeft(chart, area) {
    this._moveChart(chart, area, false);
  }

  _moveChartRight(chart, area) {
    this._moveChart(chart, area, true);
  }

  _moveChart(chart, area, toRight = true) {
    const charts = getProp(this.state.view, `settings.${area}`, []);
    charts.move(charts.indexOf(chart), toRight);
    this.setState({
      view: setProp(this.state.view, `settings.${area}`, charts),
    });
    this.save();
  }

  loadDataForCharts(
    invalidateAll = false,
    updateOnlyGlobalFilterRelated = false,
  ) {
    const promises = [];
    for (const areaId of [
      'area_1',
      'area_2',
      'area_3',
      'area_4',
      'area_5',
      'area_6',
      'area_7',
      'area_8',
      'area_9',
    ]) {
      const charts = getProp(this.state.view.settings, areaId, []);
      for (const chart of charts) {
        if (chart.invalid === true || invalidateAll) {
          if (updateOnlyGlobalFilterRelated) {
            if (this.isChartGlobalRelated(chart)) {
              promises.push(this.loadChartData(areaId, charts, chart));
            }
          } else {
            promises.push(this.loadChartData(areaId, charts, chart));
          }
        }
      }
    }
    return promises;
  }

  isChartGlobalRelated(chart) {
    return this.state.view._id === 'dashboard';

    // Toto sa uz nepouziva pretoze grafy na dashboarde su vsetky zavisle od globalneho filtra
    // Nechavam to tu preto, aby sa to mzono raz niekedy pouzilo...

    // for (const filter of getProp(chart, 'filters', [])) {
    //     if (getProp(filter, 'data.use', true) === true) {
    //         return true;
    //     }
    //     if (getProp(filter, 'date.use', true) === true) {
    //         return true;
    //     }
    //     if (getProp(filter, 'venue.use', true) === true) {
    //         return true;
    //     }
    //     if (getProp(filter, 'finance.use', true) === true) {
    //         return true;
    //     }
    //     if (getProp(filter, 'product.use', true) === true) {
    //         return true;
    //     }
    // }
    // return false;
  }

  loadChartData(areaId, charts, chart) {
    const type1 = getProp(chart, 'type1', '');
    const type2 = getProp(chart, 'type2', '');
    const type3 = getProp(chart, 'type3', '');

    // Construct url from selected types
    let apiUrl = buildQueryUrl(type1, type2, type3);

    // Need for multiple API calls because there are multiple filters available
    const promises = [];

    for (let i = 0; i < chart.filters.length; i++) {
      // i is index of each filter
      (idx => {
        // Create search filter from either local or global filters
        let chartFilter = {};
        if (this.isDashboard()) {
          // Ignore local filter setting
          chartFilter = buildChartFilter({}, getProp(this.props, 'filter', {}));
        } else {
          // Ignore global filter setting
          chartFilter = buildChartFilter({}, chart.filters[idx]);
        }
        if (typeof chart.filtersExtended === 'undefined') {
          chart.filtersExtended = [];
        }
        chart.filtersExtended[idx] = JSON.parse(JSON.stringify(chartFilter));

        // Build params
        const params = buildQueryParams(chartFilter);

        // Api call
        promises.push(
          Api.get(apiUrl, params)
            .then(response => {
              chart.error = chartErrors().SUCCESS;
              if (type1 === 'total' || type1 === 'summary') {
                chart.data = response[type2.replaceAll('-', '_')];
                chart.invalid = false;
                this.setState({
                  view: setProp(this.state.view, `settings.${areaId}`, charts),
                });
              } else if (type1 === 'histogram') {
                if (typeof chart.data === 'undefined') {
                  chart.data = [];
                }
                chart.data[idx] = response[type1];
                chart.invalid = false;
                this.setState({
                  view: setProp(this.state.view, `settings.${areaId}`, charts),
                });
              }
            })
            .catch(error => {
              if (getProp(error, 'details.code') === 403) {
                // Nebolo mozne nacitat graf kvoli chybe ACL
                chart.error = chartErrors().ACL_ERROR;
              } else {
                // Ina chyba
                chart.error = chartErrors().GENERIC_ERROR;
              }
            }),
        );
      })(i);
    }

    return Promise.all(promises);
  }

  save() {
    if (this.state.view._id) {
      this.props.dispatch(incrementSaveCounter());
      return Api.post(`/user/ui/stats-views`, JSON.stringify(this.state.view))
        .then(() => {
          this.props.dispatch(decrementSaveCounter());
          this.loadAllViews();
        })
        .catch(() => {
          this.props.dispatch(decrementSaveCounter());
        });
    }
    return new Promise((resolve, reject) => {
      resolve();
    });
  }

  onChangeView(newView) {
    this.loadView(newView._id);
  }

  addView() {
    this.props.dispatch(
      setAlert({
        type: 'input',
        title: __('Nový pohľad'),
        text: __('Zadajte názov nového pohľadu'),
        showCancelButton: true,
        confirmButtonText: __('Vytvoriť'),
        cancelButtonText: __('Zrušiť'),
        inputPlaceholder: '',
        onConfirm: val => {
          if (val.trim()) {
            const id = val.slugify();
            const view = {
              _id: id,
              title: val,
              settings: {},
            };
            Api.post(`/user/ui/stats-views`, JSON.stringify(view))
              .then(() => {
                this.loadAllViews();
                this.loadView(id).then(() => {
                  this.setState({ edit: true });
                });
                this.props.dispatch(setAlert(null));
              })
              .catch(() => {
                this.props.dispatch(
                  setAlert({
                    type: 'error',
                    title: __('Pri vytváraní pohľadu došlo k chybe'),
                    onConfirm: () => this.props.dispatch(setAlert(null)),
                  }),
                );
              });
          }
        },
        onCancel: () => {
          this.props.dispatch(setAlert(null));
        },
      }),
    );
  }

  onClickToggleEditMode() {
    this.setState({ edit: !this.state.edit });
  }

  onClickRemoveCurrentView() {
    const view = this.state.view;
    if (view._id) {
      this.props.dispatch(
        setAlert({
          type: 'warning',
          title: __('Ste si istý?'),
          confirmButtonColor: '#DD6B55',
          cancelButtonColor: '#d33',
          showCancelButton: true,
          confirmButtonText: __('Áno, zmazať'),
          cancelButtonText: __('Nie, zrušiť'),
          buttonsStyling: false,
          text: __("Naozaj chcete zmazať pohľad '%(name)s'?").format({
            name: view.title,
          }),
          onConfirm: () => {
            Api.delete(`/user/ui/stats-views/${view._id}`)
              .then(() => {
                this.loadView(this.props.defaultId);
                this.loadAllViews();
                this.props.dispatch(setAlert(null));
              })
              .catch(() => {
                this.props.dispatch(setAlert(null));
              });
          },
          onCancel: () => this.props.dispatch(setAlert(null)),
        }),
      );
    }
  }

  isDashboard() {
    return this.props.defaultId === 'dashboard';
  }

  onClickSaveBtn() {
    this.save();
    this.setState({ edit: false });
  }

  onClickStartEditMode() {
    this.setState({ edit: true });
  }

  isViewEmpty() {
    const settings = getProp(this.state.view, 'settings', []);
    let isEmpty = true;
    for (let viewId in settings) {
      if (settings.hasOwnProperty(viewId)) {
        if (Array.isArray(settings[viewId]) && settings[viewId].length > 0) {
          isEmpty = false;
          break;
        }
      }
    }
    return isEmpty;
  }

  render() {
    try {
      return (
        <div id="content-wrapper">
          <MainHeader
            title={this.state.view.title}
            progress={100}
            editTitle={this.state.edit}
            onChangeTitle={this.onChangeTitle.bind(this)}
            onChangeView={this.onChangeView.bind(this)}
            selectedView={this.state.view}
            views={this.state.views}
            cashregisterSelect={this.isDashboard()}
            onChangeCashRegister={this.props.onChangeCashRegister.bind(this)}
            viewSelect={!this.isDashboard()}
            showFilter={this.isDashboard()}
            afterTitle={
              this.state.edit ? (
                <span style={{ display: 'flex' }}>
                  &nbsp;
                  <span
                    style={{ marginLeft: '4px' }}
                    className="btn btn-primary save-btn"
                    onClick={this.onClickSaveBtn.bind(this)}
                    title={__('Uložiť')}
                  >
                    <span>{__('Uložiť')}</span>
                    <Icon name="save" />
                  </span>
                  <span
                    className={cx({
                      clickable: true,
                      hidden:
                        !this.state.view._id ||
                        this.isDashboard() ||
                        this.state.view._id === 'default' ||
                        !this.state.edit,
                    })}
                    onClick={this.onClickRemoveCurrentView.bind(this)}
                    title={__('Odstrániť aktuálny pohľad')}
                  >
                    <Icon name="remove" />
                  </span>
                </span>
              ) : (
                <span
                  className="clickable"
                  onClick={this.onClickToggleEditMode.bind(this)}
                  title={__('Mód úpravy')}
                >
                  <Icon
                    className={cx({ active: this.state.edit })}
                    name="edit"
                  />
                </span>
              )
            }
          />
          <Content>
            {this.props.children}

            {this.isViewEmpty() && !this.state.loading && !this.state.edit ? (
              <div
                style={{ cursor: 'pointer', marginTop: '40px' }}
                className="text-muted text-center"
                onClick={this.onClickStartEditMode.bind(this)}
              >
                <h1>{__('Pridajte prvý graf')}</h1>
                <h4>{__('Kliknutím sem zapnete mód úprav')}</h4>
              </div>
            ) : null}

            <div className="row row-flex">
              {getProp(this.state.view.settings, 'area_1', []).map(
                (chart, idx) => {
                  return (
                    <div
                      className="col-xs-6 col-sm-4 col-md-3 col-lg-2"
                      key={idx}
                    >
                      {this.createChart(chart, 'area_1')}
                    </div>
                  );
                },
              )}
              <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                <Placeholder
                  show={this.state.edit}
                  iconName={
                    this.state.moveChart !== null ? 'checkmark' : 'plus-math'
                  }
                  active={this.state.moveChart !== null}
                  onClick={this.onClickAdd.bind(this, 'area_1')}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {getProp(this.state.view.settings, 'area_2', []).map(
                  (chart, idx) => {
                    return (
                      <div key={idx}>{this.createChart(chart, 'area_2')}</div>
                    );
                  },
                )}
                <Placeholder
                  show={this.state.edit}
                  iconName={
                    this.state.moveChart !== null ? 'checkmark' : 'plus-math'
                  }
                  active={this.state.moveChart !== null}
                  onClick={this.onClickAdd.bind(this, 'area_2')}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                {getProp(this.state.view.settings, 'area_3', []).map(
                  (chart, idx) => {
                    return (
                      <div key={idx}>{this.createChart(chart, 'area_3')}</div>
                    );
                  },
                )}
                <Placeholder
                  show={this.state.edit}
                  iconName={
                    this.state.moveChart !== null ? 'checkmark' : 'plus-math'
                  }
                  active={this.state.moveChart !== null}
                  onClick={this.onClickAdd.bind(this, 'area_3')}
                />
              </div>
              <div className="col-md-4">
                {getProp(this.state.view.settings, 'area_4', []).map(
                  (chart, idx) => {
                    return (
                      <div key={idx}>{this.createChart(chart, 'area_4')}</div>
                    );
                  },
                )}
                <Placeholder
                  show={this.state.edit}
                  iconName={
                    this.state.moveChart !== null ? 'checkmark' : 'plus-math'
                  }
                  active={this.state.moveChart !== null}
                  onClick={this.onClickAdd.bind(this, 'area_4')}
                />
              </div>
              <div className="col-md-4">
                {getProp(this.state.view.settings, 'area_5', []).map(
                  (chart, idx) => {
                    return (
                      <div key={idx}>{this.createChart(chart, 'area_5')}</div>
                    );
                  },
                )}
                <Placeholder
                  show={this.state.edit}
                  iconName={
                    this.state.moveChart !== null ? 'checkmark' : 'plus-math'
                  }
                  active={this.state.moveChart !== null}
                  onClick={this.onClickAdd.bind(this, 'area_5')}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {getProp(this.state.view.settings, 'area_6', []).map(
                  (chart, idx) => {
                    return (
                      <div key={idx}>{this.createChart(chart, 'area_6')}</div>
                    );
                  },
                )}
                <Placeholder
                  show={this.state.edit}
                  iconName={
                    this.state.moveChart !== null ? 'checkmark' : 'plus-math'
                  }
                  active={this.state.moveChart !== null}
                  onClick={this.onClickAdd.bind(this, 'area_6')}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                {getProp(this.state.view.settings, 'area_7', []).map(
                  (chart, idx) => {
                    return (
                      <div key={idx}>{this.createChart(chart, 'area_7')}</div>
                    );
                  },
                )}
                <Placeholder
                  show={this.state.edit}
                  iconName={
                    this.state.moveChart !== null ? 'checkmark' : 'plus-math'
                  }
                  active={this.state.moveChart !== null}
                  onClick={this.onClickAdd.bind(this, 'area_7')}
                />
              </div>
              <div className="col-md-6">
                {getProp(this.state.view.settings, 'area_8', []).map(
                  (chart, idx) => {
                    return (
                      <div key={idx}>{this.createChart(chart, 'area_8')}</div>
                    );
                  },
                )}
                <Placeholder
                  show={this.state.edit}
                  iconName={
                    this.state.moveChart !== null ? 'checkmark' : 'plus-math'
                  }
                  active={this.state.moveChart !== null}
                  onClick={this.onClickAdd.bind(this, 'area_8')}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {getProp(this.state.view.settings, 'area_9', []).map(
                  (chart, idx) => {
                    return (
                      <div key={idx}>{this.createChart(chart, 'area_9')}</div>
                    );
                  },
                )}
                <Placeholder
                  show={this.state.edit}
                  iconName={
                    this.state.moveChart !== null ? 'checkmark' : 'plus-math'
                  }
                  active={this.state.moveChart !== null}
                  onClick={this.onClickAdd.bind(this, 'area_9')}
                />
              </div>
            </div>

            <SidePanel
              show={this.state.editPosition !== null}
              onClose={(ev, chart) => {
                this.setState({ editPosition: null });
              }}
            >
              <div>
                {this.state.editPosition !== null &&
                this.state.editChart === null ? (
                  <AddWidget
                    allowFilters={!this.isDashboard()}
                    onSave={(ev, chart) => {
                      // We have new chart to save
                      const view = this.state.view;
                      const charts = getProp(
                        view.settings,
                        this.state.editPosition,
                        [],
                      );
                      chart.invalid = true;
                      charts.push(chart);
                      view.settings = setProp(
                        view.settings,
                        this.state.editPosition,
                        charts,
                      );
                      this.setState({ view: view }, () => {
                        this.save().then(() => {
                          Promise.all(this.loadDataForCharts()).then(() => {
                            this.save();
                          });
                        });
                      });
                      this.setState({ editPosition: null });
                    }}
                  />
                ) : null}
                {this.state.editPosition !== null &&
                this.state.editChart !== null ? (
                  <ChartForm
                    data={this.state.editChart}
                    allowFilters={!this.isDashboard()}
                    onSave={(ev, chart) => {
                      // We have chart to update
                      const view = this.state.view;
                      const charts = view.settings[this.state.editPosition];
                      const idx = charts.indexOf(this.state.editChart);
                      charts.splice(idx, 1, chart);
                      chart.invalid = true;
                      view.settings = setProp(
                        view.settings,
                        this.state.editPosition,
                        charts,
                      );
                      this.setState({ view: view }, () => {
                        this.save().then(() => {
                          Promise.all(this.loadDataForCharts()).then(() => {
                            this.save();
                          });
                        });
                      });
                      this.setState({ editChart: null, editPosition: null });
                    }}
                  />
                ) : null}
              </div>
            </SidePanel>
          </Content>
        </div>
      );
    } catch (e) {
      console.error(e);
    }
  }
}

View.defaultProps = {
  onChangeCashRegister: () => {},
};

export default connect(
  state => ({
    filter: state.filter,
  }),
  null,
  null,
  { forwardRef: true },
)(View);
