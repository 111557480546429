import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../actions';
import Api from '../../service/Api';
import {
  findGetParameter,
  formatNumber,
  getProp,
  __,
} from '../../utilities/common';
import Content from './../../component/content';
import DataFilterInput from './../../component/dataFilterInput';
import DataTable from './../../component/dataTable';
import FloatingActionButton from './../../component/floatingActionButton';
import Icon from './../../component/icon';
import Loader from './../../component/loader';
import LoadMoreBtn from './../../component/loadMoreBtn';
import MainHeader from './../../component/mainHeader';
import ProtectedContent from './../../component/protectedContent';
import SidePanel from './../../component/sidePanel';
import { CategoryFormGenerator } from './remoteAccess/categoryFormGenerator';
import { DeskFormGenerator } from './remoteAccess/deskFormGenerator';
import { FormGenerator } from './remoteAccess/formGenerator';
import { ProductFormGenerator } from './remoteAccess/productFormGenerator';
import { UnitFormGenerator } from './remoteAccess/unitFormGenerator';
import { UserFormGenerator } from './remoteAccess/userFormGenerator';

const MAX_LIMIT = 10000;

class RemoteAccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'category',
      sort: null,
      query: '',
      activeTabNextOffset: 0,
      activeTabLimit: 1000,
      entityData: {},
      entityFormError: {},
      entityIdKey: null,
      showSidePanel: false,
      showCloneSidePanel: false,
      cloneCashregisterId: null,
      cashregisterList: [],
      schemaLoading: true,
      detailLoading: true,
      listLoading: 0,
      statuses: {},
      cashregistersLoading: false,
      selectedProducts: [],
      bulkEditOpened: false,
        editing: false
    };

    this.schemas = {};
  }

  componentDidMount() {
    this.fetchSchemas();
    let defaultTab = findGetParameter('default');
    if (defaultTab) {
      this.setState({ activeTab: defaultTab }, () => {
        this.setTab(this.state.activeTab);
      });
    } else {
      this.setTab(this.state.activeTab);
    }
  }

  getCashregisterId() {
    return this.props.params.id;
  }

  refreshList() {
    this.getMainList(this.state.activeTab);
  }

  getMainList(entityName, offset = 0, limit = MAX_LIMIT) {
    this.setState({ listLoading: this.state.listLoading + 1, selectedProducts: [] });
    const promises = [];
    const params = {};
    if (limit !== MAX_LIMIT) {
      params.limit = limit;
      params.offset = offset;
    }
    if (this.state.sort !== null) {
      params.sorter = this.getSorterName(this.state.activeTab, this.state.sort);
    }
    params.search = this.state.query;
    promises.push(
      this.getList(entityName, `?${Api.arrayToURL(params)}`, offset !== 0).then(
        response => {
          let nextOffset = null;
          if ('next_offset' in response) {
            nextOffset = response.next_offset;
          }
          this.setState({
            entityIdKey: response.segment_id_key,
            activeTabNextOffset: nextOffset,
          });
        },
      ),
    );

    if (entityName === 'desk') {
      promises.push(this.getList('room'));
    }
    if (entityName === 'product') {
      promises.push(this.getList('category'));
    }

    promises.push(this.getSyncStatus());

    return Promise.all(promises).then(response => {
      this.setState({ listLoading: this.state.listLoading - 1 });
      return response[0];
    });
  }

  getList(entityName, params = '', append = false) {
    return Api.get(
      `/user/cash-registers/${this.getCashregisterId()}/setup/${this.getApiEntityName(
        entityName,
      )}${params}`,
    ).then(response => {
      let state = this.state;
      if (append) {
        state[`${entityName}List`] = [
          ...state[`${entityName}List`],
          ...response.items,
        ];
      } else {
        state[`${entityName}List`] = response.items;
      }
      this.setState(state);
      return response;
    });
  }

  fetchSchemas() {
    this.setState({ schemaLoading: true });

    let schemaIgnoreConfig = {
      user: [
        'use_pass_hash',
        'ctrl_deletable',
        'ctrl_editable',
        'use_lang',
        'use_display_name_without_diacritic',
        'use_display_name',
        'use_id',
        'sync_deleted',
      ],
      category: [
        'cat_id',
        'cat_shortname',
        'sho_id',
        'ctrl_deletable',
        'ctrl_editable',
        'sync_deleted',
      ],
      desk: ['des_id', 'ctrl_deletable', 'ctrl_editable', 'sync_deleted'],
      unit: [
        'uni_id',
        'uni_base_unit',
        'ctrl_deletable',
        'ctrl_editable',
        'sync_deleted',
      ],
      vat: ['sync_deleted', 'ctrl_deletable', 'ctrl_editable', 'vat_id'],
      room: [
        'roo_id',
        'ctrl_deletable',
        'ctrl_editable',
        'sync_deleted',
        'deletable',
        'is_deleted',
      ],
      payment: [
        'pay_id',
        'pay_chargable',
        'pay_grouping_enabled',
        'ctrl_deletable',
        'ctrl_editable',
        'sync_deleted',
        'pay_order',
        'pay_type',
      ],
      product: [
        'pro_id',
        'pro_unit',
        'pro_no_single_sale',
        'ctrl_deletable',
        'ctrl_editable',
        'sync_deleted',
      ],
      "product-bulk": [
        'pro_id',
        'pro_unit',
        'pro_no_single_sale',
        'ctrl_deletable',
        'ctrl_editable',
        'sync_deleted',
      ],
    };
    const promises = [];

    for (let schemaName in schemaIgnoreConfig) {
      promises.push(
        Api.get(`/docs/schemas/cash-register/setup/${schemaName}.schema.json`),
      );
    }

    return Promise.all(promises)
      .then(schemas => {
        let idx = 0;
        let schemaKeys = Object.keys(schemaIgnoreConfig);
        for (const schema of schemas) {
          schema.ignored = schemaIgnoreConfig[schemaKeys[idx]];
          this.schemas[schemaKeys[idx]] = schema;
          idx++;
        }
        this.setState({ schemaLoading: false });
      })
      .catch(() => {
        this.setState({ schemaLoading: false });

        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Dáta sa nepodarilo načítať'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
      });
  }

  prepareEntity(entityName, entity) {
    const mValues = this.getSchema(this.state.activeTab);
    let obj = Object.assign({}, entity);

    if (entityName === 'product') {
      if (entity.pro_quantity !== '') {
        obj['pro_quantity'] = entity.pro_quantity * 1000;
      }
      if (entity.pro_sum !== '') {
        obj['pro_sum'] = entity.pro_sum * 100;
      }
      if (entity.pro_sum_vat !== '') {
        obj['pro_sum_vat'] = entity.pro_sum_vat * 100;
      }
    } else if (entityName === 'vat') {
      obj['vat_value'] = entity.vat_value * 100;
    }

    for (const property in obj) {
      // existuje tato prop v scheme?
      if (property in mValues.properties) {
        if (obj[property] && mValues.properties[property].type === 'integer') {
          // pretypujeme, ak treba
          obj[property] = Number.parseInt(obj[property]);
        }

        if (mValues.required.includes(property)) {
          // validujeme len tie props, ktore maju hodnotu a su required
          const entityFormError = this.state.entityFormError;
          if (mValues.properties[property].type === 'integer') {
            // validujeme integer
            if (Number.isNaN(obj[property]) || obj[property] === '') {
              entityFormError[property] = true;
              this.setState({ entityFormError });
              throw new Error({
                entityName,
                prop: property,
                propDescription: mValues.properties[property].description,
                msg: 'NOT_A_NUMBER',
              });
            }
          } else if (mValues.properties[property].type === 'string') {
            // validujeme string
            if (
              typeof obj[property] === 'string' &&
              obj[property].trim().length > 0
            ) {
              // ok
            } else {
              entityFormError[property] = true;
              this.setState({ entityFormError });
              throw new Error({
                entityName,
                prop: property,
                propDescription: mValues.properties[property].description,
                msg: 'NOT_A_STRING',
              });
            }
          }
        } else if (obj[property] === '') {
          delete obj[property];
        }

        // specificke pre kategoriu, cat_parent_id musi byt nastaveny na null, ak nie je nic vybrate (davame do root kategorie)
        if (entityName === 'category' && property === 'cat_parent_id') {
          if (!obj[property]) {
            obj[property] = null;
          } else {
            obj[property] = Number.parseInt(obj[property]);
          }
        }
      } else {
        // odstranime z objektu na poslanie, ak prop nie je v scheme
        delete obj[property];
      }
    }
    return obj;
  }

  saveBulkProducts() {
    const errorCallback = error => {
      if (error.response && error.response.status === 400) {
        if (error.details.name === 'INVALID_SCHEMA') {
          const formError = this.state.entityFormError;
          const paths = getProp(error.details, 'payload.paths', []);
          for (const path of paths) {
            formError[path] = true;
          }
          this.setState({ entityFormError: formError });
        }
      } else {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Entitu sa nepodarilo uložiť'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
      }
      throw error;
    };

    return new Promise((resolve, reject) => {
      const items = getProp(this.state, 'productList', []);
      let promises = [];

      try {
        for (let i = 0; i < items.length ; i++) {
          const item = items[i];

          if (this.state.selectedProducts.indexOf(i) > -1) {
            const data = this.prepareEntity('product', Object.assign(item, this.state.entityData));
            const id = item.pro_id;
            if ('pro_id' in data) {
              delete data['pro_id'];
            }

            promises.push(Api.put(
              `/user/cash-registers/${this.getCashregisterId()}/setup/plu/${id}`,
              JSON.stringify(data),
            ).catch(errorCallback));
          }
        }
      } catch (err) {
        if ('propDescription' in err) {
          if (
            err.msg === 'NOT_A_NUMBER' &&
            err.entityName === 'product' &&
            err.prop === 'pro_unit_id'
          ) {
            this.props.dispatch(
              setAlert({
                type: 'warning',
                title: __('Chybne vyplnená položka'),
                text: __('Vyberte prosím mernú jednotku'),
                onConfirm: () => this.props.dispatch(setAlert(null)),
              }),
            );
          } else if (err.msg === 'NOT_A_NUMBER') {
            this.props.dispatch(
              setAlert({
                type: 'warning',
                title: __('Chybne vyplnená položka'),
                text: __('Pole %(field)s musí byť číslo').format({
                  field: err.propDescription,
                }),
                onConfirm: () => this.props.dispatch(setAlert(null)),
              }),
            );
          } else if (err.msg === 'NOT_A_STRING') {
            this.props.dispatch(
              setAlert({
                type: 'warning',
                title: __('Chybne vyplnená položka'),
                text: __('Pole %(field)s musí byť textová hodnota').format({
                  field: err.propDescription,
                }),
                onConfirm: () => this.props.dispatch(setAlert(null)),
              }),
            );
          }
        }
        reject(err);
      }

      Promise.all(promises).then(() => {
        resolve();
      }).catch((e) => {reject(e)});
    });
  }

  saveEntity(entityName) {
    const id = this.state.entityData[this.state.entityIdKey];

    const errorCallback = error => {
      if (entityName === 'product' && error.details.code === 409) {
        if (error.details.name === 'DUPLICATE_NAME') {
          const formError = this.state.entityFormError;
          formError['pro_name'] = true;
          this.setState({ entityFormError: formError });
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Produkt s týmto názvom už existuje'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        } else if (error.details.name === 'DUPLICATE_PRO_PLU_CODE') {
          const formError = this.state.entityFormError;
          formError['pro_plu_code'] = true;
          this.setState({ entityFormError: formError });
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Produkt s týmto PLUe  už existuje'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        }
      } else if (error.response.status === 400) {
        if (error.details.name === 'INVALID_SCHEMA') {
          const formError = this.state.entityFormError;
          const paths = getProp(error.details, 'payload.paths', []);
          for (const path of paths) {
            formError[path] = true;
          }
          this.setState({ entityFormError: formError });
        }
      } else {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Entitu sa nepodarilo uložiť'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
      }
      throw error;
    };

    try {
      const data = this.prepareEntity(entityName, this.state.entityData);
      if (id) {
        if (this.state.entityIdKey in data) {
          delete data[this.state.entityIdKey];
        }
        return Api.put(
          `/user/cash-registers/${this.getCashregisterId()}/setup/${this.getApiEntityName(
            entityName,
          )}/${id}`,
          JSON.stringify(data),
        ).catch(errorCallback);
      } else {
        return Api.post(
          `/user/cash-registers/${this.getCashregisterId()}/setup/${this.getApiEntityName(
            entityName,
          )}`,
          JSON.stringify(data),
        ).catch(errorCallback);
      }
    } catch (err) {
      if ('propDescription' in err) {
        if (
          err.msg === 'NOT_A_NUMBER' &&
          err.entityName === 'product' &&
          err.prop === 'pro_unit_id'
        ) {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Chybne vyplnená položka'),
              text: __('Vyberte prosím mernú jednotku'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        } else if (err.msg === 'NOT_A_NUMBER') {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Chybne vyplnená položka'),
              text: __('Pole %(field)s musí byť číslo').format({
                field: err.propDescription,
              }),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        } else if (err.msg === 'NOT_A_STRING') {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Chybne vyplnená položka'),
              text: __('Pole %(field)s musí byť textová hodnota').format({
                field: err.propDescription,
              }),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        }
      } else {
        throw err;
      }
    }

    return Promise.reject();
  };

  validateEntity = (entityName, callback) => {
      const id = this.state.entityData[this.state.entityIdKey];
      let error = false;

      if (entityName === 'desk') {
          const desks = getProp(this.state, 'deskList', []);
          const name = getProp(this.state, 'entityData.des_name', {});
          const room_id = getProp(this.state, 'entityData.roo_id', {});

          for (let key in desks) {
              if (!desks.hasOwnProperty(key)) continue;

              if (id !== getProp(desks[key], 'des_id') && parseInt(room_id) === parseInt(getProp(desks[key], 'roo_id')) && name === getProp(desks[key], 'des_name')) {
                  this.props.dispatch(
                      setAlert({
                          type: 'warning',
                          title: __('Chybne vyplnená položka'),
                          text: __('Stôl s týmto názvom už existuje'),
                          onConfirm: () => this.props.dispatch(setAlert(null))
                      }),
                  );

                  error = true;

                  break;
              }
          }
      }
      
      if (error) {
          this.setState({
              detailLoading: false
          });
      } else {
          callback();
      }
  };

  actionHandler(schemaName, action) {
    if (action.type === 'change') {
      const data = this.state.entityData;

      const formError = this.state.entityFormError;
      if (action.payload.property in formError) {
        delete formError[action.payload.property];
      }

      data[action.payload.property] = action.payload.value;
      this.setState({ entityData: data, entityFormError: formError });
    }
  }

  setTab(tab) {
    this.setState(
      { sort: null, query: '', activeTab: tab, activeTabNextOffset: 0 },
      () => {
        let limit = MAX_LIMIT;
        if (this.state.activeTab === 'product') {
          limit = this.state.activeTabLimit;
        }
        this.getMainList(this.state.activeTab, 0, limit);
      },
    );
  }

  loadMore() {
    let limit = MAX_LIMIT;
    if (this.state.activeTab === 'product') {
      limit = this.state.activeTabLimit;
    }
    this.getMainList(
      this.state.activeTab,
      this.state.activeTabNextOffset,
      limit,
    );
  }

  onClickTab(tab, e) {
    e.preventDefault();
    this.setTab(tab);
  }

  renderList(schemaName) {
    switch (schemaName) {
      case 'user':
        return this.renderTable(
          'user',
          getProp(this.state, 'userList', []),
          {
            use_name: __('Prihlasovacie meno'),
            use_first_name: __('Meno'),
            use_surname: __('Priezvisko'),
            use_mobil: __('Telefón'),
            use_email: __('E-mail'),
          },
          __('Žiadni používatelia'),
        );
      case 'category':
        return this.renderTable(
          'category',
          getProp(this.state, 'categoryList', []),
          {
            cat_name: __('Názov kategórie'),
            cat_parent_id: {
              label: __('Nadradená'),
              value: value => {
                for (let cat of this.state.categoryList) {
                  if (cat.cat_id === value) {
                    return cat.cat_name;
                  }
                }
                return '';
              },
            },
            cat_activated: {
              label: __('Aktívna'),
              value: value => {
                if (value === 1) {
                  return __('Áno');
                } else if (value === 0) {
                  return __('Nie');
                }
                return '';
              },
            },
          },
          __('Žiadne kategórie'),
        );
      case 'desk':
        return this.renderTable(
          'desk',
          getProp(this.state, 'deskList', []),
          {
            des_name: __('Názov'),
            des_x: __('X'),
            des_y: __('Y'),
            roo_id: {
              label: __('Miestnosť'),
              value: value => {
                for (let room of this.state.roomList) {
                  if (room.roo_id === value) {
                    return room.roo_name;
                  }
                }
                return '';
              },
            },
            des_type: {
              label: __('Typ'),
              value: value => {
                if (value === 1) {
                  return __('Normálny');
                } else if (value === 2) {
                  return __('Stena');
                }
                return '';
              },
            },
          },
          __('Žiadne stoly'),
        );
      case 'unit':
        return this.renderTable(
          'unit',
          getProp(this.state, 'unitList', []),
          { uni_name: __('Názov'), uni_shortcut: __('Skratka') },
          __('Žiadne merné jednotky'),
        );
      case 'vat':
        return this.renderTable(
          'vat',
          getProp(this.state, 'vatList', []),
          {
            vat_name: __('Názov'),
            vat_value: {
              label: __('Hodnota'),
              value: value => {
                return `${formatNumber(value / 100)}%`;
              },
            },
          },
          __('Žiadne daňové hladiny'),
        );
      case 'room':
        return this.renderTable(
          'room',
          getProp(this.state, 'roomList', []),
          { roo_name: __('Názov') },
          __('Žiadne miestnosti'),
        );
      case 'payment':
        return this.renderTable(
          'payment',
          getProp(this.state, 'paymentList', []),
          { pay_name: __('Názov') },
          __('Žiadne spôsoby platby'),
        );
      case 'product':
        return this.renderTable(
          'product',
          getProp(this.state, 'productList', []),
          {
            pro_plu_code: __('PLU'),
            pro_name: __('Názov'),
            cat_id: {
              label: __('Kategória'),
              value: value => {
                for (let cat of this.state.categoryList) {
                  if (cat.cat_id === value) {
                    return cat.cat_name;
                  }
                }
                return '';
              },
            },
          },
          __('Žiadne produkty'),
          [0, 1, 2],
          sort => {
            this.setState({ sort }, () => {
              this.getMainList(this.state.activeTab, 0, MAX_LIMIT);
            });
          },
        );
      default:
        return null;
    }
  }

  getSorterName(schemaName, idx) {
    const sort = `${idx}`;
    if (schemaName === 'product') {
      const sortMapping = {
        '0': 'plu',
        '-0': '-plu',
        '1': 'name',
        '-1': '-name',
        '2': 'category',
        '-2': '-category',
      };
      return sort in sortMapping ? sortMapping[sort] : null;
    }
    return null;
  }

  onSelectAllProducts(selected) {
    let selectedProducts = [];

    if (selected) {
      const products = getProp(this.state, 'productList', []);
      selectedProducts = products.map((item, index) => index);
    } else {
      selectedProducts = [];
    }

    this.setState({selectedProducts: selectedProducts});
  }

  onSelectProduct(idx) {
    const selectedProducts = [...this.state.selectedProducts];
    const index = selectedProducts.indexOf(idx);

    if (index !== -1) {
      selectedProducts.splice(index, 1);
    } else {
      selectedProducts.push(idx);
    }

    this.setState({selectedProducts: selectedProducts});
  }

  renderTable(
    schemaName,
    items = [],
    headerMapping = {},
    emptyMessage = null,
    sortableColsIdx = [],
    onSort = null,
  ) {
    let selectableItems = false;
    let selectedItems = [];

    if (schemaName === 'product') {
      selectableItems = true;
      selectedItems = this.state.selectedProducts;
    }

    return (
      <div>
        <DataTable
          selectableItems={selectableItems}
          selectedItems={selectedItems}
          onSelectItem={this.onSelectProduct.bind(this)}
          onSelectAllItems={this.onSelectAllProducts.bind(this)}
          cols={[
            ...Object.keys(headerMapping).map(prop => {
              if (typeof headerMapping[prop] === 'object') {
                return headerMapping[prop].label;
              }
              return headerMapping[prop];
            }),
            '',
          ]}
          sortableColsIdx={sortableColsIdx}
          currentSort={this.state.sort}
          onSort={sort => {
            if (typeof onSort === 'function') {
              onSort(sort);
            }
          }}
          rows={items.map(item => {
            let row = Object.keys(headerMapping).map(prop => {
              return prop in item
                ? typeof headerMapping[prop].value === 'function'
                  ? headerMapping[prop].value(item[prop])
                  : item[prop]
                : '-';
            });

            let editButtonVisible = true;
            let deleteButtonVisible = true;

            // pre VAT bude edit a delete vzdy zakazany
            if (this.state.activeTab === 'vat') {
              editButtonVisible = false;
              deleteButtonVisible = false;
            }

            if (['unit', 'payment'].includes(this.state.activeTab) && item.ctrl_editable !== 1) {
              editButtonVisible = false;
            }

            if (['unit', 'payment'].includes(this.state.activeTab) && item.ctrl_deletable !== 1) {
              deleteButtonVisible = false;
            }

            row.push(
              <div className="text-right">
                {editButtonVisible ? (
                <span
                  className="btn btn-primary-o btn-circle"
                  onClick={this.onClickEdit.bind(this, Object.assign({}, item))}
                >
                  <Icon name="edit" />
                </span>
                ) : null}
                &nbsp;
                {deleteButtonVisible ? (
                  <span
                    className="btn btn-danger-o btn-circle"
                    onClick={this.onClickDelete.bind(
                      this,
                      this.state.activeTab,
                      Object.assign({}, item),
                    )}
                  >
                    <Icon name="remove" />
                  </span>
                ) : null}
              </div>,
            );

            return row;
          })}
        />
        <h4 className={cx('text-center', { hidden: items.length > 0 })}>
          {emptyMessage}
        </h4>
        <LoadMoreBtn
          nextOffset={this.state.activeTabNextOffset}
          onClick={this.loadMore.bind(this)}
        />
      </div>
    );
  }

  getSchema(schemaName) {
    if (schemaName in this.schemas) {
      return this.schemas[schemaName];
    }
    throw Error(`Schema for '${schemaName}' not found!`);
  }

  onClickAdd() {
    this.setState({ detailLoading: true, showSidePanel: true });
    this.getEmptyEntity(this.state.activeTab).then(emptyEntity => {
      this.setState({
        detailLoading: false,
        entityData: emptyEntity,
        entityFormError: {},
          editing: false
      });
    });
  }

  onClickEdit(entity) {
    this.setState({ detailLoading: true, showSidePanel: true });
    this.getEmptyEntity(this.state.activeTab).then(emptyEntity => {
      let entityObj = Object.assign(emptyEntity, entity);
      if (this.state.activeTab === 'product') {
        entityObj['pro_quantity'] = entity.pro_quantity / 1000;
        entityObj['pro_sum'] = entity.pro_sum / 100;
        entityObj['pro_sum_vat'] = entity.pro_sum_vat / 100;
      } else if (this.state.activeTab === 'vat') {
        entityObj['vat_value'] = entity.vat_value / 100;
      }
      this.setState({
        detailLoading: false,
        entityData: entityObj,
        entityFormError: {},
          editing: true
      });
    });
  }

  getEmptyEntity(schemaName) {
    return new Promise((resolve, reject) => {
      let entityFormData = {};
      let promises = [];
      const schema = this.getSchema(schemaName);

      if (schemaName === 'product' || schemaName === 'product-bulk') {
        schema.payload = {
          categoryList: [],
          unitList: [],
          cashRegisterId: this.props.params.id,
        };
        promises.push(
          this.getList('category').then(() => {
            schema.payload.categoryList = this.state.categoryList;
          }),
        );
        promises.push(
          this.getList('unit').then(() => {
            schema.payload.unitList = this.state.unitList;
          }),
        );
        promises.push(
          this.getList('vat').then(() => {
            schema.payload.vatList = this.state.vatList;
            entityFormData['pro_vat'] = schema.payload.vatList[0].vat_id;
          }),
        );
      }

      for (const prop in schema.properties) {
        if (schema.ignored.includes(prop)) {
          continue;
        }
        if (schemaName === 'desk' && prop === 'roo_id') {
          promises.push(
            this.getList('room').then(() => {
              schema.payload = this.state.roomList;
            }),
          );
        } else if (schemaName === 'category' && prop === 'cat_parent_id') {
          schema.payload = this.state.categoryList;
        }
        if (schema.properties[prop].enum) {
          entityFormData[prop] = schema.properties[prop].enum[0];
        } else {
          entityFormData[prop] = '';
        }
      }

      if (schemaName === 'user') {
        entityFormData['right_name'] = [];
      }

      Promise.all(promises).then(() => {
        resolve(entityFormData);
      });
    });
  }

  onClickDelete(entityName, entity) {
    // vymazat entitu
    this.props.dispatch(
      setAlert({
        type: 'warning',
        title: __('Ste si istý?'),
        confirmButtonColor: '#DD6B55',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: __('Áno, zmazať'),
        cancelButtonText: __('Nie, zrušiť'),
        buttonsStyling: false,
        onConfirm: () => {
          this.deleteEntity(entityName, entity).then(() => {
            this.props.dispatch(setAlert(null));
            this.refreshList();
          });
        },
        onCancel: () => this.props.dispatch(setAlert(null)),
      }),
    );
  }

  deleteEntity(entityName, entity) {
    const id = entity[this.state.entityIdKey];
    if (id) {
      return Api.delete(
        `/user/cash-registers/${this.getCashregisterId()}/setup/${this.getApiEntityName(
          entityName,
        )}/${id}`,
      ).catch(() => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Entitu sa nepodarilo odstrániť'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
      });
    } else {
      throw new Error(`Entity has no id`);
    }
  }

  onClickSync(entityName) {
    return this.sync(entityName).then(() => {
      this.getSyncStatus();
      this.refreshList();
    });
  }

  sync(entityName) {
    return Api.post(
      `/user/cash-registers/${this.getCashregisterId()}/setup/status/${entityName}/commit`,
    );
  }

  onClickSyncAll(entityName) {
    return this.sync(entityName).then(() => {
      this.getSyncStatus();
      this.refreshList();
    });
  }

  syncAll() {
    Promise.all(
      Object.keys(this.state.statuses).map(type => {
        return this.sync(type);
      }),
    );
  }

  getSyncStatus() {
    return Api.get(
      `/user/cash-registers/${this.getCashregisterId()}/setup/status`,
    ).then(response => {
      this.setState({ statuses: response.status });
    });
  }

  generateForm(entityData) {
    const type = this.state.bulkEditOpened ? 'product-bulk' : this.state.activeTab;

    const schema = this.getSchema(type);
    let form = null;
    if (this.state.activeTab === 'user') {
      form = UserFormGenerator.factory(
        this.actionHandler.bind(this, this.state.activeTab),
        schema,
      );
    } else if (this.state.activeTab === 'desk') {
      form = DeskFormGenerator.factory(
        this.actionHandler.bind(this, this.state.activeTab),
        schema,
      );
    } else if (this.state.activeTab === 'unit') {
      form = UnitFormGenerator.factory(
        this.actionHandler.bind(this, this.state.activeTab),
        schema,
      );
    } else if (this.state.activeTab === 'category') {
      form = CategoryFormGenerator.factory(
        this.actionHandler.bind(this, this.state.activeTab),
        schema,
      );
    } else if (this.state.activeTab === 'product') {
      form = ProductFormGenerator.factory(
        this.actionHandler.bind(this, this.state.activeTab),
        schema,
      );
    } else {
      form = new FormGenerator(
        this.actionHandler.bind(this, this.state.activeTab),
        schema,
      );
    }
    form.currentLoggedUser = this.props.user;
    return form.generate(entityData, this.state.entityFormError, getProp(this.state, 'editing', false));
  }

  isDirty(entityName) {
    return getProp(this.state.statuses, `${entityName}.status`) === 'DIRTY';
  }

  isSomethingDirty() {
    for (let type in this.state.statuses) {
      if (this.state.statuses[type].status === 'DIRTY') {
        return true;
      }
    }
    return false;
  }

  onClickBack() {
    if (this.isFromRemoteAccess()) {
      this.context.router.push(`/app/remote-access/`);
    } else {
      this.context.router.push(
        `/app/cash-register/${this.getCashregisterId()}/view`,
      );
    }
  }

  getApiEntityName(entityName) {
    if (entityName === 'product') {
      return 'plu';
    }
    return entityName;
  }

  onChangeCloneCashregister(ev) {
    this.setState({ cloneCashregisterId: ev.target.value });
  }

  onSubmitClone(sourceCashregisterId, targetCashregisterId, ev) {
    ev.preventDefault();

    if (!targetCashregisterId) {
      this.props.dispatch(
        setAlert({
          type: 'warning',
          title: __('Vyberte pokladňu z ktorej chcete kopírovať'),
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
      return;
    }

    this.props.dispatch(
      setAlert({
        type: 'warning',
        title: __('Ste si istý?'),
        confirmButtonColor: '#DD6B55',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: __('Áno, zmazať'),
        cancelButtonText: __('Nie, zrušiť'),
        buttonsStyling: false,
        text: __(
          'Všetky dáta v tejto pokladni budú vymazané a nahradené dátami vybratej pokladne',
        ),
        onConfirm: () => {
          this.setState({
            cashregistersLoading: true,
          });
          Api.post(
            `/user/cash-registers/${targetCashregisterId}/setup/copy/${sourceCashregisterId}`,
          )
            .then(() => {
              window.location.reload();
            })
            .catch(() => {
              this.setState({
                cashregistersLoading: false,
              });
              this.props.dispatch(
                setAlert({
                  type: 'error',
                  title: __('Pokladnicu nebolo možné skopírovať'),
                  onConfirm: () => this.props.dispatch(setAlert(null)),
                }),
              );
            });
        },
        onCancel: () => this.props.dispatch(setAlert(null)),
      }),
    );
  }

  onClickClone() {
    this.setState({ cashregistersLoading: true, showCloneSidePanel: true });
    Api.get('/user/cash-registers')
      .then(response => {
        this.setState({
          cashregistersLoading: false,
          cashregisterList: response.cashregisters,
        });
      })
      .catch(response => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Nebolo možné načítať zoznam pokladní'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
        this.setState({ cashregistersLoading: false });
      });
  }

  onClickExport = async (segment = null) => {
    let url = `/user/cash-registers/${this.getCashregisterId()}/setup/export`;
    let response = await Api.get(segment ? `${url}/${segment}` : url);
    Api.redirect(response.url);
  };

  onClickImport() {
    var x = document.createElement('INPUT');
    x.setAttribute('type', 'file');
    x.click();
    x.addEventListener('change', event => {
      this.setState({ listLoading: true });
      const formData = new FormData();
      formData.append('import', event.target.files[0]);
      Api.post(
        `/user/cash-registers/${this.getCashregisterId()}/setup/import`,
        formData,
        null,
      )
        .then(response => {
          this.getSyncStatus().then(() => {
            this.setState({ listLoading: false });
          });
        })
        .catch(error => {
          this.props.dispatch(
            setAlert({
              type: 'error',
              title: __(
                'Nebolo možné spracovať import. Skontrolujte formát súboru.',
              ),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
          this.setState({ listLoading: false });
        });
    });
  }

  isFromRemoteAccess() {
    return this.props.location.pathname.search(/app\/remote-access/) >= 0;
  }

  onClickBulkEditProducts() {
    this.setState({ detailLoading: true, showSidePanel: true, bulkEditOpened: true });
    this.getEmptyEntity('product-bulk').then(emptyEntity => {
      let entityObj = Object.assign(emptyEntity, {});
      // if (this.state.activeTab === 'product') {
      //   entityObj['pro_quantity'] = entity.pro_quantity / 1000;
      //   entityObj['pro_sum'] = entity.pro_sum / 100;
      //   entityObj['pro_sum_vat'] = entity.pro_sum_vat / 100;
      // }
      this.setState({
        detailLoading: false,
        entityData: entityObj,
        entityFormError: {},
      });
    });
  }

  render() {
    return (
      <ProtectedContent resource="cash_register" action="setup">
        <div id="content-wrapper">
          <MainHeader
            title={__('Vzdialená správa pokladne %(name)s').format({
              name: this.getCashregisterId(),
            })}
            progress={100}
            onClickBack={
              this.getCashregisterId() ? this.onClickBack.bind(this) : null
            }
          >
            <span onClick={this.onClickClone.bind(this)} className="clickable">
              {__('Kopírovať z inej pokladnice')}
            </span>
            &nbsp;{' | '}&nbsp;
            <span
              className="clickable"
              onClick={this.onClickExport.bind(this, null)}
            >
              {__('Stiahnuť konfiguráciu')}
            </span>
            &nbsp;{' | '}&nbsp;
            <span className="clickable" onClick={this.onClickImport.bind(this)}>
              {__('Nahrať konfiguráciu')}
            </span>
          </MainHeader>
          <Content>
            {!this.isSomethingDirty() &&
            Object.keys(this.state.statuses).length > 0 ? (
              <div>
                <div className="alert alert-success">
                  <Icon className="icon-main" name="checkmark" />
                  {__('Všetky údaje sú synchronizované s pokladňou.')}
                </div>
              </div>
            ) : null}

            <ul className="nav nav-pills nav-justified">
              {Object.keys(this.state.statuses)
                .filter(type => type in RemoteAccessEntities)
                .map(type => {
                  return (
                    <li
                      key={type}
                      className={cx({ active: this.state.activeTab === type })}
                    >
                      <a
                        href="/"
                        onClick={this.onClickTab.bind(this, type)}
                        className="clickable"
                      >
                        {this.isDirty(type) ? (
                          <span>
                            <Icon name="attention" />
                            &nbsp;
                          </span>
                        ) : null}
                        {RemoteAccessEntities[type].label}
                      </a>
                    </li>
                  );
                })}
            </ul>

            <div className="tab-content">
              <div role="tabpanel" className="tab-pane active">
                {this.state.activeTab === 'product' ? (
                  <DataFilterInput
                    onChangeQuery={ev => {
                      const query = ev.target.value;
                      this.setState({ query }, () => {
                        this.getMainList(this.state.activeTab, 0, MAX_LIMIT);
                      });
                    }}
                    query={this.state.query}
                  />
                ) : null}
                {this.state.listLoading > 0 ? (
                  <div style={{ marginTop: '150px' }}>
                    <Loader show={true} />
                  </div>
                ) : (
                  <A>{() => this.renderList(this.state.activeTab)}</A>
                )}
              </div>
            </div>

            <SidePanel
              onClose={() => {
                this.setState({ showSidePanel: false, bulkEditOpened: false });
              }}
              show={this.state.showSidePanel}
            >
              {this.state.schemaLoading ||
              this.state.detailLoading ||
              this.state.listLoading > 0 ? (
                <Loader show={true} />
              ) : this.state.showSidePanel ? (
                <div>
                  <div>
                    <h2 className="side-panel-title">
                      {this.state.entityData[this.state.entityIdKey]
                        ? __('Upraviť')
                        : (this.state.bulkEditOpened ? __('Hromadná úprava') : __('Pridať'))}
                    </h2>
                    <hr />
                  </div>
                  {this.generateForm(this.state.entityData)}
                  <div className="text-center">
                    <button
                      type="submit"
                      onClick={() => {
                        this.setState({ detailLoading: true });

                        if (this.state.bulkEditOpened) {
                          this.saveBulkProducts()
                            .then(() => {
                              this.setState({
                                showSidePanel: false,
                                entityData: {},
                                detailLoading: false,
                                bulkEditOpened: false
                              });
                              this.refreshList();
                            })
                            .catch(() => {
                              this.setState({ detailLoading: false });
                            });
                        } else {
                          this.validateEntity(this.state.activeTab, () => {
                            this.saveEntity(this.state.activeTab)
                              .then(() => {
                                this.setState({
                                  showSidePanel: false,
                                  entityData: {},
                                  detailLoading: false,
                                  bulkEditOpened: false
                                });
                                this.refreshList();
                              })
                              .catch(() => {
                                this.setState({ detailLoading: false });
                              });
                          })
                        }
                      }}
                      className="btn btn-primary"
                    >
                      {__('Uložiť')}
                    </button>
                  </div>
                </div>
              ) : null}
            </SidePanel>

            <SidePanel
              onClose={() => {
                this.setState({ showCloneSidePanel: false });
              }}
              show={this.state.showCloneSidePanel}
            >
              {this.state.cashregistersLoading ? (
                <Loader show={true} />
              ) : this.state.showCloneSidePanel ? (
                <div>
                  <div>
                    <h2 className="side-panel-title">
                      {__('Kópia dát z inej pokladnice')}
                    </h2>
                    <hr />
                  </div>
                  <form
                    className="form-horizontal"
                    onSubmit={this.onSubmitClone.bind(
                      this,
                      this.state.cloneCashregisterId,
                      this.getCashregisterId(),
                    )}
                  >
                    <div className="form-group">
                      <label htmlFor="range" className="col-xs-4 control-label">
                        {__('Zdrojová pokladnica')}:
                      </label>
                      <div className="col-sm-4 col-xs-8">
                        <select
                          id="range"
                          className="form-control"
                          value={this.state.cloneCashregisterId}
                          onChange={this.onChangeCloneCashregister.bind(this)}
                        >
                          <option value="">{__('Vyberte pokladnicu')}</option>
                          {this.state.cashregisterList.map(item => {
                            return (
                              <option key={item._id} value={item._id}>
                                {getProp(item, 'name', '-')}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="text-center">
                      <button type="submit" className="btn btn-primary">
                        {__('Kopírovať')}
                      </button>
                    </div>
                  </form>
                </div>
              ) : null}
            </SidePanel>

            <div className="fbtns">
              {this.state.activeTab !== 'vat' ? (
                <FloatingActionButton
                  onClick={this.onClickAdd.bind(this)}
                  title={__('Pridať')}
                  noWrapper={true}
                />
              ) : null}
              {this.isDirty(this.state.activeTab) ? (
                <FloatingActionButton
                  onClick={this.onClickSync.bind(this, this.state.activeTab)}
                  icon={'synchron'}
                  title={__('Vytvoriť aktualizáciu')}
                  noWrapper={true}
                />
              ) : null}
              <FloatingActionButton
                onClick={this.onClickExport.bind(this, this.state.activeTab)}
                icon={'download'}
                title={__('Stiahnuť konfiguráciu')}
                noWrapper={true}
              />
              {this.state.activeTab === 'product' && this.state.selectedProducts.length > 0 ? (
                <FloatingActionButton
                  icon={'edit'}
                  onClick={this.onClickBulkEditProducts.bind(this)}
                  title={__('Hromadne upraviť')}
                  noWrapper={true}
                />
              ) : null}
            </div>
          </Content>
        </div>
      </ProtectedContent>
    );
  }
}

RemoteAccess.contextTypes = {
  router: PropTypes.object,
};

class A extends React.Component {
  render() {
    return this.props.children();
  }
}

export default connect(state => ({
  user: state.user,
}))(RemoteAccess);

export const RemoteAccessEntities = {
  user: { label: __('Používatelia') },
  category: { label: __('Kategórie') },
  desk: { label: __('Stoly') },
  unit: { label: __('Merné jednotky') },
  vat: { label: __('Daňové hladiny') },
  room: { label: __('Miestnosti') },
  payment: { label: __('Spôsoby platby') },
  product: { label: __('Produkty') },
};
