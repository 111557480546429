import React from 'react';
import { __ } from '../utilities/common';
import Icon from './../component/icon';

class DataFilterInput extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-offset-8 col-md-4">
          <div style={{ marginBottom: '12px' }}>
            <div className="input-group">
              <input
                className="form-control"
                placeholder={this.props.placeholder}
                onChange={this.props.onChangeQuery}
                value={this.props.query}
              />
              <span className="input-group-addon">
                <Icon name="search" />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DataFilterInput.defaultProps = {
  placeholder: __('Začnite písať ...'),
};

export default DataFilterInput;
