import React from 'react';
import * as Cookies from 'js-cookie';
import {
  IndexRoute,
  browserHistory,
  Redirect,
  Route,
  Router,
} from 'react-router';
import App from './module/app';
import AppRoot from './module/appRoot';
import Login from './module/login';
import PasswordRecovery from './module/passwordRecovery';
import PaymentGpwebpay from './module/payment-gpwebpay';
import Registration from './module/registration';
import CashRegisterForm from './module/cash-register/form';
import CashRegisterBackups from './module/cash-register/backups';
import CashRegisterDashboard from './module/cash-register/dashboard';
import CashRegisterList from './module/cash-register/list';
import CashRegisterRemoteAccess from './module/cash-register/remoteAccess';
import CashRegisterRemoteAccessContainer from './module/cash-register/remoteAccessContainer';
import CashRegisterRemoteAccessList from './module/cash-register/remoteAccessList';
import CashRegisterTransLog from './module/cash-register/translog';
import CashRegisterTransLogFilter from './module/cash-register/translog/filter';
import CashRegisterTransLogForm from './module/cash-register/translog/form';
import CashRegisterUpdates from './module/cash-register/updates';
import CashRegisterView from './module/cash-register/view';
import Framework from './module/framework';
import License from './module/license';
import LicenseTable from './module/licenseTable';
import NoMatch from './module/nomatch';
import Profile from './module/profile';
import ProfileCompanyForm from './module/profile/companyForm';
import ProfileForm from './module/profile/form';
import ProfilePasswdForm from './module/profile/passwordChangeForm';
import Stats from './module/stats';
import UserForm from './module/user/form';
import VenueForm from './module/venue/form';
import VenueList from './module/venue/list';
import PropTypes from 'prop-types';
import { __ } from './utilities/common';
import Content from './component/content';
import Icon from './component/icon';
import MainHeader from './component/mainHeader';
import AppExternal from './external/app';
import CashRegisterListExternal from './external/cash-register-list';
import OtaUpdateList from './module/ota-update/list';
import OtaUpdateFilter from './module/ota-update/filter';
import OtaUpdateDetail from './module/ota-update/detail';
import OtaUpdateFormEdit from './module/ota-update/formEdit';
import OtaUpdateFormAdd from './module/ota-update/formAdd';
import Partners from './module/partners/list';
import PartnersFormAdd from './module/partners/formAdd';
import PartnersFormEdit from './module/partners/formEdit';
import PartnerDetail from './module/partners/detail';
import PartnerCashRegisters from './module/partners/cashRegisters';
import PartnerMembers from './module/partners/members';
import CashRegistersPartner from './module/partner-cash-register/list';

import EcrList from './module/ecr/list';
import EcrFilter from './module/ecr/filter';
import EcrGroupList from './module/ecr-group/list';
import EcrGroupForm from './module/ecr-group/form';

const CashRegisterFormExternal = (props, context) => {
  const back = () => {
    context.router.push('/external/cash-register/list');
  };

  return (
    <div id="content-wrapper">
      <MainHeader title={__('Nová pokladňa')} progress={100} onClickBack={back}>
        <span
          onClick={AppExternal.logout.bind(null, context)}
          className="clickable"
        >
          <Icon name="exit" />
        </span>
      </MainHeader>
      <Content>
        <CashRegisterForm renderForExternal={true} {...props} />
      </Content>
    </div>
  );
};

CashRegisterFormExternal.contextTypes = {
  router: PropTypes.object,
};

export const requireAuth = (nextState, replace) => {
  const token = Cookies.get('token');
  if (!token) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname },
    });
  }
};

export const requireExternalAuth = (nextState, replace) => {
  const token = Cookies.get('token');
  if (!token) {
    replace({
      pathname: '/external/login',
      state: { nextPathname: nextState.location.pathname },
    });
  }
};

const Routes = () => {
  return (
    <Router history={browserHistory}>
      <Redirect from="/" to="/app" />
      <Redirect from="/app" to="/app/ota-updates" />
      <Redirect from="/external" to="/external/cash-register/list" />
      <Route path="/" component={AppRoot}>
        <Route path="/login" component={Login} />
        <Route path="/registration" component={Registration} />
        <Route path="/payment/gpwebpay" component={PaymentGpwebpay} />
        <Route path="/password-recovery" component={PasswordRecovery} />
        <Route path="/password-recovery/:token" component={PasswordRecovery} />
        <Route path="/app" component={App} onEnter={requireAuth}>
          <Route path="/app/profile" component={Profile}>
            <Route path="/app/user/add/:role" component={UserForm} />
            <Route path="/app/user/edit/:id" component={UserForm} />
            <Route path="/app/profile/edit" component={ProfileForm} />
            <Route path="/app/profile/passwd" component={ProfilePasswdForm} />
            <Route
              path="/app/profile/edit-company"
              component={ProfileCompanyForm}
            />
          </Route>
          <Route path="/app/dashboard" component={CashRegisterDashboard}>
            <Route
              path="/app/dashboard/cash-register/:id/translog/add"
              component={CashRegisterTransLogForm}
            />
          </Route>
          <Route
            path="/app/remote-access"
            component={CashRegisterRemoteAccessContainer}
          >
            <IndexRoute component={CashRegisterRemoteAccessList} />
            <Route path=":id" component={CashRegisterRemoteAccess} />
          </Route>
          <Route path="/app/cash-register" component={CashRegisterList}>
            <Route path=":id/view" component={CashRegisterView} />
            <Route
              path=":id/remote-access"
              component={CashRegisterRemoteAccess}
            />
            <Route path=":id/translog" component={CashRegisterTransLog}>
              <Route path="filter" component={CashRegisterTransLogFilter} />
              <Route path="add" component={CashRegisterTransLogForm} />
            </Route>
            <Route path=":id/backups" component={CashRegisterBackups} />
            <Route path=":id/updates" component={CashRegisterUpdates} />
            <Route
              path="add"
              components={{
                main: CashRegisterList,
                sidepanel: CashRegisterForm,
              }}
            />
            <Route
              path=":id"
              components={{
                main: CashRegisterList,
                sidepanel: CashRegisterForm,
              }}
            />
          </Route>
          <Route path="/app/venue" component={VenueList}>
            <Route path="/app/venue/add" component={VenueForm} />
            <Route path="/app/venue/:id" component={VenueForm} />
          </Route>
          <Route path="/app/licenses" component={LicenseTable} />
          <Route path="/app/license" component={License} />
          <Route path="/app/fw" component={Framework} />
          <Route path="/app/stats" component={Stats} />
          <Route path="/app/file-manager" component={CashRegisterBackups} />
          <Route path="/app/partners" component={Partners}>
            <Route
              path="add"
              components={{
                sidepanel: PartnersFormAdd,
              }}
            />
            <Route
              path=":id/edit"
              components={{
                sidepanel: PartnersFormEdit,
              }}
            />
          </Route>
          <Route
            path="/app/partner-cash-register"
            component={CashRegistersPartner}
          />
          <Route path="/app/partners/:id" component={PartnerDetail}>
            <IndexRoute component={PartnerCashRegisters} />
            <Route path="members" component={PartnerMembers} />
          </Route>
          <Route path="/app/ota-updates" component={OtaUpdateList}>
            <Route
              path="filter"
              components={{
                sidepanel: OtaUpdateFilter,
              }}
            />
            <Route
              path="add"
              components={{
                sidepanel: OtaUpdateFormAdd,
              }}
            />
            <Route
              path=":id/edit"
              components={{
                sidepanel: OtaUpdateFormEdit,
              }}
            />
          </Route>
          <Route path="/app/ota-updates/:id/detail" component={OtaUpdateDetail}>
            <Route
              path="edit"
              components={{
                sidepanel: OtaUpdateFormEdit,
              }}
            />
          </Route>
          <Route path="/app/ecrs" component={EcrList}>
            <Route
              path="filter"
              components={{
                sidepanel: EcrFilter,
              }}
            />
          </Route>
          <Route path="/app/ecr-groups" component={EcrGroupList}>
            <Route
              path="add"
              components={{
                sidepanel: EcrGroupForm,
              }}
            />
            <Route
              path=":id/edit"
              components={{
                sidepanel: EcrGroupForm,
              }}
            />
          </Route>
          <Route path="*" component={NoMatch} status={404} />
        </Route>
        <Route path="/external/login" component={Login} />
        <Route
          path="/external"
          component={AppExternal}
          onEnter={requireExternalAuth}
        >
          <Route
            path="/external/cash-register/add"
            component={CashRegisterFormExternal}
          />
          <Route
            path="/external/cash-register/list"
            component={CashRegisterListExternal}
          />
          <Route path="*" component={NoMatch} status={404} />
        </Route>
      </Route>
    </Router>
  );
};

export default Routes;
