import moment from 'moment';
import { getProp, __ } from '../utilities/common';
import { getDateRange } from './common';

const getChartProductSubtypes = () => {
  return {
    finalprice: {
      label: __('Celková tržba'),
      subtypes: {
        pie: __('Koláčový'),
        bar: __('Stĺpcový'),
      },
    },
    finalprice_vat: {
      label: __('Celková tržba s DPH'),
      subtypes: {
        pie: __('Koláčový'),
        bar: __('Stĺpcový'),
      },
    },
    nrofrecords: {
      label: __('Počet záznamov'),
      subtypes: {
        pie: __('Koláčový'),
        bar: __('Stĺpcový'),
      },
    },
    avgdailynrofrecords: {
      label: __('Priemerný denný počet záznamov'),
      subtypes: {
        pie: __('Koláčový'),
        bar: __('Stĺpcový'),
      },
    },
    avgdailyfinalprice: {
      label: __('Priemerná denná celková tržba'),
      subtypes: {
        pie: __('Koláčový'),
        bar: __('Stĺpcový'),
      },
    },
    avgdailyfinalprice_vat: {
      label: __('Priemerná denná celková tržba s DPH'),
      subtypes: {
        pie: __('Koláčový'),
        bar: __('Stĺpcový'),
      },
    },
  };
};

export const getChartTypes = () => {
  return {
    histogram: {
      label: __('Trend'),
      subtypes: {
        min15: {
          label: __('15 min'),
          subtypes: {
            finalprice: {
              label: __('Celková tržba'),
            },
            finalprice_vat: {
              label: __('Celková tržba s DPH'),
            },
            nrofrecords: {
              label: __('Počet záznamov'),
            },
          },
        },
        hourly: {
          label: __('Hodinový'),
          subtypes: {
            finalprice: {
              label: __('Celková tržba'),
            },
            finalprice_vat: {
              label: __('Celková tržba s DPH'),
            },
            nrofrecords: {
              label: __('Počet záznamov'),
            },
          },
        },
        daily: {
          label: __('Denný'),
          subtypes: {
            finalprice: {
              label: __('Celková tržba'),
            },
            finalprice_vat: {
              label: __('Celková tržba s DPH'),
            },
            nrofrecords: {
              label: __('Počet záznamov'),
            },
          },
        },
        weekly: {
          label: __('Týždenný'),
          subtypes: {
            finalprice: {
              label: __('Celková tržba'),
            },
            finalprice_vat: {
              label: __('Celková tržba s DPH'),
            },
            nrofrecords: {
              label: __('Počet záznamov'),
            },
            avgdailynrofrecords: {
              label: __('Priemerný denný počet záznamov'),
            },
          },
        },
        monthly: {
          label: __('Mesačný'),
          subtypes: {
            finalprice: {
              label: __('Celková tržba'),
            },
            finalprice_vat: {
              label: __('Celková tržba s DPH'),
            },
            nrofrecords: {
              label: __('Počet záznamov'),
            },
            avgdailynrofrecords: {
              label: __('Priemerný denný počet záznamov'),
            },
          },
        },
        quarterly: {
          label: __('Štvrťročný'),
          subtypes: {
            finalprice: {
              label: __('Celková tržba'),
            },
            finalprice_vat: {
              label: __('Celková tržba s DPH'),
            },
            nrofrecords: {
              label: __('Počet záznamov'),
            },
            avgdailynrofrecords: {
              label: __('Priemerný denný počet záznamov'),
            },
          },
        },
      },
    },
    total: {
      label: __('Informačná hodnota'),
      subtypes: {
        finalprice: {
          label: __('Celková tržba'),
        },
        finalprice_vat: {
          label: __('Celková tržba s DPH'),
        },
        nrofrecords: {
          label: __('Počet záznamov'),
        },
        nrofitems: {
          label: __('Počet položiek'),
        },
        avgdailynrofrecords: {
          label: __('Priemerný denný počet záznamov'),
        },
        avgdailyfinalprice: {
          label: __('Priemerná denná celková tržba'),
        },
        avgdailyfinalprice_vat: {
          label: __('Priemerná denná celková tržba s DPH'),
        },
      },
    },
    summary: {
      label: __('Sumárny'),
      subtypes: {
        products: {
          label: __('Produkt'),
          subtypes: getChartProductSubtypes(),
        },
        venues: {
          label: __('Prevádzka'),
          subtypes: getChartProductSubtypes(),
        },
        'cash-registers': {
          label: __('Pokladňa'),
          subtypes: getChartProductSubtypes(),
        },
        operators: {
          label: __('Operátor'),
          subtypes: getChartProductSubtypes(),
        },
      },
    },
  };
};

export const getChartName = (type1, type2 = null, type3 = null) => {
  const chartTypes = getChartTypes();
  let name = '';
  if (type1) {
    name = chartTypes[type1].label;
  }
  if (type2) {
    name = chartTypes[type1].subtypes[type2].label;
  }
  if (type3) {
    name = chartTypes[type1].subtypes[type2].subtypes[type3].label;
  }
  return name;
};

/**
 * Method used to build Api request query for concrete chart type. Unknown parameters should be passed as
 * empty string.
 *
 * @param type1
 * @param type2
 * @param type3
 * @returns {string}
 */
export const buildQueryUrl = (type1, type2, type3 = '') => {
  let url = '/bireports';
  url += '/' + type1 + '/' + type2;
  if (type3 !== '') {
    url += '/' + type3;
  }
  return url;
};

/**
 * Build parameters object, that can be used directly in Api request. It is generated according filter passed in.
 *
 * @param filter
 * @returns {{}}
 */
export const buildQueryParams = filter => {
  // Data
  const params = {};
  const dataType = getProp(filter, 'data.fiction_data', false);
  if (dataType === true) {
    params['data_type'] = 'real-fiction';
  } else {
    params['data_type'] = 'real';
  }

  // Date
  let dateRange = getProp(filter, 'date.date_range');
  if (dateRange) {
    try {
      const range = getDateRange(dateRange);
      params['date_from'] = range[0].format('YYYY-MM-DD');
      params['date_to'] = range[1].format('YYYY-MM-DD');
    } catch (e) {
      console.error(e);
    }
  } else {
    let dateFrom = getProp(filter, 'date.date_from');
    let dateTo = getProp(filter, 'date.date_to');
    if (dateFrom) {
      params['date_from'] = moment(dateFrom).format('YYYY-MM-DD');
    }
    if (dateTo) {
      params['date_to'] = moment(dateTo).format('YYYY-MM-DD');
    }
  }
  params['weekday'] = getProp(filter, 'date.days', []).join(',');

  // Venue
  params['venue'] = getProp(filter, 'venue.venues', [])
    .map(item => item.id)
    .join(',');
  params['cashregister'] = getProp(filter, 'venue.cashregister', [])
    .map(item => item.id)
    .join(',');
  params['operator'] = getProp(filter, 'venue.employee', [])
    .map(item => item.label)
    .join(',');

  // Finance
  // params['currency'] = getProp(filter, 'finance.currency', null); // Toto umyselne neposielame, API si to dogeneruje podla nastavanie usera
  params['tax_id'] = getProp(filter, 'finance.tax_groups', [])
    .map(item => item.id)
    .join(',');
  params['payment_type'] = getProp(filter, 'finance.payment_type', [])
    .map(item => item.id)
    .join(',');

  // Product
  params['product_price_from'] = getProp(filter, 'product.price_from', null);
  params['product_price_to'] = getProp(filter, 'product.price_to', null);
  params['product_plu'] = getProp(filter, 'product.plu', [])
    .map(item => item.label)
    .join(',');

  // Clear null or empty params
  for (var idx in params) {
    if (params[idx] === null || params[idx] === '') {
      delete params[idx];
    }
  }

  return params;
};

/**
 * Constructs new chart filter either from local or global filter.
 * Which filter to use is determined by boolean property "use". If "use" is TRUE or not found, global filter is used.
 *
 * @param localFilter
 * @param globalFilter
 * @returns {{}}
 */
export const buildChartFilter = (localFilter, globalFilter) => {
  const chartFilter = {};
  for (const category of ['data', 'date', 'venue', 'finance', 'product']) {
    if (getProp(localFilter, `${category}.use`, true) === true) {
      // Use global filter
      chartFilter[category] = getProp(globalFilter, category, {});
    } else {
      // Use local filter
      chartFilter[category] = getProp(localFilter, category, {});
    }
  }
  return chartFilter;
};

export const chartErrors = () => {
  return {
    SUCCESS: 0, // no error
    GENERIC_ERROR: 1,
    ACL_ERROR: 2,
  };
};
