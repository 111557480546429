import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import TaxSummary from './../../../component/translog/taxSummary';
import Totals from './../../../component/translog/totals';
import {
  formatPrice,
  getPaymentMethods,
  getProp,
  __,
} from './../../../utilities/common';

class Detail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paragon: {
        items: [],
      },
    };
  }

  init(props) {
    this.setState({ paragon: props.receipt });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  componentDidMount() {
    this.init(this.props);
  }

  getItemProp(idx, prop, defaultValue = '') {
    return getProp(this.state.paragon.items[idx], prop, defaultValue);
  }

  render() {
    let operatorName = getProp(this.state.paragon, 'operator_name', null);
    return (
      <div>
        <h2 className="side-panel-title">
          {__('Detail paragonu %(id)s').format({ id: this.props.paragon_id })}
        </h2>
        <hr />
        <form className="form-horizontal" onSubmit={this.onSubmit}>
          <fieldset className="fieldset fieldset-root">
            <legend>{__('Základné údaje')}</legend>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                {__('Receipt ID')}:
              </label>
              <div className="col-sm-4 font-lg">
                <strong>{getProp(this.state.paragon, 'receipt_id')}</strong>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">{__('EJ ID')}:</label>
              <div className="col-sm-4 font-lg">
                <strong>{getProp(this.state.paragon, 'ej_id')}</strong>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                {__('ID operátora')}:
              </label>
              <div className="col-sm-4 font-lg">
                <strong>{getProp(this.state.paragon, 'operator_id')}</strong>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                {__('Dátum a čas')}:
              </label>
              <div className="col-sm-4 font-lg">
                <strong>
                  {moment(getProp(this.state.paragon, 'datetime')).format(
                    'DD.MM.YYYY HH:mm:ss',
                  )}
                </strong>
              </div>
            </div>
            <div className="form-group">
              <label className="col-sm-4 control-label">
                {__('Vytvoril')}:
              </label>
              <div className="col-sm-4 font-lg">
                <strong>
                  {getProp(this.state.paragon, 'operator_id')}{' '}
                  {operatorName !== null ? `(${operatorName})` : ''}
                </strong>
              </div>
            </div>
          </fieldset>
          <fieldset className="fieldset fieldset-root">
            <legend>{__('Položky')}</legend>
            {this.state.paragon.items.map((item, idx) => {
              return (
                <Item
                  key={idx}
                  idx={idx}
                  getProp={this.getItemProp.bind(this)}
                  currency={getProp(this.state.paragon, 'currency')}
                />
              );
            })}
          </fieldset>
          <TaxSummary
            vatListObj={this.props.vatListObj}
            taxItems={this.state.paragon.tax_items}
            currency={getProp(this.state.paragon, 'currency')}
          />
          <Totals
            items={this.state.paragon.items}
            currency={getProp(this.state.paragon, 'currency')}
          />
          <fieldset className="fieldset fieldset-root">
            <legend>{__('Rozpis platby')}</legend>
            {getProp(this.state.paragon, 'payments', []).map(item => {
              return (
                <div className="form-group" key={item.type}>
                  <label className="col-sm-4 control-label">
                    {getPaymentMethods().get(item.type)}:
                  </label>
                  <div className="col-sm-4 font-lg">
                    <strong>
                      {formatPrice(
                        getProp(item, 'paid_amount', 0),
                        getProp(this.state.paragon, 'currency'),
                      )}
                    </strong>
                  </div>
                </div>
              );
            })}
          </fieldset>
        </form>
      </div>
    );
  }
}

const Item = props => {
  return (
    <fieldset className="fieldset">
      <div className="form-group">
        <div className="col-sm-4 font-lg">
          <strong>
            {props.getProp(props.idx, 'quantity')} {__('ks')}
          </strong>
        </div>
        <div className="col-xs-8 font-lg">
          <strong>{props.getProp(props.idx, 'plu')}</strong>
          &nbsp;
          <strong>{props.getProp(props.idx, 'name')}</strong>
        </div>
      </div>
      <div className="form-group">
        <div className="col-sm-4">{__('EAN')}</div>
        <div className="col-sm-4">{props.getProp(props.idx, 'ean')}</div>
      </div>
      <div className="form-group">
        <div className="col-xs-4">{__('S DPH')}</div>
        <div className="col-sm-4">
          {formatPrice(
            props.getProp(props.idx, 'unit_price_tx'),
            props.currency,
          )}{' '}
          / {__('ks')}
        </div>
        <div className="col-sm-4">
          {formatPrice(
            props.getProp(props.idx, 'items_price_tx'),
            props.currency,
          )}{' '}
          {__('Celkom')}
        </div>
      </div>
      <div className="form-group">
        <div className="col-xs-4">{__('Bez DPH')}</div>
        <div className="col-sm-4">
          {formatPrice(props.getProp(props.idx, 'unit_price'), props.currency)}{' '}
          / {__('ks')}
        </div>
        <div className="col-sm-4">
          {formatPrice(props.getProp(props.idx, 'items_price'), props.currency)}{' '}
          {__('Celkom')}
        </div>
      </div>
      <div className="form-group">
        <div className="col-sm-4">{__('DPH')}</div>
        <div className="col-sm-4">
          {props.getProp(props.idx, 'tax_id')}:{' '}
          {props.getProp(props.idx, 'tax_rate')}%
        </div>
      </div>
    </fieldset>
  );
};

export default connect(state => ({}))(Detail);
