import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Api from '../../service/Api';
import { getProp, setProp, __ } from '../../utilities/common';
import { setAlert } from './../../actions';
import Loader from '../../component/loader';

class FormEdit extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      user: {
        company: {
          address: {
            country: 'SK',
          },
        },
      },
      isFetching: false,
      formError: {},
      agree: false,
    };
    this.context = context;
  }

  componentDidMount() {
    this.loadDetail(this.props.params.id);
  }


  onChangeUserProp = (prop, ev) => {
    const { target: { value } } = ev;

    this.setState(state => {
      const { user, formError } = state;
      if (prop in formError) {
        delete formError[prop];
      }

      return {
        user: setProp(user, prop, value),
      }
    });
  }

  getUserProp = (prop, defaultValue = '') => {
    return getProp(this.state.user, prop, defaultValue);
  }

  loadDetail = async (id) => {
    this.setState({ isFetching: true });
    try {
      const response = await Api.get(`/admin/partners/${id}`);
      const { company_name, taxid, partner_id, regnr, vatid, address: { city, country, number, street, zip } } = response;
      const user = {
        "company": {
          "address": {
            "country": country,
            "street": street,
            "number": number,
            "city": city,
            "zip": zip,
          },
          "company_name": company_name,
          "regnr": regnr,
          "taxid": taxid,
          "vatid": vatid,
          "partner_id": partner_id,
        },
      }
      this.setState({
        user,
        isFetching: false,
      });
    } catch {
      this.props.dispatch(
        setAlert({
          type: 'error',
          title: __('Nebolo možné načítať detail partnera'),
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
      this.setState({ isFetching: false });
    }
  }

  onSubmit = async (ev) => {
    ev.preventDefault();

    this.setState({ isFetching: true });

    let { user } = this.state;

    if (user.phones) {
      user.phones = [user.phones];
    } else {
      user.phones = [];
    }

    delete user.partner_id;

    try {
      await Api.put(`/admin/partners/${this.props.params.id}`, JSON.stringify(user));
      this.setState({ isFetching: false });
      this.props.dispatch(
        setAlert({
          type: 'success',
          title: __('Uloženie úspešné'),
          onConfirm: () => {
            this.props.dispatch(setAlert(null));
            this.props.onSuccess();
            this.props.onClose();
          },
        }),
      );
    } catch (error) {

      this.setState({ isFetching: false });

      if (error.response.status === 400) {
        let msg = '';
        if (error.details.name === 'INVALID_SCHEMA') {
          const formError = this.state.formError;
          const paths = getProp(error.details, 'payload.paths', []);
          for (const path of paths) {
            formError[path] = true;
          }
          this.setState({ formError });
          msg = __('Nesprávne vyplnená položka');
        } else if (error.details.name === 'INVALID_VAT') {
          msg = __('Zadané IčDPH nie je platné');
        } else if (error.details.name === 'VAT_VALIDATION_FAILED') {
          msg = __('Nepodarilo sa overiť IčDPH, skúste registráciu neskôr');
        } else if (error.details.name === 'INVALID_VAT_COMPANY_NAME') {
          msg =
            __('Prekontrolujte prosím názov Vašej spoločnosti. Správne: ') +
            getProp(error.details, 'payload.company_name');
        }

        if (msg) {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: msg,
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        }
      } else if (error.response.status === 409) {
        if (error.details.name === 'USER_ALREADY_EXISTS') {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Email už existuje'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        } else if (error.details.name === 'COMPANY_ALREADY_EXISTS') {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Firma s týmto IČOm už existuje'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        }
      } else {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Pri registrácii nastala neznáma chyba'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
      }
    }
  }

  getFormGroupInput = (name, label, inputProps) => {
    return (
      <div
        className={cx('form-group', {
          'has-error': name in this.state.formError,
        })}
      >
        <label htmlFor={name}>{label}</label>
        <input
          id={name}
          type="text"
          className="form-control"
          onChange={(e) => this.onChangeUserProp(name, e)}
          value={this.getUserProp(name) || ''}
          {...inputProps}
        />
      </div>
    );
  }

  render() {

    return (
      <div>
        <h2 className="side-panel-title">{__('Editácia')}</h2>
        <Loader show={this.state.isFetching}>
          <form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-sm-12">
                <fieldset className="fieldset fieldset-root">
                  <legend>{__('Údaje o firme')}</legend>
                  {this.getFormGroupInput(
                    'company.company_name',
                    __('Názov'),
                    { required: true },
                  )}
                  {this.getFormGroupInput('company.regnr', __('IČO'), {
                    required: true,
                  })}
                  {this.getFormGroupInput('company.taxid', __('DIČ'), {
                    placeholder: __('nepovinné'),
                  })}
                  {this.getFormGroupInput('company.vatid', __('IčDPH'), {
                    placeholder: __('nepovinné'),
                  })}
                  {this.getFormGroupInput('company.partner_id', __('Partner ID'), {
                    disabled: true,
                  })}
                </fieldset>
              </div>
              <div className="col-sm-12">
                <fieldset className="fieldset fieldset-root">
                  <legend>{__('Adresa firmy')}</legend>
                  {this.getFormGroupInput(
                    'company.address.street',
                    __('Ulica'),
                    { required: true },
                  )}
                  {this.getFormGroupInput(
                    'company.address.number',
                    __('Číslo'),
                    { required: true },
                  )}
                  {this.getFormGroupInput(
                    'company.address.city',
                    __('Mesto'),
                    { required: true },
                  )}
                  {this.getFormGroupInput(
                    'company.address.zip',
                    __('PSČ'),
                    { required: true },
                  )}
                  <div
                    className={cx('form-group', {
                      'has-error':
                        'company.address.country' in this.state.formError,
                    })}
                  >
                    <label htmlFor="company.address.country">
                      {__('Krajina')}
                    </label>
                    <select
                      id="company.address.country"
                      className="form-control"
                      onChange={(e) => this.onChangeUserProp('company.address.country', e)}
                      value={this.getUserProp('company.address.country')}
                    >
                      <option value="SK">{__('Slovensko')}</option>
                      <option value="CZ">{__('Česká republika')}</option>
                    </select>
                  </div>
                </fieldset>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                {__('Uložiť')}
              </button>
            </div>
          </form>
        </Loader>
      </div>
    );
  }
}

FormEdit.contextTypes = {
  router: PropTypes.object,
};

export default connect()(FormEdit);
