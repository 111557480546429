import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SweetAlert from 'sweetalert-react';

class AppRoot extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {};
    this.context = context;
  }

  render() {
    return (
      <div>
        {this.props.children}
        <SweetAlert
          title=""
          {...this.props.alert}
          show={this.props.alert !== null}
        />
      </div>
    );
  }
}

AppRoot.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({
  alert: state.alert,
}))(AppRoot);
