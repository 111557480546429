import React from 'react';
import { __ } from '../../../utilities/common';
import { FormGenerator } from './formGenerator';

export class UnitFormGenerator extends FormGenerator {
  generateSelectControlOptions(property, isRequired) {
    if (property === 'uni_cat_id') {
      return [
        <option key={0} value={1}>
          {__('váhové')}
        </option>,
        <option key={1} value={2}>
          {__('objemové')}
        </option>,
        <option key={2} value={3}>
          {__('množstevné(porcia, kus)')}
        </option>,
        <option key={4} value={4}>
          {__('dĺžkové')}
        </option>,
      ];
    } else {
      return super.generateSelectControlOptions(property, isRequired);
    }
  }
}
