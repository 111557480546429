import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getProp, __ } from '../../../utilities/common';
import Datepicker from './../../../component/form/datepicker';

class Filter extends React.Component {
  constructor(props) {
    super(props);

    let date_from = getProp(this.props, 'parameters.filter.date_from', null);
    if (date_from) {
      date_from = moment(date_from);
    }

    let date_to = getProp(this.props, 'parameters.filter.date_to', null);
    if (date_to) {
      date_to = moment(date_to);
    }

    this.state = {
      date_from: date_from,
      date_to: date_to,
      ej_id: getProp(this.props, 'parameters.filter.ej_id'),
      receipt_id: getProp(this.props, 'parameters.filter.receipt_id'),
      operator_id: getProp(this.props, 'parameters.filter.operator_id'),
      total_sum_range: getProp(
        this.props,
        'parameters.filter.total_sum_range',
        true,
      ),
      finalprice_from: getProp(this.props, 'parameters.filter.finalprice_from'),
      finalprice_to: getProp(this.props, 'parameters.filter.finalprice_to'),
      finalprice: getProp(this.props, 'parameters.filter.finalprice'),
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangeFromDate(date) {
    this.setState({ date_from: date });
  }

  onChangeToDate(date) {
    this.setState({ date_to: date });
  }

  onChangeEjId(ev) {
    this.setState({ ej_id: ev.target.value });
  }

  onChangeReceiptId(ev) {
    this.setState({ receipt_id: ev.target.value });
  }

  onChangeOperatorId(ev) {
    this.setState({ operator_id: ev.target.value });
  }

  onChangeInput(ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  }

  onSubmit(ev) {
    ev.preventDefault();
    let filter = {
      ej_id: this.state.ej_id,
      receipt_id: this.state.receipt_id,
      operator_id: this.state.operator_id,
      total_sum_range: this.state.total_sum_range,
    };

    if (this.state.total_sum_range) {
      if (this.state.finalprice_from) {
        filter['finalprice_from'] = this.state.finalprice_from;
      }
      if (this.state.finalprice_to) {
        filter['finalprice_to'] = this.state.finalprice_to;
      }
    } else {
      if (this.state.finalprice) {
        filter['finalprice'] = this.state.finalprice;
      }
    }

    if (this.state.date_from) {
      filter.date_from = this.state.date_from.format('YYYY-MM-DD');
    }

    if (this.state.date_to) {
      filter.date_to = this.state.date_to.format('YYYY-MM-DD');
    }

    this.props.onSuccess(filter);
    this.context.router.push(this.props.returnUrl);
  }

  onChangeTotalSumFilter(ev) {
    this.setState({ total_sum_range: ev.target.value === 'true' });
  }

  render() {
    return (
      <div>
        <h2 className="side-panel-title">{__('Filter')}</h2>
        <hr />
        <form className="form-horizontal" onSubmit={this.onSubmit}>
          <fieldset className="fieldset fieldset-root">
            <div className="form-group">
              <label htmlFor="inputEmail3" className="col-sm-4 control-label">
                {__('EJ ID')}:
              </label>
              <div className="col-sm-4">
                <input
                  className="form-control"
                  value={this.state.ej_id}
                  onChange={this.onChangeEjId.bind(this)}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="inputEmail3" className="col-sm-4 control-label">
                {__('Rozsah')}:
              </label>
              <div className="col-sm-4">
                <Datepicker
                  selected={this.state.date_from}
                  onChange={this.onChangeFromDate.bind(this)}
                />
              </div>
              <div className="col-sm-4">
                <Datepicker
                  selected={this.state.date_to}
                  onChange={this.onChangeToDate.bind(this)}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="inputEmail3" className="col-sm-4 control-label">
                {__('Receipt ID')}:
              </label>
              <div className="col-sm-4">
                <input
                  className="form-control"
                  value={this.state.receipt_id}
                  onChange={this.onChangeReceiptId.bind(this)}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="inputEmail3" className="col-sm-4 control-label">
                {__('Vytvoril')}:
              </label>
              <div className="col-sm-4">
                <input
                  className="form-control"
                  value={this.state.operator_id}
                  onChange={this.onChangeOperatorId.bind(this)}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="inputEmail3" className="col-sm-4 control-label">
                {__('Celková suma')}:
              </label>
              <div className="col-sm-4">
                <select
                  className="form-control"
                  value={this.state.total_sum_range}
                  onChange={this.onChangeTotalSumFilter.bind(this)}
                >
                  <option value={true}>{__('Rozsah')}</option>
                  <option value={false}>{__('Presná suma')}</option>
                </select>
              </div>
              {this.state.total_sum_range ? (
                <span>
                  <div className="col-sm-2">
                    <input
                      className="form-control"
                      placeholder={__('od')}
                      value={this.state.finalprice_from}
                      name="finalprice_from"
                      onChange={this.onChangeInput.bind(this)}
                    />
                  </div>
                  <div className="col-sm-2">
                    <input
                      className="form-control"
                      placeholder={__('do')}
                      value={this.state.finalprice_to}
                      name="finalprice_to"
                      onChange={this.onChangeInput.bind(this)}
                    />
                  </div>
                </span>
              ) : (
                <div className="col-sm-4">
                  <input
                    className="form-control"
                    placeholder={__('hodnota')}
                    value={this.state.finalprice}
                    name="finalprice"
                    onChange={this.onChangeInput.bind(this)}
                  />
                </div>
              )}
            </div>
            <div className="text-center">
              <button className="btn btn-primary">{__('Nastaviť')}</button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

Filter.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({}))(Filter);
