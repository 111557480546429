import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import GlobalFilterDelegator from '../GlobalFilterDelegator';
import Api from '../service/Api';
import { __ } from '../utilities/common';
import {
  selectCashRegister,
  setAlert,
  setFilter,
  showFilter,
} from './../actions';
import GlobalFilterForm from './../component/globalFilterForm';

class GlobalFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      venues: [],
      cashRegisters: [],
      filter: props.filter || {},
      vats: [],
    };

    GlobalFilterDelegator.addDelegate(this);
  }

  componentWillUnmount() {
    GlobalFilterDelegator.removeDelegate(this);
  }

  onChangeGlobalFilter() {
    this.setState({ filter: this.props.filter });
  }

  componentDidMount() {
    Api.get('/user/venues').then(response => {
      this.setState({ venues: response.venues });
    });

    Api.get('/user/cash-registers').then(response => {
      if (
        this.props.selectCashRegister === null &&
        response.cashregisters.length > 0
      ) {
        // If no cash-register is selected, select first
        this.props.dispatch(selectCashRegister(response.cashregisters[0]));
      }
      this.setState({ cashRegisters: response.cashregisters });
    });

    Api.get('/user/setup-globals/vat').then(response => {
      let vats = {};
      response.items.forEach(item => {
        if (!(item.vat_name in vats)) {
          vats[item.vat_name] = [];
        }
        vats[item.vat_name].push(`${item.vat_value / 100}%`);
      });

      let vatsArr = [];
      Object.keys(vats).forEach(vat_name => {
        vatsArr.push({
          id: vat_name,
          values: vats[vat_name].join(', '),
        });
      });

      this.setState({ vats: vatsArr });
    });
  }

  save() {
    Api.put('/user/ui/global-filter', JSON.stringify(this.state.filter)).then(
      response => {
        this.props.dispatch(setFilter(response));
        GlobalFilterDelegator.globalFilterChanged();
      },
    );
  }

  onChangeGlobalFilterForm(data) {
    this.setState({ filter: data });
    //this.props.dispatch(setFilter(data));
  }

  onSubmit(ev) {
    ev.preventDefault();
    this.save();
    this.props.dispatch(showFilter(false));
  }

  onClickReset() {
    this.props.dispatch(
      setAlert({
        type: 'warning',
        title: __('Ste si istý?'),
        confirmButtonColor: '#DD6B55',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: __('Áno'),
        cancelButtonText: __('Nie'),
        buttonsStyling: false,
        text: __('Naozaj chcete vyprázdniť filter?'),
        onConfirm: () => {
          this.setState(
            {
              filter: {},
            },
            () => {
              this.props.dispatch(setAlert(null));
            },
          );
        },
        onCancel: () => this.props.dispatch(setAlert(null)),
      }),
    );
  }

  render() {
    return (
      <div>
        <h2 className="side-panel-title">{__('Filter')}</h2>
        <hr />
        <form className="form-horizontal" onSubmit={this.onSubmit.bind(this)}>
          <GlobalFilterForm
            allVenues={this.state.venues}
            allCashRegisters={this.state.cashRegisters}
            vats={this.state.vats}
            data={this.state.filter}
            onChange={this.onChangeGlobalFilterForm.bind(this)}
          />

          <div className="text-center">
            <button type="submit" className="btn btn-primary">
              {__('Aplikovať')}
            </button>
            &nbsp;
            <button
              type="button"
              onClick={this.onClickReset.bind(this)}
              className="btn btn-default-o"
            >
              {__('Vyprázdniť filter')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

GlobalFilter.contextTypes = {
  router: PropTypes.object,
};

export default connect(
  state => ({
    user: state.user,
    filter: state.filter,
    selectCashRegister: state.selectCashRegister,
  }),
  null,
  null,
  { forwardRef: true },
)(GlobalFilter);
