import cx from 'classnames';
import React from 'react';

const Loader = props => {
  return (
    <div className={cx('loader-container', props.className)}>
      {props.children}
      <div className={cx('loader-backdrop', { hidden: !props.show })} />
      <div className={cx('loader', { hidden: !props.show })} />
    </div>
  );
};

export default Loader;
