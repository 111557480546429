import cx from 'classnames';
import React from 'react';
import Icon from './../component/icon';

const ViewElement = props => {
  return (
    <div
      className={cx('view-element', { active: props.active })}
      style={{ position: 'relative' }}
    >
      {props.children}
      <div className={cx('overlay', { hidden: props.showControls !== true })} />
      <div
        className={cx('view-element-controls', {
          hidden: props.showControls !== true,
        })}
      >
        {typeof props.onClickEdit === 'function' ? (
          <span
            className={cx('btn btn-white btn-sm btn-circle')}
            onClick={props.onClickEdit.bind(null)}
          >
            <Icon name="edit" />
          </span>
        ) : null}

        {typeof props.onClickMove === 'function' ? (
          <span
            className={cx('btn btn-white btn-sm btn-circle')}
            onClick={props.onClickMove.bind(null)}
          >
            <Icon name="resize-four-directions" className="icon-rotate-45" />
          </span>
        ) : null}

        {typeof props.onClickMoveUp === 'function' ? (
          <span
            className={cx('btn btn-white btn-sm btn-circle')}
            onClick={props.onClickMoveUp.bind(null)}
          >
            <Icon name="collapse-arrow" />
          </span>
        ) : null}
        {typeof props.onClickMoveDown === 'function' ? (
          <span
            className={cx('btn btn-white btn-sm btn-circle')}
            onClick={props.onClickMoveDown.bind(null)}
          >
            <Icon name="expand-arrow" />
          </span>
        ) : null}

        {typeof props.onClickMoveLeft === 'function' ? (
          <span
            className={cx('btn btn-white btn-sm btn-circle')}
            onClick={props.onClickMoveLeft.bind(null)}
          >
            <Icon name="back" />
          </span>
        ) : null}
        {typeof props.onClickMoveRight === 'function' ? (
          <span
            className={cx('btn btn-white btn-sm btn-circle')}
            onClick={props.onClickMoveRight.bind(null)}
          >
            <Icon name="forward" />
          </span>
        ) : null}

        {typeof props.onClickRemove === 'function' ? (
          <span
            className={cx('btn btn-white btn-sm btn-circle')}
            onClick={props.onClickRemove.bind(null)}
          >
            <Icon name="multiply" />
          </span>
        ) : null}
      </div>
    </div>
  );
};

ViewElement.defaultProp = {
  active: false,
};

export default ViewElement;
