import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router';
import Icon from './icon';
import PropTypes from 'prop-types';

const FloatingActionButton = props => {
  const button = (typeof props.linkTo !== 'undefined' ? (
      <Link
          to={props.linkTo}
          className="btn btn-lg btn-default btn-circle"
          style={{ borderRadius: '50%' }}
      >
        <Icon name={props.icon} />
      </Link>
    ) : (
      <span
          className="btn btn-lg btn-default btn-circle"
          style={{ borderRadius: '50%' }}
          onClick={props.onClick.bind(null)}
      >
        <Icon name={props.icon} />
      </span>
    ));

  return (props.noWrapper ? button : (
      <div title={props.title} className={cx('fab', props.className)}>
        {button}
      </div>
    )
  );
};

FloatingActionButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  noWrapper: PropTypes.bool
};

FloatingActionButton.defaultProps = {
  onClick: () => {},
  icon: 'plus-math',
  className: '',
  title: '',
  noWrapper: false
};

export default FloatingActionButton;
