import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Api from '../../service/Api';
import { getProp, __ } from '../../utilities/common';
import { setAlert } from './../../actions';
import Content from './../../component/content';
import DataTable from './../../component/dataTable';
import Icon from './../../component/icon';
import Loader from './../../component/loader';
import LoadMoreBtn from './../../component/loadMoreBtn';
import MainHeader from './../../component/mainHeader';
import { formatFilesize } from './../../utilities/common';

class BackupsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: 100,
      next_offset: null,
      cashRegisterId: props.params.id,
      backups: [],
      loader: false,
    };
  }

  componentDidMount() {
    this.loadBackups(this.state.limit, this.state.next_offset);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    var nextId = nextProps.params.id;
    if (nextId !== this.state.cashRegisterId) {
      this.setState(
        { cashRegisterId: nextId, backups: [], next_offset: null },
        () => {
          this.loadBackups(this.state.limit, this.state.next_offset);
        },
      );
    }
  }

  onChangeDate(date) {
    this.setState({ date: date });
  }

  loadBackups(limit, offset) {
    this.setState({ loader: true });

    let url = '/user/backups';
    if (this.state.cashRegisterId) {
      url = `/user/cash-registers/${this.state.cashRegisterId}/backups`;
    }
    Api.get(url, { limit: limit, offset: offset })
      .then(response => {
        this.setState({
          loader: false,
          backups: this.state.backups.concat(response.backups),
          next_offset: response.next_offset,
        });
      })
      .catch(error => {
        this.setState({ loader: false });
      });
  }

  restoreBackup(backup_id, cash_register_id) {
    Api.post(
      `/user/cash-registers/${cash_register_id}/backups/${backup_id}/restore`,
    )
      .then(response => {
        this.props.dispatch(
          setAlert({
            type: 'success',
            title: __('Záloha bude obnovená'),
            onConfirm: () => {
              this.props.dispatch(setAlert(null));
            },
          }),
        );
      })
      .catch(error => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Obnovenie zálohy zlyhalo'),
            onConfirm: () => {
              this.props.dispatch(setAlert(null));
            },
          }),
        );
      });
  }

  restoreBackupConfirmation(backup_id, cash_register_id) {
    this.props.dispatch(
      setAlert({
        type: 'warning',
        title: __('Prajete si obnoviť zálohu?'),
        showCancelButton: true,
        cancelButtonText: __('Zrušiť'),
        confirmButtonText: 'Obnoviť zálohu',
        showLoaderOnConfirm: true,
        onConfirm: () => {
          this.restoreBackup(backup_id, cash_register_id);
        },
        onCancel: () => {
          this.props.dispatch(setAlert(null));
        },
      }),
    );
  }

  async downloadBackup(backup_id, cash_register_id) {
    try {
      const response = await Api.get(
        `/user/cash-registers/${cash_register_id}/backups/${backup_id}`,
      );
      Api.redirect(response.url);
    } catch (e) {
      this.props.dispatch(
        setAlert({
          type: 'error',
          title: __('Stiahnutie zálohy zlyhalo'),
          onConfirm: () => {
            this.props.dispatch(setAlert(null));
          },
        }),
      );
    }
  }

  onClickBack() {
    this.context.router.push(
      `/app/cash-register/${this.state.cashRegisterId}/view`,
    );
  }

  render() {
    let backupsData = [];
    for (let i = 0; i < this.state.backups.length; i++) {
      let backup = this.state.backups[i];
      let cashRegisterId = this.state.cashRegisterId;
      if (!this.state.cashRegisterId) {
        backup = this.state.backups[i].backup;
        cashRegisterId = this.state.backups[i].cash_register_id;
      }

      let row = [];
      if (!this.state.cashRegisterId) {
        row.push(
          <Link
            to={`/app/cash-register/${cashRegisterId}/view`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {cashRegisterId}
          </Link>,
        );
      }

      let datetimeCol = moment(backup.datetime).format('DD.MM.YYYY HH:mm');
      const status = getProp(backup, 'status');
      if (status === 'NOT_RESTORED') {
        datetimeCol = (
          <span>
            {datetimeCol}{' '}
            <span className="text-danger">({__('neaplikovaná')})</span>
          </span>
        );
      } else if (status === 'RESTORE_ERROR') {
        const statusDesc = getProp(backup, 'status_description', []);
        datetimeCol = (
          <span>
            {datetimeCol}{' '}
            <span className="text-danger">({statusDesc.join(', ')})</span>
          </span>
        );
      }
      row = [
        ...row,
        datetimeCol,
        backup.name,
        backup.type,
        formatFilesize(backup.size),
        <span>
          <div
            onClick={this.downloadBackup.bind(this, backup._id, cashRegisterId)}
            className="btn btn-primary-o btn-circle"
            title={__('Stiahnuť zálohu')}
          >
            <Icon name="download" />
          </div>
          &nbsp;
          <div
            onClick={this.restoreBackupConfirmation.bind(
              this,
              backup._id,
              cashRegisterId,
            )}
            className="btn btn-primary-o btn-circle"
            title={__('Obnoviť zálohu')}
          >
            <Icon name="restart" />
          </div>
        </span>,
      ];
      backupsData.push(row);
    }

    let cols = [__('Dátum a čas'), __('Názov'), __('Typ'), __('Veľkosť'), ''];
    if (!this.state.cashRegisterId) {
      cols.unshift(__('Číslo pokladne'));
    }

    //neaplikovana

    return (
      <div id="content-wrapper">
        <MainHeader
          title={
            this.state.cashRegisterId
              ? __('Zálohy pokladne')
              : __('File manager')
          }
          progress={100}
          onClickBack={
            this.state.cashRegisterId ? this.onClickBack.bind(this) : null
          }
        />
        <Content>
          <Loader show={this.state.loader}>
            <div>
              <DataTable
                cols={cols}
                rows={backupsData}
                classNames={[
                  null,
                  null,
                  null,
                  null,
                  null,
                  'text-right',
                  'text-right',
                ]}
              />
              <h4
                className={cx('text-center', {
                  hidden: this.state.loader === true || backupsData.length > 0,
                })}
              >
                {__('Žiadne transakcie')}
              </h4>
            </div>
          </Loader>
          <LoadMoreBtn
            nextOffset={this.state.next_offset}
            onClick={this.loadMore.bind(this)}
          />
        </Content>
        {/*<SidePanel onSuccess={onSuccessSidePanel} parameters={{backups: this.state.backups, filter: this.state.filter}} returnUrl={`/app/cash-register/${this.state.cashRegisterId}/backups`}>{this.props.children}</SidePanel>*/}
      </div>
    );
  }

  loadMore() {
    this.loadBackups(this.state.limit, this.state.next_offset);
  }
}

BackupsPage.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({}))(BackupsPage);
