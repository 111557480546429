import React from 'react';
import { getProp, setProp, __ } from '../utilities/common';
import ChartForm from './../component/chartForm';
import RadioButton from './../component/form/radioButton';
import UseChartForm from './../component/useChartForm';

class AddWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: 'new_chart',
    };
  }

  onChangeObjectProp(prop, ev) {
    let value = ev.target.value;
    this.setState(setProp(this.state, prop, value));
  }

  getObjectProp(prop, defaultValue = '') {
    return getProp(this.state, prop, defaultValue);
  }

  onChangeForm(item, event) {
    this.setState({ form: item.value });
  }

  render() {
    try {
      let form = null;
      if (this.state.form === 'existing_chart') {
        form = <UseChartForm onSave={this.props.onSave} />;
      } else if (this.state.form === 'new_chart') {
        form = (
          <ChartForm
            onSave={this.props.onSave}
            allowFilters={this.props.allowFilters}
          />
        );
      }

      return (
        <div>
          <h2 className="side-panel-title">{__('Pridať widget')}</h2>
          <hr />
          <div className="text-center">
            <RadioButton
              id="createNew"
              value={this.state.form}
              onChange={this.onChangeForm.bind(this)}
              options={[
                { value: 'new_chart', label: __('Nový graf') },
                { value: 'existing_chart', label: __('Existujúci graf') },
              ]}
            />
          </div>
          {form}
        </div>
      );
    } catch (e) {
      console.error(e);
    }
  }
}

AddWidget.defaultProps = {
  allowFilters: true,
};

export default AddWidget;
