import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Api from '../../service/Api';
import { getProp, __ } from '../../utilities/common';
import Content from './../../component/content';
import DataTable from './../../component/dataTable';
import FloatingActionButton from './../../component/floatingActionButton';
import Icon from './../../component/icon';
import Loader from './../../component/loader';
import LoadMoreBtn from './../../component/loadMoreBtn';
import MainHeader from './../../component/mainHeader';
import ProtectedContent from './../../component/protectedContent';
import SidePanel from './../../component/sidePanel';
import { formatPrice } from './../../utilities/common';
import CashRegisterTransLogDetail from './translog/detail';
import { setAlert } from '../../actions.js';

class TransLog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cashRegisterId: props.params.id,
      receipts: [],
      loader: false,
      filter: {},
      next_offset: null,
      limit: 100,
      cashRegister: null,
      receipt: null,
      vatListObj: {},
    };
  }

  componentDidMount() {
    this.setState({ loader: true });

    Api.get(`/user/cash-registers/${this.props.params.id}/setup/vat`)
      .then(response => {
        const vatListObj = {};
        for (let vat of response.items) {
          vatListObj[vat.vat_id] = vat;
        }
        this.setState({ vatListObj: vatListObj });
        this.loadReceipts(null);
      })
      .catch(() => {
        this.setState({ laoder: false });
      });
  }

  onChangeDate(date) {
    this.setState({ date: date });
  }

  loadReceipts(offset) {
    const filter = Object.assign(
      { limit: this.state.limit, offset: offset },
      this.state.filter,
    );

    return Api.get(`/user/cash-registers/${this.state.cashRegisterId}`).then(
      response => {
        this.setState({ cashRegister: response });

        Api.get(
          `/user/cash-registers/${this.state.cashRegisterId}/translog`,
          filter,
        )
          .then(response => {
            if (!offset) {
              this.setState({
                loader: false,
                receipts: response.receipts,
                next_offset: response.next_offset,
              });
            } else {
              this.setState({
                loader: false,
                receipts: this.state.receipts.concat(response.receipts),
                next_offset: response.next_offset,
              });
            }
          })
          .catch(error => {
            this.setState({ loader: false });
          });
      },
    );
  }

  setFilter(filter) {
    this.setState(
      {
        filter: filter,
      },
      this.loadReceipts,
    );
  }

  onClickBack() {
    this.context.router.push(
      `/app/cash-register/${this.state.cashRegisterId}/view`,
    );
  }

  onClickDetail(receipt) {
    this.setState({ receipt });
  }

  onClickImport() {
    var x = document.createElement('INPUT');
    x.setAttribute('type', 'file');
    x.click();
    x.addEventListener('change', event => {
      this.setState({ loader: true });
      const formData = new FormData();
      formData.append('import', event.target.files[0]);
      Api.post(
        `/user/cash-registers/${
          this.state.cashRegisterId
        }/paragons-import/real`,
        formData,
        null,
      )
        .then(response => {
          this.loadReceipts(0).then(() => {
            this.setState({ loader: false });
          });
        })
        .catch(error => {
          this.props.dispatch(
            setAlert({
              type: 'error',
              title: __(
                'Nebolo možné spracovať import. Skontrolujte formát súboru.',
              ),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
          this.setState({ loader: false });
        });
    });
  }

  render() {
    let onSuccessSidePanel = filter => {
      this.setFilter(filter);
    };

    let title = __('Transakčná história');
    if (this.state.cashRegister) {
      title += ` - ${__('Pokladňa')} ${this.state.cashRegister._id} ${
        this.state.cashRegister.name ? '| ' + this.state.cashRegister.name : ''
      }`;
    }

    return (
      <div id="content-wrapper">
        <MainHeader
          title={title}
          progress={100}
          onClickBack={this.onClickBack.bind(this)}
        >
          <span className="clickable" onClick={this.onClickImport.bind(this)}>
            {__('Importovať')}
          </span>
          &nbsp;|&nbsp;
          <a
            href={`${
              process.env.PUBLIC_URL
            }/data/public_data_example-import-csv.csv`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {__('Príklad importného súboru')}
          </a>
        </MainHeader>
        <Content>
          <Loader show={this.state.loader}>
            <div className="row">
              <div className="col-md-6" />
              <div className="col-md-6">
                <Link
                  to={`/app/cash-register/${
                    this.state.cashRegisterId
                  }/translog/filter`}
                  className="btn btn-primary-o pull-right"
                >
                  {__('Upraviť filter')}
                </Link>
              </div>
            </div>

            <div>
              <DataTable
                cols={[
                  __('Receipt ID'),
                  __('EJ ID'),
                  __('Dátum vystavenia'),
                  __('Počet položiek'),
                  __('Vytvorený manuálne'),
                  __('Celková suma bez DPH'),
                  __('Vytvoril'),
                  '',
                ]}
                rows={this.state.receipts.map(receipt => {
                  let operatorId = getProp(receipt, 'operator_id');
                  let operatorName = getProp(receipt, 'operator_name');
                  if (operatorName) {
                    operatorName = `${operatorId} (${operatorName})`;
                  } else {
                    operatorName = operatorId;
                  }
                  return {
                    className: cx({ muted: receipt.paragon === 'fiction' }),
                    _data: [
                      receipt.receipt_id,
                      receipt.ej_id,
                      moment(receipt.datetime).format('DD.MM.YYYY HH:mm'),
                      receipt.items.length,
                      getProp(receipt, 'manual', false) ? (
                        <Icon name="checkmark" />
                      ) : null,
                      formatPrice(receipt.total.final_price, receipt.currency),
                      operatorName ? operatorName : '',
                      <span
                        className="btn btn-primary-o btn-circle"
                        title={__('Detail')}
                        onClick={this.onClickDetail.bind(this, receipt)}
                      >
                        <Icon name="ingredients-list" />
                      </span>,
                    ],
                  };
                })}
                classNames={[
                  'font-lg',
                  null,
                  null,
                  null,
                  'text-center',
                  null,
                  null,
                  'text-right',
                ]}
              />
              <h4
                className={cx('text-center', {
                  hidden:
                    this.state.loader === true ||
                    this.state.receipts.length > 0,
                })}
              >
                {__('Žiadne transakcie')}
              </h4>
            </div>
            <LoadMoreBtn
              nextOffset={this.state.next_offset}
              onClick={this.loadReceipts.bind(this, this.state.next_offset)}
            />
          </Loader>
          <ProtectedContent resource="bm.paragon_import" action="enabled">
            <FloatingActionButton
              linkTo={`/app/cash-register/${this.props.params.id}/translog/add`}
              title={__('Pridať transakciu')}
            />
          </ProtectedContent>
        </Content>
        <SidePanel
          onSuccess={onSuccessSidePanel}
          parameters={{
            receipts: this.state.receipts,
            filter: this.state.filter,
          }}
          returnUrl={`/app/cash-register/${this.state.cashRegisterId}/translog`}
        >
          {this.props.children}
        </SidePanel>
        <SidePanel
          onClose={() => {
            this.setState({ receipt: null });
          }}
          show={this.state.receipt !== null}
        >
          {this.state.receipt !== null ? (
            <CashRegisterTransLogDetail
              receipt={this.state.receipt}
              paragon_id={this.state.receipt._id}
              vatListObj={this.state.vatListObj}
            />
          ) : null}
        </SidePanel>
      </div>
    );
  }
}

TransLog.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({}))(TransLog);
