import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Api from '../../service/Api';
import { getProp, isAllowed, setProp, __ } from '../../utilities/common';
import { setAlert } from './../../actions';
import TokenGroup from './../../component/form/tokenGroup';
import Loader from './../../component/loader';
import UserForm from './../../component/userForm';
import { guid } from './../../utilities/common';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
      savingLoader: true,
      formError: {},
      cashRegisters: [],
      venues: [],
      roles: [],
    };

    this.guid = guid();
  }

  componentDidMount() {
    const promises = [];

    // General purpose promises
    const generalPromises = [];
    generalPromises.push(Api.get(`/user/cash-registers`));
    generalPromises.push(Api.get(`/user/venues`));
    promises.push(
      Promise.all(generalPromises).then(responses => {
        return {
          venues: responses[1].venues,
          cashRegisters: responses[0].cashregisters,
        };
      }),
    );

    // Promises specific to detail of item
    const detailPromises = [];
    if (this.props.params.id) {
      detailPromises.push(
        Api.get(`/user/company/users/${this.props.params.id}`),
      );
      detailPromises.push(
        Api.get(`/user/company/users/${this.props.params.id}/venues`),
      );
      detailPromises.push(
        Api.get(`/user/company/users/${this.props.params.id}/cash-registers`),
      );
      promises.push(
        Promise.all(detailPromises).then(responses => {
          try {
            const user = responses[0];
            const venues = responses[1].venues;
            const cashRegisters = responses[2].cashregisters;

            // @TODO should be fixed api-side
            user.cash_registers =
              user.cash_registers === null ? [] : user.cash_registers;
            user.venues = user.venues === null ? [] : user.venues;

            const cr = [];
            for (const c of cashRegisters) {
              if (user.cash_registers.includes(c._id)) {
                cr.push({ label: c.name, id: c._id });
              }
            }
            user.cash_registers = cr;

            const ve = [];
            for (const v of venues) {
              if (user.venues.includes(v._id)) {
                ve.push({ label: v.name, id: v._id });
              }
            }
            user.venues = ve;

            const roles = [];
            if (
              isAllowed(
                `company.${getProp(user, 'company_role')}s`,
                'set_role_owner',
              )
            ) {
              roles.push({ id: 'owner', label: __('Administrátor') });
            }
            if (
              isAllowed(
                `company.${getProp(user, 'company_role')}s`,
                'set_role_manager',
              )
            ) {
              roles.push({ id: 'manager', label: __('Manažér') });
            }
            if (
              isAllowed(
                `company.${getProp(user, 'company_role')}s`,
                'set_role_employee',
              )
            ) {
              roles.push({ id: 'employee', label: __('Zamestnanec') });
            }

            return {
              user: user,
              roles: roles,
            };
          } catch (e) {
            console.error(e);
          }
        }),
      );
    }

    Promise.all(promises)
      .then(states => {
        let newState = { savingLoader: false };
        for (const s of states) {
          newState = Object.assign(newState, s);
        }
        this.setState(newState);
      })
      .catch(() => {
        this.setState({ savingLoader: false });
      });
  }

  onChangeUser(prop, ev) {
    const formError = this.state.formError;
    if (prop in formError) {
      delete formError[prop];
    }
    this.setState({
      formError: formError,
      user: setProp(this.state.user, prop, ev.target.value),
    });
  }

  onSubmit(ev) {
    ev.preventDefault();
    this.setState({ savingLoader: true });

    let cashRegisters = getProp(this.state.user, 'cash_registers', []);
    if (cashRegisters !== null) {
      cashRegisters = cashRegisters.map(item => item.id);
    }

    let venues = getProp(this.state.user, 'venues', []);
    if (venues !== null) {
      venues = venues.map(item => item.id);
    }

    const user = {
      forename: getProp(this.state.user, 'forename'),
      surname: getProp(this.state.user, 'surname'),
      email: getProp(this.state.user, 'email'),
      cash_registers: cashRegisters,
      venues: venues,
    };

    const phone = getProp(this.state.user, 'phones');
    if (typeof phone === 'object') {
      user.phones = phone;
    } else if (phone) {
      user.phones = [phone];
    } else {
      user.phones = [];
    }

    let promise = null;
    if (this.props.params.id) {
      user.company_role = getProp(this.state.user, 'company_role');
      promise = Api.put(
        `/user/company/users/${this.props.params.id}`,
        JSON.stringify(user),
      );
    } else {
      user.company_role = this.props.params.role;
      const password = getProp(this.state.user, 'password');
      if (password.trim()) {
        user.password = password;
      }
      promise = Api.post(`/user/company/users`, JSON.stringify(user));
    }

    promise
      .then(() => {
        this.setState({ savingLoader: false });
        this.props.parameters.onSuccess();
        this.context.router.push(this.props.returnUrl);
      })
      .catch(error => {
        this.setState({ savingLoader: false });
        if (error.response.status === 400) {
          if (error.details.name === 'INVALID_SCHEMA') {
            const formError = this.state.formError;
            const paths = getProp(error.details, 'payload.paths', []);
            for (const path of paths) {
              formError[path] = true;
            }
            this.setState({ formError: formError });
          } else if (error.details.name === 'INVALID_COMPANY_ROLE') {
            const formError = this.state.formError;
            formError['company_role'] = true;
            this.setState({ formError: formError });
          }
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Nesprávne vyplnená položka'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        } else if (error.response.status === 409) {
          if (error.details.name === 'USER_ALREADY_EXISTS') {
            this.props.dispatch(
              setAlert({
                type: 'warning',
                title: __('Email už existuje'),
                onConfirm: () => this.props.dispatch(setAlert(null)),
              }),
            );
          }
        } else {
          this.props.dispatch(
            setAlert({
              type: 'error',
              title: __('Pri ukladaní nastala neznáma chyba'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        }
      });
  }

  onSubmitToken(prop, ev, value) {
    if (!value) {
      return;
    }
    const arr = getProp(this.props.data, prop, []);
    arr.push({ label: value });

    // Vyprazdnime input
    this.setState(setProp(this.state, prop, ''));

    this.props.onChange(setProp(this.props.data, prop, arr));
  }

  onRemoveToken(prop, idx, item, ev) {
    const arr = getProp(this.state.user, prop, []);
    arr.splice(idx, 1);

    this.setState({ user: setProp(this.state.user, prop, arr) });
  }

  onChangeTokenSelect(prop, ev) {
    const arr = getProp(this.state.user, prop, []);
    if (ev.target.value) {
      arr.push({
        label: ev.target.options[ev.target.selectedIndex].text,
        id: ev.target.value,
      });
      this.setState({ user: setProp(this.state.user, prop, arr) });
    }
  }

  getObjectProp(prop, defaultValue = '') {
    return getProp(this.state.user, prop, defaultValue);
  }

  onChangeVenues(ev) {
    let checked = ev.target.checked;
    if (checked) {
      this.setState({ user: setProp(this.state.user, 'venues', null) });
    } else {
      this.setState({ user: setProp(this.state.user, 'venues', []) });
    }
  }

  onChangeCashRegisters(ev) {
    let checked = ev.target.checked;
    if (checked) {
      this.setState({ user: setProp(this.state.user, 'cash_registers', null) });
    } else {
      this.setState({ user: setProp(this.state.user, 'cash_registers', []) });
    }
  }

  render() {
    let title = __('Úprava profilu');
    if (!this.props.params.id) {
      if (this.props.params.role === 'owner') {
        title = __('Pridať administrátora');
      } else if (this.props.params.role === 'manager') {
        title = __('Pridať manažéra');
      } else if (this.props.params.role === 'employee') {
        title = __('Pridať zamestnanca');
      } else {
        title = '';
      }
    }

    return (
      <div>
        <h2 className="side-panel-title">{title}</h2>
        <hr />
        <Loader show={this.state.savingLoader}>
          <form className="form-horizontal" onSubmit={this.onSubmit.bind(this)}>
            <UserForm
              onChange={this.onChangeUser.bind(this)}
              user={this.state.user}
              isNew={this.props.params.id ? false : true}
              errors={this.state.formError}
              roles={this.state.roles}
            />

            <div
              className={cx({
                hidden: this.state.user.company_role === 'owner',
              })}
            >
              <div className="form-group">
                <label className="col-sm-4 control-label" htmlFor="venue">
                  {__('Prevádzka')}:{' '}
                </label>
                <div className="col-sm-4">
                  <select
                    id="venue"
                    className="form-control"
                    onChange={this.onChangeTokenSelect.bind(this, 'venues')}
                  >
                    <option value="">{__('Vyberte')}</option>
                    {this.state.venues
                      .filter(item => {
                        const items = getProp(this.state.user, 'venues');
                        for (const i of items) {
                          if (i.id === item._id) {
                            return false;
                          }
                        }
                        return true;
                      })
                      .map((item, idx) => {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div
                className={cx('form-group', {
                  hidden: this.getObjectProp('venues', []).length === 0,
                })}
              >
                <div className="col-sm-8 col-sm-offset-4">
                  <TokenGroup
                    items={this.getObjectProp('venues', [])}
                    onRemove={this.onRemoveToken.bind(this, 'venues')}
                  />
                </div>
              </div>
              <div
                className={cx('form-group', {
                  hidden: this.getObjectProp('venues', []).length > 0,
                })}
              >
                <div className={'text-center text-danger col-sm-12'}>
                  <em>
                    {__(
                      'Pozor, ak nezvolíte žiadne prevádzky, tento používateľ nebude mať k žiadnej prístup',
                    )}
                  </em>
                </div>
              </div>
            </div>

            <div
              className={cx({
                hidden: this.state.user.company_role === 'owner',
              })}
            >
              <div className="form-group">
                <label
                  className="col-sm-4 control-label"
                  htmlFor="cashregister"
                >
                  {__('Pokladňa')}:{' '}
                </label>
                <div className="col-sm-4">
                  <select
                    id="cashregister"
                    className="form-control"
                    onChange={this.onChangeTokenSelect.bind(
                      this,
                      'cash_registers',
                    )}
                  >
                    <option value="">{__('Vyberte')}</option>
                    {this.state.cashRegisters
                      .filter(item => {
                        const items = getProp(
                          this.state.user,
                          'cash_registers',
                        );
                        for (const i of items) {
                          if (i.id === item._id) {
                            return false;
                          }
                        }
                        return true;
                      })
                      .map((item, idx) => {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div
                className={cx('form-group', {
                  hidden: this.getObjectProp('cash_registers', []).length === 0,
                })}
              >
                <div className="col-sm-8 col-sm-offset-4">
                  <TokenGroup
                    items={this.getObjectProp('cash_registers', [])}
                    onRemove={this.onRemoveToken.bind(this, 'cash_registers')}
                  />
                </div>
              </div>
              <div
                className={cx('form-group', {
                  hidden: this.getObjectProp('cash_registers', []).length > 0,
                })}
              >
                <div className={'text-center text-danger col-sm-12'}>
                  <em>
                    {__(
                      'Pozor, ak nezvolíte žiadne pokladne, tento používateľ nebude mať k žiadnej prístup',
                    )}
                  </em>
                </div>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                {this.props.params.id ? __('Uložiť') : __('Vytvoriť')}
              </button>
            </div>
          </form>
        </Loader>
      </div>
    );
  }
}

Form.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({
  user: state.user,
}))(Form);
