import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Api from '../../service/Api';
import { getProp, __, setProp } from '../../utilities/common';
import { setAlert } from './../../actions';
import Loader from './../../component/loader';
import cx from 'classnames';

class FormAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saveOtaUpdateLoader: false,
      formData: {
        ecrVersionMin: '',
        icmVersionMin: '',
        file: null,
      },
      formErrors: {},
    };
  }

  onChangeFormDataProp = (prop, e) => {
    const formData = setProp(this.state.formData, prop, e.target.value);

    this.setState({
      formData,
    });
  };

  onChangeFile = e => {
    let file = null;

    if (e.target.files.length > 0) {
      file = e.target.files[0];
    }

    const formData = setProp(this.state.formData, 'file', file);

    this.setState({
      formData,
    });
  };

  getFormProp = (prop, defaultValue = '') => {
    const { formData } = this.state;

    return getProp(formData, prop, defaultValue);
  };

  onSubmit = e => {
    e.preventDefault();

    const { dispatch } = this.props;
    const { formData } = this.state;
    const versionPattern = /^\d+\.\d+\.\d+$/;
    const file = getProp(formData, 'file');
    const ecrVersionMin = getProp(formData, 'ecrVersionMin');
    const icmVersionMin = getProp(formData, 'icmVersionMin');
    const POSTobj = new FormData();

    let err = false;
    let formErrors = {};

    this.setState(
      {
        saveOtaUpdateLoader: true,
        formErrors,
      },
      () => {
        if (!file) {
          formErrors.otaupdate = __('Vyberte súbor pre upload');
          err = true;
        } else {
          POSTobj.append('otaupdate', file);
        }

        if (ecrVersionMin.trim() !== '') {
          if (!versionPattern.test(ecrVersionMin)) {
            formErrors.ecr_version_min = __(
              'Zadajte min. ECR verziu vo formáte 0.0.0',
            );
            err = true;
          } else {
            POSTobj.append('ecr_version_min', ecrVersionMin);
          }
        }

        if (icmVersionMin.trim() !== '') {
          if (!versionPattern.test(icmVersionMin)) {
            formErrors.icm_version_min = __(
              'Zadajte min. SM verziu vo formáte 0.0.0',
            );
            err = true;
          } else {
            POSTobj.append('icm_version_min', icmVersionMin);
          }
        }

        if (err) {
          this.setState({
            saveOtaUpdateLoader: false,
            formErrors,
          });

          if (formErrors) {
            for (let a in formErrors) {
              if (!formErrors.hasOwnProperty(a)) continue;

              dispatch(
                setAlert({
                  type: 'warning',
                  title: formErrors[a],
                  onConfirm: () => dispatch(setAlert(null)),
                }),
              );

              break;
            }
          }

          return;
        }

        Api.post(`/admin/otaupdates`, POSTobj, null)
          .then(response => {
            this.setState({
              saveOtaUpdateLoader: false,
            });

            this.props.onSuccess(true);
            this.context.router.push(this.props.returnUrl);
          })
          .catch(error => {
            this.setState({
              saveOtaUpdateLoader: false,
            });

            if (error.response.status === 400) {
              if (error.details.name === 'INVALID_SCHEMA') {
                const paths = getProp(error.details, 'payload.paths', []);

                for (const path of paths) {
                  const realt_path = path.split('.');

                  formErrors[realt_path[0]] = true;
                }

                this.setState({ formErrors });

                dispatch(
                  setAlert({
                    type: 'warning',
                    title: __('Nesprávne vyplnená položka'),
                    onConfirm: () => dispatch(setAlert(null)),
                  }),
                );
              } else if (error.details.name === 'BAD_REQUEST') {
                dispatch(
                  setAlert({
                    type: 'warning',
                    title: __('Vyberte správny súbor pre nahratie'),
                    onConfirm: () => dispatch(setAlert(null)),
                  }),
                );
              }
            } else {
              dispatch(
                setAlert({
                  type: 'error',
                  title: __('Pri ukladaní nastala neznáma chyba'),
                  onConfirm: () => dispatch(setAlert(null)),
                }),
              );
            }
          });
      },
    );
  };

  render() {
    const { saveOtaUpdateLoader, formErrors } = this.state;

    const printFileInfo = () => {
      const { formData } = this.state;
      const file = formData.file;

      if (file) {
        return (
          <div>
            {getProp(file, 'name')} ({getProp(file, 'size')}b)
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        <h2 className="side-panel-title">{__('Nová aktualizácia')}</h2>
        <hr />
        <Loader show={saveOtaUpdateLoader}>
          <form className="form-horizontal" onSubmit={this.onSubmit}>
            <fieldset className="fieldset">
              <div
                className={cx('form-group', {
                  'has-error': 'otaupdate' in formErrors,
                })}
              >
                <label className="col-xs-4 control-label">
                  {__('Aktualizačný súbor')}:
                </label>
                <div className="col-xs-8">
                  <input
                    type="file"
                    className="form-control"
                    style={{ border: 'none' }}
                    onChange={this.onChangeFile}
                  />
                  {printFileInfo()}
                </div>
              </div>
              <div
                className={cx('form-group', {
                  'has-error': 'ecr_version_min' in formErrors,
                })}
              >
                <label className="col-xs-4 control-label">
                  {__('Min. verzia ECR')}:
                </label>
                <div className="col-xs-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="0.0.0"
                    onChange={e => {
                      this.onChangeFormDataProp('ecrVersionMin', e);
                    }}
                    value={this.getFormProp('ecrVersionMin')}
                  />
                </div>
              </div>
              <div
                className={cx('form-group', {
                  'has-error': 'icm_version_min' in formErrors,
                })}
              >
                <label className="col-xs-4 control-label">
                  {__('Min. verzia SM')}:
                </label>
                <div className="col-xs-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="0.0.0"
                    onChange={e => {
                      this.onChangeFormDataProp('icmVersionMin', e);
                    }}
                    value={this.getFormProp('icmVersionMin')}
                  />
                </div>
              </div>
            </fieldset>
            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                {__('Odoslať')}
              </button>
            </div>
          </form>
        </Loader>
      </div>
    );
  }
}

FormAdd.contextTypes = {
  router: PropTypes.object,
};

FormAdd.defaultProps = {};

export default connect(state => ({}))(FormAdd);
