import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Api from '../../service/Api';
import { getProp, setProp, __ } from '../../utilities/common';
import { setAlert } from './../../actions';
import Loader from './../../component/loader';

class Form extends React.Component {
  constructor(props) {
    super(props);

    let openingHours = [];
    for (let i = 0; i < 7; i++) {
      openingHours.push(['', '', '', '']);
    }

    this.state = {
      editing: props.params.id ? true : false,
      venueId: props.params.id,
      venue: {
        address: {
          country: 'SK',
        },
      },
      loadDetailLoader: props.params.id ? true : false,
      savingLoader: false,
      openingHours: openingHours,
      venuesList: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.state.editing) {
      this.loadVenueDetail();
    }
  }

  onChangeVenueProp(prop, ev) {
    let venue = this.state.venue;
    venue = setProp(venue, prop, ev.target.value);
    this.setState({ venue: venue });
  }

  getVenueProp(prop, defaultValue = '') {
    return getProp(this.state.venue, prop, defaultValue);
  }

  onChangeOpeningHours(day, index, ev) {
    let openingHours = this.state.openingHours;
    openingHours[day][index] = ev.target.value;
    this.setState({ openingHours: openingHours });
  }

  getOpeningHours(day, index) {
    return this.state.openingHours[day][index];
  }

  loadVenueDetail() {
    Api.get(`/user/venues/${this.state.venueId}`)
      .then(response => {
        let newState = this.state;

        let venue = {
          name: response.name,
          supervisor: response.supervisor,
          phone: response.phone,
          address: response.address || { country: 'SK' },
          geolocation: response.geolocation || {},
          opening_hours: response.opening_hours || {},
        };

        if (response.opening_hours) {
          newState.openingHours = this.parseOpeningHours(
            response.opening_hours,
          );
        }

        newState.loadDetailLoader = false;
        newState.venue = venue;
        this.setState(newState);
      })
      .catch(response => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Nebolo možné načítať detail prevádzky'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
        this.setState({ loadDetailLoader: false });
      });
  }

  parseOpeningHours(opening_hours) {
    let openingHours = [];
    for (let i = 0; i < 7; i++) {
      let day = opening_hours[i];

      let from1 = '';
      let to1 = '';
      let from2 = '';
      let to2 = '';

      if (day.hours.length > 0) {
        from1 = day.hours[0].from;
        to1 = day.hours[0].to;
      }

      if (day.hours.length > 1) {
        from2 = day.hours[1].from;
        to2 = day.hours[1].to;
      }

      openingHours.push([from1, to1, from2, to2]);
    }
    return openingHours;
  }

  onSubmit(ev) {
    ev.preventDefault();

    let onSuccess = response => {
      this.setState({ savingLoader: false });
      this.props.dispatch(
        setAlert({
          type: 'success',
          title: __('Prevádzka bola úspešne uložená'),
          onConfirm: () => {
            this.props.dispatch(setAlert(null));
            this.props.onSuccess();
            this.context.router.push('/app/venue');
          },
        }),
      );
    };

    let onError = response => {
      this.setState({ savingLoader: false });
      this.props.dispatch(
        setAlert({
          type: 'error',
          title: __('Pri ukladaní prevádzky došlo k chybe'),
          onConfirm: () => {
            this.props.dispatch(setAlert(null));
          },
        }),
      );
    };

    let data = {};
    data.name = this.state.venue.name;
    data.supervisor = this.state.venue.supervisor;
    data.phone = this.state.venue.phone;

    data.opening_hours = [];
    for (let i = 0; i < 7; i++) {
      let hours = [];
      let dayName = '';

      switch (i) {
        case 0:
          dayName = 'Monday';
          break;
        case 1:
          dayName = 'Tuesday';
          break;
        case 2:
          dayName = 'Wednesday';
          break;
        case 3:
          dayName = 'Thursday';
          break;
        case 4:
          dayName = 'Friday';
          break;
        case 5:
          dayName = 'Saturday';
          break;
        case 6:
          dayName = 'Sunday';
          break;
        default:
          throw new Error('InvalidDayNumber');
      }

      if (this.state.openingHours[i][0] && this.state.openingHours[i][1]) {
        hours.push({
          from: this.state.openingHours[i][0],
          to: this.state.openingHours[i][1],
        });
      }

      if (this.state.openingHours[i][2] && this.state.openingHours[i][3]) {
        hours.push({
          from: this.state.openingHours[i][2],
          to: this.state.openingHours[i][3],
        });
      }

      data.opening_hours.push({
        day: dayName,
        hours: hours,
      });
    }

    let street = getProp(this.state.venue, 'address.street');
    let number = getProp(this.state.venue, 'address.number');
    let city = getProp(this.state.venue, 'address.city');
    let zip = getProp(this.state.venue, 'address.zip');
    let country = getProp(this.state.venue, 'address.country');

    var error = '';

    if (street || number || city || zip) {
      if (!(street && number && city && zip && country)) {
        error = __('V adrese musíte vyplniť všetky údaje');
      }
    }

    if (error !== '') {
      this.props.dispatch(
        setAlert({
          type: 'warning',
          title: error,
          onConfirm: () => {
            this.props.dispatch(setAlert(null));
          },
        }),
      );
      return;
    }

    this.setState({ savingLoader: true });

    if (street && number && city && zip && country) {
      data.address = {
        street: street,
        number: number,
        city: city,
        zip: zip,
        country: country,
      };
    }

    let lat = getProp(this.state.venue, 'geolocation.lat');
    let lon = getProp(this.state.venue, 'geolocation.lon');
    if (lat && lon) {
      data.geolocation = {
        lat: parseFloat(lat),
        lon: parseFloat(lon),
      };
    }

    if (this.state.editing) {
      Api.put(`/user/venues/${this.state.venueId}`, JSON.stringify(data))
        .then(onSuccess)
        .catch(onError);
    } else {
      Api.post('/user/venues', JSON.stringify(data))
        .then(onSuccess)
        .catch(onError);
    }
  }

  render() {
    let title = this.state.editing
      ? __('Detail prevádzky')
      : __('Nová prevádzka');
    let buttonText = this.state.editing ? __('Uložiť') : __('Pridať');

    let openingHoursInputsHtml = [];
    for (let i = 0; i < 7; i++) {
      let dayName = '';

      switch (i) {
        case 0:
          dayName = __('Pondelok');
          break;
        case 1:
          dayName = __('Utorok');
          break;
        case 2:
          dayName = __('Streda');
          break;
        case 3:
          dayName = __('Štvrtok');
          break;
        case 4:
          dayName = __('Piatok');
          break;
        case 5:
          dayName = __('Sobota');
          break;
        case 6:
          dayName = __('Nedeľa');
          break;
        default:
          throw new Error('InvalidDayNumber');
      }

      openingHoursInputsHtml.push(
        <div key={i}>
          <div className="form-group">
            <label className="col-sm-4 control-label">{dayName}: </label>
            <div className="col-xs-2">
              <input
                type="text"
                className="form-control"
                placeholder={__('Od')}
                onChange={this.onChangeOpeningHours.bind(this, i, 0)}
                value={this.getOpeningHours(i, 0)}
                required={false}
              />
            </div>
            <div className="col-xs-2">
              <input
                type="text"
                className="form-control"
                placeholder={__('Do')}
                onChange={this.onChangeOpeningHours.bind(this, i, 1)}
                value={this.getOpeningHours(i, 1)}
                required={false}
              />
            </div>
            <div className="col-xs-2">
              <input
                type="text"
                className="form-control"
                placeholder={__('Od')}
                onChange={this.onChangeOpeningHours.bind(this, i, 2)}
                value={this.getOpeningHours(i, 2)}
                required={false}
              />
            </div>
            <div className="col-xs-2">
              <input
                type="text"
                className="form-control"
                placeholder={__('Do')}
                onChange={this.onChangeOpeningHours.bind(this, i, 3)}
                value={this.getOpeningHours(i, 3)}
                required={false}
              />
            </div>
          </div>
        </div>,
      );
    }

    return (
      <div>
        <h2 className="side-panel-title">{title}</h2>
        <hr />
        <Loader show={this.state.savingLoader || this.state.loadDetailLoader}>
          <form className="form-horizontal" onSubmit={this.onSubmit}>
            <fieldset className="fieldset">
              <legend>{__('Údaje prevádzky')}</legend>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Názov')}:{' '}
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('Názov')}
                    onChange={this.onChangeVenueProp.bind(this, 'name')}
                    value={this.getVenueProp('name')}
                    required={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Zodpovedný vedúci')}:{' '}
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('(nepovinné)')}
                    onChange={this.onChangeVenueProp.bind(this, 'supervisor')}
                    value={this.getVenueProp('supervisor')}
                    required={false}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Tel. kontakt')}:{' '}
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('(nepovinné)')}
                    onChange={this.onChangeVenueProp.bind(this, 'phone')}
                    value={this.getVenueProp('phone')}
                    required={false}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Zemepisná šírka')}:{' '}
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={
                      __('Zemepisná šírka') + ' ' + __('(nepovinné)')
                    }
                    onChange={this.onChangeVenueProp.bind(
                      this,
                      'geolocation.lat',
                    )}
                    value={this.getVenueProp('geolocation.lat')}
                    required={false}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Zemepisná dĺžka')}:{' '}
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={
                      __('Zemepisná dĺžka') + ' ' + __('(nepovinné)')
                    }
                    onChange={this.onChangeVenueProp.bind(
                      this,
                      'geolocation.lon',
                    )}
                    value={this.getVenueProp('geolocation.lon')}
                    required={false}
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="fieldset">
              <legend>{__('Adresa') + ' ' + __('(nepovinné)')}</legend>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Ulica')}:{' '}
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('Ulica')}
                    onChange={this.onChangeVenueProp.bind(
                      this,
                      'address.street',
                    )}
                    value={this.getVenueProp('address.street')}
                    required={false}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Číslo')}:{' '}
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('Číslo')}
                    onChange={this.onChangeVenueProp.bind(
                      this,
                      'address.number',
                    )}
                    value={this.getVenueProp('address.number')}
                    required={false}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Mesto')}:{' '}
                </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('Mesto')}
                    onChange={this.onChangeVenueProp.bind(this, 'address.city')}
                    value={this.getVenueProp('address.city')}
                    required={false}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">{__('PSČ')}: </label>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={__('PSČ')}
                    onChange={this.onChangeVenueProp.bind(this, 'address.zip')}
                    value={this.getVenueProp('address.zip')}
                    required={false}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-sm-4 control-label">
                  {__('Krajina')}:{' '}
                </label>
                <div className="col-sm-4">
                  <select
                    className="form-control"
                    onChange={this.onChangeVenueProp.bind(
                      this,
                      'address.country',
                    )}
                    value={this.getVenueProp('address.country')}
                  >
                    <option value="SK">{__('Slovensko')}</option>
                    <option value="CZ">{__('Česká republika')}</option>
                  </select>
                </div>
              </div>
            </fieldset>
            <fieldset className="fieldset">
              <legend>
                {__('Otváracie hodiny') + ' ' + __('(nepovinné)')}
              </legend>
              {openingHoursInputsHtml}
            </fieldset>
            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                {buttonText}
              </button>
            </div>
          </form>
        </Loader>
      </div>
    );
  }
}

Form.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({}))(Form);
