import moment from 'moment';
import React from 'react';
import Datepicker from '../component/form/datepicker';
import TextInput from '../component/form/textInput';
import { getProp, setProp, __ } from '../utilities/common';
import Content from './../component/content';
import Checkbox from './../component/form/checkbox';
import RadioButton from './../component/form/radioButton';
import Icon from './../component/icon';
import MainHeader from './../component/mainHeader';

class Framework extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      radio: null,
      test: 'Testovaci input',
    };
  }

  onChangeDate(date) {
    this.setState({ date: date });
  }

  onChangeRadio(ev) {
    this.setState({ radio: ev.target.value });
  }

  onChangeCustomProp(prop, ev) {
    let state = this.state;
    state = setProp(state, prop, ev.target.value);
    this.setState(state);
  }

  getCustomProp(prop, defaultValue = '') {
    return getProp(this.state, prop, defaultValue);
  }

  render() {
    return (
      <div id="content-wrapper">
        <MainHeader title={__('UI Framework')} progress={65} />
        <Content>
          <div className="row">
            <div className="col-md-4">
              <div className="panel panel-default">
                <div className="panel-heading">Formulár - Basic</div>
                <div className="panel-body">
                  <form>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Password"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputFile">File input</label>
                      <input type="file" id="exampleInputFile" />
                      <p className="help-block">
                        Example block-level help text here.
                      </p>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" />
                        Check me out
                      </label>
                    </div>
                    <button type="submit" className="btn btn-default">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="panel panel-default">
                <div className="panel-heading">Formulár - Basic</div>
                <div className="panel-body">
                  <form>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Password"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputFile">File input</label>
                      <input type="file" id="exampleInputFile" />
                      <p className="help-block">
                        Example block-level help text here.
                      </p>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" />
                        Check me out
                      </label>
                    </div>
                    <button type="submit" className="btn btn-default">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="panel panel-default">
                <div className="panel-heading">Formulár - Basic</div>
                <div className="panel-body">
                  <form>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Password"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputFile">File input</label>
                      <input type="file" id="exampleInputFile" />
                      <p className="help-block">
                        Example block-level help text here.
                      </p>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" />
                        Check me out
                      </label>
                    </div>
                    <button type="submit" className="btn btn-default">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="panel panel-default">
                <div className="panel-heading">Formulár - Basic</div>
                <div className="panel-body">
                  <form>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Password"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputFile">File input</label>
                      <input type="file" id="exampleInputFile" />
                      <p className="help-block">
                        Example block-level help text here.
                      </p>
                    </div>
                    <div className="checkbox">
                      <label>
                        <input type="checkbox" />
                        Check me out
                      </label>
                    </div>
                    <button type="submit" className="btn btn-default">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="panel panel-default">
                <div className="panel-heading">Formulár - Horizontal</div>
                <div className="panel-body">
                  <form className="form-horizontal">
                    <fieldset className="fieldset fieldset-root">
                      <legend>Filter 1</legend>
                      <div className="form-group">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-2 control-label"
                        >
                          Rozsah:
                        </label>
                        <div className="col-sm-5">
                          <Datepicker
                            selected={this.state.date}
                            onChange={this.onChangeDate.bind(this)}
                            label="Dátum od"
                          />
                        </div>
                        <div className="col-sm-5">
                          <Datepicker
                            selected={this.state.date}
                            onChange={this.onChangeDate.bind(this)}
                            label="Dátum do"
                          />
                        </div>
                      </div>
                      <fieldset className="fieldset">
                        <legend>Prevádzka</legend>
                        <div className="form-group">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-2 control-label"
                          >
                            Kategória:
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="email"
                              className="form-control"
                              id="inputEmail3"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-xs-6">
                            <TextInput
                              value={this.getCustomProp('test')}
                              onChange={this.onChangeCustomProp.bind(
                                this,
                                'test',
                              )}
                              label="Prevádzka"
                            />
                          </div>
                          <div className="col-xs-6">
                            <TextInput
                              value={this.getCustomProp('test2')}
                              onChange={this.onChangeCustomProp.bind(
                                this,
                                'test2',
                              )}
                              label="Pokladňa"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-xs-6">
                            <TextInput
                              value={this.getCustomProp('test3')}
                              onChange={this.onChangeCustomProp.bind(
                                this,
                                'test3',
                              )}
                              label="Kategória"
                            />
                          </div>
                          <div className="col-xs-6">
                            <TextInput
                              value={this.getCustomProp('test4')}
                              onChange={this.onChangeCustomProp.bind(
                                this,
                                'test4',
                              )}
                              label="Operátor"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="inputPassword3"
                            className="col-sm-2 control-label"
                          >
                            Názov grafu:
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control"
                              id="inputPassword3"
                              placeholder="Password"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-5 col-md-offset-2">
                            <Datepicker />
                          </div>
                          <div className="col-sm-5">
                            <Datepicker
                              selected={this.state.date}
                              onChange={this.onChangeDate.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="radio1"
                            className="col-sm-2 control-label"
                          >
                            Kategória:
                          </label>
                          <div className="col-sm-10">
                            <RadioButton
                              id="radio1"
                              value={this.state.radio}
                              onChange={this.onChangeRadio.bind(this)}
                              options={[
                                {
                                  value: 'val1',
                                  label: 'Radio 1',
                                },
                                { value: 'val2', label: 'Radio 2' },
                                {
                                  value: 'val3',
                                  label: 'Option 3',
                                },
                              ]}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="inputPassword4"
                            className="col-sm-2 control-label"
                          >
                            Názov grafu:
                          </label>
                          <div className="col-sm-10">
                            <select
                              id="inputPassword4"
                              className="form-control"
                            >
                              <option>123</option>
                              <option>abcssdf</option>
                              <option>123</option>
                              <option>123</option>
                              <option>123</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-10">
                            <div className="checkbox">
                              <Checkbox id="chk" />
                              <label htmlFor="chk">Remember me</label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-10">
                            <div className="checkbox">
                              <Checkbox id="chk2" />
                              <label htmlFor="chk2">Dont remember me</label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-10">
                            <span className="checkbox-inline">
                              <Checkbox id="chk3" />
                              <label htmlFor="chk3">1</label>
                            </span>
                            <span className="checkbox-inline">
                              <Checkbox id="chk4" />
                              <label htmlFor="chk4">2</label>
                            </span>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset className="fieldset">
                        <legend>Prevádzka</legend>
                        <div className="form-group">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-2 control-label"
                          >
                            Kategória:
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="email"
                              className="form-control"
                              id="inputEmail3"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="inputPassword3"
                            className="col-sm-2 control-label"
                          >
                            Názov grafu:
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control"
                              id="inputPassword3"
                              placeholder="Password"
                            />
                          </div>
                        </div>
                      </fieldset>
                    </fieldset>

                    <fieldset className="fieldset fieldset-root">
                      <legend>Filter 2</legend>
                      <fieldset className="fieldset">
                        <legend>Prevádzka</legend>
                        <div className="form-group">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-2 control-label"
                          >
                            Kategória:
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="email"
                              className="form-control"
                              id="inputEmail3"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="inputPassword3"
                            className="col-sm-2 control-label"
                          >
                            Názov grafu:
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control"
                              id="inputPassword3"
                              placeholder="Password"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-5 col-md-offset-2">
                            <Datepicker />
                          </div>
                          <div className="col-sm-5">
                            <Datepicker
                              selected={this.state.date}
                              onChange={this.onChangeDate.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="inputPassword4"
                            className="col-sm-2 control-label"
                          >
                            Názov grafu:
                          </label>
                          <div className="col-sm-10">
                            <select
                              id="inputPassword4"
                              className="form-control"
                            >
                              <option>123</option>
                              <option>abcssdf</option>
                              <option>123</option>
                              <option>123</option>
                              <option>123</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="col-sm-offset-2 col-sm-10">
                            <div className="checkbox">
                              <label>
                                <input type="checkbox" />
                                Remember me
                              </label>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset className="fieldset">
                        <legend>Prevádzka</legend>
                        <div className="form-group">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-2 control-label"
                          >
                            Kategória:
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="email"
                              className="form-control"
                              id="inputEmail3"
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            htmlFor="inputPassword3"
                            className="col-sm-2 control-label"
                          >
                            Názov grafu:
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control"
                              id="inputPassword3"
                              placeholder="Password"
                            />
                          </div>
                        </div>
                      </fieldset>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-default">
                <div className="panel-heading">Btns</div>
                <div className="panel-body">
                  <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button
                        type="submit"
                        className="btn btn-default btn-sm btn-circle"
                      >
                        <Icon name="speed" />
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary btn-sm btn-circle"
                      >
                        <Icon name="speed" />
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary-o btn-sm btn-circle"
                      >
                        <Icon name="speed" />
                      </button>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button
                        type="submit"
                        className="btn btn-default btn-circle"
                      >
                        <Icon name="speed" />
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary btn-circle"
                      >
                        <Icon name="speed" />
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary-o btn-circle"
                      >
                        <Icon name="speed" />
                      </button>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button
                        type="submit"
                        className="btn btn-default btn-lg btn-circle"
                      >
                        <Icon name="speed" />
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-circle"
                      >
                        <Icon name="speed" />
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary-o btn-lg btn-circle"
                      >
                        <Icon name="speed" />
                      </button>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button type="submit" className="btn btn-default btn-sm">
                        <Icon name="speed" />
                        Sign in
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-default btn-sm">
                        Sign in
                        <Icon name="speed" />
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-default btn-sm">
                        Sign in
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-primary btn-sm">
                        <Icon name="speed" />
                        Sign in
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-primary btn-sm">
                        Sign in
                        <Icon name="speed" />
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-primary btn-sm">
                        Sign in
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary-o btn-sm"
                      >
                        <Icon name="speed" />
                        Sign in
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary-o btn-sm"
                      >
                        Sign in
                        <Icon name="speed" />
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary-o btn-sm"
                      >
                        Sign in
                      </button>
                      &nbsp;
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button type="submit" className="btn btn-default">
                        <Icon name="speed" />
                        Sign in
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-default">
                        Sign in
                        <Icon name="speed" />
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-default">
                        Sign in
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-primary">
                        <Icon name="speed" />
                        Sign in
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-primary">
                        Sign in
                        <Icon name="speed" />
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-primary">
                        Sign in
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-primary-o">
                        <Icon name="speed" />
                        Sign in
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-primary-o">
                        Sign in
                        <Icon name="speed" className="icon-push-right" />
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-primary-o">
                        Sign in
                      </button>
                      &nbsp;
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-sm-offset-2 col-sm-10">
                      <button type="submit" className="btn btn-default btn-lg">
                        <Icon name="speed" className="icon-push-left" />
                        Sign in
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-default btn-lg">
                        Sign in
                        <Icon name="speed" className="icon-push-right" />
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-default btn-lg">
                        Sign in{' '}
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-primary btn-lg">
                        <Icon name="speed" className="icon-push-left" />
                        Sign in
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-primary btn-lg">
                        Sign in
                        <Icon name="speed" className="icon-push-right" />
                      </button>
                      &nbsp;
                      <button type="submit" className="btn btn-primary btn-lg">
                        Sign in
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary-o btn-lg"
                      >
                        <Icon name="speed" className="icon-push-left" />
                        Sign in
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary-o btn-lg"
                      >
                        Sign in
                        <Icon name="speed" className="icon-push-right" />
                      </button>
                      &nbsp;
                      <button
                        type="submit"
                        className="btn btn-primary-o btn-lg"
                      >
                        Sign in
                      </button>
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Firma</th>
                  <th className="text-center">Prevádzky</th>
                  <th className="text-center">Pokladne</th>
                  <th className="text-center">Manažéri</th>
                  <th className="text-center">Zamestnanci</th>
                  <th>Licencia</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-lg">Fero a syn</td>
                  <td className="text-center">43</td>
                  <td className="text-center">33</td>
                  <td className="text-center">10</td>
                  <td className="text-center">10</td>
                  <td>Basic</td>
                </tr>
                <tr>
                  <td className="font-lg">bart.sk s.r.o.</td>
                  <td className="text-center">5</td>
                  <td className="text-center">466</td>
                  <td className="text-center">14</td>
                  <td className="text-center">4</td>
                  <td>Premium</td>
                </tr>
                <tr>
                  <td className="font-lg">Google.com</td>
                  <td className="text-center">4</td>
                  <td className="text-center">7</td>
                  <td className="text-center">99</td>
                  <td className="text-center">123</td>
                  <td>Basic</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="table-responsive">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Firma</th>
                  <th className="text-center">Prevádzky</th>
                  <th className="text-center">Pokladne</th>
                  <th className="text-center">Manažéri</th>
                  <th className="text-center">Zamestnanci</th>
                  <th>Licencia</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="font-lg">Fero a syn</td>
                  <td className="text-center">43</td>
                  <td className="text-center">33</td>
                  <td className="text-center">10</td>
                  <td className="text-center">10</td>
                  <td>Basic</td>
                </tr>
                <tr>
                  <td className="font-lg">bart.sk s.r.o.</td>
                  <td className="text-center">5</td>
                  <td className="text-center">466</td>
                  <td className="text-center">14</td>
                  <td className="text-center">4</td>
                  <td>Premium</td>
                </tr>
                <tr>
                  <td className="font-lg">Google.com</td>
                  <td className="text-center">4</td>
                  <td className="text-center">7</td>
                  <td className="text-center">99</td>
                  <td className="text-center">123</td>
                  <td>Basic</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h1>{__('Používatelia')}</h1>
          <h2>{__('Používatelia')}</h2>
          <h3>{__('Používatelia')}</h3>

          <div className="row">
            <div className="col-md-4">
              <div className="alert alert-success">
                <Icon className="icon-main" name="checkmark" />
                <strong>Super!</strong>
                Podarilo sa to
              </div>
            </div>
            <div className="col-md-4">
              <div className="alert alert-warning">
                <strong>Pozor!</strong>
                Mozno sa nieco nepodarilo
              </div>
            </div>
            <div className="col-md-4">
              <div className="alert alert-danger">
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <Icon name="multiply" />
                </button>
                <Icon className="icon-main" name="cancel" />
                <strong>Chyba!</strong>
                Nec dis in nunc odio diam duis cras et augue, aliquet risus
                integer aliquet? Augue. Porta in dapibus? Sociis. Nec phasellus
                mattis tortor montes, pellentesque arcu porta turpis, etiam ac
                mid risus! Nisi vut cursus, ridiculus, proin porttitor
                adipiscing elementum. Sit magna! Et augue integer habitasse!
                Nunc nascetur, ut cum? Lorem egestas turpis dis. Etiam augue
                velit vel! Ridiculus, nec! Mattis cursus cursus cum rhoncus
                pulvinar? Tincidunt rhoncus! Augue amet in enim, montes arcu,
                nunc, dis. Integer augue! Cursus nisi. Est enim adipiscing sit.
                Porttitor integer ridiculus dapibus massa pulvinar, parturient,
                sed in in! Ultricies quis, arcu sit! Purus adipiscing sit duis,
                rhoncus hac porttitor enim urna purus quis tincidunt adipiscing
                ac, a tempor lundium natoque nec placerat ultricies augue.
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="panel panel-default">
                <div className="panel-heading panel-separated">
                  Panel heading without title
                </div>
                <div className="panel-body panel-separated">
                  <Icon name="pencil" />
                  Basic panel exampleBasic panel example
                  <hr />
                  Basic panel exampleBasic panel exampleBasic panel exampleBasic
                  panel exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel example
                </div>
                <div className="panel-body panel-separated">
                  Basic panel exampleBasic panel exampleBasic panel exampleBasic
                  panel exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel example
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="panel panel-secondary">
                <div className="panel-heading panel-separated">
                  Panel heading without title
                </div>
                <div className="panel-body panel-separated">
                  Basic panel exampleBasic panel exampleBasic panel exampleBasic
                  panel exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel
                  <hr />
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic
                  <hr />
                  panel exampleBasic panel example
                </div>
                <div className="panel-body panel-separated">
                  Basic panel exampleBasic panel exampleBasic panel exampleBasic
                  panel exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel example
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="panel panel-default">
                <div className="panel-heading panel-separated">
                  Panel heading without title
                </div>
                <div className="panel-body panel-separated">
                  Basic panel exampleBasic panel exampleBasic panel exampleBasic
                  panel exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel example
                </div>
                <div className="panel-body panel-separated">
                  Basic panel exampleBasic panel exampleBasic panel exampleBasic
                  panel exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel example
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="panel panel-default">
                <div className="panel-body">
                  Basic panel exampleBasic panel exampleBasic panel exampleBasic
                  panel exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel exampleBasic panel
                  exampleBasic panel exampleBasic panel example
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="panel panel-default">
                <div className="panel-heading">Panel heading without title</div>
                <div className="panel-body">SAgsfdg dfgh fsdfsd</div>
                <div className="panel-body">
                  Nisi cras nec urna tortor vut augue mauris in vel elit
                  integer, scelerisque aenean ultricies, parturient adipiscing
                  augue velit sociis placerat lectus vel quis tempor a ultrices
                  nisi etiam cum dapibus cum et in massa, dignissim, in
                  facilisis. Rhoncus eros duis auctor tristique elementum
                  placerat ut augue parturient, dignissim, magna sagittis vut
                  sed amet habitasse, dolor? Ultrices diam? Magna dapibus. Arcu
                  amet sed, penatibus vut cum parturient tincidunt proin lorem,
                  tortor placerat, enim aliquet dis? Eros ut! Elit a mus, magna
                  natoque sed elit? Tempor magna placerat amet montes dolor?
                  Tempor urna aenean dictumst vut aliquet. Elit sed vut augue
                  augue ridiculus proin elementum. Cursus, mus! Ultrices in?
                  Porttitor lectus? Odio, nunc, sociis adipiscing, integer enim
                  ridiculus, eu penatibus in.
                </div>
              </div>
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

export default Framework;
