import cx from 'classnames';
import React from 'react';
import { guid } from './../../utilities/common';

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      controlled: false,
    };

    if (typeof props.focused !== 'undefined') {
      this.state.controlled = true;
      this.state.focused = props.focused;
    }

    this.id = guid();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.state.controlled && newProps.focused !== this.props.focused) {
      this.setState({ focused: newProps.focused });
    }
  }

  toggleClass(e) {
    this.setState({
      focused: !this.state.focused,
    });
  }

  render() {
    const rest = Object.assign({}, this.props);
    delete rest.focused;

    return (
      <span
        className={cx('material-input', this.props.className, {
          'focused-input': this.state.focused,
          focused: this.state.focused || this.props.value.length > 0,
        })}
      >
        <label className="control-label" htmlFor={this.id}>
          {this.props.label ? this.props.label : this.props.placeholder}
        </label>
        <input
          type="text"
          id={this.id}
          value={this.props.value}
          onChange={this.props.onChange.bind(this)}
          onFocus={this.toggleClass.bind(this)}
          onBlur={this.toggleClass.bind(this)}
          {...rest}
          className="form-control"
        />
      </span>
    );
  }
}

TextInput.defaultProps = {
  onChange: () => {},
  value: '',
  label: null,
  placeholder: '',
  className: '',
};

export default TextInput;
