import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Api from '../../service/Api';
import { getProp, setProp, __ } from '../../utilities/common';
import { setAlert } from './../../actions';
import Loader from '../../component/loader';
import { locale } from '../../locale';

class FormAdd extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      user: {
        company: {
          address: {
            country: 'SK',
          },
        },
      },
      isFetching: false,
      formError: {},
      agree: false,
    };
    this.context = context;
  }

  onChangeUserProp = (prop, ev) => {
    const { target: { value } } = ev;
    this.setState(state => {
      const { user, formError } = state;
      if (prop in formError) {
        delete formError[prop];
      }

      return {
        user: setProp(user, prop, value),
      }
    });
  }

  getUserProp = (prop, defaultValue = '') => {
    return getProp(this.state.user, prop, defaultValue);
  }

  onSubmit = async (ev) => {
    ev.preventDefault();

    if (!this.state.agree) {
      this.props.dispatch(
        setAlert({
          type: 'warning',
          title: __('Musíte súhlasiť s obchodnými podmienkami'),
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
      return;
    }

    this.setState({ isFetching: true });
    let { user } = this.state;
    if (user.phones) {
      user.phones = [user.phones];
    } else {
      user.phones = [];
    }
    try {
      await Api.post('/admin/partners', JSON.stringify(user));
      this.props.dispatch(
        setAlert({
          type: 'success',
          title: __('Registrácia úspešná'),
          onConfirm: () => {
            this.props.dispatch(setAlert(null));
            this.props.onSuccess();
            this.props.onClose();
          },
        }),
      );
      this.setState({ isFetching: false });
    }
    catch (error) {
      this.setState({ isFetching: false });
      if (error.response.status === 400) {
        let msg = '';
        if (error.details.name === 'INVALID_SCHEMA') {
          const formError = this.state.formError;
          const paths = getProp(error.details, 'payload.paths', []);
          for (const path of paths) {
            formError[path] = true;
          }
          this.setState({ formError });
          msg = __('Nesprávne vyplnená položka');
        } else if (error.details.name === 'INVALID_VAT') {
          msg = __('Zadané IčDPH nie je platné');
        } else if (error.details.name === 'VAT_VALIDATION_FAILED') {
          msg = __('Nepodarilo sa overiť IčDPH, skúste registráciu neskôr');
        } else if (error.details.name === 'INVALID_VAT_COMPANY_NAME') {
          msg =
            __('Prekontrolujte prosím názov Vašej spoločnosti. Správne: ') +
            getProp(error.details, 'payload.company_name');
        }

        if (msg) {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: msg,
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        }
      } else if (error.response.status === 409) {
        if (error.details.name === 'USER_ALREADY_EXISTS') {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Email už existuje'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        } else if (error.details.name === 'COMPANY_ALREADY_EXISTS') {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('FirmBASE_URLa s týmto IČOm už existuje'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        } else if (error.details.name === 'PARTNER_ID_ALREADY_EXISTS') {
          this.props.dispatch(
            setAlert({
              type: 'warning',
              title: __('Firma s týmto partnerským id už existuje'),
              onConfirm: () => this.props.dispatch(setAlert(null)),
            }),
          );
        }
      } else {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Pri registrácii nastala neznáma chyba'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
      }
    }
  }

  getFormGroupInput(name, label, inputProps) {
    return (
      <div
        className={cx('form-group', {
          'has-error': name in this.state.formError,
        })}
      >
        <label htmlFor={name}>{label}</label>
        <input
          id={name}
          type="text"
          name={name}
          className="form-control"
          onChange={(e) => this.onChangeUserProp(name, e)}
          value={this.getUserProp(name)}
          {...inputProps}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        <h2 className="side-panel-title">{__('Registrácia')}</h2>
        <Loader show={this.state.isFetching}>
          <form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-sm-12">
                <fieldset className="fieldset fieldset-root">
                  <legend>{__('Údaje o osobe')}</legend>
                  {this.getFormGroupInput('forename', __('Meno'), {
                    required: true,
                  })}
                  {this.getFormGroupInput('surname', __('Priezvisko'), {
                    required: true,
                  })}
                  {this.getFormGroupInput('email', __('Email'), {
                    required: true,
                  })}
                  {this.getFormGroupInput('password', __('Heslo'), {
                    placeholder: __('nepovinné'),
                  })}
                  {this.getFormGroupInput('phones', __('Telefón'), {
                    placeholder: __('nepovinné'),
                  })}
                </fieldset>
              </div>
              <div className="col-sm-12">
                <fieldset className="fieldset fieldset-root">
                  <legend>{__('Údaje o firme')}</legend>
                  {this.getFormGroupInput(
                    'company.company_name',
                    __('Názov'),
                    { required: true },
                  )}
                  {this.getFormGroupInput('company.regnr', __('IČO'), {
                    required: true,
                  })}
                  {this.getFormGroupInput('company.taxid', __('DIČ'), {
                    placeholder: __('nepovinné'),
                  })}
                  {this.getFormGroupInput('company.vatid', __('IčDPH'), {
                    placeholder: __('nepovinné'),
                  })}
                  {this.getFormGroupInput('company.partner_id', __('Partner ID (formát YY, použite veľké pismena a číslice)'), {
                    required: true,
                    pattern: '^[A-Z0-9]{2}$',
                    placeholder: __('A0'),
                  })}
                </fieldset>
              </div>
              <div className="col-sm-12">
                <fieldset className="fieldset fieldset-root">
                  <legend>{__('Adresa firmy')}</legend>
                  {this.getFormGroupInput(
                    'company.address.street',
                    __('Ulica'),
                    { required: true },
                  )}
                  {this.getFormGroupInput(
                    'company.address.number',
                    __('Číslo'),
                    { required: true },
                  )}
                  {this.getFormGroupInput(
                    'company.address.city',
                    __('Mesto'),
                    { required: true },
                  )}
                  {this.getFormGroupInput(
                    'company.address.zip',
                    __('PSČ'),
                    { required: true },
                  )}
                  <div
                    className={cx('form-group', {
                      'has-error':
                        'company.address.country' in this.state.formError,
                    })}
                  >
                    <label htmlFor="company.address.country">
                      {__('Krajina')}
                    </label>
                    <select
                      id="company.address.country"
                      name='company.address.country'
                      className="form-control"
                      onChange={(e) => this.onChangeUserProp('company.address.country', e)}
                      value={this.getUserProp('company.address.country')}
                    >
                      <option value="SK">{__('Slovensko')}</option>
                      <option value="CZ">{__('Česká republika')}</option>
                    </select>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="text-center register-checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={this.state.agree}
                  onChange={ev =>
                    this.setState({ agree: ev.target.checked })
                  }
                  style={{marginRight: '4px', marginTop: 0}}
                />
                {__('Odoslaním registrácie súhlasím s')}
              </label>
              <a
                href={`${process.env.REACT_APP_API_BASE_URL}/docs/vop/${
                  locale.lang
                  }`}
                target="_blank"
                rel="noopener noreferrer"
                style={{marginLeft: '4px'}}
              >
                {__('obchodnými podmienkami')}
              </a>
            </div>
            <br />
            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                {__('Registrovať')}
              </button>
            </div>
          </form>
        </Loader>
      </div>
    );
  }
}

FormAdd.contextTypes = {
  router: PropTypes.object,
};

export default connect()(FormAdd);
