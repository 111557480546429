import cx from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Api from '../../service/Api';
import { getProp, __ } from '../../utilities/common';
import { setAlert } from './../../actions';
import Content from './../../component/content';
import DataFilter from './../../component/dataFilter';
import DataTable from './../../component/dataTable';
import FloatingActionButton from './../../component/floatingActionButton';
import Icon from './../../component/icon';
import Loader from './../../component/loader';
import MainHeader from './../../component/mainHeader';
import ProtectedContent from './../../component/protectedContent';
import SidePanel from './../../component/sidePanel';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getCashRegisterLoader: false,
      cashRegistersList: [],
      venuesListAssoc: {},
    };
  }

  componentDidMount() {
    Api.get('/user/venues').then(response => {
      const assoc = {};
      for (let venue of response.venues) {
        assoc[venue._id] = venue;
      }
      this.setState({ venuesListAssoc: assoc });
      this.loadCashRegisters();
    });
  }

  onClickDeletecashRegister(cashRegister) {
    this.props.dispatch(
      setAlert({
        type: 'warning',
        title: __('Ste si istý?'),
        confirmButtonColor: '#DD6B55',
        cancelButtonColor: '#d33',
        showCancelButton: true,
        confirmButtonText: __('Áno, zmazať'),
        cancelButtonText: __('Nie, zrušiť'),
        buttonsStyling: false,
        text: __("Naozaj chcete zmazať pokladňu '%(name)s'?").format({
          name: cashRegister._id,
        }),
        onConfirm: () => {
          this.deleteCashRegister(cashRegister._id);
        },
        onCancel: () => this.props.dispatch(setAlert(null)),
      }),
    );
  }

  deleteCashRegister(cashRegisterId) {
    this.setState({ getCashRegisterLoader: true });
    Api.delete(`/user/cash-registers/${cashRegisterId}`)
      .then(response => {
        // ak bolo zmazanie uspesne, tak refreshneme zoznam cashRegisters
        this.props.dispatch(
          setAlert({
            type: 'success',
            title: __('Pokladňa bola zmazaná'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
        this.loadCashRegisters();
      })
      .catch(response => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Pri mazaní pokladne došlo k chybe'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
        this.setState({ getCashRegisterLoader: false });
      });
  }

  loadCashRegisters() {
    // nacitame zoznam adries
    this.setState({ getCashRegisterLoader: true });
    Api.get('/user/cash-registers')
      .then(response => {
        this.setState({
          getCashRegisterLoader: false,
          cashRegistersList: response.cashregisters,
        });
      })
      .catch(response => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Nebolo možné načítať zoznam pokladní'),
            onConfirm: () => this.props.dispatch(setAlert(null)),
          }),
        );
        this.setState({ savingLoader: false, getCashRegisterLoader: false });
      });
  }

  render() {
    let onSuccessSidePanel = () => {
      this.loadCashRegisters();
    };

    if (this.props.main) {
      return (
        <div id="content-wrapper">
          {this.props.main}
          <SidePanel
            onSuccess={onSuccessSidePanel}
            returnUrl="/app/cash-register"
          >
            {this.props.sidepanel}
          </SidePanel>
        </div>
      );
    } else if (this.props.children) {
      return this.props.children;
    } else {
      // vytvorime si data pre search
      const searchData = this.state.cashRegistersList.map(item => {
        return {
          _id: item._id,
          name: getProp(item, 'name', '-'),
          venue: getProp(this.state.venuesListAssoc[item.venue_id], 'name'),
        };
      });
      return (
        <div id="content-wrapper">
          <MainHeader
            title={
              this.props.headerText
                ? this.props.headerText
                : __('Zoznam pokladní')
            }
            progress={100}
          />
          <Content>
            <Loader show={this.state.getCashRegisterLoader}>
              <DataFilter data={searchData}>
                {items => {
                  return (
                    <div>
                      <DataTable
                        cols={[__('JKP'), __('Názov'), __('Prevádzka'), '']}
                        rows={items.map(i => {
                          let item = {};
                          for (let c of this.state.cashRegistersList) {
                            if (c._id === i._id) {
                              item = c;
                              break;
                            }
                          }
                          return [
                            this.props.remoteAccess ? (
                              <Link to={`/app/remote-access/${item._id}`}>
                                {item._id}
                              </Link>
                            ) : (
                              <Link
                                to={'/app/cash-register/' + item._id + '/view'}
                              >
                                {item._id}
                              </Link>
                            ),
                            getProp(item, 'name', '-'),
                            getProp(
                              this.state.venuesListAssoc[item.venue_id],
                              'name',
                            ),
                            this.props.remoteAccess ? (
                              <span>
                                <Link
                                  to={'/app/remote-access/' + item._id}
                                  className="btn btn-primary-o btn-circle"
                                >
                                  <Icon name="custom-vzdialena-sprava" />
                                </Link>
                              </span>
                            ) : (
                              <span>
                                <Link
                                  to={'/app/cash-register/' + item._id}
                                  className="btn btn-primary-o btn-circle"
                                >
                                  <Icon name="edit" />
                                </Link>
                                &nbsp;
                                <button
                                  onClick={this.onClickDeletecashRegister.bind(
                                    this,
                                    item,
                                  )}
                                  className="btn btn-danger-o btn-circle"
                                  title={__('Zmazať')}
                                >
                                  <Icon name="remove" />
                                </button>
                              </span>
                            ),
                          ];
                        })}
                        classNames={[null, null, null, 'text-right']}
                      />
                      <h4
                        className={cx('text-center', {
                          hidden:
                            this.state.getVenuesLoader === true ||
                            items.length > 0,
                        })}
                      >
                        {__('Žiadne pokladne')}
                      </h4>
                    </div>
                  );
                }}
              </DataFilter>
            </Loader>

            {!this.props.remoteAccess && (
              <ProtectedContent resource="cash_register" action="create">
                <FloatingActionButton
                  linkTo="/app/cash-register/add"
                  title={__('Pridať pokladňu')}
                />
              </ProtectedContent>
            )}
            <div className="cleaner" />
          </Content>
        </div>
      );
    }
  }
}

List.defaultProps = {
  remoteAccess: false,
};

export default connect(state => ({}))(List);
