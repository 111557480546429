import Cookies from 'js-cookie';
import React from 'react';
import { connect } from 'react-redux';
import Api from '../service/Api';
import { getProp, setProp } from '../utilities/common';
import { setFilter, setUser } from './../actions';
import Loader from './../component/loader';
import { Acl } from './../';

class AppAuthorized extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      init: true,
    };

    const initPromises = [];
    initPromises.push(Api.get('/user/acl'));
    initPromises.push(
      Api.get('/user').then(user => {
        return Promise.all([
          Api.get('/user/company'),
          Api.get('/user/license'),
        ]).then(responses => {
          const [company, license] = responses;

          user.company = company;
          user.license = license;

          this.props.dispatch(setUser(user));
        });
      }),
    );

    initPromises.push(
      Api.get('/user/ui/global-filter').then(response => {
        this.props.dispatch(setFilter(response));
      }),
    );

    initPromises.push(
      Api.get('/user/acl').then(response => {
        Acl.acl = response;
        //global.Acl.bm.data_evaluation.enabled = false;
        //global.Acl.bm.paragon_import.enabled = false;
        //global.Acl.bm.filters_employee.enabled = true;
        //global.Acl.bm.comparing.enabled = false;
        //global.Acl.cash_register.setup = false;
        //global.Acl.cash_register.twig_license_key_manage = false;

        //global.Acl.bm.comparing.enabled = false;
        // Mozno odkomentovat pre maximalne prava - testovanie API
        /*global.Acl = {
                company: {
                    owners: {
                        __all__: false,
                        create: true,
                        update: true,
                        'delete': true,
                        set_role_owner: true,
                        set_role_manager: true,
                        set_role_employee: true
                    },
                    managers: {
                        __all__: false,
                        create: true,
                        update: true,
                        'delete': true,
                        set_role_owner: true,
                        set_role_manager: true,
                        set_role_employee: true
                    },
                    employees: {
                        __all__: false,
                        create: true,
                        update: true,
                        'delete': true,
                        set_role_owner: true,
                        set_role_manager: true,
                        set_role_employee: true
                    }
                }
            }*/
      }),
    );

    Promise.all(initPromises).then(() => {
      // Set defaults
      let filter = this.props.filter;
      let currency = getProp(filter, 'finance.currency');
      if (!currency) {
        filter = setProp(
          filter,
          'finance.currency',
          getProp(this.props.user, 'company.currency'),
        );
        this.props.dispatch(setFilter(filter));
      }

      this.setState({ init: false });
    });
  }

  componentDidMount() {
    document.documentElement.classList.remove('scroll');
  }

  componentWillUnmount() {
    document.documentElement.classList.add('scroll');
  }

  render() {
    if (this.state.init === true) {
      return <Loader show />;
    } else {
      return this.props.children;
    }
  }

  static logout() {
    Cookies.remove('token');
    let d = new Date();
    window.location = '/login?ts=' + d.getTime();
  }
}

export default connect(state => ({
  user: state.user,
  filter: state.filter,
  filterShow: state.filterShow,
  menuVisible: state.menuVisible,
}))(AppAuthorized);
