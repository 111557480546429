import React from 'react';
import { Link } from 'react-router';
import { isAllowed, __ } from '../utilities/common';
import Icon from './icon';

const ProtectedContent = props => {
  if (isAllowed(props.resource, props.action)) {
    if (props.children.length > 0) {
      throw new Error(
        'Component ProtectedContent must contain exactly one or no react components.',
      );
    } else {
      return props.children || null;
    }
  } else {
    let notAvailableEl = (
      <div className="feature-not-available">
        <Icon name="sad" className="shake" />
        <h2 className="title">
          {props.msg === null
            ? __('Hopla! Táto funkcia je dostupná len pre vyššie licencie')
            : props.msg}
        </h2>
        <Link to="/app/licenses" className="btn btn-primary">
          {__('Upgrade')}
        </Link>
      </div>
    );

    if (props.showWarning) {
      if (props.overlay) {
        return (
          <div className="feature-not-available-overlay">
            <div className="feature-not-available-overlay-content">
              {props.children}
            </div>
            {notAvailableEl}
          </div>
        );
      } else {
        return notAvailableEl;
      }
    } else {
      return null;
    }
  }
};

ProtectedContent.defaultProps = {
  action: null,
  showWarning: false,
  overlay: false,
  msg: null,
};

export default ProtectedContent;
