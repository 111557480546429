import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './color-palette.scss';

class ColorPalette extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickColor(color) {
    this.props.onChange(color);
  }

  render() {
    return (
      <div className="color-pallete">
        {this.props.colors.map((color, idx) => {
          return (
            <span
                key={idx}
              className={cx('clickable color-pallete-color', {
                active: this.props.value === color,
              })}
              style={{ backgroundColor: color }}
              onClick={this.onClickColor.bind(this, color)}
            />
          );
        })}
      </div>
    );
  }
}

ColorPalette.defaultProps = {
  onChange: () => {},
  value: '',
  colors: ['#000000', '#808080', '#ffffff'],
};

ColorPalette.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  colors: PropTypes.array,
};

export default ColorPalette;
