import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Api from '../../service/Api';
import { getProp, __, setProp } from '../../utilities/common';
import { setAlert } from './../../actions';
import Loader from './../../component/loader';
import Datepicker from '../../component/form/datepicker';
import moment from 'moment';
import cx from 'classnames';
import Checkbox from '../../component/form/checkbox';

class FormEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      otaUpdate: {},
      otaUpdateId: props.params.id,
      loadOtaUpdateLoader: false,
      saveOtaUpdateLoader: false,
      ecrGroups: [],
      loadEcrGroupsLoader: false,
      ecrGroupId: '',
      ecrSns: [],
      ecrSnsTmp: [],
      formErrors: {},
    };
  }

  componentDidMount() {
    this.loadOtaUpdate();
    this.loadEcrGroups();
  }

  loadOtaUpdate = () => {
    const { dispatch } = this.props;
    const { otaUpdateId } = this.state;

    this.setState(
      {
        loadOtaUpdateLoader: true,
      },
      () => {
        Api.get(`/admin/otaupdates/${otaUpdateId}`)
          .then(response => {
            let newState = this.state;

            const availability = getProp(response, 'availability', null);

            const otaUpdate = {
              id: response._id,
              date: availability ? moment(availability) : null,
              time: availability ? moment(availability).format('HH:mm') : '',
              active: getProp(response, 'active', false),
              icmVersionMin: getProp(response, 'icm_version_min', []).join('.'),
              ecrVersionMin: getProp(response, 'ecr_version_min', []).join('.'),
              ecrSns: getProp(response, 'ecrsns', []).join('\n'),
              fileName: getProp(response, 'filename', ''),
            };

            newState.loadOtaUpdateLoader = false;
            newState.otaUpdate = otaUpdate;

            this.setState(newState);
          })
          .catch(response => {
            dispatch(
              setAlert({
                type: 'error',
                title: __('Nebolo možné načítať detail aktualizácie'),
                onConfirm: () => dispatch(setAlert(null)),
              }),
            );

            this.setState({
              loadOtaUpdateLoader: false,
            });
          });
      },
    );
  };

  loadEcrGroups = () => {
    const { dispatch } = this.props;

    this.setState(
      {
        loadEcrGroupsLoader: true,
      },
      () => {
        Api.get('/admin/ecrgroups')
          .then(response => {
            this.setState({
              loadEcrGroupsLoader: false,
              ecrGroups: getProp(response, 'ecrgroups', []),
              ecrSns: getProp(response, 'ecrgroups', []).reduce(
                (accumulator, currentValue) => {
                  return { ...accumulator, [currentValue._id]: currentValue };
                },
                {},
              ),
            });
          })
          .catch(response => {
            dispatch(
              setAlert({
                type: 'error',
                title: __('Nebolo možné načítať ECR skupiny'),
                onConfirm: () => dispatch(setAlert(null)),
              }),
            );

            this.setState({
              loadEcrGroupsLoader: false,
            });
          });
      },
    );
  };

  onChangeOtaUpdateProp = (prop, e, isCheckbox = false) => {
    const otaUpdate = setProp(
      this.state.otaUpdate,
      prop,
      isCheckbox ? e.target.checked : e.target.value,
    );

    this.setState({
      otaUpdate,
    });
  };

  onChangeOtaUpdateDate = date => {
    const otaUpdate = setProp(
      this.state.otaUpdate,
      'date',
      date ? moment(date) : null,
    );

    if (date === null) {
      setProp(otaUpdate, 'time', '');
    }

    this.setState({
      otaUpdate,
    });
  };

  onChangeEcrGroup = e => {
    this.setState(
      {
        ecrGroupId: e.target.value,
      },
      () => {
        const { ecrGroupId, ecrSns, otaUpdate } = this.state;
        let otaUpdateEcrSns = getProp(otaUpdate, 'ecrSns', '');
        const ecrSnsTmp = getProp(ecrSns, `${ecrGroupId}.ecrsns`, []);

        if (ecrSnsTmp.length > 0) {
          otaUpdateEcrSns += '\n' + ecrSnsTmp.join('\n');
        }

        const newOtaUpdate = setProp(otaUpdate, 'ecrSns', otaUpdateEcrSns);

        this.setState({
          otaUpdate: newOtaUpdate,
        });
      },
    );
  };

  getOtaUpdateProp = (prop, defaultValue = '') => {
    const { otaUpdate } = this.state;

    return getProp(otaUpdate, prop, defaultValue);
  };

  onSubmit = e => {
    e.preventDefault();

    const { dispatch } = this.props;
    const { otaUpdateId, otaUpdate } = this.state;
    const versionPattern = /^\d+\.\d+\.\d+$/;
    const {
      date,
      time,
      ecrVersionMin,
      icmVersionMin,
      ecrSns,
      active,
    } = otaUpdate;

    let err = false;
    let formErrors = {};
    let datetime = null;

    this.setState(
      {
        saveOtaUpdateLoader: true,
        formErrors,
      },
      () => {
        if (date !== null) {
          datetime = moment(date);
          datetime.startOf('date');

          // Check time
          const timePattern = /^([01]\d|2[0-3]):?([0-5]\d)$/;

          if (time.trim() !== '') {
            if (!timePattern.test(time)) {
              formErrors.time = __('Zadajte platný čas vo formáte 00:00');
              err = true;
            } else {
              const timeParsed = time.split(':');

              datetime.hours(timeParsed[0]);
              datetime.minutes(timeParsed[1]);
            }
          }
        } else {
          formErrors.date = __('Zadajte dátum platnosti od');
          err = true;
        }

        if (ecrVersionMin.trim() !== '') {
          if (!versionPattern.test(ecrVersionMin)) {
            formErrors.ecr_version_min = __(
              'Zadajte min. ECR verziu vo formáte 0.0.0',
            );
            err = true;
          }
        }

        if (icmVersionMin.trim() !== '') {
          if (!versionPattern.test(icmVersionMin)) {
            formErrors.icm_version_min = __(
              'Zadajte min. SM verziu vo formáte 0.0.0',
            );
            err = true;
          }
        }

        if (err) {
          this.setState({
            saveOtaUpdateLoader: false,
            formErrors,
          });

          if (formErrors) {
            for (let a in formErrors) {
              if (!formErrors.hasOwnProperty(a)) continue;

              dispatch(
                setAlert({
                  type: 'warning',
                  title: formErrors[a],
                  onConfirm: () => dispatch(setAlert(null)),
                }),
              );

              break;
            }
          }

          return;
        }

        const obj = {
          availability: datetime.toISOString(),
          active,
          ecr_version_min: ecrVersionMin.split('.').map(function(x) {
            return parseInt(x, 10);
          }),
          icm_version_min: icmVersionMin.split('.').map(function(x) {
            return parseInt(x, 10);
          }),
          ecrsns: ecrSns.split('\n'),
        };

        Api.put(`/admin/otaupdates/${otaUpdateId}`, JSON.stringify(obj))
          .then(response => {
            this.setState({
              saveOtaUpdateLoader: false,
            });

            const date = getProp(response, 'availability', null);
            const newOtaUpdate = {
              ...otaUpdate,
              date: date ? moment(date) : null,
              time: date ? moment(date).format('HH:mm') : '',
              ecrSns: getProp(response, 'ecrsns', []).join('\n'),
            };

            this.setState({
              otaUpdate: newOtaUpdate,
              ecrGroupId: '',
              ecrSnsTmp: [],
            });

            this.props.onSuccess(true);
          })
          .catch(error => {
            this.setState({
              saveOtaUpdateLoader: false,
            });

            if (error.response.status === 400) {
              if (error.details.name === 'INVALID_SCHEMA') {
                const paths = getProp(error.details, 'payload.paths', []);

                for (const path of paths) {
                  const realt_path = path.split('.');

                  formErrors[realt_path[0]] = true;
                }

                this.setState({ formErrors });
              }

              dispatch(
                setAlert({
                  type: 'warning',
                  title: __('Nesprávne vyplnená položka'),
                  onConfirm: () => dispatch(setAlert(null)),
                }),
              );
            } else {
              dispatch(
                setAlert({
                  type: 'error',
                  title: __('Pri ukladaní nastala neznáma chyba'),
                  onConfirm: () => dispatch(setAlert(null)),
                }),
              );
            }
          });
      },
    );
  };

  render() {
    const {
      saveOtaUpdateLoader,
      loadOtaUpdateLoader,
      loadEcrGroupsLoader,
      ecrGroups,
      formErrors,
      ecrGroupId,
    } = this.state;

    return (
      <div>
        <h2 className="side-panel-title">{__('Úprava aktualizácie')}</h2>
        <hr />
        <Loader
          show={
            saveOtaUpdateLoader || loadOtaUpdateLoader || loadEcrGroupsLoader
          }
        >
          <form className="form-horizontal" onSubmit={this.onSubmit}>
            <fieldset className="fieldset">
              <div className="form-group">
                <label className="col-xs-4 control-label">{__('Názov')}:</label>
                <div className="col-sm-8 col-xs-8">
                  <input
                    type="text"
                    readOnly={true}
                    className="form-control"
                    placeholder={__('Názov')}
                    value={this.getOtaUpdateProp('fileName')}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col-xs-8 col-xs-offset-4 checkbox">
                  <Checkbox
                    id="ota-update-active"
                    checked={this.getOtaUpdateProp('active', false)}
                    onChange={e => {
                      this.onChangeOtaUpdateProp('active', e, true);
                    }}
                  />
                  <label htmlFor="ota-update-active">{__('Aktívny')}</label>
                </div>
              </div>
              <div
                className={cx('form-group', {
                  'has-error':
                    'availability' in formErrors || 'date' in formErrors,
                })}
              >
                <label className="col-xs-4 control-label">
                  {__('Platné od (dátum)')}:{' '}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-sm-4 col-xs-8">
                  <Datepicker
                    selected={this.getOtaUpdateProp('date', null)}
                    dateFormat="dd.MM.yyyy"
                    onChange={this.onChangeOtaUpdateDate}
                    placeholderText="01.01.2000"
                  />
                </div>
              </div>
              <div
                className={cx('form-group', {
                  'has-error':
                    'availability' in formErrors || 'time' in formErrors,
                })}
              >
                <label className="col-xs-4 control-label">
                  {__('Platné od (čas)')}:
                </label>
                <div className="col-xs-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="00:00"
                    onChange={e => {
                      this.onChangeOtaUpdateProp('time', e);
                    }}
                    value={this.getOtaUpdateProp('time')}
                  />
                </div>
              </div>
              <div
                className={cx('form-group', {
                  'has-error': 'ecr_version_min' in formErrors,
                })}
              >
                <label className="col-xs-4 control-label">
                  {__('Min. verzia ECR')}:
                </label>
                <div className="col-xs-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="0.0.0"
                    onChange={e => {
                      this.onChangeOtaUpdateProp('ecrVersionMin', e);
                    }}
                    value={this.getOtaUpdateProp('ecrVersionMin')}
                  />
                </div>
              </div>
              <div
                className={cx('form-group', {
                  'has-error': 'icm_version_min' in formErrors,
                })}
              >
                <label className="col-xs-4 control-label">
                  {__('Min. verzia SM')}:
                </label>
                <div className="col-xs-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="0.0.0"
                    onChange={e => {
                      this.onChangeOtaUpdateProp('icmVersionMin', e);
                    }}
                    value={this.getOtaUpdateProp('icmVersionMin')}
                  />
                </div>
              </div>
              <div
                className={cx('form-group', {
                  'has-error': 'ecrsns' in formErrors,
                })}
              >
                <label className="col-xs-4 control-label">
                  {__('Sériové čísla ECR')}:
                </label>
                <div className="col-sm-6 col-xs-8">
                  <textarea
                    style={{ minHeight: 200 }}
                    className="form-control"
                    placeholder={__(
                      'Uveďte každé sériové číslo na jeden riadok. Ak update platí pre každú ECR, uveďte *.',
                    )}
                    onChange={e => {
                      this.onChangeOtaUpdateProp('ecrSns', e);
                    }}
                    value={this.getOtaUpdateProp('ecrSns')}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-4 col-sm-offset-4">
                  <select
                    className="form-control"
                    value={ecrGroupId}
                    onChange={this.onChangeEcrGroup}
                    name="ecrGroupId"
                  >
                    <option value="">{__('Pridať ECR zo skupiny')}</option>
                    {ecrGroups.map((i, index) => {
                      return (
                        <option key={index} value={i._id}>
                          {i.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </fieldset>
            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                {__('Uložiť')}
              </button>
            </div>
          </form>
        </Loader>
      </div>
    );
  }
}

FormEdit.contextTypes = {
  router: PropTypes.object,
};

FormEdit.defaultProps = {};

export default connect(state => ({}))(FormEdit);
