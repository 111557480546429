import React from 'react';
import { __ } from '../../../utilities/common';
import { FormGenerator } from './formGenerator';

export class CategoryFormGenerator extends FormGenerator {
  generateInputControl(property, value, editing = false) {
    if (property === 'cat_parent_id') {
      let attrs = {};
      if (typeof value !== 'undefined') {
        attrs = { value };
      }
      if (value === null) {
        attrs = { value: '' };
      }
      return (
        <select
          className="form-control"
          onChange={this.onChange.bind(this, property)}
          {...attrs}
        >
          <option value="">{__('Žiadna')}</option>
          {this.schema.payload
            .filter(item => item.cat_id !== this.values.cat_id)
            .map(item => {
              return (
                <option key={item.cat_id} value={item.cat_id}>
                  {item.cat_name}
                </option>
              );
            })}
        </select>
      );
    } else {
      return super.generateInputControl(property, value);
    }
  }
}
