import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Api from '../../../service/Api';
import { getProp, __ } from '../../../utilities/common';
import { setAlert } from './../../../actions';
import DataTable from './../../../component/dataTable';
import Icon from './../../../component/icon';
import Loader from './../../../component/loader';
import { formatFilesize } from './../../../utilities/common';

class BackupsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backups: [],
      cashRegisterId: props.cashRegisterId,
      loader: false,
    };
  }

  componentDidMount() {
    this.loadBackups(this.props.cashRegisterId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    var nextId = nextProps.cashRegisterId;
    if (nextId !== this.props.cashRegisterId) {
      this.loadBackups(nextId);
    }
  }

  loadBackups(cashRegisterId) {
    this.setState({ loader: true });
    Api.get(`/user/cash-registers/${cashRegisterId}/backups`, { limit: 5 })
      .then(response => {
        this.setState({ loader: false, backups: response.backups });
      })
      .catch(error => {
        this.setState({ loader: false });
      });
  }

  async downloadBackup(backup_id) {
    try {
      const response = await Api.get(
        `/user/cash-registers/${
          this.state.cashRegisterId
        }/backups/${backup_id}`,
      );
      Api.redirect(response.url);
    } catch (e) {
      this.props.dispatch(
        setAlert({
          type: 'error',
          title: __('Stiahnutie zálohy zlyhalo'),
          onConfirm: () => {
            this.props.dispatch(setAlert(null));
          },
        }),
      );
    }
  }

  restoreBackup(backup_id) {
    Api.post(
      `/user/cash-registers/${
        this.state.cashRegisterId
      }/backups/${backup_id}/restore`,
    )
      .then(response => {
        this.props.dispatch(
          setAlert({
            type: 'success',
            title: __('Záloha bude obnovená'),
            onConfirm: () => {
              this.props.dispatch(setAlert(null));
              this.props.updateUpdatesList(); // updatne updateList
            },
          }),
        );
      })
      .catch(error => {
        this.props.dispatch(
          setAlert({
            type: 'error',
            title: __('Obnovenie zálohy zlyhalo'),
            onConfirm: () => {
              this.props.dispatch(setAlert(null));
            },
          }),
        );
      });
  }

  restoreBackupConfirmation(backup_id) {
    this.props.dispatch(
      setAlert({
        type: 'warning',
        title: __('Prajete si obnoviť zálohu?'),
        showCancelButton: true,
        cancelButtonText: __('Zrušiť'),
        confirmButtonText: 'Obnoviť zálohu',
        showLoaderOnConfirm: true,
        onConfirm: () => {
          this.restoreBackup(backup_id);
        },
        onCancel: () => {
          this.props.dispatch(setAlert(null));
        },
      }),
    );
  }

  render() {
    return (
      <div className="panel panel-secondary">
        <div className="panel-heading panel-separated">
          {__('Zoznam záloh')}
        </div>
        <div className="panel-body panel-separated min-height-loader">
          <Loader show={this.state.loader}>
            <DataTable
              cols={[__('Dátum a čas'), __('Veľkosť'), '']}
              rows={this.state.backups.map(backup => {
                let datetimeCol = moment(backup.datetime).format(
                  'DD.MM.YYYY HH:mm',
                );
                const status = getProp(backup, 'status');
                if (status === 'NOT_RESTORED') {
                  datetimeCol = (
                    <span>
                      {datetimeCol}{' '}
                      <span className="text-danger">
                        ({__('neaplikovaná')})
                      </span>
                    </span>
                  );
                } else if (status === 'RESTORE_ERROR') {
                  const statusDesc = getProp(backup, 'status_description', []);
                  datetimeCol = (
                    <span>
                      {datetimeCol}{' '}
                      <span className="text-danger">
                        ({statusDesc.join(', ')})
                      </span>
                    </span>
                  );
                }
                return [
                  datetimeCol,
                  formatFilesize(backup.size),
                  <span>
                    <div
                      onClick={this.downloadBackup.bind(this, backup._id)}
                      className="btn btn-primary-o btn-circle"
                    >
                      <Icon name="download" />
                    </div>
                    &nbsp;
                    <div
                      onClick={this.restoreBackupConfirmation.bind(
                        this,
                        backup._id,
                      )}
                      className="btn btn-primary-o btn-circle"
                      title={__('Obnoviť zálohu')}
                    >
                      <Icon name="restart" />
                    </div>
                  </span>,
                ];
              })}
              classNames={[null, null, 'text-right text-nowrap']}
            />
            <h4
              className={cx('text-center', {
                hidden:
                  this.state.loadingOrders === true ||
                  this.state.backups.length > 0,
              })}
            >
              {__('Žiadne zálohy')}
            </h4>
          </Loader>
          <div className="text-right">
            <Link
              to={`/app/cash-register/${this.props.cashRegisterId}/backups`}
              className={cx('btn btn-primary', {
                hidden:
                  this.state.loader === false &&
                  this.state.backups.length === 0,
              })}
            >
              {__('Zobraziť všetky')}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({}))(BackupsList);
