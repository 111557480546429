import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Api from '../service/Api';
import { getProp, setProp, __ } from '../utilities/common';
import { setAlert } from './../actions';
import Loader from './../component/loader';
import { locale } from '../locale';

class Registration extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      user: {
        company: {
          address: {
            country: 'SK',
          },
        },
      },
      loading: false,
      formError: {},
      agree: false,
    };
    this.context = context;
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangeUserProp(prop, ev) {
    let user = this.state.user;
    const formError = this.state.formError;
    if (prop in formError) {
      delete formError[prop];
    }
    user = setProp(user, prop, ev.target.value);
    this.setState({ user: user });
  }

  getUserProp(prop, defaultValue = '') {
    return getProp(this.state.user, prop, defaultValue);
  }

  onSubmit(ev) {
    ev.preventDefault();

    if (!this.state.agree) {
      this.props.dispatch(
        setAlert({
          type: 'warning',
          title: __('Musíte súhlasiť s obchodnými podmienkami'),
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
      return;
    }

    if (this.getUserProp('password') !== this.getUserProp('password2')) {
      this.props.dispatch(
        setAlert({
          type: 'warning',
          title: __('Heslá sa nezhodujú'),
          onConfirm: () => this.props.dispatch(setAlert(null)),
        }),
      );
    } else {
      this.setState({ loading: true });
      const user = Object.assign({}, this.state.user);
      if (user.phones) {
        user.phones = [user.phones];
      } else {
        user.phones = [];
      }
      delete user['password2'];
      Api.post('/users/registration', JSON.stringify(user))
        .then(response => {
          this.setState({ loading: false });
          this.props.dispatch(
            setAlert({
              type: 'success',
              title: __('Registrácia úspešná'),
              onConfirm: () => {
                this.props.dispatch(setAlert(null));
                this.context.router.push('/login');
              },
            }),
          );
        })
        .catch(error => {
          this.setState({ loading: false });
          if (error.response.status === 400) {
            let msg = '';
            if (error.details.name === 'INVALID_SCHEMA') {
              const formError = this.state.formError;
              const paths = getProp(error.details, 'payload.paths', []);
              for (const path of paths) {
                formError[path] = true;
              }
              this.setState({ formError });
              msg = __('Nesprávne vyplnená položka');
            } else if (error.details.name === 'INVALID_VAT') {
              msg = __('Zadané IčDPH nie je platné');
            } else if (error.details.name === 'VAT_VALIDATION_FAILED') {
              msg = __('Nepodarilo sa overiť IčDPH, skúste registráciu neskôr');
            } else if (error.details.name === 'INVALID_VAT_COMPANY_NAME') {
              msg =
                __('Prekontrolujte prosím názov Vašej spoločnosti. Správne: ') +
                getProp(error.details, 'payload.company_name');
            }

            if (msg) {
              this.props.dispatch(
                setAlert({
                  type: 'warning',
                  title: msg,
                  onConfirm: () => this.props.dispatch(setAlert(null)),
                }),
              );
            }
          } else if (error.response.status === 409) {
            if (error.details.name === 'USER_ALREADY_EXISTS') {
              this.props.dispatch(
                setAlert({
                  type: 'warning',
                  title: __('Email už existuje'),
                  onConfirm: () => this.props.dispatch(setAlert(null)),
                }),
              );
            } else if (error.details.name === 'COMPANY_ALREADY_EXISTS') {
              this.props.dispatch(
                setAlert({
                  type: 'warning',
                  title: __('Firma s týmto IČOm už existuje'),
                  onConfirm: () => this.props.dispatch(setAlert(null)),
                }),
              );
            }
          } else {
            this.props.dispatch(
              setAlert({
                type: 'error',
                title: __('Pri registrácii nastala neznáma chyba'),
                onConfirm: () => this.props.dispatch(setAlert(null)),
              }),
            );
          }
        });
    }
  }

  getFormGroupInput(name, label, inputProps) {
    return (
      <div
        className={cx('form-group', {
          'has-error': name in this.state.formError,
        })}
      >
        <label htmlFor={name}>{label}</label>
        <input
          id={name}
          type="text"
          className="form-control"
          onChange={this.onChangeUserProp.bind(this, name)}
          value={this.getUserProp(name)}
          {...inputProps}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="container">
        <div className="registration-form login-form panel panel-secondary">
          <div className="panel-heading">{__('Registrácia')}</div>
          <div className="panel-body">
            <Loader show={this.state.loading}>
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-sm-4">
                    <fieldset className="fieldset fieldset-root">
                      <legend>{__('Údaje o osobe')}</legend>
                      {this.getFormGroupInput('forename', __('Meno'), {
                        required: true,
                      })}
                      {this.getFormGroupInput('surname', __('Priezvisko'), {
                        required: true,
                      })}
                      {this.getFormGroupInput('email', __('Email'), {
                        required: true,
                      })}
                      {this.getFormGroupInput('password', __('Heslo'), {
                        required: true,
                        type: 'password',
                      })}
                      {this.getFormGroupInput('password2', __('Heslo znova'), {
                        required: true,
                        type: 'password',
                      })}
                      {this.getFormGroupInput('phones', __('Telefón'), {
                        placeholder: __('nepovinné'),
                      })}
                    </fieldset>
                  </div>
                  <div className="col-sm-4">
                    <fieldset className="fieldset fieldset-root">
                      <legend>{__('Údaje o firme')}</legend>
                      {this.getFormGroupInput(
                        'company.company_name',
                        __('Názov'),
                        { required: true },
                      )}
                      {this.getFormGroupInput('company.regnr', __('IČO'), {
                        required: true,
                      })}
                      {this.getFormGroupInput('company.taxid', __('DIČ'), {
                        placeholder: __('nepovinné'),
                      })}
                      {this.getFormGroupInput('company.vatid', __('IčDPH'), {
                        placeholder: __('nepovinné'),
                      })}
                    </fieldset>
                  </div>
                  <div className="col-sm-4">
                    <fieldset className="fieldset fieldset-root">
                      <legend>{__('Adresa firmy')}</legend>
                      {this.getFormGroupInput(
                        'company.address.street',
                        __('Ulica'),
                        { required: true },
                      )}
                      {this.getFormGroupInput(
                        'company.address.number',
                        __('Číslo'),
                        { required: true },
                      )}
                      {this.getFormGroupInput(
                        'company.address.city',
                        __('Mesto'),
                        { required: true },
                      )}
                      {this.getFormGroupInput(
                        'company.address.zip',
                        __('PSČ'),
                        { required: true },
                      )}
                      <div
                        className={cx('form-group', {
                          'has-error':
                            'company.address.country' in this.state.formError,
                        })}
                      >
                        <label htmlFor="company.address.country">
                          {__('Krajina')}
                        </label>
                        <select
                          id="company.address.country"
                          className="form-control"
                          onChange={this.onChangeUserProp.bind(
                            this,
                            'company.address.country',
                          )}
                          value={this.getUserProp('company.address.country')}
                        >
                          <option value="SK">{__('Slovensko')}</option>
                          <option value="CZ">{__('Česká republika')}</option>
                        </select>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="text-center">
                  <label>
                    <input
                      type="checkbox"
                      checked={this.state.agree}
                      onChange={ev =>
                        this.setState({ agree: ev.target.checked })
                      }
                    />{' '}
                    {__('Odoslaním registrácie súhlasím s')}
                  </label>{' '}
                  <a
                    href={`${process.env.REACT_APP_API_BASE_URL}/docs/vop/${
                      locale.lang
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {__('obchodnými podmienkami')}
                  </a>
                </div>
                <br />
                <div className="text-center">
                  <Link to="/login" className="btn btn-primary-o">
                    {__('Späť')}
                  </Link>
                  &nbsp;
                  <button type="submit" className="btn btn-primary">
                    {__('Registrovať')}
                  </button>
                </div>
              </form>
            </Loader>
          </div>
        </div>
      </div>
    );
  }
}

Registration.contextTypes = {
  router: PropTypes.object,
};

export default connect(state => ({}))(Registration);
